import React from 'react';
import PropTypes from 'prop-types';
import { mdiFolderOutline } from '@mdi/js';
import { Tooltip } from '@mui/material';
import { BaseTableCell } from '../../../common/tables/BaseTable';
import { LinkName, LinkWrapper, LinkIcon } from './CellLinkWithIcon';

function NameCell({ id, name, isFolder }) {
  return (
    <BaseTableCell>
      <LinkWrapper to={`/${isFolder ? 'folders' : 'risks'}/${id}`}>
        {isFolder ? <LinkIcon path={mdiFolderOutline} /> : null}
        <Tooltip placement="top" enterNextDelay={1000} title={name}>
          <LinkName>{name}</LinkName>
        </Tooltip>
      </LinkWrapper>
    </BaseTableCell>
  );
}

NameCell.propTypes = {
  id: PropTypes.string,
  isFolder: PropTypes.bool,
  name: PropTypes.string,
};

export default React.memo(NameCell);
