import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import theme from '../../../../../styles/theme';
import RiskMatrixCell from '../../../../common/charts/MatrixCell';
import RiskMatrixTooltip from './RiskMatrixTooltip';

function RiskMatrix({
  riskImpacts,
  riskCurrentLikelihoodId,
  likelihoods,
  impactValues,
  impactTargets,
}) {
  const intl = useIntl();
  const data = likelihoods.map((likelihood) => ({
    id: likelihood.value,
    data: impactValues
      .slice()
      .reverse()
      .map((impactValue) => ({
        x: impactValue.value,
        y: likelihood.value * impactValue.value,
        leftOfCenter: impactValue.value < impactValues.length / 2,
        impacts: riskImpacts
          .filter(
            (impact) =>
              impact.currentImpactValueId === impactValue.id &&
              riskCurrentLikelihoodId === likelihood.id,
          )
          .map((impact) => ({
            impactType: impact.type,
            impactTargetId: impact.impactTargetId,
          })),
      })),
  }));

  return (
    <MatrixWrapper>
      {data.length ? (
        <ResponsiveHeatMap
          data={data}
          cellComponent={RiskMatrixCell}
          margin={{ top: 0, right: 20, bottom: 40, left: 40 }}
          axisTop={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: 0,
            legend: intl.formatMessage({ id: 'IMPACTS' }),
            legendPosition: 'middle',
            legendOffset: 34,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: intl.formatMessage({ id: 'LIKELIHOODS' }),
            legendPosition: 'middle',
            legendOffset: -30,
          }}
          colors={{
            type: 'diverging',
            scheme: 'greys',
            divergeAt: 1.5,
            minValue: 1,
            maxValue: 25,
          }}
          forceSquare={true}
          borderColor={theme.arter.color.gray}
          borderWidth={1}
          emptyColor={theme.arter.color.white}
          hoverTarget="cell"
          tooltip={({ cell }) => (
            <RiskMatrixTooltip cell={cell} impactTargets={impactTargets} />
          )}
        />
      ) : null}
    </MatrixWrapper>
  );
}

RiskMatrix.propTypes = {
  riskImpacts: PropTypes.arrayOf(
    PropTypes.shape({
      currentImpactValueId: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  riskCurrentLikelihoodId: PropTypes.string,
  likelihoods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
  impactValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
  impactTargets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};

const MatrixWrapper = styled.div`
  margin-top: ${(props) => props.theme.arter.spacing.medium};
  height: calc(100% - 36px) !important;
`;

export default React.memo(RiskMatrix);
