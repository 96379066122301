import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';

const tenantId = window.location.pathname.split('/')[1];
const router = createBrowserRouter([{ path: '*', Component: AppRoutes }], {
  basename: `/${tenantId}`,
});

function App() {
  return <RouterProvider router={router} />;
}

export default App;
