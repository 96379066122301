import React from 'react';
import { useIntl } from 'react-intl';
import { useSettings } from '../../../SettingsContext';
import SettingPage from '../SettingPage';
import TagHeaderRow from './TagHeaderRow';
import TagRow from './TagRow';

function tagFormInitializer({ name }) {
  return {
    name: { initialValue: name, value: name, dirty: false },
  };
}

function Tags() {
  const intl = useIntl();
  const { tags, fetchTags, isFetchingTags } = useSettings();

  return (
    <SettingPage
      title={intl.formatMessage({ id: 'TAGS' })}
      HeaderRowComponent={TagHeaderRow}
      RowComponent={TagRow}
      formDataInitializer={tagFormInitializer}
      createFormInitialData={{ name: '' }}
      rootEndpoint="/api/v1/tags"
      settings={tags}
      fetchSettings={fetchTags}
      isFetchingSettings={isFetchingTags}
    />
  );
}

export default Tags;
