import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  mdiPlus,
  mdiTrashCanOutline,
  mdiShieldAccountOutline,
  mdiFolderMoveOutline,
  mdiFileExcelOutline,
} from '@mdi/js';
import { Header, HeaderTools, HeaderToolsDropdown } from '../header/Header';
import { DropdownItem, DropdownItemIcon } from '../../common/Dropdown';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import SaveButton from '../../common/buttons/SaveButton';
import CancelButton from '../../common/buttons/CancelButton';
import ButtonIcon from '../../common/buttons/ButtonIcon';
import FolderNameInput from './FolderNameInput';
import {
  ShowWhenFormChanged,
  HideWhenFormChanged,
  useForm,
} from '../../FormContext';
import Spinner from '../../common/Spinner';
import RenderByRightRole from '../../RenderByRightRole';
import RIGHT_ROLE from '../../../constants/right-role';
import { useFolder } from './FolderContext';
import ExportExcelButton from './excel-export/ExportExcelButton';
import CopyLinkDropdownItem from '../header/CopyLinkDropdownItem';

function FolderHeader() {
  const intl = useIntl();
  const {
    folder,
    isUpdatingRights,
    setIsCreatingRisk,
    setIsCreatingFolder,
    setIsUpdatingRights,
    setIsMovingFolder,
    setIsDeletingFolder,
    setIsSelectingRiskImportFile,
  } = useFolder();
  const { submitForm, submitting, resetChanges } = useForm();

  return (
    <Header
      breadcrumbItems={[
        {
          id: 'risks',
          name: intl.formatMessage({ id: 'RISKS' }),
          link: '/folders/root',
        },
        ...folder.breadcrumb.map((item) => ({
          ...item,
          link: `/folders/${item.id}`,
        })),
        { id: folder.id, name: folder.name },
      ]}
    >
      <FolderNameInput />
      <HeaderTools>
        {!isUpdatingRights ? (
          <ShowWhenFormChanged>
            <SaveButton onClick={submitForm} disabled={submitting}>
              <FormattedMessage id="SAVE" />
            </SaveButton>
            <CancelButton onClick={() => resetChanges()} disabled={submitting}>
              <FormattedMessage id="CANCEL" />
            </CancelButton>
            {submitting ? <Spinner /> : null}
          </ShowWhenFormChanged>
        ) : null}
        <HideWhenFormChanged>
          <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
            <PrimaryButton onClick={() => setIsCreatingRisk(true)}>
              <ButtonIcon path={mdiPlus} />
              <FormattedMessage id="CREATE_RISK" />
            </PrimaryButton>
          </RenderByRightRole>
          <HeaderToolsDropdown>
            <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
              <DropdownItem
                role="button"
                onClick={() => setIsCreatingFolder(true)}
              >
                <DropdownItemIcon path={mdiPlus} />
                <FormattedMessage id="CREATE_FOLDER" />
              </DropdownItem>
            </RenderByRightRole>
            <CopyLinkDropdownItem />
            <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
              <DropdownItem
                role="button"
                onClick={() => setIsMovingFolder(true)}
              >
                <DropdownItemIcon path={mdiFolderMoveOutline} />
                <FormattedMessage id="MOVE_TO" />
              </DropdownItem>
            </RenderByRightRole>
            <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
              <DropdownItem
                role="button"
                onClick={() => setIsSelectingRiskImportFile(true)}
              >
                <DropdownItemIcon path={mdiFileExcelOutline} />
                <FormattedMessage id="IMPORT_EXCEL" />
              </DropdownItem>
            </RenderByRightRole>
            <ExportExcelButton />
            <RenderByRightRole role={RIGHT_ROLE.ADMIN}>
              <DropdownItem
                role="button"
                onClick={() => setIsUpdatingRights(true)}
              >
                <DropdownItemIcon path={mdiShieldAccountOutline} />
                <FormattedMessage id="RIGHTS" />
              </DropdownItem>
            </RenderByRightRole>
            <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
              {folder ? (
                <DropdownItem
                  role="button"
                  onClick={() => setIsDeletingFolder(true)}
                >
                  <DropdownItemIcon path={mdiTrashCanOutline} />
                  <FormattedMessage id="DELETE_FOLDER" />
                </DropdownItem>
              ) : null}
            </RenderByRightRole>
          </HeaderToolsDropdown>
        </HideWhenFormChanged>
      </HeaderTools>
    </Header>
  );
}

export default FolderHeader;
