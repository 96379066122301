import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { slideFromTop } from '../../styles/keyframe-styles';
import useOnClickOutsideElement from '../../hooks/useOnClickOutsideElement';

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));

const DropdownContext = React.createContext();

function Dropdown({ children }) {
  const [show, setShow] = React.useState(false);
  const ref = React.useRef();

  return (
    <div ref={ref}>
      <DropdownContext.Provider value={[show, setShow, ref]}>
        {children}
      </DropdownContext.Provider>
    </div>
  );
}
Dropdown.propTypes = {
  children: PropTypes.node,
};

function DropdownToggleButton({ children: child }) {
  const [show, setShow] = React.useContext(DropdownContext);
  const childClone = React.cloneElement(child, {
    onClick: callAll(() => setShow(!show), child.props.onClick),
  });

  return <div>{childClone}</div>;
}

DropdownToggleButton.propTypes = {
  children: PropTypes.node,
};

function DropdownContent({ children }) {
  const [show, setShow, ref] = React.useContext(DropdownContext);
  useOnClickOutsideElement(ref, () => setShow(false));

  return show ? (
    <Wrapper data-testid="dropdown-content">
      <DropdownItemWrapper onClick={() => setShow(false)}>
        <ul>{children}</ul>
      </DropdownItemWrapper>
    </Wrapper>
  ) : null;
}

DropdownContent.propTypes = {
  children: PropTypes.node,
};

const Wrapper = styled.div`
  position: relative;
`;

const DropdownItemWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  min-width: 230px;
  max-width: 240px;
  padding: ${(props) => props.theme.arter.spacing.medium};
  border: ${(props) => props.theme.arter.border.main};
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  background-color: ${(props) => props.theme.arter.color.white};
  box-shadow: 0 4px 15px -8px ${(props) => props.theme.arter.color.black};
  opacity: 0;
  animation: ${slideFromTop} forwards
    ${(props) => props.theme.arter.animation.speed.normal};
`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.arter.font.size.medium};
  padding: ${(props) => props.theme.arter.spacing.medium};
  background-color: ${(props) => props.theme.arter.color.white};
  cursor: pointer;
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.veryLightGray};
  }
`;

const DropdownItemName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DropdownItemIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.arter.spacing.medium};
  min-width: ${(props) => props.theme.arter.icon.size.small};
  width: ${(props) => props.theme.arter.icon.size.small};
`;

export {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownToggleButton,
  DropdownItemIcon,
  DropdownItemName,
};
