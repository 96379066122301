import React from 'react';
import PropTypes from 'prop-types';
import EmptyCellData from './EmptyCellData';
import { BaseTableCell } from '../../../common/tables/BaseTable';

function LikelihoodCell({ likelihood, isFolder }) {
  return (
    <BaseTableCell>
      {likelihood || <EmptyCellData empty={isFolder} />}
    </BaseTableCell>
  );
}

LikelihoodCell.propTypes = {
  isFolder: PropTypes.bool,
  likelihood: PropTypes.number,
};

export default React.memo(LikelihoodCell);
