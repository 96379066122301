import React from 'react';
import styled from 'styled-components';
import { mdiAlertOutline } from '@mdi/js';
import PropTypes from 'prop-types';
import WarningIcon from './WarningIcon';

function ValidationNotification({ validationMessage }) {
  return (
    <Wrapper>
      <WarningIcon path={mdiAlertOutline} />
      <Message role="alert">{validationMessage}</Message>
    </Wrapper>
  );
}

ValidationNotification.propTypes = {
  validationMessage: PropTypes.string,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.arter.spacing.medium};
  width: 100%;
`;

const Message = styled.p`
  color: ${(props) => props.theme.arter.color.red};
  margin-left: ${(props) => props.theme.arter.spacing.small};
`;

export default ValidationNotification;
