import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import Icon from '@mdi/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { mdiTableRowRemove } from '@mdi/js';
import { useFolder } from '../FolderContext';
import {
  Modal,
  ModalButtons,
  ModalCloseButton,
  ModalContent,
  ModalContentWrap,
} from '../../../common/Modal';
import ImportFailImage from '../../../../styles/images/import-failed.svg';
import Spinner from '../../../common/Spinner';
import { useSettings } from '../../../SettingsContext';
import SheetJSONToRisksConverter from './sheet-json-to-risks-converter';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import useApi from '../../../../hooks/useApi';
import HelpNotification from '../../../common/notifications/HelpNotification';
import CancelButton from '../../../common/buttons/CancelButton';
import theme from '../../../../styles/theme';
import ValidationNotification from '../../../common/notifications/ValidationNotification';
import { useNotifications } from '../../../NotificationsContext';
import ExcelImportPreviewTable from './preview-table/ExcelImportPreviewTable';
import WarningIcon from '../../../common/notifications/WarningIcon';

function ExcelImportPreviewModal({ file, onClose }) {
  const intl = useIntl();
  const { showSuccessNotification } = useNotifications();
  const { impactTargets, likelihoods, impactValues } = useSettings();
  const { folder, fetchFolder } = useFolder();
  const [impactTargetsInUse, setImpactTargetsInUse] = React.useState();
  const [importResult, setImportResult] = React.useState(null);
  const [importError, setImportError] = React.useState(null);
  const {
    isError: isPostError,
    isSuccess: isPostSuccess,
    isLoading: isPosting,
    callEndpoint: postRisks,
  } = useApi({
    endpoint: `/api/v1/bulk/folders/${folder.id ? folder.id : 'root'}/risks`,
    method: 'POST',
  });

  React.useEffect(() => {
    async function readExcel(excel) {
      try {
        const data = await excel.arrayBuffer();
        const workbook = XLSX.read(data, {
          type: 'binary',
          dateNF: 'yyyy-mm-dd',
        });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const excelData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: '',
          raw: false,
        });
        const converter = new SheetJSONToRisksConverter(
          likelihoods,
          impactTargets,
          impactValues,
        );

        setImportResult(converter.convert(excelData));
        setImpactTargetsInUse(converter.getImpactTargetsInUse(excelData[0]));
      } catch (err) {
        setImportError(err);
      }
    }

    readExcel(file);
  }, [file, impactTargets, impactValues, likelihoods]);

  React.useEffect(() => {
    if (isPostSuccess) {
      showSuccessNotification(
        intl.formatMessage({ id: 'IMPORTED_SUCCESSFULLY' }),
      );
      fetchFolder();
    }
  }, [isPostSuccess, fetchFolder, showSuccessNotification, intl]);

  return (
    <Modal open onClose={() => onClose()} closeOnBackdropClick={!isPosting}>
      <ModalBox title={intl.formatMessage({ id: 'IMPORT_EXCEL_PREVIEW' })}>
        {importError ? (
          <ContentWrapper>
            <HelpNotification
              title={intl.formatMessage({ id: 'FAILED_TO_IMPORT' })}
              srcImage={ImportFailImage}
              infoText={intl.formatMessage({
                id: 'IMPORT_EXCEL_FAILED_MESSAGE',
              })}
            />
            <ButtonRow>
              <ModalCloseButton>
                <PrimaryButton>
                  <FormattedMessage id="CLOSE" />
                </PrimaryButton>
              </ModalCloseButton>
            </ButtonRow>
          </ContentWrapper>
        ) : null}
        {importResult ? (
          <ContentWrapper>
            <ExcelImportMessage data-testid="excel-import-status-message">
              <FormattedMessage
                id="IMPORT_EXCEL_ROW_COUNT_MESSAGE"
                values={{
                  rowCount: importResult.risks.length,
                  fileName: file.name,
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
              <StatsSpan>
                <WarningIcon />
                <FormattedMessage
                  id="IMPORT_EXCEL_ISSUE_COUNT_MESSAGE"
                  values={{
                    issueCount: importResult.issueCount,
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </StatsSpan>
              <StatsSpan>
                <Icon path={mdiTableRowRemove} />
                <FormattedMessage
                  id="IMPORT_EXCEL_SKIPPED_ROW_COUNT_MESSAGE"
                  values={{
                    skippedRowCount: importResult.invalidCount,
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </StatsSpan>
            </ExcelImportMessage>
            {importResult.risks.length ? (
              <ExcelImportPreviewTable
                impactTargetsInUse={impactTargetsInUse}
                imports={importResult.risks}
              />
            ) : null}
            {isPostError ? (
              <ValidationNotification
                validationMessage={intl.formatMessage({
                  id: 'FAILED_TO_IMPORT',
                })}
              />
            ) : null}
            <ButtonRow>
              <PrimaryButton
                onClick={() =>
                  postRisks(importResult.risks.map(({ risk }) => risk))
                }
                disabled={isPosting}
              >
                <FormattedMessage id="IMPORT" />
              </PrimaryButton>
              <ModalCloseButton>
                <CancelButton disabled={isPosting}>
                  <FormattedMessage id="CANCEL" />
                </CancelButton>
              </ModalCloseButton>
              {isPosting ? (
                <Spinner size={theme.arter.icon.size.medium} />
              ) : null}
            </ButtonRow>
          </ContentWrapper>
        ) : null}
        {!importResult && !importError ? (
          <LoadingWrap>
            <Spinner size={theme.arter.icon.size.large} />
            <LoadingText>
              <FormattedMessage id="LOADING" />
            </LoadingText>
          </LoadingWrap>
        ) : null}
      </ModalBox>
    </Modal>
  );
}

ExcelImportPreviewModal.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  onClose: PropTypes.func,
};

const ModalBox = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: none;
  min-height: 400px;
  margin-top: 0px;
  align-self: center;

  @media ${(props) => props.theme.arter.device.pad} {
    min-width: 400px;
  }
`;

const ContentWrapper = styled(ModalContentWrap)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
`;

const LoadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const LoadingText = styled.p`
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  margin-top: ${(props) => props.theme.arter.spacing.medium};
`;

const ButtonRow = styled(ModalButtons)`
  margin-top: auto;
  padding-top: ${(props) => props.theme.arter.spacing.extraLarge};
`;

const ExcelImportMessage = styled.p`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};

  svg {
    width: ${(props) => props.theme.arter.icon.size.small};
    margin-right: ${(props) => props.theme.arter.spacing.small};
  }

  b {
    margin: 0 ${(props) => props.theme.arter.spacing.small};

    &:first-child {
      color: ${(props) => props.theme.arter.color.product.default};
    }
  }
`;

const StatsSpan = styled.span`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 ${(props) => props.theme.arter.spacing.mediumLarge} 0
      ${(props) => props.theme.arter.spacing.medium};
    background-color: ${(props) => props.theme.arter.color.product.default};
    border-radius: ${(props) => props.theme.arter.border.radius.full};
    opacity: 0.4;
  }
`;

export default ExcelImportPreviewModal;
