import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import ClearButton from '../../../../common/buttons/ClearButton';
import FormLabel from '../../../../common/FormLabel';
import FilterMenuChipSelect from '../FilterMenuChipSelect';

function FilterChipSelect({
  label,
  htmlFor,
  options,
  selectedOptions,
  onChange,
  onClear,
  ...props
}) {
  const intl = useIntl();

  return (
    <Wrapper {...props}>
      <Header>
        <Label htmlFor={htmlFor}>{label}</Label>
        {selectedOptions.length ? (
          <ClearButton onClick={() => onClear()}>
            <FormattedMessage id="CLEAR" />
          </ClearButton>
        ) : null}
      </Header>
      <FilterMenuChipSelect
        options={options}
        id={htmlFor}
        selectedOptions={selectedOptions.map((id) =>
          options.find((option) => option.id === id),
        )}
        onChange={(selected) => onChange(selected.map(({ id }) => id))}
        placeholderText={`${intl.formatMessage({ id: 'ADD' })}...`}
      />
    </Wrapper>
  );
}

FilterChipSelect.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.arter.spacing.large};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 28px;
`;

const Label = styled(FormLabel)`
  align-self: flex-end;
`;

export default FilterChipSelect;
