import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FilterChipSelect from './FilterChipSelect';
import { useKeycloak } from '../../../../KeycloakContext';

function ResponsibleFilter({ selectedResponsible, onChange, onClear }) {
  const intl = useIntl();
  const { userMap } = useKeycloak();

  return (
    <FilterChipSelect
      data-testid="all-filters-responsible-filter"
      label={intl.formatMessage({ id: 'RESPONSIBLE' })}
      htmlFor="responsible-select"
      options={Array.from(userMap.values()).map(
        ({ id, firstName, lastName }) => ({
          id,
          name: `${lastName}, ${firstName}`,
        }),
      )}
      selectedOptions={selectedResponsible}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

ResponsibleFilter.propTypes = {
  selectedResponsible: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default ResponsibleFilter;
