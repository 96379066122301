import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import theme from '../styles/theme';
import { useForm } from './FormContext';
import { popFromBottom } from '../styles/keyframe-styles';
import SaveButton from './common/buttons/SaveButton';
import CancelButton from './common/buttons/CancelButton';
import Spinner from './common/Spinner';
import ButtonRow from './common/ButtonRow';

function FormEditBar() {
  const { submitForm, submitting, resetChanges } = useForm();

  return (
    <>
      <EditToolbar>
        <SaveButton onClick={submitForm} disabled={submitting}>
          <FormattedMessage id="SAVE" />
        </SaveButton>
        <CancelButton onClick={() => resetChanges()} disabled={submitting}>
          <FormattedMessage id="CANCEL" />
        </CancelButton>
        {submitting ? <Spinner size={theme.arter.icon.size.medium} /> : null}
      </EditToolbar>
    </>
  );
}

const EditToolbar = styled(ButtonRow)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  border-top: ${(props) => props.theme.arter.border.main};
  padding: ${(props) =>
    `${props.theme.arter.spacing.mediumLarge} ${props.theme.arter.spacing.large}`};
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.1);
  animation: ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  @media ${(props) => props.theme.arter.device.pad} {
    position: sticky;
    width: 100%;
    flex: 0 0 100%;
    z-index: 3;
  }
`;

export default FormEditBar;
