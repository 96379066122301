import React from 'react';
import PropTypes from 'prop-types';
import REALM_ROLE from '../constants/realm-role';
import RIGHT_ROLE from '../constants/right-role';
import { useKeycloak } from './KeycloakContext';

const RightsContext = React.createContext();

const RIGHT_ROLE_HIERARCHY = {
  [RIGHT_ROLE.ADMIN]: 3,
  [RIGHT_ROLE.MODIFIER]: 2,
  [RIGHT_ROLE.VIEWER]: 1,
};

function RightsProvider({ rights, ...props }) {
  const { hasRole, userGroups } = useKeycloak();

  function hasRight(requiredRole) {
    const hasRights = rights.groups.some(
      (right) =>
        RIGHT_ROLE_HIERARCHY[right.role] >=
          RIGHT_ROLE_HIERARCHY[requiredRole] && userGroups.includes(right.id),
    );

    return hasRole(REALM_ROLE.RISKS_ADMIN) || hasRights;
  }

  function hasModifierRight() {
    return hasRight(RIGHT_ROLE.MODIFIER);
  }

  return (
    <RightsContext.Provider value={{ rights, hasRight, hasModifierRight }}>
      {props.children}
    </RightsContext.Provider>
  );
}

RightsProvider.propTypes = {
  rights: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  children: PropTypes.node,
};

function useRights() {
  const context = React.useContext(RightsContext);
  if (!context) {
    throw new Error(`useRights must be used within a RightsProvider`);
  }
  return context;
}

export { RightsProvider, useRights };
