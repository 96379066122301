import React from 'react';
import MoveInFolderTreeModal from '../../MoveInFolderTreeModal';
import { useFolder } from './FolderContext';
import { useFolderTree } from '../../FolderTreeContext';

function MoveFolderModal() {
  const { folder, fetchFolder, isMovingFolder, setIsMovingFolder } =
    useFolder();
  const { update: updateFolderTree } = useFolderTree();

  return isMovingFolder ? (
    <MoveInFolderTreeModal
      openedFolders={folder.breadcrumb.map((item) => item.id)}
      disabledFolders={[folder.id]}
      patchEndpoint={`/api/v1/folders/${folder.id}`}
      patchAttribute="parentFolderId"
      onClose={() => setIsMovingFolder(false)}
      onSuccess={() => {
        updateFolderTree();
        fetchFolder();
      }}
    />
  ) : null;
}

export default MoveFolderModal;
