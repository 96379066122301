import MainTitle from './MainTitle';
import SectionTitle from './SectionTitle';
import Blockquote from './Blockquote';
import NavigationContainer from './NavigationContainer';
import NavItem from './NavItem';
import Wrapper from './Wrapper';
import Content from './Content';

const components = {
  h1: MainTitle,
  h2: SectionTitle,
  blockquote: Blockquote,
  NavItem,
  Navigation: NavigationContainer,
  ContentWrapper: Wrapper,
  Content,
};

export default components;
