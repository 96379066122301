import React from 'react';
import { useIntl } from 'react-intl';
import { FormProvider } from '../../FormContext';
import { useFolderTree } from '../../FolderTreeContext';
import { useNotifications } from '../../NotificationsContext';
import { useFolder } from './FolderContext';

function formForFolder({ name }) {
  return {
    name: { initialValue: name, value: name, dirty: false },
  };
}

function FolderForm(props) {
  const intl = useIntl();
  const { folder } = useFolder();
  const { update: updateFolderTree } = useFolderTree();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  return (
    <FormProvider
      initialData={folder}
      formDataInitializer={formForFolder}
      endpoint={`/api/v1/folders/${folder.id}`}
      method="PATCH"
      onSuccess={() => {
        updateFolderTree();
        showSuccessNotification(intl.formatMessage({ id: 'CHANGES_SAVED' }));
      }}
      onError={() =>
        showErrorNotification(
          intl.formatMessage({
            id: 'FAILED_TO_SAVE_CHANGES',
          }),
        )
      }
      {...props}
    />
  );
}

export default FolderForm;
