/* eslint-disable no-param-reassign */
import React from 'react';

function useAutosizeTextArea(ref, value) {
  React.useLayoutEffect(() => {
    ref.current.style.height = 'inherit';
    ref.current.style.height = `${ref.current.scrollHeight}px`;
  }, [ref, value]);
}

export default useAutosizeTextArea;
