import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StateFilter from './StateFilter';
import TagsFilter from './TagsFilter';
import NameSearch from './NameSearch';
import IAmResponsibleFilter from './IAmResponsibleFilter';
import NoSubfoldersFilter from './NoSubfoldersFilter';
import AllFiltersButton from './AllFiltersButton';
import ClearAllFiltersButton from './ClearAllFiltersButton';
import useDeviceMediaQuery from '../../../../hooks/useDeviceMediaQuery';
import { useFolderFilter } from './FolderFilterContext';

function FolderFilterRow({ risks, folders }) {
  const { isDesktop, isDesktopMedium } = useDeviceMediaQuery();
  const { clearAllFilters, anyFiltersApplied } = useFolderFilter();

  return (
    <Wrapper data-testid="folder-filter-row">
      <NameSearch risks={risks} folders={folders} />
      <FiltersWrapper>
        {isDesktopMedium ? (
          <>
            <StateFilter />
            <TagsFilter />
          </>
        ) : null}
        {isDesktop ? (
          <>
            <IAmResponsibleFilter />
            <NoSubfoldersFilter />
          </>
        ) : null}
        <AllFiltersButton />
        {isDesktop && anyFiltersApplied ? (
          <ClearAllFiltersButton onClear={clearAllFilters} />
        ) : null}
      </FiltersWrapper>
    </Wrapper>
  );
}

FolderFilterRow.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  risks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .MuiInputBase-root,
  button {
    margin-right: ${(props) => props.theme.arter.spacing.small};
    margin-left: 0;
  }
`;

export default FolderFilterRow;
