import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import EmptyCellData from './EmptyCellData';
import { BaseTableCell } from '../../../common/tables/BaseTable';
import { useKeycloak } from '../../../KeycloakContext';

function ResponsibleCell({ responsible, isFolder }) {
  const { userMap } = useKeycloak();
  const intl = useIntl();

  const users =
    responsible && responsible.users.length
      ? responsible.users
          .map((id) => {
            const user = userMap.get(id);
            return user
              ? `${user.lastName} ${user.firstName}`
              : intl.formatMessage({ id: 'DELETED_USER' });
          })
          .join(', ')
      : null;

  return (
    <BaseTableCell>
      {responsible && responsible.users.length ? (
        <Tooltip placement="top" enterNextDelay={1000} title={users}>
          <Responsibles>{users}</Responsibles>
        </Tooltip>
      ) : (
        <EmptyCellData empty={isFolder} />
      )}
    </BaseTableCell>
  );
}

ResponsibleCell.propTypes = {
  isFolder: PropTypes.bool,
  responsible: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.string),
  }),
};

const Responsibles = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

export default React.memo(ResponsibleCell);
