import PropTypes from 'prop-types';
import React from 'react';
import { mdiDice5Outline } from '@mdi/js';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import FormGroup from '../../common/FormGroup';
import FormLabel from '../../common/FormLabel';
import Select from '../../common/Select';
import Spinner from '../../common/Spinner';
import IconWithBackground from '../../common/IconWithBackground';
import { useForm } from '../../FormContext';
import { useRights } from '../../RightsContext';

function RiskLikelihoods({ likelihoods }) {
  const intl = useIntl();
  const { hasModifierRight } = useRights();
  const { state, dispatchFormChange } = useForm();

  return (
    <Wrapper data-testid="risk-likelihood-card" disabled={!hasModifierRight()}>
      <Header>
        <IconWithBackground iconPath={mdiDice5Outline} />
        <Title>{intl.formatMessage({ id: 'LIKELIHOOD' })}</Title>
      </Header>
      {likelihoods ? (
        <Likelihoods>
          <SelectWrapper>
            <FormLabel htmlFor="current-likelihood">
              <FormattedMessage id="CURRENT" />
            </FormLabel>
            <Select
              id="current-likelihood"
              value={state.currentLikelihoodId.value}
              onChange={(event) =>
                dispatchFormChange({
                  field: 'currentLikelihoodId',
                  value: event.target.value,
                })
              }
            >
              <option value="">{intl.formatMessage({ id: 'NOT_SET' })}</option>
              {likelihoods.map((likelihood) => (
                <option key={likelihood.id} value={likelihood.id}>
                  {likelihood.name}
                </option>
              ))}
            </Select>
          </SelectWrapper>
          <SelectWrapper>
            <FormLabel htmlFor="target-likelihood">
              <FormattedMessage id="TARGET" />
            </FormLabel>
            <Select
              id="target-likelihood"
              disabled={!hasModifierRight()}
              value={state.targetLikelihoodId.value}
              onChange={(event) =>
                dispatchFormChange({
                  field: 'targetLikelihoodId',
                  value: event.target.value,
                })
              }
            >
              <option value="">{intl.formatMessage({ id: 'NOT_SET' })}</option>
              {likelihoods.map((likelihood) => (
                <option key={likelihood.id} value={likelihood.id}>
                  {likelihood.name}
                </option>
              ))}
            </Select>
          </SelectWrapper>
        </Likelihoods>
      ) : (
        <Spinner size={theme.arter.icon.size.medium} />
      )}
    </Wrapper>
  );
}

RiskLikelihoods.propTypes = {
  likelihoods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
};

const Wrapper = styled.fieldset`
  position: relative;
  flex: 1;
  background-color: ${(props) => props.theme.arter.color.white};
  max-height: 240px;
  max-width: 500px;
  height: auto;
  border: 1px solid ${(props) => props.theme.arter.color.lightGray};
  padding: ${(props) =>
    `${props.theme.arter.spacing.large} ${props.theme.arter.spacing.large}`};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  margin-bottom: ${(props) => props.theme.arter.spacing.large};
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover:enabled {
    box-shadow: 0 3px 20px -13px ${(props) => props.theme.arter.color.black};
  }

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    max-width: 250px;
    min-width: 180px;
    margin-bottom: 0;
    margin-right: ${(props) => props.theme.arter.spacing.medium};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};
`;

const Title = styled.h4`
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

const Likelihoods = styled.div`
  position: relative;
  z-index: 1;
  display: flex;

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    flex-direction: column;
    width: 100%;
  }
`;

const SelectWrapper = styled(FormGroup)`
  flex: 1;
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};

  ${Select} {
    margin-top: ${(props) => props.theme.arter.spacing.small};
    margin-left: -${(props) => props.theme.arter.spacing.medium};
    border-color: transparent;
    background-color: transparent;
    background-image: linear-gradient(45deg, transparent 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, transparent 50%);

    &:disabled {
      opacity: 1;
    }

    &:hover:enabled {
      border-color: ${(props) => props.theme.arter.color.black};
      background-image: linear-gradient(
          45deg,
          transparent 50%,
          ${(props) => props.theme.arter.color.black} 50%
        ),
        linear-gradient(
          135deg,
          ${(props) => props.theme.arter.color.black} 50%,
          transparent 50%
        );
    }

    &:focus-visible {
      border-color: ${(props) => props.theme.arter.color.product.default};
    }
  }

  &:first-child {
    margin-right: ${(props) => props.theme.arter.spacing.medium};
  }

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    width: calc(100% + ${(props) => props.theme.arter.spacing.large});

    &:first-child {
      margin-right: 0;
    }
  }
`;

export default RiskLikelihoods;
