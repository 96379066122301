import styled from 'styled-components';

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.arter.color.white};
  margin-top: auto;
  padding-top: ${(props) => props.theme.arter.spacing.medium};

  button {
    margin-right: ${(props) => props.theme.arter.spacing.medium};
  }
`;

export default ButtonRow;
