import React from 'react';
import { useIntl } from 'react-intl';
import { useSettings } from '../../../SettingsContext';
import SettingPage from '../SettingPage';
import ImpactTargetHeaderRow from './ImpactTargetHeaderRow';
import ImpactTargetRow from './ImpactTargetRow';

function impactTargetFormInitializer({ name, description }) {
  return {
    name: { initialValue: name, value: name, dirty: false },
    description: {
      initialValue: description,
      value: description,
      dirty: false,
    },
  };
}

function ImpactTargets() {
  const intl = useIntl();
  const { impactTargets, fetchImpactTargets, isFetchingImpactTargets } =
    useSettings();

  return (
    <SettingPage
      title={intl.formatMessage({ id: 'IMPACT_TARGETS' })}
      HeaderRowComponent={ImpactTargetHeaderRow}
      RowComponent={ImpactTargetRow}
      formDataInitializer={impactTargetFormInitializer}
      createFormInitialData={{ name: '', description: '' }}
      rootEndpoint="/api/v1/impact-targets"
      settings={impactTargets}
      fetchSettings={fetchImpactTargets}
      isFetchingSettings={isFetchingImpactTargets}
    />
  );
}

export default ImpactTargets;
