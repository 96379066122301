import React from 'react';
import { useIntl } from 'react-intl';
import { mdiTrashCanOutline } from '@mdi/js';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from '../../../common/Checkbox';
import ClearIconButton from '../../../common/buttons/ClearIconButton';
import RenderByRightRole from '../../../RenderByRightRole';
import RIGHT_ROLE from '../../../../constants/right-role';
import { useRights } from '../../../RightsContext';
import useAutosizeTextArea from '../../../../hooks/useAutosizeTextArea';
import TextArea from '../../../common/TextArea';
import { useNotifications } from '../../../NotificationsContext';

const ACTION_NAME_MAX_CHARACTERS = 2000;

function ActionItem({
  action,
  onNameChange,
  onDoneChange,
  onRemove,
  ...props
}) {
  const intl = useIntl();
  const { hasModifierRight } = useRights();
  const { showInfoNotification, closeNotification } = useNotifications();
  const [
    maxCharactersReachedNotification,
    setMaxCharactersReachedNotification,
  ] = React.useState(null);
  const textAreaInputRef = React.useRef(null);
  useAutosizeTextArea(textAreaInputRef, action.name);

  const handleNameInputOnBlur = React.useCallback(() => {
    const name = textAreaInputRef.current.value;
    if (!name.trim()) {
      onRemove();
    }
  }, [onRemove]);

  const showMaxCharactersReachedNotification = React.useCallback(() => {
    setMaxCharactersReachedNotification(
      showInfoNotification(
        intl.formatMessage({ id: 'MAXIMUM_CHARACTERS_REACHED' }),
        intl.formatMessage({ id: 'MAXIMUM_CHARACTERS_REACHED_MESSAGE' }),
        () => setMaxCharactersReachedNotification(null),
      ),
    );
  }, [intl, showInfoNotification]);

  const checkMaxCharacters = React.useCallback(
    (event) => {
      if (
        maxCharactersReachedNotification &&
        event.target.value.length !== ACTION_NAME_MAX_CHARACTERS
      ) {
        closeNotification(maxCharactersReachedNotification.id);
      } else if (
        !maxCharactersReachedNotification &&
        event.target.value.length === ACTION_NAME_MAX_CHARACTERS
      ) {
        showMaxCharactersReachedNotification();
      }
    },
    [
      closeNotification,
      maxCharactersReachedNotification,
      showMaxCharactersReachedNotification,
    ],
  );

  return (
    <Wrapper {...props}>
      <ActionCheckbox
        isDisabled={!hasModifierRight()}
        isChecked={action.done}
        onChange={onDoneChange}
      />
      <ActionInputField
        disabled={!hasModifierRight()}
        onChange={onNameChange}
        onKeyUp={checkMaxCharacters}
        onInput={checkMaxCharacters}
        value={action.name}
        autoFocus={!action.name}
        spellCheck={false}
        onBlur={() => handleNameInputOnBlur()}
        ref={textAreaInputRef}
        rows={1}
        maxLength={ACTION_NAME_MAX_CHARACTERS}
      />
      <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
        <RemoveActionButton
          tooltip={intl.formatMessage({ id: 'DELETE' })}
          aria-label="Remove action"
          iconPath={mdiTrashCanOutline}
          onClick={onRemove}
        />
      </RenderByRightRole>
    </Wrapper>
  );
}

ActionItem.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    done: PropTypes.bool.isRequired,
  }).isRequired,
  onNameChange: PropTypes.func,
  onDoneChange: PropTypes.func,
  onRemove: PropTypes.func,
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

const ActionCheckbox = styled(Checkbox)`
  align-self: flex-start;
  margin-top: ${(props) => props.theme.arter.spacing.medium};
`;

const RemoveActionButton = styled(ClearIconButton)`
  position: absolute;
  top: ${(props) => props.theme.arter.spacing.small};
  right: ${(props) => props.theme.arter.spacing.small};
  margin-left: ${(props) => props.theme.arter.spacing.medium};
  background-color: ${(props) => props.theme.arter.color.white};
`;

const ActionInputField = styled(TextArea)`
  border-color: transparent;
  background-color: ${(props) => props.theme.arter.color.extraLightGray};
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
  margin-left: -${(props) => props.theme.arter.spacing.small};
  overflow: hidden;

  &:disabled {
    opacity: 1;
  }

  &:focus,
  &:hover {
    background-color: ${(props) => props.theme.arter.color.veryLightGray};
    border-color: transparent;
  }

  &:focus {
    border-color: ${(props) => props.theme.arter.color.product.default};
    background-color: ${(props) => props.theme.arter.color.extraLightGray};

    + ${RemoveActionButton} {
      display: none;
    }
  }
`;

export default ActionItem;
