import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { mdiClose } from '@mdi/js';
import theme from '../../../../styles/theme';
import {
  MenuHeader,
  MenuTitle,
  MenuCloseButton,
} from '../../../common/MenuHeader';
import Changeset from './Changeset';
import {
  popFromBottom,
  popFromRight,
} from '../../../../styles/keyframe-styles';
import Spinner from '../../../common/Spinner';
import useFormattedChangeLog from './useFormattedChangelog';

function Changelog({ url, onClose }) {
  const intl = useIntl();
  const { formattedChangelog, isFetchingChangelog } =
    useFormattedChangeLog(url);

  return (
    <Wrapper>
      <MenuHeader>
        <MenuTitle>{intl.formatMessage({ id: 'CHANGELOG' })}</MenuTitle>
        <MenuCloseButton
          tooltip={intl.formatMessage({ id: 'CLOSE' })}
          iconPath={mdiClose}
          onClick={onClose}
        />
      </MenuHeader>
      {!isFetchingChangelog && formattedChangelog ? (
        <Content>
          {formattedChangelog.map(({ timestamp, user, changes }) => (
            <Changeset
              key={timestamp}
              timestamp={timestamp}
              user={user}
              changes={changes}
            />
          ))}
        </Content>
      ) : (
        <Spinner size={theme.arter.icon.size.medium} />
      )}
    </Wrapper>
  );
}

Changelog.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

const Wrapper = styled.section`
  overflow: auto;
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: ${(props) => props.theme.arter.color.white};
  padding: ${(props) => props.theme.arter.spacing.large};
  border-top-left-radius: ${(props) => props.theme.arter.border.radius.large};
  border-top-right-radius: ${(props) => props.theme.arter.border.radius.large};
  box-shadow: 0 -2px 15px -10px rgba(0, 0, 0, 0.75);
  animation: ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  @media ${(props) => props.theme.arter.device.pad} {
    min-width: 520px;
    width: 520px;
    top: 0;
    left: auto;
    box-shadow: -2px 0 15px -10px rgba(0, 0, 0, 0.75);
    border-top-right-radius: 0;
    border-bottom-left-radius: ${(props) =>
      props.theme.arter.border.radius.large};
    animation-name: ${popFromRight};
  }
`;

const Content = styled.div``;

export default Changelog;
