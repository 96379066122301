import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import theme from '../../styles/theme';
import Spinner from './Spinner';

function LoadingPage() {
  const intl = useIntl();
  return (
    <Wrapper>
      <SpinnerWrapper>
        <Spinner size={theme.arter.icon.size.large} />
        <Text>{intl.formatMessage({ id: 'LOADING' })}</Text>
      </SpinnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -100px;
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.arter.font.size.extraLarge};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  margin-left: ${(props) => props.theme.arter.spacing.large};
`;

export default LoadingPage;
