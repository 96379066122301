import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SectionTitle from '../../common/SectionTitle';

function FolderContentCount({ title, count, ...props }) {
  return (
    <CountTitle {...props}>
      {`${title}:`}
      <Count>{count}</Count>
    </CountTitle>
  );
}

FolderContentCount.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const CountTitle = styled(SectionTitle)`
  position: sticky;
  left: 0;
  font-size: ${(props) => props.theme.arter.font.size.large};
  margin-bottom: ${(props) => props.theme.arter.spacing.mediumLarge};
`;

const Count = styled.span`
  color: ${(props) => props.theme.arter.color.product.default};
  margin-left: ${(props) => props.theme.arter.spacing.small};
`;

export default FolderContentCount;
