import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import impactType from '../../../constants/impact-type';

function MatrixCell({
  cell,
  borderWidth,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
}) {
  const opportunityCount = cell.data.impacts.filter(
    (impact) => impact.impactType === impactType.OPPORTUNITY,
  ).length;
  const threatCount = cell.data.impacts.filter(
    (impact) => impact.impactType === impactType.THREAT,
  ).length;

  const handlers = React.useMemo(
    (event) => ({
      onMouseEnter: onMouseEnter ? onMouseEnter(cell, event) : undefined,
      onMouseMove: onMouseMove ? onMouseMove(cell, event) : undefined,
      onMouseLeave: onMouseLeave ? onMouseLeave(cell, event) : undefined,
      onClick: onClick ? onClick(cell, event) : undefined,
    }),
    [onMouseEnter, cell, onMouseMove, onMouseLeave, onClick],
  );

  if (cell.value === null) return null;

  const isBiggerBox = cell.width >= 50;
  const boxSize = isBiggerBox ? 24 : 20;
  const xTextPosition = isBiggerBox ? 13 : 10;
  const singleBoxPosition = isBiggerBox ? -12 : -10;
  const xOpportunityBoxPosition = isBiggerBox ? 1 : 0;
  const xThreathBoxPosition = isBiggerBox ? -25 : -20;

  return (
    <Cell {...handlers} transform={`translate(${cell.x}, ${cell.y})`}>
      <CellBackground
        x={-cell.width / 2}
        y={-cell.height / 2}
        width={cell.width}
        height={cell.height}
        fill={cell.color}
        fillOpacity={cell.opacity}
        strokeWidth={borderWidth}
        stroke={cell.borderColor}
      />
      {opportunityCount ? (
        <>
          <BoxOpportunity
            x={threatCount ? xOpportunityBoxPosition : singleBoxPosition}
            y={singleBoxPosition}
            rx={4}
            ry={4}
            width={boxSize}
            height={boxSize}
          />
          <BoxText
            x={threatCount ? xTextPosition : 0}
            y={1}
            alignmentBaseline="middle"
            textAnchor="middle"
          >
            {opportunityCount}
          </BoxText>
        </>
      ) : null}
      {threatCount ? (
        <>
          <BoxThreat
            x={opportunityCount ? xThreathBoxPosition : singleBoxPosition}
            y={singleBoxPosition}
            rx={4}
            ry={4}
            width={boxSize}
            height={boxSize}
          />
          <BoxText
            x={opportunityCount ? -xTextPosition : 0}
            y={1}
            alignmentBaseline="middle"
            textAnchor="middle"
          >
            {threatCount}
          </BoxText>{' '}
        </>
      ) : null}
    </Cell>
  );
}

MatrixCell.propTypes = {
  cell: PropTypes.object.isRequired,
  value: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
};

const CellBackground = styled.rect`
  transition: ${(props) => props.theme.arter.animation.speed.normal};
`;
const Cell = styled.g`
  cursor: pointer;

  &:hover {
    ${CellBackground} {
      fill: ${(props) => props.theme.arter.color.extraLightGray};
    }
  }
`;

const BoxThreat = styled.rect`
  fill: #f3b4b1;
`;

const BoxOpportunity = styled(BoxThreat)`
  fill: #beebcf;
`;

const BoxText = styled.text`
  font-size: ${(props) => props.theme.arter.font.size.small};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
`;

export default MatrixCell;
