import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';
import WarningIcon from '../../../common/notifications/WarningIcon';

function InvalidValueIcon() {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'IMPORT_EXCEL_INVALID_VALUE_MESSAGE',
      })}
    >
      <Wrapper>
        <WarningIcon />
      </Wrapper>
    </Tooltip>
  );
}

const Wrapper = styled.span`
  position: absolute;
  display: inline-block;
  transform: translateY(-2px);
  margin-left: ${(props) => props.theme.arter.spacing.small};
`;

export default InvalidValueIcon;
