import PropTypes from 'prop-types';
import RIGHT_ROLE from '../constants/right-role';
import { useRights } from './RightsContext';

function RenderByRightRole({ role, children }) {
  const { hasRight } = useRights();

  return hasRight(role) ? children : null;
}

RenderByRightRole.propTypes = {
  role: PropTypes.oneOf(Object.values(RIGHT_ROLE)),
  children: PropTypes.node,
};

export default RenderByRightRole;
