import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function EmptyCellData({ empty }) {
  return empty ? '' : <FormattedMessage id="NOT_SET" />;
}

EmptyCellData.propTypes = {
  empty: PropTypes.bool,
};

export default EmptyCellData;
