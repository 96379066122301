import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormattedMessage } from 'react-intl';
import FilterSelect from './FilterSelect';
import SelectValue from './SelectValue';
import CountItem from './CountItem';
import SelectIcon from './SelectIcon';
import ListItemCheckIcon from '../../../common/multi-select/ListItemCheckIcon';
import DropdownListItem from '../../../common/multi-select/DropdownListItem';
import ListItemText from '../../../common/multi-select/ListItemText';
import RISK_STATE from '../../../../constants/risk-state';
import { useFolderFilter } from './FolderFilterContext';

function StateFilter() {
  const { filter, setFilter } = useFolderFilter();
  const [selected, setSelected] = React.useState(filter.states);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === 'string' ? value.split(',') : value);
  };

  React.useEffect(() => {
    setSelected(filter.states);
  }, [filter.states]);

  return (
    <FilterSelect
      $active={filter.states.length}
      IconComponent={SelectIcon}
      multiple
      value={selected}
      onChange={handleChange}
      onClose={() => setFilter({ ...filter, states: selected })}
      input={<OutlinedInput />}
      displayEmpty={true}
      renderValue={() => (
        <SelectValue>
          {filter.states.length ? (
            <CountItem count={filter.states.length} />
          ) : null}
          <FormattedMessage id="STATE" />
        </SelectValue>
      )}
      data-testid="state-filter"
      MenuProps={{
        slotProps: {
          backdrop: { 'data-testid': 'filter-backdrop', invisible: true },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {Object.values(RISK_STATE).map((riskState) => (
        <DropdownListItem key={riskState} value={riskState}>
          <ListItemText>
            <FormattedMessage id={riskState} />
          </ListItemText>
          <ListItemCheckIcon show={selected.includes(riskState)} />
        </DropdownListItem>
      ))}
    </FilterSelect>
  );
}

export default StateFilter;
