import React from 'react';
import { useIntl } from 'react-intl';
import { mdiOrderBoolAscendingVariant } from '@mdi/js';
import styled from 'styled-components';
import ActionItem from './ActionItem';
import { Section, Header, Content, AddButton } from './RightMenuSection';
import ActionsImage from '../../../../styles/images/actions.svg';
import { useForm } from '../../../FormContext';
import HelpNotification from '../../../common/notifications/HelpNotification';
import RenderByRightRole from '../../../RenderByRightRole';
import RIGHT_ROLE from '../../../../constants/right-role';

function ActionsTab() {
  const intl = useIntl();
  const { state, dispatchFormChange, dirtyCheck } = useForm();

  function addRiskAction(newAction) {
    const actions = [...state.actions.value, newAction];
    dispatchFormChange({
      field: 'actions',
      value: actions,
      dirty: dirtyCheck(
        state.actions.initialValue,
        actions.filter((action) => action.name.trim()),
      ),
    });
  }

  function removeRiskAction(removedIndex) {
    dispatchFormChange({
      field: 'actions',
      value: state.actions.value.filter(
        (action, index) => index !== removedIndex,
      ),
    });
  }

  function changeRiskActionName(name, changedIndex) {
    const actions = state.actions.value.map((action, index) =>
      index === changedIndex ? { ...action, name } : action,
    );
    dispatchFormChange({
      field: 'actions',
      value: actions,
      dirty: dirtyCheck(
        state.actions.initialValue,
        actions.filter((action) => action.name.trim()),
      ),
    });
  }

  function changeRiskActionDoneStatus(done, changedIndex) {
    dispatchFormChange({
      field: 'actions',
      value: state.actions.value.map((action, index) =>
        index === changedIndex ? { ...action, done } : action,
      ),
    });
  }

  return (
    <ActionsSectionWrapper>
      <Header
        label={intl.formatMessage({ id: 'ACTIONS' })}
        iconPath={mdiOrderBoolAscendingVariant}
      >
        <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
          <AddButton
            tooltip={intl.formatMessage({ id: 'ADD_ACTION' })}
            onClick={() => addRiskAction({ name: '', done: false })}
          />
        </RenderByRightRole>
      </Header>
      <Content>
        {state.actions.value.length ? (
          state.actions.value.map((action, index) => (
            <ActionItem
              key={index}
              action={action}
              onNameChange={(event) =>
                changeRiskActionName(event.target.value, index)
              }
              onDoneChange={(event) =>
                changeRiskActionDoneStatus(event.target.checked, index)
              }
              onRemove={() => removeRiskAction(index)}
              data-testid={`action-item-${index}`}
            ></ActionItem>
          ))
        ) : (
          <HelpNotification
            small
            srcImage={ActionsImage}
            title={intl.formatMessage({ id: 'ADD_ACTIONS' })}
          />
        )}
      </Content>
    </ActionsSectionWrapper>
  );
}

const ActionsSectionWrapper = styled(Section)`
  margin-bottom: 0;

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    margin-bottom: 50px;
  }
`;

export default ActionsTab;
