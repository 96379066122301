import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import RISK_PRIORITY from '../../../../../constants/risk-priority';
import FilterChipSelect from './FilterChipSelect';

function PriorityFilter({ selectedPriorities, onChange, onClear }) {
  const intl = useIntl();

  return (
    <FilterChipSelect
      data-testid="all-filters-priority-filter"
      label={intl.formatMessage({ id: 'PRIORITY' })}
      htmlFor="priority-select"
      options={Object.values(RISK_PRIORITY).map((priority) => ({
        id: priority,
        name: intl.formatMessage({ id: priority }),
      }))}
      selectedOptions={selectedPriorities}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

PriorityFilter.propTypes = {
  selectedPriorities: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(RISK_PRIORITY)),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default PriorityFilter;
