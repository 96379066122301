import React from 'react';
import { useIntl } from 'react-intl';
import useApi from '../../../../hooks/useApi';

function useFormattedChangeLog(url) {
  const intl = useIntl();
  const {
    response: changelog,
    isLoading: isFetchingChangelog,
    callEndpoint: fetchChangelog,
  } = useApi({
    endpoint: url,
    method: 'GET',
  });
  const [formattedChangelog, setFormattedChangelog] = React.useState(null);
  const formatChange = React.useCallback(
    (change) => {
      switch (change.targetType) {
        case 'STATE':
        case 'MANAGEMENT_STRATEGY':
        case 'PRIORITY':
        case 'IMPACT_TYPE':
          return {
            ...change,
            from: intl.formatMessage({
              id: change.from,
            }),
            to: intl.formatMessage({
              id: change.to,
            }),
          };
        case 'RESPONSIBLE':
        case 'DESCRIPTION':
        case 'NAME':
        case 'CURRENT_LIKELIHOOD':
        case 'TARGET_LIKELIHOOD':
        case 'IMPACT_CURRENT_VALUE':
        case 'IMPACT_TARGET_VALUE':
          return {
            ...change,
            from:
              change.from ||
              intl.formatMessage({
                id: 'NOT_SET',
              }),
            to:
              change.to ||
              intl.formatMessage({
                id: 'NOT_SET',
              }),
          };
        case 'REVISION_DATE':
          return {
            ...change,
            from: change.from
              ? intl.formatDate(change.from, {
                  format: 'dateFormat',
                })
              : intl.formatMessage({
                  id: 'NOT_SET',
                }),
            to: change.to
              ? intl.formatDate(change.to, {
                  format: 'dateFormat',
                })
              : intl.formatMessage({
                  id: 'NOT_SET',
                }),
          };
        case 'ACTION_DONE':
          return {
            ...change,
            from: intl.formatMessage({
              id: change.from === 'true' ? 'DONE' : 'UNDONE',
            }),
            to: intl.formatMessage({
              id: change.to === 'true' ? 'DONE' : 'UNDONE',
            }),
          };
        default:
          return change;
      }
    },
    [intl],
  );

  React.useEffect(() => {
    fetchChangelog();
  }, [fetchChangelog]);

  React.useEffect(() => {
    if (changelog) {
      setFormattedChangelog(
        changelog.map((changeset) => ({
          ...changeset,
          changes: changeset.changes.map((change) => formatChange(change)),
        })),
      );
    }
  }, [changelog, formatChange]);

  return { formattedChangelog, isFetchingChangelog };
}

export default useFormattedChangeLog;
