import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  mdiChevronRight,
  mdiFolderMoveOutline,
  mdiFolderOutline,
  mdiShieldLockOutline,
} from '@mdi/js';
import { Tooltip } from '@mui/material';
import theme from '../styles/theme';
import ValidationNotification from './common/notifications/ValidationNotification';
import SaveButton from './common/buttons/SaveButton';
import CancelButton from './common/buttons/CancelButton';
import {
  Modal,
  ModalContent,
  ModalContentWrap,
  ModalButtons,
  ModalCloseButton,
} from './common/Modal';
import useApi from '../hooks/useApi';
import Spinner from './common/Spinner';
import {
  TreeItemWrapper,
  TreeItem,
  ToggleButton,
  FolderIcon,
  NameWrapper,
  ItemName,
} from './common/TreeItem';
import { useNotifications } from './NotificationsContext';
import { FolderTreeProvider, useFolderTree } from './FolderTreeContext';

function MoveInFolderTreeModal({
  patchEndpoint,
  patchAttribute,
  openedFolders,
  disabledFolders,
  onClose,
  onSuccess,
}) {
  return (
    <FolderTreeProvider
      openedFolders={['root', ...openedFolders]}
      disabledFolders={disabledFolders}
    >
      <MoveModal
        patchEndpoint={patchEndpoint}
        patchAttribute={patchAttribute}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </FolderTreeProvider>
  );
}

MoveInFolderTreeModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  openedFolders: PropTypes.arrayOf(PropTypes.string),
  disabledFolders: PropTypes.arrayOf(PropTypes.string),
  patchEndpoint: PropTypes.string.isRequired,
  patchAttribute: PropTypes.string.isRequired,
};

function MoveModal({ patchEndpoint, patchAttribute, onClose, onSuccess }) {
  const intl = useIntl();
  const { showSuccessNotification } = useNotifications();
  const { selectedFolder, folderTree, isLoadingFolderTree, isFolderDisabled } =
    useFolderTree();
  const {
    isLoading: isMoving,
    isError: isMoveError,
    isSuccess: isMoveSuccess,
    callEndpoint: move,
  } = useApi({
    endpoint: patchEndpoint,
    method: 'PATCH',
  });

  React.useEffect(() => {
    if (isMoveSuccess) {
      onClose();
      onSuccess();
      showSuccessNotification(intl.formatMessage({ id: 'MOVED_SUCCESSFULLY' }));
    }
  }, [isMoveSuccess, onClose, showSuccessNotification, intl, onSuccess]);

  return (
    <Modal open onClose={onClose} closeOnBackdropClick={!isMoving}>
      <ModalContent title={intl.formatMessage({ id: 'MOVE_TO' })}>
        <ContentWrap>
          <TreeWrapper>
            {!isLoadingFolderTree && folderTree ? (
              <FolderTreeItem
                key={folderTree.id}
                folder={folderTree}
                disabled={isFolderDisabled(folderTree.id)}
              />
            ) : (
              <Spinner size={theme.arter.icon.size.medium} />
            )}
            {isMoveError ? (
              <ValidationNotification
                validationMessage={intl.formatMessage({
                  id: 'FAILED_TO_MOVE',
                })}
              />
            ) : null}
          </TreeWrapper>
          <ModalButtons>
            <SaveButton
              onClick={() =>
                move({
                  [patchAttribute]:
                    selectedFolder === 'root' ? '' : selectedFolder,
                })
              }
              disabled={!selectedFolder || isMoving}
            >
              <FormattedMessage id="MOVE" />
            </SaveButton>
            <ModalCloseButton>
              <CancelButton disabled={isMoving}>
                <FormattedMessage id="CANCEL" />
              </CancelButton>
            </ModalCloseButton>
            {isMoving ? <Spinner size={theme.arter.icon.size.medium} /> : null}
          </ModalButtons>
        </ContentWrap>
      </ModalContent>
    </Modal>
  );
}

MoveModal.propTypes = {
  patchEndpoint: PropTypes.string.isRequired,
  patchAttribute: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

function FolderTreeItem({ folder, disabled }) {
  const intl = useIntl();
  const {
    isFolderOpen,
    toggleFolderById,
    setSelectedFolder,
    isFolderSelected,
    isFolderDisabled,
  } = useFolderTree();
  const id = folder.id ? folder.id : 'root';
  const { name, subFolders, allowedToModify } = folder;

  return (
    <TreeItemWrapper>
      <Item $active={isFolderSelected(id)}>
        <ToggleButton
          path={mdiChevronRight}
          $active={isFolderOpen(id)}
          data-testid={`toggle-${id}`}
          onClick={() => toggleFolderById(id)}
        ></ToggleButton>
        <ItemNameWrapper
          $disabled={disabled || !allowedToModify}
          onClick={() => setSelectedFolder(id)}
        >
          {isFolderSelected(id) ? (
            <MoveIcon path={mdiFolderMoveOutline} />
          ) : (
            <FolderIcon path={mdiFolderOutline} />
          )}
          <ItemName>{name || intl.formatMessage({ id: 'RISKS' })}</ItemName>
        </ItemNameWrapper>
        {!allowedToModify ? (
          <Tooltip
            placement="top"
            title={intl.formatMessage({ id: 'NO_RIGHTS_INFO' })}
          >
            <ShieldIcon
              path={mdiShieldLockOutline}
              size={theme.arter.icon.size.small}
            />
          </Tooltip>
        ) : null}
      </Item>
      {isFolderOpen(id)
        ? subFolders.map((subFolder) => (
            <FolderTreeItem
              key={subFolder.id}
              folder={subFolder}
              disabled={disabled || isFolderDisabled(subFolder.id)}
            />
          ))
        : null}
    </TreeItemWrapper>
  );
}

FolderTreeItem.propTypes = {
  folder: PropTypes.shape({
    allowedToModify: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    subFolders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
  disabled: PropTypes.bool,
};

const TreeWrapper = styled.div`
  overflow: auto;
  min-height: 32px;
  max-height: 370px;
`;

const ContentWrap = styled(ModalContentWrap)`
  ${TreeItemWrapper}:first-child {
    margin-left: 0;

    ${TreeItem} {
      padding-left: 0;
      margin-left: 0;
    }
  }
`;

const Item = styled(TreeItem)`
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  background-color: ${(props) =>
    props.$active ? props.theme.arter.color.product.light : 'transparent'};
  border: 1px solid
    ${(props) =>
      props.$active ? props.theme.arter.color.product.default : 'transparent'};
`;

const MoveIcon = styled(FolderIcon)``;

const ShieldIcon = styled(FolderIcon)`
  margin-right: ${(props) => props.theme.arter.spacing.large};
`;

const ItemNameWrapper = styled(NameWrapper)`
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
`;

export default MoveInFolderTreeModal;
