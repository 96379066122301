import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FilterChipSelect from './FilterChipSelect';
import { useSettings } from '../../../../SettingsContext';

function ImpactTargetFilter({ selectedImpactTargets, onChange, onClear }) {
  const intl = useIntl();
  const { impactTargets } = useSettings();

  return (
    <FilterChipSelect
      data-testid="all-filters-impact-target-filter"
      label={intl.formatMessage({ id: 'IMPACT_TARGET' })}
      htmlFor="impact-target-select"
      options={impactTargets}
      selectedOptions={selectedImpactTargets}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

ImpactTargetFilter.propTypes = {
  selectedImpactTargets: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default ImpactTargetFilter;
