import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiCheck,
  mdiClose,
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { TableRow, TableData, RowTools } from '../../common/tables/EditTable';
import IconButton from '../../common/buttons/IconButton';
import { useForm } from '../../FormContext';
import Spinner from '../../common/Spinner';
import Select from '../../common/Select';
import InputField from '../../common/InputField';
import TextArea from '../../common/TextArea';
import SaveButton from '../../common/buttons/SaveButton';
import CancelButton from '../../common/buttons/CancelButton';
import theme from '../../../styles/theme';

function SettingRow({
  id,
  inputFields,
  DeleteModalComponent,
  onEdit,
  onCancel,
  onDelete,
  isRowBeingEdited,
  isAnotherRowBeingEdited,
  disableSave,
  ...props
}) {
  const intl = useIntl();
  const {
    isChanged,
    submitForm,
    resetChanges,
    submitting: isSaving,
  } = useForm();
  const [isDeleting, setIsDeleting] = React.useState(false);

  function handleCancelClick() {
    resetChanges();
    onCancel();
  }

  return (
    <TableRow
      $active={isRowBeingEdited}
      $deactive={isSaving || isAnotherRowBeingEdited}
      {...props}
    >
      {inputFields.map((FieldComponent, index) => (
        <SettingsTableData $active={isRowBeingEdited} key={index}>
          <FieldComponent disabled={isSaving || !isRowBeingEdited} />
        </SettingsTableData>
      ))}
      <SettingsTableData>
        <SettingRowTools>
          {isSaving ? <Spinner size={theme.arter.icon.size.medium} /> : null}
          {isRowBeingEdited ? (
            <>
              <SaveButton
                disabled={isSaving || !isChanged() || disableSave}
                onClick={submitForm}
              >
                <ButtonIcon path={mdiCheck} />
                <ButtonText>
                  <FormattedMessage id="SAVE" />
                </ButtonText>
              </SaveButton>
              <CancelButton
                disabled={isSaving}
                onClick={() => handleCancelClick()}
              >
                <ButtonIcon path={mdiClose} />
                <ButtonText>
                  <FormattedMessage id="CANCEL" />
                </ButtonText>
              </CancelButton>
            </>
          ) : (
            <>
              <IconButton
                tooltip={intl.formatMessage({ id: 'EDIT' })}
                iconPath={mdiPencilOutline}
                onClick={onEdit}
                disabled={isAnotherRowBeingEdited}
              />
              <IconButton
                tooltip={intl.formatMessage({ id: 'DELETE' })}
                iconPath={mdiTrashCanOutline}
                disabled={isAnotherRowBeingEdited}
                onClick={() => setIsDeleting(true)}
              />
            </>
          )}
        </SettingRowTools>
      </SettingsTableData>
      {isDeleting ? (
        <DeleteModalComponent
          id={id}
          onDelete={onDelete}
          onClose={() => setIsDeleting(false)}
        />
      ) : null}
    </TableRow>
  );
}

SettingRow.propTypes = {
  id: PropTypes.string,
  inputFields: PropTypes.arrayOf(PropTypes.elementType),
  DeleteModalComponent: PropTypes.elementType,
  isAnotherRowBeingEdited: PropTypes.bool,
  isRowBeingEdited: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  disableSave: PropTypes.bool,
};

const SettingsTableData = styled(TableData)`
  padding-left: 0;

  ${Select},
  ${InputField},
  ${TextArea} {
    border-color: ${(props) =>
      props.$active ? props.theme.arter.color.darkGray : 'transparent'};

    &:focus,
    &:active {
      border-color: ${(props) =>
        props.$active
          ? props.theme.arter.color.product.default
          : 'transparent'};
      box-shadow: ${(props) =>
        props.$active
          ? `'inset 0 0 0 1px' + ${props.theme.arter.color.product.default}`
          : 'none'};
    }

    &:hover {
      border-color: ${(props) =>
        props.$active ? props.theme.arter.color.darkGray : 'transparent'};
    }
  }
`;

const ButtonText = styled.span``;

const ButtonIcon = styled(Icon)`
  display: none;
  width: ${(props) => props.theme.arter.icon.size.medium};
`;

const SettingRowTools = styled(RowTools)`
  width: 180px;

  ${SaveButton} {
    margin-left: ${(props) => props.theme.arter.spacing.medium};
  }

  @media (max-width: 1024px) {
    width: 84px;

    ${SaveButton},
    ${CancelButton} {
      width: 38px;
      padding: ${(props) => props.theme.arter.spacing.medium};
      min-width: auto;

      ${ButtonText} {
        display: none;
      }
      ${ButtonIcon} {
        display: inline-block;
      }
    }
  }
`;

export default SettingRow;
