import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { slideFromLeft } from '../../../../styles/keyframe-styles';

function CountItem({ count }) {
  return <Wrapper>{count}</Wrapper>;
}

CountItem.propTypes = {
  count: PropTypes.number,
};

const Wrapper = styled.div`
  position: absolute;
  left: -25px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  color: ${(props) => props.theme.arter.color.white};
  background-color: ${(props) => props.theme.arter.color.product.default};
  font-size: ${(props) => props.theme.arter.font.size.small};
  font-weight: ${(props) => props.theme.arter.font.weight.regular};
  animation: ${slideFromLeft} forwards
    ${(props) => props.theme.arter.animation.speed.normal};
`;

export default CountItem;
