import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BaseCell from './BaseCell';

function ManagementStrategyCell({ managementStrategy, issue }) {
  return (
    <BaseCell issue={issue}>
      <FormattedMessage id={managementStrategy} />
    </BaseCell>
  );
}

ManagementStrategyCell.propTypes = {
  managementStrategy: PropTypes.string,
  issue: PropTypes.bool,
};

export default ManagementStrategyCell;
