import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '@mdi/react';
import Tooltip from '@mui/material/Tooltip';
import Button from './Button';

function IconButton({ tooltip, iconPath, className, small, ...props }) {
  return (
    <Tooltip title={tooltip}>
      <Wrapper $small={small} className={className} {...props}>
        <ButtonIcon $small={small} path={iconPath} />
      </Wrapper>
    </Tooltip>
  );
}

IconButton.propTypes = {
  tooltip: PropTypes.string,
  iconPath: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
};

const Wrapper = styled(Button)`
  padding: 7px;
  min-width: auto;
  width: ${(props) => (props.$small ? '28px' : '38px')};
  height: ${(props) => (props.$small ? '28px' : '38px')};
  background-color: transparent;
  transition: ${(props) => props.theme.arter.animation.speed.normal};
  cursor: pointer;
  border-radius: ${(props) => props.theme.arter.border.radius.small};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.lightGray};
  }
`;

const ButtonIcon = styled(Icon)`
  color: ${(props) => props.theme.arter.color.black};
  margin-right: 0 !important;
  width: ${(props) =>
    props.$small
      ? props.theme.arter.icon.size.small
      : props.theme.arter.icon.size.medium};
  height: ${(props) =>
    props.$small
      ? props.theme.arter.icon.size.small
      : props.theme.arter.icon.size.medium};
`;

export default IconButton;
