import styled from 'styled-components';

const Content = styled.div`
  background-color: ${(props) => props.theme.arter.color.white};
  flex: 2;
  margin: ${(props) => props.theme.arter.spacing.mediumLarge} 0;
  scroll-padding-top: 4rem;

  ul {
    padding-left: ${(props) => props.theme.arter.spacing.mediumLarge};
    margin: ${(props) => props.theme.arter.spacing.medium} 0;
    list-style-type: initial;
    font-size: ${(props) => props.theme.arter.font.size.medium};

    li {
      margin-bottom: ${(props) => props.theme.arter.spacing.medium};
    }
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    margin-top: 0;
  }
`;

export default Content;
