import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '@mdi/react';
import { mdiCheck } from '@mdi/js';

function Checkbox({ labelText, isChecked, isDisabled, onChange, ...props }) {
  return (
    <Wrapper {...props}>
      <Input
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onChange={(event) => onChange(event)}
      />
      <Box>
        <CheckIcon path={mdiCheck} />
      </Box>
      {labelText}
    </Wrapper>
  );
}

Checkbox.propTypes = {
  labelText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: ${(props) => props.theme.arter.animation.speed.normal};
  font-size: ${(props) => props.theme.arter.font.size.medium};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  color: ${(props) => props.theme.arter.color.black};
  margin-bottom: 0;

  &:hover {
    span {
      background-color: ${(props) => props.theme.arter.color.extraLightGray};
    }
  }
`;

const CheckIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  opacity: 0;
  color: ${(props) => props.theme.arter.color.white};
`;

const Input = styled.input`
  position: relative;
  left: 16px;
  opacity: 0;
  width: 2px;
  height: 2px;
  margin: 0;

  &:checked ~ span {
    background-color: ${(props) => props.theme.arter.color.product.default};
    border-color: ${(props) => props.theme.arter.color.product.default};

    ${CheckIcon} {
      opacity: 1;
    }
  }

  &:focus-visible {
    ~ span {
      box-shadow: 0 0 0 4px ${(props) => props.theme.arter.color.product.light};
    }
  }

  &:disabled {
    + span {
      background-color: ${(props) => props.theme.arter.color.lightGray};
      cursor: default;
      border-color: ${(props) => props.theme.arter.color.gray};
      pointer-events: none;

      ${CheckIcon} {
        color: ${(props) => props.theme.arter.color.white};
      }
    }
  }
`;

const Box = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: transparent;
  transition: 0.2s;
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  border: 1px solid ${(props) => props.theme.arter.color.product.darkGray};
  margin-right: ${(props) => props.theme.arter.spacing.medium};
`;

export default Checkbox;
