import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { mdiArrowLeft } from '@mdi/js';
import IconButton from '../../common/buttons/IconButton';

function SettingsHeader({ title }) {
  const intl = useIntl();

  return (
    <Header>
      <RouterLink to="/settings">
        <IconButton
          tooltip={intl.formatMessage({ id: 'BACK' })}
          iconPath={mdiArrowLeft}
        />
      </RouterLink>
      <Title>{title}</Title>
    </Header>
  );
}

SettingsHeader.propTypes = {
  title: PropTypes.string,
};

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.arter.font.size.extraExtraLarge};
  margin-left: ${(props) => props.theme.arter.spacing.small};
`;

export default SettingsHeader;
