import React from 'react';
import { mdiFinance } from '@mdi/js';
import { useIntl } from 'react-intl';
import RISK_MANAGEMENT_STRATEGY from '../../../constants/risk-management-strategy';
import Select from '../../common/Select';
import RiskCard from './RiskCard';
import { useForm } from '../../FormContext';

function RiskManagementStrategyCard() {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();

  return (
    <RiskCard
      icon={mdiFinance}
      title={intl.formatMessage({ id: 'MANAGEMENT_STRATEGY' })}
      data-testid="risk-management-strategy-card"
    >
      <Select
        value={state.managementStrategy.value}
        onChange={(event) =>
          dispatchFormChange({
            field: 'managementStrategy',
            value: event.target.value,
          })
        }
      >
        {Object.values(RISK_MANAGEMENT_STRATEGY).map((managementStrategy) => (
          <option key={managementStrategy} value={managementStrategy}>
            {intl.formatMessage({
              id: managementStrategy,
            })}
          </option>
        ))}
      </Select>
    </RiskCard>
  );
}

export default RiskManagementStrategyCard;
