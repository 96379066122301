import React from 'react';
import PropTypes from 'prop-types';
import { mdiFolderOutline } from '@mdi/js';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import { BaseTableCell } from '../../../common/tables/BaseTable';
import { LinkIcon, LinkName, LinkWrapper } from './CellLinkWithIcon';

function LocationCell({ breadcrumb }) {
  const intl = useIntl();
  const id = breadcrumb.length ? breadcrumb[breadcrumb.length - 1].id : 'root';
  const name = breadcrumb.length
    ? breadcrumb[breadcrumb.length - 1].name
    : intl.formatMessage({ id: 'RISKS' });
  const tooltip = `${intl.formatMessage({ id: 'RISKS' })} ${
    breadcrumb.length ? '>' : ''
  } ${breadcrumb.map((item) => item.name).join(' > ')}`;

  return (
    <BaseTableCell>
      <LinkWrapper to={`/folders/${id}`}>
        <LinkIcon path={mdiFolderOutline} />
        <Tooltip describeChild title={tooltip}>
          <LinkName>{name}</LinkName>
        </Tooltip>{' '}
      </LinkWrapper>
    </BaseTableCell>
  );
}

LocationCell.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default React.memo(LocationCell);
