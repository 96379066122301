import React from 'react';
import useApi from '../hooks/useApi';

const SettingsContext = React.createContext();

function SettingsProvider(props) {
  const {
    response: likelihoods,
    callEndpoint: fetchLikelihoods,
    isLoading: isFetchingLikelihoods,
  } = useApi({
    endpoint: '/api/v1/likelihoods',
    method: 'GET',
  });
  const {
    response: impactTargets,
    callEndpoint: fetchImpactTargets,
    isLoading: isFetchingImpactTargets,
  } = useApi({
    endpoint: '/api/v1/impact-targets',
    method: 'GET',
  });
  const {
    response: impactValues,
    callEndpoint: fetchImpactValues,
    isLoading: isFetchingImpactValues,
  } = useApi({
    endpoint: '/api/v1/impact-values',
    method: 'GET',
  });
  const {
    response: tags,
    callEndpoint: fetchTags,
    isLoading: isFetchingTags,
  } = useApi({
    endpoint: '/api/v1/tags',
    method: 'GET',
  });

  React.useEffect(() => {
    fetchLikelihoods();
    fetchImpactTargets();
    fetchImpactValues();
    fetchTags();
  }, [fetchLikelihoods, fetchImpactTargets, fetchImpactValues, fetchTags]);

  return (
    <SettingsContext.Provider
      value={{
        likelihoods,
        fetchLikelihoods,
        isFetchingLikelihoods,
        impactTargets,
        fetchImpactTargets,
        isFetchingImpactTargets,
        impactValues,
        fetchImpactValues,
        isFetchingImpactValues,
        tags,
        fetchTags,
        isFetchingTags,
      }}
      {...props}
    />
  );
}

function useSettings() {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }
  return context;
}

export { SettingsProvider, useSettings };
