import {
  differenceInMonths,
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  isBefore,
} from 'date-fns';

function historyDataToChartData(historyData, firstValidDataPointIndex) {
  const data = historyData
    .slice(firstValidDataPointIndex)
    .map((historyPoint) => ({
      x: historyPoint.timestamp,
      y: historyPoint.likelihoodValue * historyPoint.impactValue || null,
      type: historyPoint.impactType,
    }));

  const [latestDataPoint] = data.slice(-1);
  const isActiveImpact = latestDataPoint.y != null;
  const hasImpactChangedToday =
    latestDataPoint.x === format(new Date(), 'yyyy-MM-dd');
  if (isActiveImpact && !hasImpactChangedToday) {
    data.push({
      x: format(new Date(), 'yyyy-MM-dd'),
      y: latestDataPoint.y,
      type: latestDataPoint.type,
      hidePoint: true,
    });
  }

  return data;
}

export function magnitudeHistoryToChartData(magnitudeHistory, impactTargets) {
  const chartData = magnitudeHistory.flatMap((history) => {
    const firstValidDataPointIndex = history.data.findIndex(
      (dataPoint) =>
        dataPoint.impactValue != null && dataPoint.likelihoodValue != null,
    );

    return firstValidDataPointIndex === -1
      ? []
      : [
          {
            id: impactTargets.find(
              (impactTarget) => impactTarget.id === history.impactTargetId,
            ).name,
            data: historyDataToChartData(
              history.data,
              firstValidDataPointIndex,
            ),
          },
        ];
  });

  return chartData;
}

export function xTickValuesForInterval({ start, end }) {
  const monthDiff = differenceInMonths(end, start);

  if (monthDiff < 3) {
    const intervalInDays = eachDayOfInterval({ start, end });
    return intervalInDays.filter(
      (_, index) => !(index % Math.ceil(intervalInDays.length / 6)),
    );
  }

  const eachMonth = eachMonthOfInterval({ start, end });

  if (monthDiff < 36) {
    const eachMonthExcludingFirst = eachMonth.slice(1);
    return eachMonthExcludingFirst.filter(
      (_, index) => !(index % Math.ceil(eachMonthExcludingFirst.length / 6)),
    );
  }

  return eachMonth.filter(
    (_, index) => !(index % (Math.ceil(eachMonth.length / 72) * 12)),
  );
}

export function xAxisMinForChartData(data) {
  const xAxisMin = data.reduce((currentMinDate, serie) => {
    const serieMinDate = new Date(serie.data[0].x);
    return currentMinDate && isBefore(currentMinDate, serieMinDate)
      ? currentMinDate
      : serieMinDate;
  }, null);

  return xAxisMin;
}

export function xTickFormattingFunctionForInterval({ start, end }, intl) {
  const monthDiff = differenceInMonths(end, start);

  if (monthDiff < 3) {
    return (value) => {
      const parts = intl.formatDateToParts(value, {
        format: 'chartDayFormat',
      });

      return `${parts[0].value} ${parts[2].value}`;
    };
  }
  if (monthDiff < 36) {
    return (value) => {
      const parts = intl.formatDateToParts(value, {
        format: 'chartMonthFormat',
      });

      return `${parts[0].value} '${parts[2].value}`;
    };
  }

  return (value) => {
    const parts = intl.formatDateToParts(value, {
      format: 'chartYearFormat',
    });

    return parts[0].value;
  };
}
