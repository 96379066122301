import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { mdiLightningBoltOutline } from '@mdi/js';
import Tooltip from '@mui/material/Tooltip';
import theme from '../../../styles/theme';
import Select from '../../common/Select';
import Spinner from '../../common/Spinner';
import IconWithBackground from '../../common/IconWithBackground';
import {
  EditTable,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableData,
} from '../../common/tables/EditTable';
import IMPACT_TYPE from '../../../constants/impact-type';
import SelectRiskImpactsModal from './AddRiskImpactsModal';
import { useForm } from '../../FormContext';
import { pulse } from '../../../styles/keyframe-styles';
import ImpactsImage from '../../../styles/images/impacts.svg';
import HelpNotification from '../../common/notifications/HelpNotification';
import MagnitudeBadge from '../../common/tables/MagnitudeBadge';
import { useRights } from '../../RightsContext';
import RIGHT_ROLE from '../../../constants/right-role';
import RenderByRightRole from '../../RenderByRightRole';
import { useSettings } from '../../SettingsContext';

function RiskImpacts({ likelihoods }) {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();
  const { impactTargets, impactValues } = useSettings();
  const currentLikelihood = likelihoods.find(
    (likelihood) => likelihood.id === state.currentLikelihoodId.value,
  );
  const targetLikelihood = likelihoods.find(
    (likelihood) => likelihood.id === state.targetLikelihoodId.value,
  );

  function selectImpactTargets(selectedImpactTargets) {
    dispatchFormChange({
      field: 'impacts',
      value: selectedImpactTargets.map(
        (impactTargetId) =>
          state.impacts.value.find(
            (impact) => impact.impactTargetId === impactTargetId,
          ) || { impactTargetId, type: IMPACT_TYPE.NOT_SET },
      ),
    });
  }

  function changeRiskImpactType(impactTargetId, type) {
    dispatchFormChange({
      field: 'impacts',
      value: state.impacts.value.map((impact) =>
        impact.impactTargetId === impactTargetId ? { ...impact, type } : impact,
      ),
    });
  }

  function changeRiskImpactCurrentValue(impactTargetId, currentImpactValueId) {
    dispatchFormChange({
      field: 'impacts',
      value: state.impacts.value.map((impact) =>
        impact.impactTargetId === impactTargetId
          ? { ...impact, currentImpactValueId }
          : impact,
      ),
    });
  }

  function changeRiskImpactTargetValue(impactTargetId, targetImpactValueId) {
    dispatchFormChange({
      field: 'impacts',
      value: state.impacts.value.map((impact) =>
        impact.impactTargetId === impactTargetId
          ? { ...impact, targetImpactValueId }
          : impact,
      ),
    });
  }

  return (
    <Wrapper>
      {impactTargets && impactValues ? (
        <>
          <ImpactHeader>
            <ImpactsTitle>
              <ImpactIcon iconPath={mdiLightningBoltOutline} />
              <FormattedMessage id="IMPACTS" />
            </ImpactsTitle>
            <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
              <SelectRiskImpactsModal
                impactTargets={impactTargets}
                riskImpactTargets={state.impacts.value.map(
                  (impact) => impact.impactTargetId,
                )}
                onSelectImpactTargets={selectImpactTargets}
              />
            </RenderByRightRole>
          </ImpactHeader>
          <ImpactsWrapper>
            {state.impacts.value.length ? (
              <ImpactsTable>
                <TableHead>
                  <TableRow>
                    <TableHeader>
                      <FormattedMessage id="NAME" />
                    </TableHeader>
                    <TableHeader>
                      <FormattedMessage id="TYPE" />
                    </TableHeader>
                    <TableHeader>
                      <FormattedMessage id="CURRENT" />
                    </TableHeader>
                    <TableHeader>
                      <FormattedMessage id="TARGET" />
                    </TableHeader>
                    <MagnitudeTableHeader>
                      <FormattedMessage id="MAGNITUDE" />
                    </MagnitudeTableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.impacts.value.map((impact) => (
                    <ImpactRow
                      key={impact.impactTargetId}
                      impact={impact}
                      impactTargets={impactTargets}
                      impactValues={impactValues}
                      onTypeChange={changeRiskImpactType}
                      onCurrentImpactValueChange={changeRiskImpactCurrentValue}
                      onTargetImpactValueChange={changeRiskImpactTargetValue}
                      currentLikelihood={currentLikelihood}
                      targetLikelihood={targetLikelihood}
                    />
                  ))}
                </TableBody>
              </ImpactsTable>
            ) : (
              <Notification
                small
                srcImage={ImpactsImage}
                title={intl.formatMessage({ id: 'SELECT_IMPACTS' })}
                infoText={intl.formatMessage({ id: 'IMPACTS_INFO' })}
              />
            )}
          </ImpactsWrapper>
        </>
      ) : (
        <Spinner size={theme.arter.icon.size.medium} />
      )}
    </Wrapper>
  );
}

RiskImpacts.propTypes = {
  likelihoods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

const Wrapper = styled.section`
  flex: 1;
  padding: ${(props) => props.theme.arter.spacing.large};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  border: ${(props) => props.theme.arter.border.main};
  background-color: ${(props) => props.theme.arter.color.white};
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover {
    box-shadow: 0 3px 20px -13px ${(props) => props.theme.arter.color.black};
  }
`;

const ImpactHeader = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ImpactIcon = styled(IconWithBackground)`
  margin-right: ${(props) => props.theme.arter.spacing.small};
  margin-bottom: 30px;
`;

const ImpactsTitle = styled.h4`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

const ImpactsWrapper = styled.div`
  overflow: auto;
`;

const Notification = styled(HelpNotification)`
  padding-bottom: ${(props) => props.theme.arter.spacing.extraLarge};
`;

function ImpactRow({
  impact,
  impactTargets,
  impactValues,
  onTypeChange,
  onCurrentImpactValueChange,
  onTargetImpactValueChange,
  currentLikelihood,
  targetLikelihood,
}) {
  const { hasModifierRight } = useRights();
  const intl = useIntl();
  const impactTarget = impactTargets.find(
    (target) => target.id === impact.impactTargetId,
  ).name;
  const currentImpactValue = impactValues.find(
    (impactValue) => impactValue.id === impact.currentImpactValueId,
  );
  const targetImpactValue = impactValues.find(
    (impactValue) => impactValue.id === impact.targetImpactValueId,
  );

  const currentMagnitude =
    currentLikelihood && currentImpactValue
      ? currentLikelihood.value * currentImpactValue.value
      : null;
  const targetMagnitude =
    targetLikelihood && targetImpactValue
      ? targetLikelihood.value * targetImpactValue.value
      : null;

  function impactValueOptions() {
    return (
      <>
        <option value="">{intl.formatMessage({ id: 'NOT_SET' })}</option>
        {impactValues.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
        ;
      </>
    );
  }

  return (
    <TableRow>
      <TableDataName aria-label={intl.formatMessage({ id: 'IMPACT_TARGET' })}>
        {impactTarget}
      </TableDataName>
      <ImpactsTableData>
        <ImpactTypeSelect
          disabled={!hasModifierRight()}
          aria-label={intl.formatMessage({ id: 'IMPACT_TYPE' })}
          value={impact.type}
          onChange={(event) =>
            onTypeChange(impact.impactTargetId, event.target.value)
          }
        >
          {Object.values(IMPACT_TYPE).map((impactType) => (
            <ImpactTypeOption key={impactType} value={impactType}>
              {intl.formatMessage({
                id: impactType,
              })}
            </ImpactTypeOption>
          ))}
        </ImpactTypeSelect>
      </ImpactsTableData>
      <ImpactsTableData>
        <Select
          disabled={!hasModifierRight()}
          aria-label={intl.formatMessage({ id: 'CURRENT_IMPACT' })}
          value={impact.currentImpactValueId || ''}
          onChange={(event) =>
            onCurrentImpactValueChange(
              impact.impactTargetId,
              event.target.value,
            )
          }
        >
          {impactValueOptions()}
        </Select>
      </ImpactsTableData>
      <ImpactsTableData>
        <Select
          disabled={!hasModifierRight()}
          aria-label={intl.formatMessage({ id: 'TARGET_IMPACT' })}
          value={impact.targetImpactValueId || ''}
          onChange={(event) =>
            onTargetImpactValueChange(impact.impactTargetId, event.target.value)
          }
        >
          {impactValueOptions()}
        </Select>
      </ImpactsTableData>

      <TableData aria-label={intl.formatMessage({ id: 'MAGNITUDE' })}>
        <MagnitudesWrapper>
          <Tooltip
            placement="top"
            title={intl.formatMessage({ id: 'CURRENT' })}
          >
            <MagnitudeBadge
              key={`current-magnitude-${currentMagnitude}`}
              $impactType={impact.type}
            >
              {currentMagnitude ? (
                <FormattedNumber
                  maximumFractionDigits={1}
                  value={currentMagnitude}
                />
              ) : (
                '-'
              )}
            </MagnitudeBadge>
          </Tooltip>
          <Tooltip placement="top" title={intl.formatMessage({ id: 'TARGET' })}>
            <TargetMagnitude key={`target-magnitude-${targetMagnitude}`}>
              (
              {targetMagnitude ? (
                <FormattedNumber
                  maximumFractionDigits={1}
                  value={targetMagnitude}
                />
              ) : (
                '-'
              )}
              )
            </TargetMagnitude>
          </Tooltip>
        </MagnitudesWrapper>
      </TableData>
    </TableRow>
  );
}

ImpactRow.propTypes = {
  impact: PropTypes.shape({
    currentImpactValueId: PropTypes.string,
    impactTargetId: PropTypes.string.isRequired,
    targetImpactValueId: PropTypes.string,
    type: PropTypes.oneOf(Object.values(IMPACT_TYPE)).isRequired,
  }).isRequired,
  impactTargets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  impactValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCurrentImpactValueChange: PropTypes.func.isRequired,
  onTargetImpactValueChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  currentLikelihood: PropTypes.shape({
    value: PropTypes.number,
  }),
  targetLikelihood: PropTypes.shape({
    value: PropTypes.number,
  }),
};

const MagnitudeTableHeader = styled(TableHeader)`
  width: 80px;
`;

const TableDataName = styled(TableData)`
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  min-width: 200px;
  vertical-align: middle;
  padding-left: ${(props) => props.theme.arter.spacing.medium};
`;

const ImpactsTableData = styled(TableData)`
  min-width: 150px;
`;

const ImpactsTable = styled(EditTable)`
  min-width: 750px;
  margin-top: ${(props) => props.theme.arter.spacing.extraLarge};
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};

  @media ${(props) => props.theme.arter.device.pad} {
    min-width: unset;
  }
`;

const ImpactTypeSelect = styled(Select)`
  color: ${(props) => {
    switch (props.value) {
      case IMPACT_TYPE.THREAT:
        return props.theme.arter.color.red;
      case IMPACT_TYPE.OPPORTUNITY:
        return props.theme.arter.color.green;
      default:
        return props.theme.arter.font.textColor;
    }
  }};
`;

const ImpactTypeOption = styled.option`
  color: ${(props) => props.theme.arter.font.textColor};
`;

const MagnitudesWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 40px;
  min-width: 50px;
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  vertical-align: middle;
  color: ${(props) => props.theme.arter.color.black};
  margin-left: ${(props) => props.theme.arter.spacing.medium};
`;

const TargetMagnitude = styled.span`
  animation: ${pulse} forwards
    ${(props) => props.theme.arter.animation.speed.normal};
  display: inline-block;
  cursor: default;
`;

export default RiskImpacts;
