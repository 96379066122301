import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FilterChipSelect from './FilterChipSelect';
import { useSettings } from '../../../../SettingsContext';

function TagsFilter({ selectedTags, onChange, onClear }) {
  const intl = useIntl();
  const { tags } = useSettings();

  return (
    <FilterChipSelect
      data-testid="all-filters-tags-filter"
      label={intl.formatMessage({ id: 'TAGS' })}
      htmlFor="tags-select"
      options={tags}
      selectedOptions={selectedTags}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

TagsFilter.propTypes = {
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default TagsFilter;
