import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import EmptyCellData from './EmptyCellData';
import { BaseTableCell } from '../../../common/tables/BaseTable';

function EditedCell({ edited }) {
  const intl = useIntl();

  return (
    <BaseTableCell>
      {edited ? (
        intl.formatDate(edited, {
          format: 'shortDateFormat',
        })
      ) : (
        <EmptyCellData empty />
      )}
    </BaseTableCell>
  );
}

EditedCell.propTypes = {
  edited: PropTypes.string,
};

export default React.memo(EditedCell);
