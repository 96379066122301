import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { mdiClose } from '@mdi/js';
import {
  MenuCloseButton,
  MenuHeader,
  MenuTitle,
} from '../../../../common/MenuHeader';
import {
  popFromBottom,
  popFromRight,
} from '../../../../../styles/keyframe-styles';
import { useFolder } from '../../FolderContext';
import Backdrop from '../../../../common/Backdrop';
import { DEFAULT_FILTER, useFolderFilter } from '../FolderFilterContext';
import StateFilter from './StateFilter';
import TagsFilter from './TagsFilter';
import ResponsibleFilter from './ResponsibleFilter';
import PriorityFilter from './PriorityFilter';
import ManagementStrategyFilter from './ManagementStrategyFilter';
import LikelihoodFilter from './LikelihoodFilter';
import ImpactTargetFilter from './ImpactTargetFilter';
import ImpactValueFilter from './ImpactValueFilter';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import ButtonRow from '../../../../common/ButtonRow';
import ClearAllFiltersButton from '../ClearAllFiltersButton';

function AllFiltersMenu() {
  const { isUpdatingAllFilters, setIsUpdatingAllFilters } = useFolder();
  const { filter, setFilter } = useFolderFilter();
  const [value, setValue] = React.useState(filter);
  const intl = useIntl();
  const anyFilterChanged =
    JSON.stringify(value) !== JSON.stringify(DEFAULT_FILTER);

  function handleClose() {
    setIsUpdatingAllFilters(false);
    setFilter(value);
  }

  React.useEffect(() => {
    setValue(filter);
  }, [filter]);

  return isUpdatingAllFilters ? (
    <>
      <Wrapper data-testid="all-filters-menu">
        <MenuHeader>
          <MenuTitle>
            <FormattedMessage id="ALL_FILTERS" />
          </MenuTitle>
          <MenuCloseButton
            tooltip={intl.formatMessage({ id: 'CLOSE' })}
            iconPath={mdiClose}
            onClick={() => handleClose()}
          />
        </MenuHeader>
        <FiltersWrapper>
          <StateFilter
            selectedStates={value.states}
            onChange={(states) =>
              setValue({
                ...value,
                states,
              })
            }
            onClear={() => setValue({ ...value, states: [] })}
          />
          <TagsFilter
            selectedTags={value.tags}
            onChange={(tags) =>
              setValue({
                ...value,
                tags,
              })
            }
            onClear={() => {
              setValue({ ...value, tags: [] });
            }}
          />
          <ResponsibleFilter
            selectedResponsible={value.responsible}
            onChange={(responsible) =>
              setValue({
                ...value,
                responsible,
              })
            }
            onClear={() => {
              setValue({ ...value, responsible: [] });
            }}
          />
          <PriorityFilter
            selectedPriorities={value.priorities}
            onChange={(priorities) =>
              setValue({
                ...value,
                priorities,
              })
            }
            onClear={() => setValue({ ...value, priorities: [] })}
          />
          <ManagementStrategyFilter
            selectedManagementStrategies={value.managementStrategies}
            onChange={(managementStrategies) =>
              setValue({
                ...value,
                managementStrategies,
              })
            }
            onClear={() => setValue({ ...value, managementStrategies: [] })}
          />
          <LikelihoodFilter
            selectedLikelihoods={value.likelihoods}
            onChange={(likelihoods) =>
              setValue({
                ...value,
                likelihoods,
              })
            }
            onClear={() => {
              setValue({ ...value, likelihoods: [] });
            }}
          />
          <ImpactTargetFilter
            selectedImpactTargets={value.impactTargets}
            onChange={(impactTargets) =>
              setValue({
                ...value,
                impactTargets,
              })
            }
            onClear={() => {
              setValue({ ...value, impactTargets: [] });
            }}
          />
          <ImpactValueFilter
            selectedImpactValues={value.impactValues}
            onChange={(impactValues) =>
              setValue({
                ...value,
                impactValues,
              })
            }
            onClear={() => {
              setValue({ ...value, impactValues: [] });
            }}
          />
        </FiltersWrapper>
        <ButtonRowWrapper>
          <PrimaryButton onClick={() => handleClose()}>
            <FormattedMessage id="OK" />
          </PrimaryButton>
          {anyFilterChanged ? (
            <ClearAllFiltersButton onClear={() => setValue(DEFAULT_FILTER)} />
          ) : null}
        </ButtonRowWrapper>
      </Wrapper>
      <Backdrop onClick={() => handleClose()} />
    </>
  ) : null;
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: ${(props) => props.theme.arter.color.white};
  padding: ${(props) => props.theme.arter.spacing.large};
  border-top-left-radius: ${(props) => props.theme.arter.border.radius.large};
  border-top-right-radius: ${(props) => props.theme.arter.border.radius.large};
  box-shadow: 0 -2px 15px -10px rgba(0, 0, 0, 0.75);
  animation: ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  @media ${(props) => props.theme.arter.device.pad} {
    min-width: 520px;
    width: 520px;
    top: 0;
    left: auto;
    box-shadow: -2px 0 15px -10px rgba(0, 0, 0, 0.75);
    border-top-right-radius: 0;
    border-bottom-left-radius: ${(props) =>
      props.theme.arter.border.radius.large};
    animation-name: ${popFromRight};
  }
`;

const FiltersWrapper = styled.div`
  overflow: auto;
`;

const ButtonRowWrapper = styled(ButtonRow)`
  justify-content: space-between;
  button {
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default AllFiltersMenu;
