import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RISK_STATE from '../../../../constants/risk-state';
import EmptyCellData from './EmptyCellData';
import { BaseTableCell } from '../../../common/tables/BaseTable';
import StateIndicator from '../../../common/tables/StateIndicator';

function StateCell({ state }) {
  return (
    <BaseTableCell>
      {state ? (
        <StateIndicator $state={state}>
          <FormattedMessage id={state} />
        </StateIndicator>
      ) : (
        <EmptyCellData empty />
      )}
    </BaseTableCell>
  );
}

StateCell.propTypes = {
  state: PropTypes.oneOf(Object.values(RISK_STATE)),
};

export default React.memo(StateCell);
