import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BaseCell from './BaseCell';

function PriorityCell({ priority, issue }) {
  return (
    <BaseCell issue={issue}>
      <FormattedMessage id={priority} />
    </BaseCell>
  );
}

PriorityCell.propTypes = {
  priority: PropTypes.string,
  issue: PropTypes.bool,
};

export default PriorityCell;
