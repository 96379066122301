import React from 'react';
import PropTypes from 'prop-types';
import BaseCell from './BaseCell';

function DescriptionCell({ description }) {
  return <BaseCell>{description}</BaseCell>;
}

DescriptionCell.propTypes = {
  description: PropTypes.string,
};

export default DescriptionCell;
