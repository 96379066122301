import React from 'react';
import RenderByRightRole from '../../RenderByRightRole';
import FolderRightsForm from './FolderRightsForm';
import RIGHT_ROLE from '../../../constants/right-role';
import { useFolder } from './FolderContext';
import RightsMenu from '../rights/Rights';

function FolderRights() {
  const { folder, isUpdatingRights, setIsUpdatingRights } = useFolder();
  const [rights, setRights] = React.useState(folder.rights);

  return isUpdatingRights ? (
    <RenderByRightRole role={RIGHT_ROLE.ADMIN}>
      <FolderRightsForm
        folderId={folder.id || 'root'}
        rights={rights}
        onSuccess={setRights}
      >
        <RightsMenu
          folder={folder}
          onClose={() => setIsUpdatingRights(false)}
        />
      </FolderRightsForm>
    </RenderByRightRole>
  ) : null;
}

export default FolderRights;
