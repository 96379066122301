import React from 'react';
import { mdiAlertOutline } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';

function WarningIcon() {
  return <SmallRedIcon path={mdiAlertOutline} />;
}

const SmallRedIcon = styled(Icon)`
  color: ${(props) => props.theme.arter.color.red};
  width: ${(props) => props.theme.arter.icon.size.small};
  height: ${(props) => props.theme.arter.icon.size.small};
`;

export default WarningIcon;
