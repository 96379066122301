import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import DeleteSettingModal from '../DeleteSettingModal';

function DeleteTagModal({ id, ...props }) {
  const intl = useIntl();

  return (
    <DeleteSettingModal
      title={intl.formatMessage({ id: 'DELETE_TAG' })}
      confirmationMessage={intl.formatMessage(
        {
          id: 'TAG_DELETE_CONFIRMATION',
        },
        {
          b: (chunks) => <b>{chunks}</b>,
          br: <br />,
        },
      )}
      deleteEndpoint={`/api/v1/tags/${id}`}
      {...props}
    />
  );
}

DeleteTagModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DeleteTagModal;
