import { css } from 'styled-components';

export default css`
  .MuiAutocomplete-option {
    height: 36px;
    font-size: ${(props) => props.theme.arter.font.size.medium};
    background-color: ${(props) => props.theme.arter.color.white};

    &[aria-selected='true'] {
      background-color: ${(props) =>
        props.theme.arter.color.product.light} !important;

      &.Mui-focused {
        background-color: ${(props) =>
          props.theme.arter.color.product.light} !important;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.arter.color.product.light};
    }
  }
`;
