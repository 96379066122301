import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BaseCell from './BaseCell';
import StateIndicator from '../../../../common/tables/StateIndicator';

function StateCell({ state, issue, ...props }) {
  return (
    <Wrapper {...props} issue={issue}>
      <StateIndicatorWithSmallText state={state}>
        <FormattedMessage id={state} />
      </StateIndicatorWithSmallText>
    </Wrapper>
  );
}

StateCell.propTypes = {
  state: PropTypes.string,
  issue: PropTypes.bool,
};

const StateIndicatorWithSmallText = styled(StateIndicator)`
  font-size: ${(props) => props.theme.arter.font.size.small};
  width: 140px;
`;

const Wrapper = styled(BaseCell)`
  span {
    transform: translate(107px, -27px);
  }
`;

export default StateCell;
