import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ShieldImage from '../styles/images/shield.svg';
import HelpNotification from './common/notifications/HelpNotification';

function PageForbiddenAccess() {
  const intl = useIntl();

  return (
    <ForbiddenAccess
      infoText={intl.formatMessage({ id: 'NO_RIGHTS_INFO_PAGE' })}
    />
  );
}

function FolderForbiddenAccess() {
  const intl = useIntl();

  return (
    <ForbiddenAccess
      infoText={intl.formatMessage({ id: 'NO_RIGHTS_INFO_FOLDER' })}
    />
  );
}

function ForbiddenAccess({ infoText }) {
  const intl = useIntl();

  return (
    <Wrapper>
      <HelpNotification
        title={intl.formatMessage({ id: 'NO_RIGHTS' })}
        srcImage={ShieldImage}
        infoText={infoText}
      />
    </Wrapper>
  );
}

ForbiddenAccess.propTypes = {
  infoText: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 100px;
`;

export { PageForbiddenAccess, FolderForbiddenAccess };
