import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import Form from '../../common/Form';
import FormLabel from '../../common/FormLabel';
import InputField from '../../common/InputField';
import SaveButton from '../../common/buttons/SaveButton';

import {
  Modal,
  ModalButtons,
  ModalCloseButton,
  ModalContent,
  ModalContentWrap,
} from '../../common/Modal';
import { useNotifications } from '../../NotificationsContext';
import { useFolderTree } from '../../FolderTreeContext';
import Spinner from '../../common/Spinner';
import theme from '../../../styles/theme';
import ValidationNotification from '../../common/notifications/ValidationNotification';
import CancelButton from '../../common/buttons/CancelButton';

function CopyRiskModal({ risk, onClose }) {
  const intl = useIntl();
  const { showSuccessNotification } = useNotifications();
  const [name, setName] = React.useState(
    intl.formatMessage(
      { id: 'COPIED_RISK_DEFAULT_NAME' },
      { sourceRiskName: risk.name },
    ),
  );
  const { addValueToDeepRiskCountByFolderId } = useFolderTree();

  const {
    isError: isPostError,
    isSuccess: isPostSuccess,
    isLoading: isPosting,
    callEndpoint: postRisk,
    response: postResponse,
  } = useApi({
    endpoint: `/api/v1/folders/${risk.folder.id || 'root'}/risks?source=${
      risk.id
    }`,
    method: 'POST',
  });

  function copyRisk(event) {
    event.preventDefault();
    postRisk({ name, folderId: null });
  }

  React.useEffect(() => {
    if (isPostSuccess) {
      addValueToDeepRiskCountByFolderId(1, risk.folder.id);
      showSuccessNotification(
        intl.formatMessage({ id: 'COPIED_SUCCESSFULLY' }),
        <NotificationLink to={`/risks/${postResponse.id}`}>
          {name}
        </NotificationLink>,
        undefined,
        true,
      );
      onClose();
    }
  }, [
    intl,
    isPostSuccess,
    postResponse,
    showSuccessNotification,
    addValueToDeepRiskCountByFolderId,
    name,
    onClose,
    risk,
  ]);

  return (
    <Modal open onClose={onClose} closeOnBackdropClick={!isPosting}>
      <ModalContent title={intl.formatMessage({ id: 'COPY_RISK' })}>
        <ModalContentWrap>
          <Form onSubmit={(event) => copyRisk(event)}>
            <FormLabel>
              <FormattedMessage id="NAME" />
              <InputField
                type="text"
                name="name"
                autoFocus
                maxLength="255"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormLabel>
            {isPostError ? (
              <ValidationNotification
                validationMessage={intl.formatMessage({
                  id: 'FAILED_TO_COPY',
                })}
              />
            ) : null}
            <ModalButtons>
              <SaveButton
                type="submit"
                disabled={isPosting || name.trim().length === 0}
              >
                <FormattedMessage id="COPY" />
              </SaveButton>
              <ModalCloseButton>
                <CancelButton type="button" disabled={isPosting}>
                  <FormattedMessage id="CANCEL" />
                </CancelButton>
              </ModalCloseButton>
              {isPosting ? (
                <Spinner size={theme.arter.icon.size.medium} />
              ) : null}
            </ModalButtons>
          </Form>
        </ModalContentWrap>
      </ModalContent>
    </Modal>
  );
}

CopyRiskModal.propTypes = {
  risk: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    folder: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

const NotificationLink = styled(Link)`
  color: ${(props) => props.theme.arter.color.product.default};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};

  &:hover {
    text-decoration: underline;
  }
`;

export default CopyRiskModal;
