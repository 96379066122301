import enLocale from 'date-fns/locale/en-GB';
import fiLocale from 'date-fns/locale/fi';
import messages from './messages';

export default {
  en: {
    reactIntl: {
      locale: 'en-GB',
      messages: messages.en,
      formats: {
        date: {
          dateFormat: {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          },
          shortDateFormat: {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          },
          chartYearFormat: {
            year: 'numeric',
          },
          chartMonthFormat: {
            month: 'short',
            year: '2-digit',
          },
          chartDayFormat: {
            day: 'numeric',
            month: 'short',
          },
        },
      },
    },
    mui: {
      locale: enLocale,
      inputFormat: 'd MMMM yyyy',
    },
  },
  fi: {
    reactIntl: {
      locale: 'fi-FI',
      messages: messages.fi,
      formats: {
        date: {
          dateFormat: {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          },
          shortDateFormat: {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          },
          chartYearFormat: {
            year: 'numeric',
          },
          chartMonthFormat: {
            month: 'short',
            year: '2-digit',
          },
          chartDayFormat: {
            day: 'numeric',
            month: 'short',
          },
        },
      },
    },
    mui: {
      locale: fiLocale,
      inputFormat: 'd.MM.yyyy',
    },
  },
};
