import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { mdiPlus } from '@mdi/js';
import { Wrapper, Content, TableWrapper } from './SettingsView';
import { TableHead, TableBody } from '../../common/tables/EditTable';
import SettingsHeader from './SettingsHeader';
import { SettingsTable } from './SettingsTable';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import ButtonIcon from '../../common/buttons/ButtonIcon';
import { FormProvider } from '../../FormContext';
import theme from '../../../styles/theme';
import Spinner from '../../common/Spinner';
import { useNotifications } from '../../NotificationsContext';

function SettingPage({
  title,
  HeaderRowComponent,
  RowComponent,
  formDataInitializer,
  createFormInitialData,
  rootEndpoint,
  settings,
  isFetchingSettings,
  fetchSettings,
}) {
  const intl = useIntl();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const [activeRowIndex, setActiveRowIndex] = React.useState(null);
  const [isCreating, setIsCreating] = React.useState(false);

  React.useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const onSuccess = React.useCallback(() => {
    setActiveRowIndex(null);
    showSuccessNotification(intl.formatMessage({ id: 'CHANGES_SAVED' }));
  }, [intl, showSuccessNotification]);

  const onError = React.useCallback(() => {
    showErrorNotification(
      intl.formatMessage({
        id: 'FAILED_TO_SAVE_CHANGES',
      }),
    );
  }, [intl, showErrorNotification]);

  const onEdit = React.useCallback((index) => {
    setActiveRowIndex(index);
  }, []);

  const onCancel = React.useCallback(() => {
    setActiveRowIndex(null);
  }, []);

  const onDelete = React.useCallback(() => {
    fetchSettings();
    showSuccessNotification(intl.formatMessage({ id: 'DELETED_SUCCESSFULLY' }));
  }, [intl, fetchSettings, showSuccessNotification]);

  const onCreate = React.useCallback(() => {
    setIsCreating(false);
    fetchSettings();
    showSuccessNotification(intl.formatMessage({ id: 'CHANGES_SAVED' }));
  }, [intl, fetchSettings, showSuccessNotification]);

  const onCreateCancel = React.useCallback(() => {
    setIsCreating(false);
  }, []);

  return (
    <Wrapper>
      <Content>
        <SettingsHeader title={title} />
        {!isFetchingSettings && settings ? (
          <>
            <TableWrapper>
              <SettingsTable>
                <TableHead>
                  <HeaderRowComponent />
                </TableHead>

                <TableBody>
                  {settings.map((setting, index) => (
                    <FormProvider
                      key={setting.id}
                      initialData={setting}
                      formDataInitializer={formDataInitializer}
                      endpoint={`${rootEndpoint}/${setting.id}`}
                      method="PUT"
                      onSuccess={onSuccess}
                      onError={onError}
                    >
                      <RowComponent
                        id={setting.id}
                        onEdit={() => onEdit(index)}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        isRowBeingEdited={activeRowIndex === index}
                        isAnotherRowBeingEdited={
                          (activeRowIndex != null &&
                            activeRowIndex !== index) ||
                          isCreating
                        }
                      />
                    </FormProvider>
                  ))}
                  {isCreating ? (
                    <FormProvider
                      initialData={createFormInitialData}
                      formDataInitializer={formDataInitializer}
                      endpoint={rootEndpoint}
                      method="POST"
                      onSuccess={onCreate}
                      onError={onError}
                    >
                      <RowComponent
                        id="new"
                        isRowBeingEdited
                        onCancel={onCreateCancel}
                      />
                    </FormProvider>
                  ) : null}
                </TableBody>
              </SettingsTable>
            </TableWrapper>
            <PrimaryButton
              disabled={activeRowIndex != null || isCreating}
              onClick={() => setIsCreating(true)}
            >
              <ButtonIcon path={mdiPlus} />
              <FormattedMessage id="CREATE" />
            </PrimaryButton>
          </>
        ) : (
          <Spinner size={theme.arter.icon.size.medium} />
        )}
      </Content>
    </Wrapper>
  );
}

SettingPage.propTypes = {
  title: PropTypes.string.isRequired,
  HeaderRowComponent: PropTypes.elementType.isRequired,
  RowComponent: PropTypes.elementType.isRequired,
  formDataInitializer: PropTypes.func.isRequired,
  createFormInitialData: PropTypes.object.isRequired,
  rootEndpoint: PropTypes.string.isRequired,
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  isFetchingSettings: PropTypes.bool,
  fetchSettings: PropTypes.func.isRequired,
};

export default SettingPage;
