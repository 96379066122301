import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  mdiLightningBoltOutline,
  mdiDice5Outline,
  mdiTagOutline,
} from '@mdi/js';
import SettingsCard from './SettingsCard';

function Settings() {
  const intl = useIntl();
  return (
    <Wrapper>
      <Content>
        <Title>
          <FormattedMessage id="SETTINGS" />
        </Title>
        <SettingsWrapper>
          <NavTitle>
            <FormattedMessage id="GENERAL" />
          </NavTitle>
          <NavWrapper>
            <SettingsCard
              to="/settings/tags"
              icon={mdiTagOutline}
              title={intl.formatMessage({ id: 'TAGS' })}
              infoText={intl.formatMessage({ id: 'TAGS_INFO' })}
            />
          </NavWrapper>
        </SettingsWrapper>
        <SettingsWrapper>
          <NavTitle>
            <FormattedMessage id="RISK_MAGNITUDE" />
          </NavTitle>
          <NavWrapper>
            <SettingsCard
              to="/settings/likelihood-scale"
              icon={mdiDice5Outline}
              title={intl.formatMessage({ id: 'LIKELIHOOD_SCALE' })}
              infoText={intl.formatMessage({ id: 'LIKELIHOOD_SCALE_INFO' })}
            />
            <SettingsCard
              to="/settings/impact-value-scale"
              icon={mdiLightningBoltOutline}
              title={intl.formatMessage({ id: 'IMPACT_SCALE' })}
              infoText={intl.formatMessage({ id: 'IMPACT_SCALE_INFO' })}
            />
            <SettingsCard
              to="/settings/impact-targets"
              icon={mdiLightningBoltOutline}
              title={intl.formatMessage({ id: 'IMPACT_TARGETS' })}
              infoText={
                intl.formatMessage({ id: 'IMPACT_TARGETS_INFO' })
              }
            />
          </NavWrapper>
        </SettingsWrapper>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: ${(props) => props.theme.arter.spacing.extraLarge};
  overflow: auto;
`;

const Content = styled.section`
  max-width: 850px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.arter.font.size.extraExtraLarge};
  margin: ${(props) =>
    `0 ${props.theme.arter.spacing.mediumLarge} 0 ${props.theme.arter.spacing.mediumLarge}`};
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};
`;

const SettingsWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};
`;

const NavTitle = styled.h3`
  font-size: ${(props) => props.theme.arter.font.size.large};
  margin: 0 ${(props) => props.theme.arter.spacing.mediumLarge};
`;

const NavWrapper = styled.nav`
  display: block;

  @media ${(props) => props.theme.arter.device.pad} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default Settings;
