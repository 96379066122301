function loadDevTools(callback) {
  if (process.env.NODE_ENV === 'development') {
    import('./dev-tools')
      .then((devTools) => devTools.install())
      .finally(callback);
  } else {
    callback();
  }
}

export default loadDevTools;
