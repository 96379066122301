import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TableHeader, TableRow } from '../../../common/tables/EditTable';

function TagHeaderRow() {
  return (
    <TableRow>
      <TableHeader>
        <FormattedMessage id="NAME" />
      </TableHeader>
      <TableHeader />
    </TableRow>
  );
}

export default TagHeaderRow;
