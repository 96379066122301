import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useFolderFilter } from './FolderFilterContext';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import ActiveBall from './ActiveBall';

function NoSubfoldersFilter() {
  const { filter, setFilter } = useFolderFilter();

  function toggleFilter() {
    setFilter({ ...filter, includeSubfolders: !filter.includeSubfolders });
  }

  return (
    <NoSubfoldersButton
      $active={!filter.includeSubfolders}
      onClick={() => toggleFilter()}
    >
      {!filter.includeSubfolders ? <ActiveBall /> : null}
      <FormattedMessage id="NO_SUBFOLDERS" />
    </NoSubfoldersButton>
  );
}

const NoSubfoldersButton = styled(SecondaryButton)`
  padding: 0 24px;
  border-color: ${(props) =>
    props.$active
      ? props.theme.arter.color.black
      : props.theme.arter.color.darkGray} !important;
  box-shadow: inset 0 0 0 ${(props) => (props.$active ? '2px' : '0')} #4d4d4d;

  &:focus-visible {
    box-shadow: ${(props) =>
      props.$active
        ? `inset 0 0 0 2px ${props.theme.arter.color.black}, 0 0 0 4px ${props.theme.arter.color.product.light}`
        : `0 0 0 4px ${props.theme.arter.color.product.light}`};
  }
`;

export default NoSubfoldersFilter;
