import RISK_STATE from '../../../../constants/risk-state';
import FOLDER_COLUMN from '../../../../constants/folder-column';
import SORT_DIRECTION from '../../../../constants/sort-direction';

const STATE_VALUE_HIERARCHY = {
  [RISK_STATE.NOT_SET]: 0,
  [RISK_STATE.UNCONTROLLED]: 1,
  [RISK_STATE.PARTIALLY_CONTROLLED]: 2,
  [RISK_STATE.CONTROLLED]: 3,
};

function nameComparator(a, b) {
  if (b.isFolder && !a.isFolder) {
    return -1;
  }

  if (!b.isFolder && a.isFolder) {
    return 1;
  }

  return b.name.localeCompare(a.name);
}

function stateComparator(a, b, direction) {
  if (b.isFolder && !a.isFolder) {
    return direction === SORT_DIRECTION.ASC ? 1 : -1;
  }

  if (a.isFolder && b.isFolder) {
    return 0;
  }

  if (!a.state) {
    return -1;
  }

  if (!b.state) {
    return 1;
  }

  return STATE_VALUE_HIERARCHY[b.state] - STATE_VALUE_HIERARCHY[a.state];
}

function editedComparator(a, b, direction) {
  if (a.isFolder && !b.isFolder) {
    return direction === SORT_DIRECTION.ASC ? -1 : 1;
  }

  if (b.isFolder && !a.isFolder) {
    return direction === SORT_DIRECTION.ASC ? 1 : -1;
  }

  if (a.isFolder && b.isFolder) {
    return 0;
  }

  if (!a.edited) {
    return 1;
  }

  if (!b.edited) {
    return -1;
  }

  if (b.edited === a.edited) {
    return 0;
  }

  return new Date(b.edited).getTime() > new Date(a.edited).getTime() ? 1 : -1;
}

function revisionDateComparator(a, b, direction) {
  if (a.isFolder && !b.isFolder) {
    return direction === SORT_DIRECTION.ASC ? -1 : 1;
  }

  if (b.isFolder && !a.isFolder) {
    return direction === SORT_DIRECTION.ASC ? 1 : -1;
  }

  if (a.isFolder && b.isFolder) {
    return 0;
  }

  if (!a.revisionDate) {
    return 1;
  }

  if (!b.revisionDate) {
    return -1;
  }

  if (b.revisionDate === a.revisionDate) {
    return 0;
  }

  return new Date(b.revisionDate).getTime() < new Date(a.revisionDate).getTime()
    ? -1
    : 1;
}

function likelihoodComparator(a, b, direction) {
  if (a.isFolder && !b.isFolder) {
    return direction === SORT_DIRECTION.ASC ? -1 : 1;
  }

  if (b.isFolder && !a.isFolder) {
    return direction === SORT_DIRECTION.ASC ? 1 : -1;
  }

  if (a.isFolder && b.isFolder) {
    return 0;
  }

  if (!a.likelihood) {
    return 1;
  }

  if (!b.likelihood) {
    return -1;
  }

  return b.likelihood - a.likelihood;
}

const folderContentTableComparatorMap = new Map([
  [FOLDER_COLUMN.NAME, (a, b) => nameComparator(a, b)],
  [FOLDER_COLUMN.STATE, (a, b, direction) => stateComparator(a, b, direction)],
  [
    FOLDER_COLUMN.EDITED,
    (a, b, direction) => editedComparator(a, b, direction),
  ],
  [
    FOLDER_COLUMN.REVISION_DATE,
    (a, b, direction) => revisionDateComparator(a, b, direction),
  ],
  [
    FOLDER_COLUMN.LIKELIHOOD,
    (a, b, direction) => likelihoodComparator(a, b, direction),
  ],
]);

export default folderContentTableComparatorMap;
