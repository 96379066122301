import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import theme from '../../../styles/theme';
import useApi from '../../../hooks/useApi';
import Form from '../../common/Form';
import FormGroup from '../../common/FormGroup';
import FormLabel from '../../common/FormLabel';
import InputField from '../../common/InputField';
import SaveButton from '../../common/buttons/SaveButton';
import CancelButton from '../../common/buttons/CancelButton';
import {
  Modal,
  ModalButtons,
  ModalCloseButton,
  ModalContent,
  ModalContentWrap,
} from '../../common/Modal';
import Spinner from '../../common/Spinner';
import ValidationNotification from '../../common/notifications/ValidationNotification';
import { useNotifications } from '../../NotificationsContext';
import { useFolder } from './FolderContext';
import { useFolderTree } from '../../FolderTreeContext';

function CreateRiskModal() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showSuccessNotification } = useNotifications();
  const [name, setName] = React.useState('');
  const { folder, isCreatingRisk, setIsCreatingRisk } = useFolder();
  const { addValueToDeepRiskCountByFolderId } = useFolderTree();

  const {
    isError: isPostError,
    isSuccess: isPostSuccess,
    isLoading: isPosting,
    callEndpoint: postRisk,
    response: postResponse,
  } = useApi({
    endpoint: `/api/v1/folders/${folder.id || 'root'}/risks`,
    method: 'POST',
  });

  function saveRisk(event) {
    event.preventDefault();
    postRisk({ name, folderId: folder.id || null });
  }

  React.useEffect(() => {
    if (isPostSuccess) {
      addValueToDeepRiskCountByFolderId(1, folder.id);
      setIsCreatingRisk(false);
      showSuccessNotification(
        intl.formatMessage({ id: 'CREATED_SUCCESSFULLY' }),
      );
      navigate(`/risks/${postResponse.id}`);
    }
  }, [
    intl,
    isPostSuccess,
    navigate,
    postResponse,
    setIsCreatingRisk,
    showSuccessNotification,
    addValueToDeepRiskCountByFolderId,
    folder,
  ]);

  return isCreatingRisk ? (
    <Modal
      open
      onClose={() => setIsCreatingRisk(false)}
      closeOnBackdropClick={!isPosting}
    >
      <ModalContent title={intl.formatMessage({ id: 'CREATE_RISK' })}>
        <ModalContentWrap>
          <Form onSubmit={(event) => saveRisk(event)}>
            <FormGroup>
              <FormLabel htmlFor="name-input">
                <FormattedMessage id="NAME" />
              </FormLabel>
              <InputField
                id="name-input"
                type="text"
                name="name"
                autoFocus
                maxLength="255"
                onChange={(event) => setName(event.target.value)}
              />
            </FormGroup>

            {isPostError ? (
              <ValidationNotification
                validationMessage={intl.formatMessage({
                  id: 'FAILED_TO_CREATE',
                })}
              />
            ) : null}
            <ModalButtons>
              <SaveButton
                type="submit"
                disabled={isPosting || name.trim().length === 0}
              >
                <FormattedMessage id="CREATE" />
              </SaveButton>
              <ModalCloseButton>
                <CancelButton type="button" disabled={isPosting}>
                  <FormattedMessage id="CANCEL" />
                </CancelButton>
              </ModalCloseButton>
              {isPosting ? (
                <Spinner size={theme.arter.icon.size.medium} />
              ) : null}
            </ModalButtons>
          </Form>
        </ModalContentWrap>
      </ModalContent>
    </Modal>
  ) : null;
}

export default CreateRiskModal;
