import React from 'react';
import { useIntl } from 'react-intl';
import { mdiShieldAccountOutline } from '@mdi/js';
import { useForm } from '../../../FormContext';
import { useKeycloak } from '../../../KeycloakContext';
import { useRights } from '../../../RightsContext';
import ChipSelect from '../../../common/multi-select/ChipSelect';
import { Content, Header, Section } from './RightMenuSection';

function RiskResponsibleUsers() {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();
  const { userMap } = useKeycloak();
  const { hasModifierRight } = useRights();

  function handleSelectUser(users) {
    dispatchFormChange({
      field: 'responsible',
      value: {
        users,
      },
    });
  }

  return (
    <Section>
      <Header
        label={intl.formatMessage({ id: 'RESPONSIBLE' })}
        htmlFor="responsible-select"
        iconPath={mdiShieldAccountOutline}
      />
      <Content>
        <ChipSelect
          id="responsible-select"
          data-testid="responsible-users-select"
          options={[...userMap.values()].map(({ id, lastName, firstName }) => ({
            id,
            name: `${lastName}, ${firstName}`,
            isDeleted: false,
          }))}
          selectedOptions={state.responsible.value.users.map((id) => {
            const user = userMap.get(id);

            return {
              id,
              name: user
                ? `${user.lastName}, ${user.firstName}`
                : intl.formatMessage({ id: 'DELETED_USER' }),
              isDeleted: !user,
            };
          })}
          isDisabled={!hasModifierRight()}
          onChange={(value) => handleSelectUser(value.map(({ id }) => id))}
          placeholderText={
            hasModifierRight()
              ? intl.formatMessage({ id: 'ADD_RESPONSIBLE' })
              : null
          }
        />
      </Content>
    </Section>
  );
}

export default RiskResponsibleUsers;
