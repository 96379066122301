import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../../FormContext';
import SettingDescriptionInputField from '../SettingDescriptionInputField';
import SettingNameInputField from '../SettingNameInputField';
import DeleteImpactTargetModal from './DeleteImpactTargetModal';
import SettingRow from '../SettingRow';

function ImpactTargetRow({ id, ...props }) {
  const { state } = useForm();

  return (
    <SettingRow
      id={id}
      data-testid={`impact-target-row-${id}`}
      inputFields={[SettingNameInputField, SettingDescriptionInputField]}
      DeleteModalComponent={DeleteImpactTargetModal}
      disableSave={state.name.value.trim().length === 0}
      {...props}
    />
  );
}

ImpactTargetRow.propTypes = {
  id: PropTypes.string,
};

export default ImpactTargetRow;
