import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';
import { useIntl } from 'react-intl';
import DEVICE from '../../../../styles/device';
import theme from '../../../../styles/theme';
import HelpNotification from '../../../common/notifications/HelpNotification';
import NoData from '../../../../styles/images/no_data.svg';

function PieChart({ data }) {
  const intl = useIntl();
  const [windowDimensions, setWindowDimensions] = React.useState(
    window.innerWidth,
  );
  const hasData = React.useMemo(
    () => data && data.some((dataPoint) => dataPoint.value > 0),
    [data],
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const windowDimensionsWidth = windowDimensions;

  return hasData ? (
    <ResponsivePie
      data={data}
      theme={{
        fontSize:
          windowDimensionsWidth < DEVICE.DESKTOP_LARGE
            ? theme.arter.font.size.small
            : theme.arter.font.size.medium,
        tooltip: {
          fontSize: theme.arter.font.size.small,
        },
      }}
      colors={{ datum: 'data.color' }}
      margin={{
        top: 20,
        right: windowDimensionsWidth < DEVICE.DESKTOP_LARGE ? 10 : 150,
        bottom: 20,
        left: 10,
      }}
      innerRadius={0.77}
      startAngle={0}
      padAngle={0}
      cornerRadius={0}
      activeOuterRadiusOffset={4}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      legends={[
        {
          data: data.map((event) => ({
            label: `${event.value}  ${event.label}`,
            color: event.color,
          })),
          anchor:
            windowDimensionsWidth < DEVICE.DESKTOP_LARGE ? 'center' : 'right',
          direction: 'column',
          justify: false,
          translateY: 0,
          translateX: windowDimensionsWidth < DEVICE.DESKTOP_LARGE ? 0 : 70,
          itemsSpacing: windowDimensionsWidth < DEVICE.DESKTOP_LARGE ? 8 : 16,
          itemWidth: 90,
          itemHeight: 18,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: windowDimensionsWidth < DEVICE.DESKTOP_LARGE ? 12 : 14,
          symbolShape: 'circle',
        },
      ]}
    />
  ) : (
    <Notification
      small
      srcImage={NoData}
      title={intl.formatMessage({ id: 'NO_DATA' })}
    />
  );
}

PieChart.propTypes = {
  data: PropTypes.array,
};

const Notification = styled(HelpNotification)`
  margin-top: ${(props) => props.theme.arter.font.size.medium};
`;

export default PieChart;
