import styled from 'styled-components';
import Icon from '@mdi/react';

const DeleteIcon = styled(Icon)`
  height: ${(props) => props.theme.arter.icon.size.small};
  width: ${(props) => props.theme.arter.icon.size.small};

  &:hover {
    color: ${(props) => props.theme.arter.color.black} !important;
  }
`;

export default DeleteIcon;
