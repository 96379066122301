import React from 'react';
import PropTypes from 'prop-types';
import { DotsItem, useTheme } from '@nivo/core';

function HideLastPointLayer({
  points,
  pointSize,
  pointBorderWidth,
  pointLabelYOffset,
}) {
  const theme = useTheme();
  const shownPoints = points.filter((point) => !point.data.hidePoint);

  return (
    <g>
      {shownPoints.map((point) => (
        <DotsItem
          key={point.id}
          x={point.x}
          y={point.y}
          datum={point.data}
          size={pointSize}
          color={point.color}
          borderWidth={pointBorderWidth}
          borderColor={point.borderColor}
          label={point.label}
          labelYOffset={pointLabelYOffset}
          theme={theme}
        />
      ))}
    </g>
  );
}

HideLastPointLayer.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      serieId: PropTypes.string,
    }),
  ),
  pointSize: PropTypes.number,
  pointBorderWidth: PropTypes.number,
  pointLabelYOffset: PropTypes.number,
};

export default HideLastPointLayer;
