import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { FormProvider } from '../../FormContext';
import { useNotifications } from '../../NotificationsContext';

function formForFolderRights({ groups }) {
  return {
    groups: { initialValue: groups, value: groups, dirty: false },
  };
}

function FolderRightsForm({ folderId, rights, onSuccess, ...props }) {
  const intl = useIntl();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  return (
    <FormProvider
      initialData={rights}
      formDataInitializer={formForFolderRights}
      endpoint={`/api/v1/folders/${folderId}/rights`}
      method="PUT"
      onSuccess={(response) => {
        onSuccess(response);
        showSuccessNotification(intl.formatMessage({ id: 'CHANGES_SAVED' }));
      }}
      onError={() =>
        showErrorNotification(
          intl.formatMessage({
            id: 'FAILED_TO_SAVE_CHANGES',
          }),
        )
      }
    >
      {props.children}
    </FormProvider>
  );
}

FolderRightsForm.propTypes = {
  folderId: PropTypes.string.isRequired,
  rights: PropTypes.shape({
    groups: PropTypes.array,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FolderRightsForm;
