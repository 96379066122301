import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';

const DropdownListItem = styled(MenuItem)`
  font-size: ${(props) => props.theme.arter.font.size.medium};

  &.Mui-selected {
    background-color: ${(props) => props.theme.arter.color.product.light};

    &:hover {
      background-color: ${(props) => props.theme.arter.color.product.light};
    }
  }
`;

export default DropdownListItem;
