import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ClearButton from '../../../common/buttons/ClearButton';

function ClearAllFiltersButton({ onClear }) {
  return (
    <ClearButton onClick={() => onClear()}>
      <FormattedMessage id="CLEAR_ALL" />
    </ClearButton>
  );
}

ClearAllFiltersButton.propTypes = {
  onClear: PropTypes.func.isRequired,
};

export default ClearAllFiltersButton;
