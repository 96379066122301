import { keyframes } from 'styled-components';

const slideFromTop = keyframes`
    0% {
      transform: translateY(-12px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
`;

const slideFromBottom = keyframes`
    0% {
      transform: translateY(12px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
`;

const slideFromLeft = keyframes`
    0% {
      transform: translateX(-4px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
`;

const appears = keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
`;

const popFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(-4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const popToBottom = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-4px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const popFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  50% {
    opacity: 1;
    transform: translateX(-4px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`;

export {
  slideFromTop,
  slideFromBottom,
  slideFromLeft,
  appears,
  popFromBottom,
  popToBottom,
  popFromRight,
  spin,
  pulse,
};
