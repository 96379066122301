import styled from 'styled-components';

const FormLabel = styled.label`
  display: block;
  color: ${(props) => props.theme.arter.color.black};
  font-size: ${(props) => props.theme.arter.font.size.medium};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
  cursor: pointer;
`;

export default FormLabel;
