import styled from 'styled-components';
import { Icon } from '@mdi/react';

const TreeItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) =>
    props.$root ? 0 : props.theme.arter.spacing.large};
`;

const TreeItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.$toggleable ? 0 : '24px')};
  padding: ${(props) => `0 0 0 ${props.theme.arter.spacing.medium}`};
  cursor: pointer;
  height: 30px;
  border-radius: ${(props) => props.theme.arter.border.radius.large};
  transition: ${(props) => props.theme.arter.animation.speed.normal};
  color: ${(props) => props.theme.arter.color.black};
  font-weight: ${(props) =>
    props.$active
      ? props.theme.arter.font.weight.bold
      : props.theme.arter.font.weight.regular};
  background-color: ${(props) =>
    props.$active ? props.theme.arter.color.product.light : 'transparent'};
  ${(props) =>
    !props.$active &&
    `
  &:hover {
    background-color: ${props.theme.arter.color.veryLightGray};
  }
  `}
`;

const ToggleButton = styled(Icon)`
  max-width: ${(props) => props.theme.arter.icon.size.small};
  min-width: ${(props) => props.theme.arter.icon.size.small};
  margin-right: ${(props) => props.theme.arter.spacing.small};
  transition: ${(props) => props.theme.arter.animation.speed.fast};
  ${(props) => props.$active && 'transform: rotate(90deg);'}
`;

const NameWrapper = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  font-size: ${(props) => props.theme.arter.font.size.medium};
  padding: ${(props) =>
    `${props.theme.arter.spacing.small} ${props.theme.arter.spacing.large} ${props.theme.arter.spacing.small} 0`};
`;

const FolderIcon = styled(Icon)`
  max-width: ${(props) => props.theme.arter.icon.size.small};
  min-width: ${(props) => props.theme.arter.icon.size.small};
  margin-right: ${(props) => props.theme.arter.spacing.small};
`;

const ItemName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${(props) => props.theme.arter.font.size.small};
`;

const Count = styled.span`
  margin-left: auto;
  font-weight: ${(props) => props.theme.arter.font.weight.regular};
  font-size: ${(props) => props.theme.arter.font.size.small};
`;

export {
  TreeItemWrapper,
  TreeItem,
  ToggleButton,
  FolderIcon,
  NameWrapper,
  ItemName,
  Count,
};
