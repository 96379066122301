import React from 'react';

function useSessionStorageState({ key, defaultValue }) {
  const [state, setState] = React.useState(() => {
    const storedStateJSON = window.sessionStorage.getItem(key);
    return storedStateJSON ? JSON.parse(storedStateJSON) : defaultValue;
  });

  function resetState() {
    setState(defaultValue);
  }

  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState, resetState];
}

export default useSessionStorageState;
