import React from 'react';
import PropTypes from 'prop-types';
import BaseCell from './BaseCell';

function NameCell({ name }) {
  return <BaseCell>{name}</BaseCell>;
}

NameCell.propTypes = {
  name: PropTypes.string,
};

export default NameCell;
