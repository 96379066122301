import { Icon } from '@mdi/react';
import styled from 'styled-components';

const MainLeftMenuItem = styled.input`
  display: flex;
  align-items: center;
  width: calc(100% - ${(props) => props.theme.arter.spacing.small});
  cursor: pointer;
  padding: ${(props) => props.theme.arter.spacing.medium};
  padding-left: ${(props) => props.theme.arter.spacing.large};
  border-radius: ${(props) => props.theme.arter.border.radius.large};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: ${(props) => props.theme.arter.spacing.large};
  color: ${(props) => props.theme.arter.color.black};
  line-height: 1;
  transition: ${(props) => props.theme.arter.animation.speed.normal};
  font-size: ${(props) => props.theme.arter.font.size.medium};
  font-weight: ${(props) =>
    props.$active
      ? props.theme.arter.font.weight.bold
      : props.theme.arter.font.weight.regular};
  background-color: ${(props) =>
    props.$active ? props.theme.arter.color.product.light : 'transparent'};
  ${(props) =>
    !props.$active &&
    `
    &:hover {
    background-color: ${props.theme.arter.color.veryLightGray};
  }
  `}
`;

const ItemIcon = styled(Icon)`
  max-width: ${(props) => props.theme.arter.icon.size.small};
  min-width: ${(props) => props.theme.arter.icon.size.small};
  margin-right: ${(props) => props.theme.arter.spacing.small};
`;

export { MainLeftMenuItem, ItemIcon };
