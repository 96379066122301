import styled from 'styled-components';
import RISK_STATE from '../../../constants/risk-state';

const StateIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.arter.font.size.small};
  white-space: nowrap;
  background-color: ${(props) => {
    switch (props.$state) {
      case RISK_STATE.CONTROLLED:
        return props.theme.arter.color.lightGreen;
      case RISK_STATE.UNCONTROLLED:
        return props.theme.arter.color.lightRed;
      case RISK_STATE.PARTIALLY_CONTROLLED:
        return props.theme.arter.color.lightOrange;
      default:
        return props.theme.arter.color.veryLightGray;
    }
  }};
  padding: ${(props) =>
    `${props.theme.arter.spacing.small} ${props.theme.arter.spacing.medium}`};
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  max-width: 170px;
  height: 32px;

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    font-size: ${(props) => props.theme.arter.font.size.medium};
  }
`;

export default StateIndicator;
