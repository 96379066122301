import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  ModalCloseButton,
  ModalContent,
  ModalContentWrap,
} from '../../common/Modal';
import WarningButton from '../../common/buttons/WarningButton';
import useApi from '../../../hooks/useApi';
import Spinner from '../../common/Spinner';
import theme from '../../../styles/theme';
import CancelButton from '../../common/buttons/CancelButton';
import ValidationNotification from '../../common/notifications/ValidationNotification';

function DeleteSettingModal({
  title,
  confirmationMessage,
  deleteEndpoint,
  onDelete,
  onClose,
}) {
  const intl = useIntl();
  const {
    isLoading: isDeleting,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    callEndpoint: deleteSetting,
  } = useApi({
    endpoint: deleteEndpoint,
    method: 'DELETE',
  });

  React.useEffect(() => {
    if (isDeleteSuccess) {
      onDelete();
    }
  }, [isDeleteSuccess, onDelete]);

  return (
    <Modal open onClose={onClose} closeOnBackdropClick={!isDeleting}>
      <ModalContent title={title}>
        <ModalContentWrap>
          <p>{confirmationMessage}</p>
          {isDeleteError ? (
            <ValidationNotification
              validationMessage={intl.formatMessage({ id: 'FAILED_TO_DELETE' })}
            />
          ) : null}
          <ModalButtons>
            <WarningButton
              disabled={isDeleting}
              onClick={() => deleteSetting()}
            >
              <FormattedMessage id="DELETE" />
            </WarningButton>
            <ModalCloseButton>
              <CancelButton disabled={isDeleting}>
                <FormattedMessage id="CANCEL" />
              </CancelButton>
            </ModalCloseButton>
            {isDeleting ? (
              <Spinner size={theme.arter.icon.size.medium} />
            ) : null}
          </ModalButtons>
        </ModalContentWrap>
      </ModalContent>
    </Modal>
  );
}

DeleteSettingModal.propTypes = {
  title: PropTypes.string,
  confirmationMessage: PropTypes.arrayOf(PropTypes.any),
  deleteEndpoint: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeleteSettingModal;
