import styled from 'styled-components';
import ChipSelect from '../../../common/multi-select/ChipSelect';

const FilterMenuChipSelect = styled(ChipSelect)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
  }
`;

export default FilterMenuChipSelect;
