import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { mdiClose } from '@mdi/js';
import { Autocomplete, TextField } from '@mui/material';
import Chip from '../Chip';
import DeleteIcon from './DeleteIcon';
import muiInputOverride from '../../../styles/mui-input-override';
import ListItemText from './ListItemText';
import ListItemCheckIcon from './ListItemCheckIcon';
import muiAutoCompleteOverride from '../../../styles/mui-auto-complete-override';

function ChipSelect({
  options,
  selectedOptions,
  isDisabled,
  onChange,
  placeholderText,
  ...props
}) {
  const handleDelete = (option) => {
    onChange(selectedOptions.filter((selected) => selected.id !== option.id));
  };

  return (
    <Wrapper
      disableClearable
      clearOnBlur
      popupIcon={null}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      multiple
      disabled={isDisabled}
      onChange={(_event, value) => onChange(value)}
      value={selectedOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={selectedOptions.length === 0 ? placeholderText : null}
        />
      )}
      renderTags={(value) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {value.map((option) => (
            <Chip
              key={option.id}
              label={option.name}
              onDelete={() => handleDelete(option)}
              data-testid={`chip-${option.id}`}
              deleteIcon={
                <DeleteIcon
                  data-testid="delete-chip"
                  path={mdiClose}
                  onMouseDown={(event) => event.stopPropagation()}
                />
              }
            />
          ))}
        </Box>
      )}
      getOptionLabel={({ name }) => name}
      options={options}
      renderOption={(optionProps, option) => (
        <MenuItem key={option.id} value={option.id} {...optionProps}>
          <ListItemText>{option.name}</ListItemText>
          <ListItemCheckIcon show={selectedOptions.includes(option.id)} />
        </MenuItem>
      )}
      {...props}
    />
  );
}

ChipSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
};

const Wrapper = styled(Autocomplete)`
  ${muiAutoCompleteOverride}
  ${muiInputOverride}
  width: 100%;

  .MuiSelect-icon {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;

    legend {
      display: none;
    }
  }

  .Mui-disabled {
    .MuiChip-deleteIcon {
      display: none;
    }
  }
`;

export default ChipSelect;
