import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BaseCell from './BaseCell';

function LikelihoodCell({ likelihood, issue }) {
  return (
    <BaseCell issue={issue}>
      {likelihood ? likelihood.value : <FormattedMessage id="NOT_SET" />}
    </BaseCell>
  );
}

LikelihoodCell.propTypes = {
  likelihood: PropTypes.shape({
    value: PropTypes.number,
  }),
  issue: PropTypes.bool,
};

export default LikelihoodCell;
