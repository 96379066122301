import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  InfoNotification,
  SuccessNotification,
  WarningNotification,
} from './common/notifications/Notification';
import useEffectOnLocationChange from '../hooks/useEffectOnLocationChange';

const NOTIFICATION_COMPONENTS = {
  INFO: React.memo(InfoNotification),
  SUCCESS: React.memo(SuccessNotification),
  WARNING: React.memo(WarningNotification),
};

export const NOTIFICATION_DEFAULT_DURATION_MS = 4000;

const NotificationsContext = React.createContext();

function NotificationProvider({ children }) {
  const [notifications, setNotifications] = React.useState([]);

  const removeNotification = React.useCallback((id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id),
    );
  }, []);

  const closeNotification = React.useCallback(
    (id) => {
      notifications.find((notifcation) => notifcation.id === id).onClose(id);
    },
    [notifications],
  );

  const closePersistentNotifications = React.useCallback(() => {
    notifications
      .filter(({ duration }) => duration === undefined)
      .forEach(({ id, onClose }) => onClose(id));
  }, [notifications]);

  const addNotification = React.useCallback(
    (Component, title, message, duration, onClose) => {
      const notification = {
        id: uuidv4(),
        Component,
        title,
        message,
        duration,
        onClose: (id) => {
          if (onClose) {
            onClose(id);
          }
          removeNotification(id);
        },
      };
      setNotifications([...notifications, notification]);

      return notification;
    },
    [notifications, removeNotification],
  );

  const showSuccessNotification = React.useCallback(
    (title, message, onClose, persistent) => {
      addNotification(
        NOTIFICATION_COMPONENTS.SUCCESS,
        title,
        message,
        persistent ? undefined : NOTIFICATION_DEFAULT_DURATION_MS,
        onClose,
      );
    },
    [addNotification],
  );

  const showErrorNotification = React.useCallback(
    (title, message, onClose) => {
      addNotification(
        NOTIFICATION_COMPONENTS.WARNING,
        title,
        message,
        NOTIFICATION_DEFAULT_DURATION_MS,
        onClose,
      );
    },
    [addNotification],
  );

  const showInfoNotification = React.useCallback(
    (title, message, onClose) =>
      addNotification(
        NOTIFICATION_COMPONENTS.INFO,
        title,
        message,
        undefined,
        onClose,
      ),
    [addNotification],
  );

  const notifcationProviderValue = React.useMemo(
    () => ({
      showSuccessNotification,
      showErrorNotification,
      showInfoNotification,
      closeNotification,
    }),
    [
      showSuccessNotification,
      showErrorNotification,
      showInfoNotification,
      closeNotification,
    ],
  );

  useEffectOnLocationChange(closePersistentNotifications);

  return (
    <NotificationsContext.Provider value={notifcationProviderValue}>
      {children}
      <Wrapper>
        {notifications.map(
          ({ id, Component, title, message, duration, onClose }) => (
            <Component
              id={id}
              key={id}
              role="alert"
              title={title}
              message={message}
              onClose={onClose}
              duration={duration}
            />
          ),
        )}
      </Wrapper>
    </NotificationsContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 350px;
  position: absolute;
  left: ${(props) => props.theme.arter.spacing.extraLarge};
  bottom: ${(props) => props.theme.arter.spacing.extraLarge};
  z-index: 6;
`;

function useNotifications() {
  const context = React.useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      `useNotifications must be used within a NotificationsProvider`,
    );
  }
  return context;
}

export { NotificationProvider, useNotifications };
