import styled from 'styled-components';
import Button from './Button';

const SecondaryButton = styled(Button)`
  color: ${(props) => props.theme.arter.color.black};
  background-color: ${(props) => props.theme.arter.color.white};
  border: 1px solid ${(props) => props.theme.arter.color.darkGray};
`;

export default SecondaryButton;
