import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import InputField from '../../common/InputField';
import { useForm } from '../../FormContext';

function SettingNameInputField({ disabled }) {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();

  return (
    <InputField
      aria-label={intl.formatMessage({ id: 'NAME' })}
      maxLength="255"
      value={state.name.value}
      onChange={(event) =>
        dispatchFormChange({
          field: 'name',
          value: event.target.value,
        })
      }
      disabled={disabled}
      placeholder={intl.formatMessage({ id: 'ADD_NAME' })}
    />
  );
}

SettingNameInputField.propTypes = {
  disabled: PropTypes.bool,
};

export default SettingNameInputField;
