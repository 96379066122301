import PropTypes from 'prop-types';
import React from 'react';
import useApi from '../hooks/useApi';

const FolderTreeContext = React.createContext();

function FolderTreeProvider({ openedFolders, disabledFolders, ...props }) {
  const {
    response: folders,
    isLoading,
    callEndpoint: update,
  } = useApi({
    endpoint: '/api/v1/folders',
    method: 'GET',
  });
  const [folderTree, setFolderTree] = React.useState(folders);
  const [openFolders, setOpenFolders] = React.useState(openedFolders);
  const [selectedFolder, setSelectedFolder] = React.useState();

  const openFolderById = React.useCallback(
    (id) => setOpenFolders((open) => [...open, id]),
    [],
  );

  const openFoldersById = React.useCallback(
    (ids) => setOpenFolders((open) => [...open, ...ids]),
    [],
  );

  const closeFolderById = React.useCallback(
    (id) => setOpenFolders((open) => open.filter((folder) => folder !== id)),
    [],
  );

  const isFolderOpen = React.useCallback(
    (id) => openFolders.includes(id),
    [openFolders],
  );

  const isFolderSelected = React.useCallback(
    (id) => selectedFolder === id,
    [selectedFolder],
  );

  const toggleFolderById = React.useCallback(
    (id) => (isFolderOpen(id) ? closeFolderById(id) : openFolderById(id)),
    [closeFolderById, isFolderOpen, openFolderById],
  );

  const isFolderDisabled = React.useCallback(
    (id) => disabledFolders.includes(id),
    [disabledFolders],
  );

  const addValueToDeepRiskCountByFolderId = React.useCallback(
    (value, id, folder) => {
      if (folder.id === id) {
        return { ...folder, deepRiskCount: folder.deepRiskCount + value };
      }

      let subFolderUpdated = false;
      const updatedSubFolders = folder.subFolders.map((subFolder) => {
        if (subFolderUpdated) {
          return subFolder;
        }

        const updated = addValueToDeepRiskCountByFolderId(value, id, subFolder);
        if (subFolder.deepRiskCount !== updated.deepRiskCount) {
          subFolderUpdated = true;
        }

        return updated;
      });

      return {
        ...folder,
        subFolders: updatedSubFolders,
        deepRiskCount: folder.deepRiskCount + (subFolderUpdated ? value : 0),
      };
    },
    [],
  );

  React.useEffect(() => {
    update();
  }, [update]);

  React.useEffect(() => {
    setFolderTree(folders);
  }, [folders]);

  return (
    <FolderTreeContext.Provider
      value={{
        folderTree,
        isLoading,
        update,
        openFolderById,
        openFoldersById,
        closeFolderById,
        isFolderOpen,
        isFolderSelected,
        selectedFolder,
        setSelectedFolder,
        toggleFolderById,
        isFolderDisabled,
        addValueToDeepRiskCountByFolderId: (value, id) =>
          setFolderTree(
            addValueToDeepRiskCountByFolderId(value, id, folderTree),
          ),
      }}
      {...props}
    />
  );
}

FolderTreeProvider.propTypes = {
  openedFolders: PropTypes.arrayOf(PropTypes.string),
  disabledFolders: PropTypes.arrayOf(PropTypes.string),
};

FolderTreeProvider.defaultProps = {
  openedFolders: [],
  disabledFolders: [],
};

function useFolderTree() {
  const context = React.useContext(FolderTreeContext);
  if (!context) {
    throw new Error(`useFolderTree must be used within a FolderTreeProvider`);
  }
  return context;
}

export { FolderTreeProvider, useFolderTree };
