import React from 'react';
import { useIntl } from 'react-intl';
import HelpNotification from '../../../common/notifications/HelpNotification';
import EmptyFolder from '../../../../styles/images/empty-folder.svg';
import { useRights } from '../../../RightsContext';

function EmptyFolderContentNotification() {
  const intl = useIntl();
  const { hasModifierRight } = useRights();

  return (
    <HelpNotification
      srcImage={EmptyFolder}
      title={intl.formatMessage({ id: 'NO_CONTENT' })}
      infoText={
        hasModifierRight()
          ? intl.formatMessage({ id: 'EMPTY_FOLDER_INFO' })
          : ''
      }
    />
  );
}

export default EmptyFolderContentNotification;
