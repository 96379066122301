import styled from 'styled-components';
import { slideFromLeft } from '../../../../styles/keyframe-styles';

const ActiveBall = styled.span`
  position: absolute;
  left: ${(props) => props.theme.arter.spacing.mediumLarge};
  width: 8px;
  height: 8px;
  margin-right: ${(props) => props.theme.arter.spacing.small};
  border-radius: ${(props) => props.theme.arter.border.radius.full};
  background-color: ${(props) => props.theme.arter.color.product.default};
  animation: ${slideFromLeft} forwards
    ${(props) => props.theme.arter.animation.speed.normal};
`;

export default ActiveBall;
