import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { mdiCircle } from '@mdi/js';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import {
  Tooltip,
  Header,
  Title,
  Content,
  TooltipListItem,
  ImpactName,
  MagnitudeTypeListItem,
} from '../../../../common/charts/CustomTooltip';

function RiskMagnitudeChartTooltipItem({ point }) {
  return (
    <>
      <TooltipListItem>
        <ImpactListItemCircle
          path={mdiCircle}
          color={point.serieColor}
        ></ImpactListItemCircle>
        <NameWrapper>
          <Name>{point.serieId}</Name>
          <Value> {point.data.y} </Value>
        </NameWrapper>
      </TooltipListItem>
      <MagnitudeTypeListItem color={point.serieColor} type={point.data.type}>
        {point.data.type ? <FormattedMessage id={point.data.type} /> : null}
      </MagnitudeTypeListItem>
    </>
  );
}

RiskMagnitudeChartTooltipItem.propTypes = {
  point: PropTypes.shape({
    serieId: PropTypes.string,
    serieColor: PropTypes.string,
    data: PropTypes.shape({
      type: PropTypes.string,
      y: PropTypes.number,
    }),
  }),
};

function RiskMagnitudeHistoryTooltip({ slice }) {
  const intl = useIntl();

  return (
    <TooltipWrapper>
      <Header>
        <DateTitle>
          {intl.formatDate(slice.points[0].data.xFormatted, {
            format: 'shortDateFormat',
          })}
        </DateTitle>
      </Header>
      <Content>
        {slice.points.map((point) => (
          <RiskMagnitudeChartTooltipItem key={point.serieId} point={point} />
        ))}
      </Content>
    </TooltipWrapper>
  );
}

RiskMagnitudeHistoryTooltip.propTypes = {
  slice: PropTypes.shape({
    points: PropTypes.arrayOf(
      PropTypes.shape({
        serieId: PropTypes.string,
        serieColor: PropTypes.string,
        data: PropTypes.shape({
          type: PropTypes.string,
          y: PropTypes.number,
          xFormatted: PropTypes.string,
        }),
      }),
    ),
  }),
};

const TooltipWrapper = styled(Tooltip)`
  position: relative;
  left: ${(props) => (props.leftOfCenter ? 4 : 'auto')};
  right: ${(props) => (props.leftOfCenter ? 'auto' : 4)};
  min-width: 200px;
  max-width: 200px;
`;

const DateTitle = styled(Title)`
  font-weight: ${(props) => props.theme.arter.font.weight.regular};
`;

const ImpactListItemCircle = styled(Icon)`
  color: ${(props) => props.color};
  min-width: 12px;
  height: 12px;
  margin-right: ${(props) => props.theme.arter.spacing.small};
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Name = styled(ImpactName)`
  display: block;
  width: 100%;
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${(props) => props.theme.arter.spacing.medium};
`;

const Value = styled.span`
  margin-left: auto;
  margin-right: ${(props) => props.theme.arter.spacing.small};
  font-size: ${(props) => props.theme.arter.font.size.small};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
`;

export default RiskMagnitudeHistoryTooltip;
