import styled from 'styled-components';
import Button from './Button';

const ClearButton = styled(Button)`
  border: none;
  color: ${(props) => props.theme.arter.color.product.default};
  background-color: ${(props) => props.theme.arter.color.white};
  height: auto;
  padding: ${(props) => props.theme.arter.spacing.medium};
  min-width: auto;
  margin-bottom: ${(props) => props.theme.arter.spacing.small};

  &:hover {
    background: transparent;
    text-decoration: underline;
  }
`;

export default ClearButton;
