import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import BaseCell from './BaseCell';

function RevisionDateCell({ revisionDate, issue }) {
  const intl = useIntl();

  return (
    <BaseCell issue={issue}>
      {revisionDate ? (
        intl.formatDate(revisionDate, {
          format: 'shortDateFormat',
        })
      ) : (
        <FormattedMessage id="NOT_SET" />
      )}
    </BaseCell>
  );
}

RevisionDateCell.propTypes = {
  revisionDate: PropTypes.string,
  issue: PropTypes.bool,
};

export default RevisionDateCell;
