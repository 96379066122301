import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BaseTableCell } from '../../../../common/tables/BaseTable';
import InvalidValueIcon from '../InvalidValueIcon';

function BaseCell({ issue, children, ...props }) {
  return (
    <PreviewTableBaseCell {...props}>
      {children}
      {issue ? <InvalidValueIcon /> : null}
    </PreviewTableBaseCell>
  );
}

BaseCell.propTypes = {
  issue: PropTypes.bool,
  children: PropTypes.node,
};

const PreviewTableBaseCell = styled(BaseTableCell)`
  position: relative;
  white-space: initial;
  line-height: 1.5;
  min-width: 130px;

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    font-size: ${(props) => props.theme.arter.font.size.small};
  }
`;

export default BaseCell;
