import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import IconWithBackground from '../../../common/IconWithBackground';
import Change from './Change';

function ChangeGroup({ icon, changes }) {
  return (
    <Wrapper>
      <ChangeIconWrapper>
        <IconWithBackground iconPath={icon} />
      </ChangeIconWrapper>
      <ChangeWrapper>
        {changes.map(({ targetType, changeType, specifier, from, to }) => (
          <Change
            key={`${targetType}-${specifier || ''}`}
            targetType={targetType}
            changeType={changeType}
            specifier={specifier}
            from={from}
            to={to}
          />
        ))}
      </ChangeWrapper>
    </Wrapper>
  );
}

ChangeGroup.propTypes = {
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      targetType: PropTypes.string,
      changeType: PropTypes.string,
      specifier: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  icon: PropTypes.string,
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 20px;
    display: inline-block;
    width: 1px;
    background-color: ${(props) => props.theme.arter.color.product.light};
  }

  &:last-child {
    &:before {
      display: none;
    }
  }
`;

const ChangeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: ${(props) => props.theme.arter.color.white};
  border: ${(props) => props.theme.arter.border.main};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  padding: ${(props) => props.theme.arter.spacing.medium};
  margin-right: ${(props) => props.theme.arter.spacing.small};

  div {
    margin-right: 0;
  }
`;

const ChangeWrapper = styled.div`
  padding: ${(props) => props.theme.arter.spacing.mediumLarge};
  flex: 1;
  background-color: ${(props) => props.theme.arter.color.extraLightGray};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
  overflow: hidden;
`;

export default ChangeGroup;
