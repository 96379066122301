import messages from '../../../../lang/messages';

class RisksToSheetJSONConverter {
  constructor(lang, likelihoods = [], impactTargets = [], impactValues = []) {
    this.lang = lang;
    this.impactTargets = impactTargets;
    this.likelihoods = likelihoods;
    this.impactValues = impactValues;
  }

  convert(risks) {
    const headerRow = [
      messages[this.lang].NAME,
      messages[this.lang].DESCRIPTION,
      messages[this.lang].STATE,
      messages[this.lang].MANAGEMENT_STRATEGY,
      messages[this.lang].PRIORITY,
      messages[this.lang].REVISION_DATE,
      messages[this.lang].LIKELIHOOD,
    ];

    this.impactTargets.forEach((impactTarget) => {
      headerRow.push(impactTarget.name);
      headerRow.push('');
    });

    const riskRows = risks.map((risk) => {
      const revisionDate = risk.revisionDate ? new Date(risk.revisionDate) : '';
      if (revisionDate) {
        revisionDate.setHours(0, 0, 0, 0);
      }

      const likelihood = this.likelihoods.find(
        ({ id }) => id === risk.currentLikelihoodId,
      );

      const impacts = this.impactTargets.flatMap((impactTarget) => {
        const impact = risk.impacts.find(
          ({ impactTargetId }) => impactTargetId === impactTarget.id,
        );

        const impactValue = this.impactValues.find(
          ({ id }) => id === impact?.currentImpactValueId,
        );

        return [
          impactValue?.value || '',
          messages[this.lang][impact?.type] || '',
        ];
      });

      return [
        risk.name,
        risk.description,
        messages[this.lang][risk.state],
        messages[this.lang][risk.managementStrategy],
        messages[this.lang][risk.priority],
        revisionDate,
        likelihood?.value || '',
        ...impacts,
      ];
    });

    return [headerRow, ...riskRows];
  }
}

export default RisksToSheetJSONConverter;
