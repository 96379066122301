import styled from 'styled-components';
import PropTypes from 'prop-types';

function TabContent({ active, children }) {
  return active ? children : null;
}

TabContent.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};

const TabsWrapper = styled.div`
  display: flex;
  margin-right: ${(props) => props.theme.arter.spacing.large};
  margin-bottom: ${(props) => props.theme.arter.spacing.large};
`;

const TabItem = styled.button`
  outline: none;
  border: none;
  font-size: ${(props) => props.theme.arter.font.size.medium};
  font-weight: ${(props) =>
    props.$active
      ? props.theme.arter.font.weight.bold
      : props.theme.arter.font.weight.normal};
  padding: ${(props) => props.theme.arter.spacing.mediumLarge};
  color: ${(props) => props.theme.arter.color.black};
  background-color: ${(props) =>
    props.$active
      ? props.theme.arter.color.product.light
      : props.theme.arter.color.extraLightGray};
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  margin-right: ${(props) => props.theme.arter.spacing.small};
  cursor: pointer;
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover {
    background-color: ${(props) =>
      props.$active
        ? props.theme.arter.color.product.light
        : props.theme.arter.color.veryLightGray};
  }

  &::last-child {
    margin-left: 0;
  }

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    padding: ${(props) =>
      `${props.theme.arter.spacing.mediumLarge} ${props.theme.arter.spacing.large}`};
  }
`;

export { TabContent, TabsWrapper, TabItem };
