import React from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import { HeaderTitleInput } from '../header/Header';
import { useForm } from '../../FormContext';
import { useRights } from '../../RightsContext';

function RiskNameInput() {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();
  const { hasModifierRight } = useRights();

  return (
    <Tooltip
      placement="bottom-start"
      title={intl.formatMessage({ id: 'CLICK_TO_EDIT_THE_NAME' })}
    >
      <HeaderTitleInput
        autoCorrect="off"
        type="text"
        name="title"
        value={state.name.value}
        aria-label={intl.formatMessage({ id: 'RISK_NAME' })}
        disabled={!hasModifierRight()}
        onChange={(event) =>
          dispatchFormChange({ field: 'name', value: event.target.value })
        }
      />
    </Tooltip>
  );
}

export default RiskNameInput;
