import styled from 'styled-components';

const NavigationContainer = styled.nav`
  background-color: ${(props) => props.theme.arter.color.extraLightGray};
  padding: ${(props) => props.theme.arter.spacing.mediumLarge};

  h4 {
    font-size: ${(props) => props.theme.arter.font.size.medium};
    margin-bottom: ${(props) => props.theme.arter.spacing.mediumLarge};
  }

  li {
    ul {
      padding-left: ${(props) => props.theme.arter.spacing.mediumLarge};
      margin-left: ${(props) => props.theme.arter.spacing.medium};
      border-left: 1px solid ${(props) => props.theme.arter.color.lightGray};
    }
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    position: sticky;
    top: 50px;
    height: 100vh;
    align-self: flex-start;
    max-width: 220px;
    min-width: 220px;
    flex: 1;
    margin: 0 -${(props) => props.theme.arter.spacing.large} -82px
      ${(props) => props.theme.arter.spacing.large};
    padding: ${(props) =>
      `${props.theme.arter.spacing.mediumLarge} 0 ${props.theme.arter.spacing.mediumLarge} ${props.theme.arter.spacing.mediumLarge}`};
    border-top-left-radius: ${(props) => props.theme.arter.spacing.mediumLarge};
    border-top-left-radius: ${(props) => props.theme.arter.spacing.mediumLarge};

    li {
      ul {
        margin-left: ${(props) => props.theme.arter.spacing.mediumLarge};
      }
    }
  }
`;

export default NavigationContainer;
