import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import HelpNotification from '../../../../common/notifications/HelpNotification';
import NoHistory from '../../../../../styles/images/no-history.svg';
import { magnitudeHistoryToChartData } from './magnitude-chart-utils';
import { useSettings } from '../../../../SettingsContext';
import MagnitudeHistoryChart from './MagnitudeHistoryChart';

function RiskMagnitudeHistory({ magnitudeHistory }) {
  const intl = useIntl();
  const { impactTargets } = useSettings();
  const data = magnitudeHistoryToChartData(magnitudeHistory, impactTargets);

  return data.length ? (
    <MagnitudeHistoryChart data={data} />
  ) : (
    <HelpNotification
      small
      srcImage={NoHistory}
      title={intl.formatMessage({ id: 'NO_HISTORY' })}
      infoText={intl.formatMessage({ id: 'NO_HISTORY_INFO' })}
    />
  );
}

RiskMagnitudeHistory.propTypes = {
  magnitudeHistory: PropTypes.arrayOf(
    PropTypes.shape({
      impactTargetId: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          timestamp: PropTypes.string,
          likelihoodValue: PropTypes.number,
          impactType: PropTypes.string,
          impactValue: PropTypes.number,
        }),
      ),
    }),
  ),
};

export default React.memo(RiskMagnitudeHistory);
