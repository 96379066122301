import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { FormProvider, ShowWhenFormChanged } from '../../FormContext';
import FormEditBar from '../../FormEditBar';
import { useNotifications } from '../../NotificationsContext';

function formForRisk({
  name,
  state,
  managementStrategy,
  priority,
  revisionDate,
  currentLikelihoodId,
  targetLikelihoodId,
  impacts,
  responsible,
  description,
  tags,
  actions,
}) {
  return {
    name: { initialValue: name, value: name, dirty: false },
    state: { initialValue: state, value: state, dirty: false },
    managementStrategy: {
      initialValue: managementStrategy,
      value: managementStrategy,
      dirty: false,
    },
    priority: { initialValue: priority, value: priority, dirty: false },
    revisionDate: {
      initialValue: revisionDate,
      value: revisionDate,
      dirty: false,
    },
    currentLikelihoodId: {
      initialValue: currentLikelihoodId,
      value: currentLikelihoodId,
      dirty: false,
    },
    targetLikelihoodId: {
      initialValue: targetLikelihoodId,
      value: targetLikelihoodId,
      dirty: false,
    },
    impacts: { initialValue: impacts, value: impacts, dirty: false },
    responsible: {
      initialValue: responsible,
      value: responsible,
      dirty: false,
    },
    description: {
      initialValue: description,
      value: description,
      dirty: false,
    },
    tags: { initialValue: tags, value: tags, dirty: false },
    actions: { initialValue: actions, value: actions, dirty: false },
  };
}

function RiskForm({ risk, onPatchSuccess, children }) {
  const intl = useIntl();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  function handlePatchSuccess(data) {
    onPatchSuccess(data.magnitudeHistory);
    showSuccessNotification(intl.formatMessage({ id: 'CHANGES_SAVED' }));
  }

  return (
    <FormProvider
      initialData={risk}
      formDataInitializer={formForRisk}
      endpoint={`/api/v1/risks/${risk.id}`}
      method="PATCH"
      onSuccess={(data) => handlePatchSuccess(data)}
      onError={() =>
        showErrorNotification(
          intl.formatMessage({
            id: 'FAILED_TO_SAVE_CHANGES',
          }),
        )
      }
    >
      {children}
      <ShowWhenFormChanged>
        <FormEditBar />
      </ShowWhenFormChanged>
    </FormProvider>
  );
}

RiskForm.propTypes = {
  risk: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    responsible: PropTypes.shape({
      users: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  onPatchSuccess: PropTypes.func,
  children: PropTypes.node,
};

export default RiskForm;
