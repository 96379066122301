import React from 'react';
import { Icon } from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import IconWithBackground from '../../../common/IconWithBackground';
import FormLabel from '../../../common/FormLabel';

function Header({ label, htmlFor, iconPath, children, ...props }) {
  return (
    <HeaderWrapper {...props}>
      <HeaderContainer>
        <IconWithBackground iconPath={iconPath}></IconWithBackground>
        <FormLabel htmlFor={htmlFor}>{label}</FormLabel>
      </HeaderContainer>
      {children}
    </HeaderWrapper>
  );
}

Header.propTypes = {
  iconPath: PropTypes.string,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.node,
};

const Section = styled.section`
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
`;

const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
  background-color: ${(props) => props.theme.arter.color.white};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.theme.arter.spacing.medium};
`;

function AddButton({ tooltip, ...props }) {
  return (
    <Tooltip title={tooltip}>
      <AddButtonWrapper {...props}>
        <Icon path={mdiPlus} />
      </AddButtonWrapper>
    </Tooltip>
  );
}

AddButton.propTypes = {
  tooltip: PropTypes.string,
};

const AddButtonWrapper = styled(SecondaryButton)`
  width: 28px;
  height: 28px;
  padding: 0;
  min-width: auto;
  margin-left: auto;

  svg {
    width: ${(props) => props.theme.arter.icon.size.small};
  }
`;

const Content = styled.div`
  padding-bottom: ${(props) => props.theme.arter.spacing.medium};
`;

export { Section, Header, Content, AddButton };
