import styled from 'styled-components';
import { appears } from '../../styles/keyframe-styles';

const Backdrop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  padding: ${(props) => props.theme.arter.spacing.extraLarge};
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  animation: ${appears} forwards
    ${(props) => props.theme.arter.animation.speed.normal};
`;

export default Backdrop;
