import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.arter.device.desktop} {
    flex-direction: row-reverse;
  }
`;

export default Wrapper;
