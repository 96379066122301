import { createGlobalStyle } from 'styled-components';
import muiCalendarOverride from './mui-calendar-override';
import muiOverrides from './mui-overrides';

export default createGlobalStyle`

* {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif !important;
}

html,
body {
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.arter.color.black};
    height: 100%;
    font-size: 100%;
    overflow: hidden;
    ${muiCalendarOverride};
    ${muiOverrides};

    #root {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: ${(props) => props.theme.arter.spacing.small};
    }
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

p {
    font-size: ${(props) => props.theme.arter.font.size.medium};
    line-height: 1.6;
    margin: 0;
}

&::placeholder {
    color: ${(props) => props.theme.arter.color.black};
    opacity: .9;
}
`;
