import styled from 'styled-components';

const SectionTitle = styled.h2`
  font-size: ${(props) => props.theme.arter.font.size.extraLarge};
  margin: ${(props) => props.theme.arter.spacing.large} 0;
  
  &:after {
    content: "";
    display: block;
    position: relative;
    top: ${(props) => props.theme.arter.spacing.small};
    border-bottom: 1px solid ${(props) => props.theme.arter.color.gray};

  }
`;

export default SectionTitle;
