import styled from 'styled-components';
import Select from '@mui/material/Select';
import muiInputOverride from '../../../../styles/mui-input-override';

const FilterSelect = styled(Select)`
  position: relative;
  ${muiInputOverride};
  background-color: ${(props) => props.theme.arter.color.white} !important;
  border: 1px solid ${(props) => props.theme.arter.color.darkGray} !important;
  border-color: ${(props) =>
    props.$active
      ? props.theme.arter.color.black
      : props.theme.arter.color.darkGray} !important;
  box-shadow: inset 0 0 0 ${(props) => (props.$active ? '2px' : '0')} #4d4d4d;

  &.MuiInputBase-root {
    height: 38px;
    min-height: auto;

    .MuiInputBase-input {
      padding-left: 28px;
      padding-right: 40px !important;

      span {
        font-weight: ${(props) => props.theme.arter.font.weight.bold};
        transition: ${(props) => props.theme.arter.animation.speed.normal};
        transform: ${(props) =>
          props.$active ? 'translateX(6px)' : 'translateX(0)'} !important;
      }
    }

    fieldset {
      border-radius: 2px;
    }

    &.Mui-focused {
      box-shadow: ${(props) =>
        props.$active
          ? `inset 0 0 0 2px ${props.theme.arter.color.black}, 0 0 0 4px ${props.theme.arter.color.product.light}`
          : `0 0 0 4px ${props.theme.arter.color.product.light}`};
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
        box-shadow: none;
      }
    }
  }
`;

export default FilterSelect;
