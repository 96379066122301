import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Icon } from '@mdi/react';
import {
  mdiClose,
  mdiInformationOutline,
  mdiAlertOutline,
  mdiCheck,
} from '@mdi/js';
import { popFromBottom, popToBottom } from '../../../styles/keyframe-styles';
import theme from '../../../styles/theme';
import IconButton from '../buttons/IconButton';

function Notification({
  id,
  title,
  message,
  statusIcon,
  onClose,
  duration,
  ...props
}) {
  const intl = useIntl();

  React.useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => onClose(id), duration);
      return () => {
        clearTimeout(timer);
      };
    }

    return undefined;
  }, [id, onClose, duration]);

  return (
    <Wrapper {...props} duration={duration}>
      {statusIcon}
      <MessageWrapper>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </MessageWrapper>
      <CloseButton
        tooltip={intl.formatMessage({ id: 'CLOSE' })}
        small
        iconPath={mdiClose}
        onClick={() => onClose(id)}
      />
    </Wrapper>
  );
}

Notification.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  statusIcon: PropTypes.object,
  onClose: PropTypes.func,
  duration: PropTypes.number,
};

function InfoNotification(props) {
  return (
    <Notification
      {...props}
      statusIcon={
        <StatusIcon
          color={theme.arter.color.product.default}
          path={mdiInformationOutline}
        />
      }
    />
  );
}

function WarningNotification(props) {
  return (
    <Notification
      {...props}
      statusIcon={
        <StatusIcon color={theme.arter.color.red} path={mdiAlertOutline} />
      }
    />
  );
}

function SuccessNotification(props) {
  return (
    <Notification
      {...props}
      statusIcon={
        <StatusIcon color={theme.arter.color.green} path={mdiCheck} />
      }
    />
  );
}

const notificationAnimation = css`
  ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.slow}
    ${(props) =>
    props.duration
      ? css`
          , ${popToBottom} forwards ${props.theme.arter.animation.speed.slow} ${
          props.duration / 1000 - 0.4
        }s;
        `
      : ';'}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  z-index: 6;
  width: 100%;
  background-color: ${(props) => props.theme.arter.color.white};
  padding: ${(props) => props.theme.arter.spacing.extraLarge};
  border: 1px solid ${(props) => props.theme.arter.color.veryLightGray};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  box-shadow: 0 4px 20px -12px ${(props) => props.theme.arter.color.black};
  animation: ${notificationAnimation};
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: ${(props) => props.theme.arter.spacing.small};
`;

const Title = styled.h4`
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

const Message = styled.p`
  margin-top: ${(props) => props.theme.arter.spacing.medium};
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

const StatusIcon = styled(Icon)`
  width: ${(props) => props.theme.arter.icon.size.small};
  height: ${(props) => props.theme.arter.icon.size.small};
  min-width: ${(props) => props.theme.arter.icon.size.small};
  min-height: ${(props) => props.theme.arter.icon.size.small};
  margin: ${(props) =>
    `${props.theme.arter.spacing.small} ${props.theme.arter.spacing.large} ${props.theme.arter.spacing.small} 0`};
  color: ${(props) => props.color};
`;

const CloseButton = styled(IconButton)``;

export { InfoNotification, WarningNotification, SuccessNotification };
