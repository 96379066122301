import React from 'react';
import useSessionStorageState from '../../../../hooks/useSessionStorageState';
import { useFolder } from '../FolderContext';

const FolderFilterContext = React.createContext();

export const DEFAULT_FILTER = {
  states: [],
  tags: [],
  text: '',
  responsible: [],
  priorities: [],
  managementStrategies: [],
  likelihoods: [],
  impactTargets: [],
  impactValues: [],
  includeSubfolders: true,
};

/* eslint-disable no-param-reassign */
function getContentFrom(folders, includeSubfolders = true) {
  return folders.reduce(
    (content, folder) => {
      if (includeSubfolders && folder.subFolders.length) {
        const subfolderContent = getContentFrom(folder.subFolders);
        content.folders = content.folders.concat(subfolderContent.folders);
        content.risks = content.risks.concat(subfolderContent.risks);
      }

      if (includeSubfolders) {
        content.folders = content.folders.concat(folder.subFolders);
      }
      content.risks = content.risks.concat(folder.risks);

      return content;
    },
    { risks: [], folders: [] },
  );
}
/* eslint-enable no-param-reassign */

function FolderFilterProvider(props) {
  const { folder } = useFolder();
  const [filter, setFilter, resetFilter] = useSessionStorageState({
    key: 'risks-module-folder-filter',
    defaultValue: DEFAULT_FILTER,
  });

  const anyFiltersApplied =
    filter.states.length > 0 ||
    filter.tags.length > 0 ||
    filter.text.length > 0 ||
    filter.responsible.length > 0 ||
    filter.priorities.length > 0 ||
    filter.managementStrategies.length > 0 ||
    filter.likelihoods.length > 0 ||
    filter.impactTargets.length > 0 ||
    filter.impactValues.length > 0 ||
    !filter.includeSubfolders;

  const allContent = React.useMemo(
    () => getContentFrom([folder], filter.includeSubfolders),
    [folder, filter.includeSubfolders],
  );
  const [searchResult, setSearchResult] = React.useState({
    folders: folder.subFolders,
    risks: folder.risks,
  });
  const [chartRisks, setChartRisks] = React.useState(allContent.risks);

  React.useEffect(() => {
    if (anyFiltersApplied) {
      const filteredRisks = allContent.risks.filter(
        (risk) =>
          (filter.text.length
            ? risk.name.toLowerCase().includes(filter.text.toLowerCase())
            : true) &&
          (filter.states.length ? filter.states.includes(risk.state) : true) &&
          (filter.tags.length
            ? filter.tags.some((tag) => risk.tags.includes(tag))
            : true) &&
          (filter.responsible.length
            ? filter.responsible.some((responsible) =>
                risk.responsible.users.includes(responsible),
              )
            : true) &&
          (filter.priorities.length
            ? filter.priorities.includes(risk.priority)
            : true) &&
          (filter.managementStrategies.length
            ? filter.managementStrategies.includes(risk.managementStrategy)
            : true) &&
          (filter.likelihoods.length
            ? filter.likelihoods.includes(risk.currentLikelihoodId)
            : true) &&
          (filter.impactTargets.length
            ? risk.impacts.some((impact) =>
                filter.impactTargets.includes(impact.impactTargetId),
              )
            : true) &&
          (filter.impactValues.length
            ? risk.impacts.some((impact) =>
                filter.impactValues.includes(impact.currentImpactValueId),
              )
            : true),
      );
      const filteredFolders =
        !filter.states.length &&
        !filter.tags.length &&
        !filter.responsible.length &&
        !filter.priorities.length &&
        !filter.managementStrategies.length &&
        !filter.likelihoods.length &&
        !filter.impactTargets.length &&
        !filter.impactValues.length &&
        filter.text.length
          ? allContent.folders.filter((subFolder) =>
              subFolder.name.toLowerCase().includes(filter.text.toLowerCase()),
            )
          : [];
      setSearchResult({ folders: filteredFolders, risks: filteredRisks });
      setChartRisks(
        Array.from(
          new Set([...getContentFrom(filteredFolders).risks, ...filteredRisks]),
        ),
      );
    } else {
      setSearchResult({ folders: folder.subFolders, risks: folder.risks });
      setChartRisks(allContent.risks);
    }
  }, [
    anyFiltersApplied,
    allContent.folders,
    allContent.risks,
    filter,
    folder.risks,
    folder.subFolders,
  ]);

  const folderFilterProviderValue = React.useMemo(
    () => ({
      filter,
      setFilter,
      anyFiltersApplied,
      clearAllFilters: resetFilter,
      allContent,
      searchResult,
      chartRisks,
    }),
    [
      filter,
      setFilter,
      anyFiltersApplied,
      resetFilter,
      allContent,
      searchResult,
      chartRisks,
    ],
  );

  return (
    <FolderFilterContext.Provider
      value={folderFilterProviderValue}
      {...props}
    />
  );
}

function useFolderFilter() {
  const context = React.useContext(FolderFilterContext);
  if (!context) {
    throw new Error(
      `useFolderFilter must be used within a FolderFilterProvider`,
    );
  }
  return context;
}

export { FolderFilterProvider, useFolderFilter };
