import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { mdiAccountMultipleOutline, mdiClose } from '@mdi/js';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import muiInputOverride from '../../../styles/mui-input-override';
import muiAutoCompleteOverride from '../../../styles/mui-auto-complete-override';
import {
  MenuHeader,
  MenuCloseButton,
  MenuTitle,
} from '../../common/MenuHeader';
import { Header } from '../risk/right-menu/RightMenuSection';
import SaveButton from '../../common/buttons/SaveButton';
import CancelButton from '../../common/buttons/CancelButton';
import { popFromBottom, popFromRight } from '../../../styles/keyframe-styles';
import RightItem from './RightItem';
import Backdrop from '../../common/Backdrop';
import { useForm, ShowWhenFormChanged } from '../../FormContext';
import RIGHT_ROLE from '../../../constants/right-role';
import Spinner from '../../common/Spinner';
import ButtonRow from '../../common/ButtonRow';
import theme from '../../../styles/theme';
import { useKeycloak } from '../../KeycloakContext';

function Rights({ onClose }) {
  const intl = useIntl();
  const { submitForm, submitting, resetChanges } = useForm();
  const { groupMap } = useKeycloak();
  const { state, dispatchFormChange } = useForm();
  const [inputValue, setInputValue] = React.useState('');
  const [availableGroups] = React.useState(
    [...groupMap].map(([key, field]) => ({
      id: key,
      name: field.name,
      accessGroup: field.accessGroup,
    })),
  );

  function handleInputChange(value) {
    setInputValue(value);
  }

  function handleSelectGroup(groups) {
    groups.unshift({ id: groups.pop().id, role: RIGHT_ROLE.VIEWER });

    dispatchFormChange({
      field: 'groups',
      value: groups.map((group) => ({ id: group.id, role: group.role })),
    });
    setInputValue('');
  }

  function handleRemoveGroup(groupId) {
    dispatchFormChange({
      field: 'groups',
      value: state.groups.value.filter((group) => group.id !== groupId),
    });
  }

  function handleChangeRightRole(newRole, groupId) {
    dispatchFormChange({
      field: 'groups',
      value: state.groups.value.map((group) =>
        group.id === groupId ? { ...group, role: newRole } : group,
      ),
    });
  }

  return (
    <>
      <Wrapper aria-label={intl.formatMessage({ id: 'RIGHTS' })}>
        <MenuHeader>
          <MenuTitle>
            <FormattedMessage id="RIGHTS" />
          </MenuTitle>
          <MenuCloseButton
            tooltip={intl.formatMessage({ id: 'CLOSE' })}
            iconPath={mdiClose}
            onClick={onClose}
          ></MenuCloseButton>
        </MenuHeader>
        <Content>
          <SectionHeader
            iconPath={mdiAccountMultipleOutline}
            label={intl.formatMessage({ id: 'GROUPS' })}
            htmlFor="groups-select"
          />
          <RightsWrapper>
            <RightGroupSelect
              id="groups-select"
              data-testid="rights-group-select"
              options={availableGroups}
              clearOnBlur
              disableClearable
              multiple
              renderTags={() => null}
              value={state.groups.value}
              inputValue={inputValue}
              onInputChange={(event) => handleInputChange(event.target.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={intl.formatMessage({ id: 'SELECT_GROUP' })}
                />
              )}
              getOptionLabel={(option) =>
                `${option.name} (${option.accessGroup})`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => handleSelectGroup(value)}
            />
            <RightsList>
              {state.groups.value.map((group) => (
                <RightItem
                  key={group.id}
                  groupId={group.id}
                  securityIdentity={groupMap.get(group.id).name}
                  accessGroup={groupMap.get(group.id).accessGroup}
                  role={group.role}
                  onChange={handleChangeRightRole}
                  onRemove={() => handleRemoveGroup(group.id)}
                />
              ))}
            </RightsList>
          </RightsWrapper>
        </Content>
        <ShowWhenFormChanged>
          <EditToolbar>
            <SaveButton onClick={submitForm} disabled={submitting}>
              <FormattedMessage id="SAVE" />
            </SaveButton>
            <CancelButton onClick={resetChanges} disabled={submitting}>
              <FormattedMessage id="CANCEL" />
            </CancelButton>
            {submitting ? (
              <Spinner size={theme.arter.icon.size.medium} />
            ) : null}
          </EditToolbar>
        </ShowWhenFormChanged>
      </Wrapper>
      <Backdrop onClick={onClose} />
    </>
  );
}

Rights.propTypes = {
  onClose: PropTypes.func,
};

const RightGroupSelect = styled(AutoComplete)`
  ${muiInputOverride}
  ${muiAutoCompleteOverride}
`;

function RightsMenu({ onClose }) {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();

  function handleCloseRights() {
    if (
      !state.groups.dirty ||
      // eslint-disable-next-line no-alert
      window.confirm(intl.formatMessage({ id: 'UNSAVED_CHANGES_CONFIRMATION' }))
    ) {
      dispatchFormChange({
        field: 'rights',
        value: state.groups.initialValue,
      });
      onClose();
    }
  }

  return <Rights onClose={() => handleCloseRights()} />;
}

RightsMenu.propTypes = {
  onClose: PropTypes.func,
};
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: ${(props) => props.theme.arter.color.white};
  padding: ${(props) =>
    `${props.theme.arter.spacing.extraLarge} ${props.theme.arter.spacing.large}`};
  border-top-left-radius: ${(props) => props.theme.arter.border.radius.large};
  border-top-right-radius: ${(props) => props.theme.arter.border.radius.large};
  box-shadow: 0 -2px 15px -10px rgba(0, 0, 0, 0.75);
  animation: ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  @media ${(props) => props.theme.arter.device.pad} {
    min-width: 360px;
    width: 560px;
    top: 0;
    left: auto;
    box-shadow: -2px 0 15px -10px rgba(0, 0, 0, 0.75);
    border-top-right-radius: 0;
    border-bottom-left-radius: ${(props) =>
      props.theme.arter.border.radius.large};
    animation-name: ${popFromRight};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const SectionHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 ${(props) => props.theme.arter.spacing.small};
`;

const RightsWrapper = styled.div``;

const RightsList = styled.ul`
  margin-top: ${(props) => props.theme.arter.spacing.large};
`;

const EditToolbar = styled(ButtonRow)`
  animation: ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  button {
    &:last-child {
      margin-right: ${(props) => props.theme.arter.spacing.mediumLarge};
    }
  }
`;

export default RightsMenu;
