import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const BaseTableHeadCell = styled(TableCell)`
  padding: ${(props) =>
    `${props.theme.arter.spacing.small} ${props.theme.arter.spacing.medium}`};
  color: ${(props) => props.theme.arter.color.black};
  font-size: ${(props) => props.theme.arter.font.size.small};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  white-space: nowrap;
  top: -${(props) => props.theme.arter.spacing.medium};
  height: 38px;
  border-bottom: 1px solid ${(props) => props.theme.arter.color.gray};

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    top: -${(props) => props.theme.arter.spacing.mediumLarge};
    font-size: ${(props) => props.theme.arter.font.size.medium};
  }
`;

const BaseTableBodyRow = styled(TableRow)`
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.extraLightGray};
  }
`;

const BaseTableCell = styled(TableCell)`
  padding: ${(props) =>
    `${props.theme.arter.spacing.small} ${props.theme.arter.spacing.medium}`};
  color: ${(props) => props.theme.arter.color.black};
  font-size: ${(props) => props.theme.arter.font.size.small};
  white-space: nowrap;
  height: 44px;
  overflow: hidden;

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    font-size: ${(props) => props.theme.arter.font.size.medium};
  }
`;

export { BaseTableHeadCell, BaseTableBodyRow, BaseTableCell };
