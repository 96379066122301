import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import TypeTooltip from '@mui/material/Tooltip';
import { mdiLightningBoltOutline, mdiCircleMedium, mdiClose } from '@mdi/js';
import {
  Tooltip,
  Header,
  Title,
  Separator,
  Content,
  ImpactsList,
  TooltipListItem,
  TypeIcon,
} from '../../../../common/charts/CustomTooltip';
import IconWithBackground from '../../../../common/IconWithBackground';
import IconButton from '../../../../common/buttons/IconButton';
import IMPACT_TYPE from '../../../../../constants/impact-type';
import { slideFromBottom } from '../../../../../styles/keyframe-styles';

const FOLDER_MATRIX_TOOLTIP_WIDTH = 270;
function FolderMatrixTooltip({ tooltipData, onClose, impactTargets }) {
  const intl = useIntl();
  const { cell, x, y } = tooltipData;

  if (cell === undefined) return null;

  function generateImpactList() {
    const impactsMap = cell.data.impacts.reduce(
      (map, impact) => ({
        ...map,
        [impact.impactTargetId]: [
          ...(map[impact.impactTargetId] || []),
          impact,
        ],
      }),
      {},
    );

    return Object.entries(impactsMap).map(([impactTargetId, impacts]) =>
      impacts.length ? (
        <ImpactsList key={impactTargetId}>
          <TooltipListItem>
            <ImpactName>
              {
                impactTargets.find(
                  (impactTarget) => impactTarget.id === impactTargetId,
                ).name
              }
            </ImpactName>
          </TooltipListItem>
          {impacts.map((impact) => (
            <TooltipListItem key={impact.riskId}>
              <TypeTooltip
                title={<TypeTooltipText>{impact.impactType}</TypeTooltipText>}
              >
                <TypeIcon type={impact.impactType} path={mdiCircleMedium} />
              </TypeTooltip>
              <RiskLink to={`/risks/${impact.riskId}`}>
                {impact.riskName}
              </RiskLink>
            </TooltipListItem>
          ))}
        </ImpactsList>
      ) : null,
    );
  }

  return (
    <FolderMatrixCustomTooltip
      $left={
        cell.data.leftOfCenter
          ? `${x}px`
          : `${x - FOLDER_MATRIX_TOOLTIP_WIDTH}px`
      }
      $top={`${y}px`}
    >
      <Header>
        <IconWithBackground iconPath={mdiLightningBoltOutline} />
        <Title>
          {intl.formatMessage({ id: 'IMPACTS' })}
          <Separator />
          {cell.value}
        </Title>
        <CloseButton
          tooltip={intl.formatMessage({ id: 'CLOSE' })}
          small
          iconPath={mdiClose}
          onClick={onClose}
        />
      </Header>
      <ContentWrapper>{generateImpactList()}</ContentWrapper>
    </FolderMatrixCustomTooltip>
  );
}

FolderMatrixTooltip.propTypes = {
  impactTargets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onClose: PropTypes.any,
  tooltipData: PropTypes.shape({
    cell: PropTypes.shape({
      data: PropTypes.shape({
        impacts: PropTypes.arrayOf(
          PropTypes.shape({
            riskId: PropTypes.string.isRequired,
            riskName: PropTypes.string.isRequired,
            impactType: PropTypes.oneOf(Object.values(IMPACT_TYPE)).isRequired,
            impactTargetId: PropTypes.string.isRequired,
          }),
        ).isRequired,
        leftOfCenter: PropTypes.bool.isRequired,
      }),
      value: PropTypes.number.isRequired,
    }),
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
};

const FolderMatrixCustomTooltip = styled(Tooltip)`
  position: fixed;
  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
  max-height: 400px;
  width: ${FOLDER_MATRIX_TOOLTIP_WIDTH}px;
  animation: ${slideFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};
`;

const ImpactName = styled.p`
  font-size: ${(props) => props.theme.arter.font.size.small};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContentWrapper = styled(Content)`
  overflow: auto;
`;

const CloseButton = styled(IconButton)`
  margin-left: auto;
`;

const RiskLink = styled(Link)`
  font-size: ${(props) => props.theme.arter.font.size.small};
  color: ${(props) => props.theme.arter.color.product.default};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.arter.color.product.default};
  }
`;

const TypeTooltipText = styled.p`
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export default FolderMatrixTooltip;
