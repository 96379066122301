import React from 'react';
import { mdiPriorityHigh } from '@mdi/js';
import { useIntl } from 'react-intl';
import RISK_PRIORITY from '../../../constants/risk-priority';
import Select from '../../common/Select';
import RiskCard from './RiskCard';
import { useForm } from '../../FormContext';

function RiskPriorityCard() {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();

  return (
    <RiskCard
      icon={mdiPriorityHigh}
      title={intl.formatMessage({ id: 'PRIORITY' })}
      data-testid="risk-priority-card"
    >
      <Select
        value={state.priority.value}
        onChange={(event) =>
          dispatchFormChange({ field: 'priority', value: event.target.value })
        }
      >
        {Object.values(RISK_PRIORITY).map((priority) => (
          <option key={priority} value={priority}>
            {intl.formatMessage({ id: priority })}
          </option>
        ))}
      </Select>
    </RiskCard>
  );
}

export default RiskPriorityCard;
