import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';

function ListItemCheckIcon({ show }) {
  return <Wrapper $show={show} path={mdiCheck} />;
}

ListItemCheckIcon.propTypes = {
  show: PropTypes.bool,
};

const Wrapper = styled(Icon)`
  opacity: ${(props) => (props.$show ? 1 : 0)};
  align-self: flex-end;
  width: ${(props) => props.theme.arter.icon.size.medium};
  height: ${(props) => props.theme.arter.icon.size.medium};
  color: ${(props) => props.theme.arter.color.product.default};
  margin-left: ${(props) => props.theme.arter.spacing.extraLarge};
`;

export default ListItemCheckIcon;
