import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GlobalStyle from '../styles/global-styles';
import theme from '../styles/theme';
import languageConfig from '../lang/config';
import { useLocalStorageConfig } from '../hooks/useLocalStorageConfig';

function AppProviders({ children }) {
  const [{ lang }] = useLocalStorageConfig();

  return (
    <IntlProvider {...languageConfig[lang].reactIntl}>
      <LocalizationProvider
        adapterLocale={languageConfig[lang].mui.locale}
        dateAdapter={AdapterDateFns}
      >
        <MuiThemeProvider theme={createTheme()}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
          </ThemeProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </IntlProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node,
};

export default AppProviders;
