import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from '@mdi/react';

const LinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.arter.color.product.default};
  min-width: 150px;

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.arter.color.product.default};
  }
`;

const LinkIcon = styled(Icon)`
  min-width: ${(props) => props.theme.arter.icon.size.medium};
  height: ${(props) => props.theme.arter.icon.size.medium};
  margin-right: ${(props) => props.theme.arter.spacing.medium};
  color: ${(props) => props.theme.arter.color.black};
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
`;

const LinkName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { LinkWrapper, LinkIcon, LinkName };
