import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconWithBackground from '../../common/IconWithBackground';
import { useRights } from '../../RightsContext';

function RiskCard({ icon, title, children, ...props }) {
  const { hasModifierRight } = useRights();

  return (
    <Card disabled={!hasModifierRight()} {...props}>
      <CardHeader>
        <IconWithBackground iconPath={icon}></IconWithBackground>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      {children}
      <CardBackgroundImage>
        <path d="M131.5 60L24 0.5H0L131.5 79V60Z" fill="#E3E0FF" />
        <path d="M132 36.6242L50.2509 1H32L132 48V36.6242Z" fill="#E3E0FF" />
      </CardBackgroundImage>
    </Card>
  );
}

RiskCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

const Card = styled.fieldset`
  position: relative;
  min-width: 100%;
  width: 100%;
  height: 74px;
  flex: 1;
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  overflow: hidden;
  transition: ${(props) => props.theme.arter.animation.speed.normal};
  border: none;
  margin: 0;
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};

  &:hover {
    &:enabled {
      > svg {
        display: none;
        opacity: 0.3;
        transform: scale(1) translateY(-28px);
      }
      select {
        border-color: ${(props) => props.theme.arter.color.darkGray};
        background-image: linear-gradient(45deg, transparent 50%, #4d4d4d 50%),
          linear-gradient(135deg, #4d4d4d 50%, transparent 50%);

        &:focus {
          border-color: initial;
        }
      }
    }
  }

  select {
    border-color: transparent;
    background-image: linear-gradient(45deg, transparent 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, transparent 50%);

    &:disabled {
      opacity: 1;
    }

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }

  @media ${(props) => props.theme.arter.device.pad} {
    height: 88px;
    padding: ${(props) =>
      `${props.theme.arter.spacing.mediumLarge} ${props.theme.arter.spacing.medium}`};
    border: 1px solid ${(props) => props.theme.arter.color.lightGray};
    max-width: 49%;
    min-width: 49%;
    margin: 0 0.5% ${(props) => props.theme.arter.spacing.medium} 0.5%;

    &:hover:enabled {
      box-shadow: 0 3px 20px -13px ${(props) => props.theme.arter.color.black};
    }
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    padding: ${(props) => props.theme.arter.spacing.mediumLarge};
    height: 104px;
    max-width: 24%;
    min-width: 24%;

    &:hover:enabled {
      > svg {
        display: block;
      }
    }
  }

  @media ${(props) => props.theme.arter.device.desktopMedium} {
    max-width: 23.5%;
    min-width: 23.5%;
    margin: 0 0.75;
  }
`;

const CardBackgroundImage = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
  transform: scale(0.6) translateX(80px) translateY(-80px);
  transform-origin: center;
  transition: ${(props) => props.theme.arter.animation.speed.normal};
  width: 132px;
  height: 79px;
  fill: none;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
  margin-left: ${(props) => props.theme.arter.spacing.medium};

  div:first-child {
    display: none;
  }
  @media ${(props) => props.theme.arter.device.desktop} {
    margin-left: 0;
    margin-bottom: ${(props) => props.theme.arter.spacing.medium};

    div:first-child {
      display: flex;
    }
  }
`;

const CardTitle = styled.h4`
  font-size: ${(props) => props.theme.arter.font.size.medium};
  margin: 0;
  cursor: default;
`;

export default RiskCard;
