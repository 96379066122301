import React from 'react';
import styled from 'styled-components';
import { mdiClose } from '@mdi/js';
import { useIntl, FormattedMessage } from 'react-intl';
import { MDXProvider } from '@mdx-js/react';
import { useHelpMenu } from '../../HelpMenuContext';
import { popFromBottom, popFromRight } from '../../../styles/keyframe-styles';
import HelpEn from '../../../help-en.mdx';
import HelpFi from '../../../help-fi.mdx';
import { useLocalStorageConfig } from '../../../hooks/useLocalStorageConfig';
import {
  MenuHeader,
  MenuCloseButton,
  MenuTitle,
} from '../../common/MenuHeader';
import components from '../../markdown/components';

function HelpMenu() {
  const { showHelpMenu, toggleHelpMenu } = useHelpMenu();
  const intl = useIntl();
  const [{ lang }] = useLocalStorageConfig();

  return showHelpMenu ? (
    <Wrapper>
      <HelpHeader>
        <HelpTitle>
          <FormattedMessage id="HELP" />
        </HelpTitle>
        <MenuCloseButton
          tooltip={intl.formatMessage({ id: 'CLOSE' })}
          iconPath={mdiClose}
          onClick={() => toggleHelpMenu()}
        ></MenuCloseButton>
      </HelpHeader>
      <MDXProvider components={components}>
        {lang === 'en' ? <HelpEn /> : <HelpFi />}
      </MDXProvider>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.section`
  position: fixed;
  background-color: ${(props) => props.theme.arter.color.white};
  z-index: 5;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-padding-top: 66px;
  padding: ${(props) => props.theme.arter.spacing.extraLarge};
  box-shadow: -2px 0 15px -10px rgba(0, 0, 0, 0.75);
  animation: ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  @media ${(props) => props.theme.arter.device.desktop} {
    max-width: 650px;
    flex: 1;
    top: 0;
    left: auto;
    padding: ${(props) => props.theme.arter.spacing.large};
    box-shadow: -2px 0 15px -10px rgba(0, 0, 0, 0.75);
    border-top-right-radius: 0;
    border-bottom-left-radius: ${(props) =>
      props.theme.arter.border.radius.large};
    border-top-left-radius: ${(props) => props.theme.arter.border.radius.large};
    animation-name: ${popFromRight};
  }
`;

const HelpTitle = styled(MenuTitle)`
  font-size: ${(props) => props.theme.arter.font.size.extraExtraLarge};
`;

const HelpHeader = styled(MenuHeader)`
  position: sticky;
  top: -${(props) => props.theme.arter.spacing.extraLarge};
  z-index: 5;
  background-color: ${(props) => props.theme.arter.color.white};
  margin: -${(props) => props.theme.arter.spacing.large} -${(props) =>
      props.theme.arter.spacing.large}
    0 -${(props) => props.theme.arter.spacing.large};
  padding: ${(props) => props.theme.arter.spacing.large};
  padding-bottom: ${(props) => props.theme.arter.spacing.mediumLarge};

  @media ${(props) => props.theme.arter.device.desktop} {
    top: -${(props) => props.theme.arter.spacing.large};
  }
`;

export default HelpMenu;
