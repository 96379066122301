import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BaseCell from './BaseCell';

function ImpactValueCell({ impact, value, issue }) {
  return (
    <BaseCell issue={issue}>
      {impact ? value ?? <FormattedMessage id="NOT_SET" /> : null}
    </BaseCell>
  );
}

ImpactValueCell.propTypes = {
  impact: PropTypes.bool,
  value: PropTypes.number,
  issue: PropTypes.bool,
};

export default ImpactValueCell;
