import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import {
  Modal,
  ModalButtons,
  ModalCloseButton,
  ModalContent,
  ModalContentWrap,
  ModalOpenButton,
} from '../../common/Modal';
import Checkbox from '../../common/Checkbox';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import CancelButton from '../../common/buttons/CancelButton';

function SelectRiskImpactsModal({
  impactTargets,
  riskImpactTargets,
  onSelectImpactTargets,
}) {
  const intl = useIntl();
  const [selectedImpactTargets, setSelectedImpactTargets] =
    React.useState(riskImpactTargets);

  function handleSelectImpactTargetChange(event, impactTargetId) {
    if (event.target.checked) {
      setSelectedImpactTargets([...selectedImpactTargets, impactTargetId]);
    } else {
      setSelectedImpactTargets(
        selectedImpactTargets.filter(
          (impactTarget) => impactTarget !== impactTargetId,
        ),
      );
    }
  }

  function handleSelectAllChange(event) {
    if (event.target.checked) {
      setSelectedImpactTargets(
        impactTargets.map((impactTarget) => impactTarget.id),
      );
    } else {
      setSelectedImpactTargets([]);
    }
  }

  React.useEffect(() => {
    setSelectedImpactTargets(riskImpactTargets);
  }, [riskImpactTargets]);

  return (
    <Modal>
      <ModalOpenButton>
        <SelectImpactButton>
          <FormattedMessage id="SELECT_IMPACTS" />
        </SelectImpactButton>
      </ModalOpenButton>
      <ModalContent title={intl.formatMessage({ id: 'SELECT_IMPACTS' })}>
        <ModalContentWrap>
          {impactTargets.length ? (
            <ImpactSelectAllCheckbox
              labelText={intl.formatMessage({ id: 'SELECT_ALL' })}
              onChange={(event) => handleSelectAllChange(event)}
              isChecked={selectedImpactTargets.length === impactTargets.length}
            />
          ) : null}
          {impactTargets.map((impactTarget) => (
            <ImpactCheckbox
              key={impactTarget.id}
              labelText={impactTarget.name}
              data-testid={`select-impact-${impactTarget.id}`}
              isChecked={selectedImpactTargets.some(
                (impactTargetId) => impactTargetId === impactTarget.id,
              )}
              onChange={(event) =>
                handleSelectImpactTargetChange(event, impactTarget.id)
              }
            />
          ))}
          <ModalButtons>
            <ModalCloseButton>
              <PrimaryButton
                onClick={() => onSelectImpactTargets(selectedImpactTargets)}
              >
                <FormattedMessage id="OK" />
              </PrimaryButton>
            </ModalCloseButton>
            <ModalCloseButton>
              <CancelButton>
                <FormattedMessage id="CANCEL" />
              </CancelButton>
            </ModalCloseButton>
          </ModalButtons>
        </ModalContentWrap>
      </ModalContent>
    </Modal>
  );
}

SelectRiskImpactsModal.propTypes = {
  impactTargets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  riskImpactTargets: PropTypes.arrayOf(PropTypes.string),
  onSelectImpactTargets: PropTypes.func,
};

const SelectImpactButton = styled(SecondaryButton)`
  margin-left: auto;
`;

const ImpactCheckbox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
`;

const ImpactSelectAllCheckbox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};
`;

export default SelectRiskImpactsModal;
