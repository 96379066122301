import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';

function useDeviceMediaQuery() {
  return {
    isPad: useMediaQuery(theme.arter.device.pad),
    isDesktop: useMediaQuery(theme.arter.device.desktop),
    isDesktopMedium: useMediaQuery(theme.arter.device.desktopMedium),
    isDesktopLarge: useMediaQuery(theme.arter.device.desktopLarge),
  };
}

export default useDeviceMediaQuery;
