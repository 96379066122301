import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { BaseTableBodyRow } from '../../../common/tables/BaseTable';
import useDeviceMediaQuery from '../../../../hooks/useDeviceMediaQuery';
import FolderContentTableHeader from './FolderContentTableHeader';
import folderContentTableComparatorMap from './folder-content-table-comparators';
import FOLDER_COLUMN from '../../../../constants/folder-column';
import SORT_DIRECTION from '../../../../constants/sort-direction';
import MagnitudesCell from './MagnitudesCell';
import LikelihoodCell from './LikelihoodCell';
import RevisionDateCell from './RevisionDateCell';
import ResponsibleCell from './ResponsibleCell';
import EditedCell from './EditedCell';
import StateCell from './StateCell';
import NameCell from './NameCell';
import { useSettings } from '../../../SettingsContext';
import { useFolderFilter } from '../filter/FolderFilterContext';
import LocationCell from './LocationCell';

function FolderContentTable({ folders, risks }) {
  const { anyFiltersApplied } = useFolderFilter();
  const { likelihoods, impactValues, impactTargets } = useSettings();
  const [sortDirection, setSortDirection] = React.useState(SORT_DIRECTION.ASC);
  const [sortBy, setSortBy] = React.useState(FOLDER_COLUMN.NAME);
  const { isDesktopMedium } = useDeviceMediaQuery();

  const getImpactTargetName = React.useCallback(
    (impactTargetId) => {
      const impactTarget = impactTargets.find(
        (target) => target.id === impactTargetId,
      );

      return impactTarget ? impactTarget.name : null;
    },
    [impactTargets],
  );

  const tableData = React.useMemo(
    () =>
      folders
        .map((folder) => ({ ...folder, isFolder: true }))
        .concat(
          risks.map((risk) => ({
            ...risk,
            isFolder: false,
            likelihood: risk.currentLikelihoodId
              ? likelihoods.find(
                  (likelihood) => likelihood.id === risk.currentLikelihoodId,
                ).value
              : undefined,
            impacts: risk.impacts.map(
              ({
                id: impactId,
                type,
                impactTargetId,
                currentImpactValueId,
              }) => ({
                id: impactId,
                type,
                targetName: getImpactTargetName(impactTargetId),
                value: currentImpactValueId
                  ? impactValues.find(
                      (impactValue) => impactValue.id === currentImpactValueId,
                    ).value
                  : undefined,
              }),
            ),
          })),
        ),
    [folders, getImpactTargetName, impactValues, likelihoods, risks],
  );

  function sortTable() {
    return [...tableData].sort(
      sortDirection === SORT_DIRECTION.DESC
        ? (a, b) =>
            folderContentTableComparatorMap.get(sortBy)(a, b, sortDirection)
        : (a, b) =>
            -folderContentTableComparatorMap.get(sortBy)(a, b, sortDirection),
    );
  }

  function handleChangeSorting(property) {
    setSortBy(property);
    setSortDirection(
      property === sortBy && sortDirection !== SORT_DIRECTION.DESC
        ? SORT_DIRECTION.DESC
        : SORT_DIRECTION.ASC,
    );
  }

  return tableData ? (
    <Wrapper data-testid="folder-content-table">
      <TableWrapper>
        <ContentTable stickyHeader>
          <FolderContentTableHeader
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={handleChangeSorting}
          />
          <TableBody>
            {sortTable().map((row) => (
              <BaseTableBodyRow
                key={row.id}
                data-testid={`folder-content-table-row-${row.id}`}
              >
                <NameCell id={row.id} name={row.name} isFolder={row.isFolder} />

                {anyFiltersApplied && isDesktopMedium ? (
                  <LocationCell breadcrumb={row.breadcrumb} />
                ) : null}
                <StateCell state={row.state} />
                <EditedCell edited={row.edited} />
                {isDesktopMedium ? (
                  <ResponsibleCell
                    responsible={row.responsible}
                    isFolder={row.isFolder}
                  />
                ) : null}
                <RevisionDateCell
                  revisionDate={row.revisionDate}
                  isFolder={row.isFolder}
                />
                <LikelihoodCell
                  likelihood={row.likelihood}
                  isFolder={row.isFolder}
                />
                <MagnitudesCell
                  impacts={row.impacts}
                  likelihood={row.likelihood}
                />
              </BaseTableBodyRow>
            ))}
          </TableBody>
        </ContentTable>
      </TableWrapper>
    </Wrapper>
  ) : null;
}

FolderContentTable.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  risks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const Wrapper = styled.section``;

const TableWrapper = styled(TableContainer)`
  overflow-x: inherit;
`;

const ContentTable = styled(Table)`
  table-layout: fixed;
  min-width: 880px;
`;

export default FolderContentTable;
