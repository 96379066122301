import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../../FormContext';
import SettingNameInputField from '../SettingNameInputField';
import DeleteTagModal from './DeleteTagModal';
import SettingRow from '../SettingRow';

function TagRow({ id, ...props }) {
  const { state } = useForm();

  return (
    <SettingRow
      id={id}
      data-testid={`tag-row-${id}`}
      inputFields={[SettingNameInputField]}
      DeleteModalComponent={DeleteTagModal}
      disableSave={state.name.value.trim().length === 0}
      {...props}
    />
  );
}

TagRow.propTypes = {
  id: PropTypes.string,
};

export default TagRow;
