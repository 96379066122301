import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import { useFolderTree } from '../../FolderTreeContext';
import { useSettings } from '../../SettingsContext';
import MainContentSpinner from '../../common/MainContentSpinner';
import theme from '../../../styles/theme';
import { FolderForbiddenAccess } from '../../ForbiddenAccess';
import { RightsProvider } from '../../RightsContext';

const FolderContext = React.createContext();

function FolderProvider({ children }) {
  const { id } = useParams();
  const [isCreatingRisk, setIsCreatingRisk] = React.useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = React.useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = React.useState(false);
  const [isUpdatingRights, setIsUpdatingRights] = React.useState(false);
  const [isMovingFolder, setIsMovingFolder] = React.useState(false);
  const [isUpdatingAllFilters, setIsUpdatingAllFilters] = React.useState(false);
  const [isSelectingRiskImportFile, setIsSelectingRiskImportFile] =
    React.useState(false);
  const { setSelectedFolder, openFoldersById } = useFolderTree();
  const { likelihoods, impactTargets, impactValues } = useSettings();

  const {
    response: folder,
    isLoading: isFetchingFolder,
    callEndpoint: fetchFolder,
    isForbidden,
  } = useApi({
    endpoint: `/api/v1/folders/${id || 'root'}`,
    method: 'GET',
  });

  React.useEffect(() => {
    setSelectedFolder(id || 'root');
  }, [id, setSelectedFolder]);

  React.useEffect(() => {
    fetchFolder();
  }, [fetchFolder]);

  React.useEffect(() => {
    if (folder) {
      openFoldersById(folder.breadcrumb.map((item) => item.id));
    }
  }, [folder, openFoldersById]);

  React.useEffect(() => {
    setIsCreatingRisk(false);
    setIsCreatingFolder(false);
    setIsDeletingFolder(false);
    setIsUpdatingRights(false);
    setIsMovingFolder(false);
    setIsUpdatingAllFilters(false);
  }, [id]);

  if (isForbidden) {
    return <FolderForbiddenAccess />;
  }

  return (
    <FolderContext.Provider
      value={{
        folder,
        fetchFolder,
        isCreatingRisk,
        setIsCreatingRisk,
        isCreatingFolder,
        setIsCreatingFolder,
        isDeletingFolder,
        setIsDeletingFolder,
        isUpdatingRights,
        setIsUpdatingRights,
        isMovingFolder,
        setIsMovingFolder,
        isUpdatingAllFilters,
        setIsUpdatingAllFilters,
        isSelectingRiskImportFile,
        setIsSelectingRiskImportFile,
      }}
    >
      {!isFetchingFolder &&
      folder &&
      likelihoods &&
      impactTargets &&
      impactValues ? (
        <RightsProvider rights={folder.rights}>{children}</RightsProvider>
      ) : (
        <MainContentSpinner size={theme.arter.icon.size.medium} />
      )}
    </FolderContext.Provider>
  );
}

function useFolder() {
  const context = React.useContext(FolderContext);
  if (!context) {
    throw new Error(`useFolder must be used within a FolderProvider`);
  }
  return context;
}

FolderProvider.propTypes = {
  children: PropTypes.node,
};

export { FolderProvider, useFolder };
