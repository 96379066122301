import styled from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  overflow: auto;
  padding: ${(props) => props.theme.arter.spacing.extraLarge};
`;

const Content = styled.section`
  max-width: 1100px;
  margin: ${(props) => props.theme.arter.spacing.medium} auto;
`;

const TableWrapper = styled.div`
  overflow: auto;
  padding: ${(props) => props.theme.arter.spacing.large} 0;
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
`;

export { Wrapper, Content, TableWrapper };
