import styled from 'styled-components';
import IconButton from './IconButton';

const ClearIconButton = styled(IconButton)`
  pointer-events: inherit;
  opacity: 0;
  width: 32px;
  height: 32px;
`;

export default ClearIconButton;
