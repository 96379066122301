import PropTypes from 'prop-types';
import Keycloak from 'keycloak-js';
import React from 'react';
import axios from 'axios';
import LoadingPage from './common/LoadingPage';

const KeycloakContext = React.createContext();

function KeycloakProvider({ tenantId, children }) {
  const [keycloakConfig, setKeycloakConfig] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [userGroups, setUserGroups] = React.useState([]);
  const [keycloakGroups, setKeycloakGroups] = React.useState(null);
  const [groupMap, setGroupMap] = React.useState({});
  const [userMap, setUserMap] = React.useState(null);
  const [currentUserId, setCurrentUserId] = React.useState(null);

  React.useEffect(() => {
    async function fetchTenantConfig() {
      const tenantConfigResponse = await axios.get(
        `/api/v1/config/${tenantId}`,
      );
      setKeycloakConfig(tenantConfigResponse.data.keycloak);
    }
    fetchTenantConfig();
  }, [tenantId]);

  const keycloak = React.useMemo(
    () =>
      keycloakConfig
        ? new Keycloak({
            realm: keycloakConfig.realm,
            url: keycloakConfig.url,
            clientId: 'risks-ui-client',
          })
        : null,
    [keycloakConfig],
  );

  React.useEffect(() => {
    if (keycloak) {
      keycloak
        .init({ onLoad: 'login-required', checkLoginIframe: false })
        .then((authenticated) => {
          setIsAuthenticated(authenticated);
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error));
      keycloak.onTokenExpired = () => {
        keycloak.updateToken().catch(() => {
          // eslint-disable-next-line no-console
          console.error(
            'Failed to refresh the token, or the session has expired',
          );
        });
      };
    }
  }, [keycloak]);

  React.useEffect(() => {
    async function fetchKeycloakGroups() {
      const keycloakGroupsResponse = await axios({
        method: 'GET',
        url: `${keycloakConfig.url}/realms/${keycloakConfig.realm}/arter-custom/groups`,
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      });
      setKeycloakGroups(keycloakGroupsResponse.data);
    }
    if (isAuthenticated) {
      setCurrentUserId(keycloak.tokenParsed.sub);
      setUserGroups(keycloak.tokenParsed.group_ids);
      fetchKeycloakGroups();
    }
  }, [isAuthenticated, keycloak, keycloakConfig]);

  React.useEffect(() => {
    async function getAccessGroupMembers(accessGroups) {
      const memberRequests = await Promise.all(
        accessGroups.map((accessGroup) =>
          axios({
            method: 'GET',
            url: `${keycloakConfig.url}/realms/${keycloakConfig.realm}/arter-custom/groups/${accessGroup.id}/members`,
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }),
        ),
      );

      setUserMap(
        new Map(
          memberRequests
            .map((response) => response.data)
            .flat()
            .map((user) => [
              user.id,
              {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
              },
            ]),
        ),
      );
    }

    if (keycloakGroups) {
      setGroupMap(
        keycloakGroups.reduce((map, accessGroup) => {
          accessGroup.subGroups.forEach((subGroup) =>
            map.set(subGroup.id, {
              name: subGroup.name,
              accessGroup: accessGroup.name,
            }),
          );
          return map;
        }, new Map()),
      );
      getAccessGroupMembers(keycloakGroups);
    }
  }, [keycloak, keycloakGroups, keycloakConfig]);

  return keycloak && isAuthenticated && userMap ? (
    <KeycloakContext.Provider
      value={{
        keycloak,
        hasRole: keycloak.hasRealmRole,
        currentUserId,
        userGroups,
        groupMap,
        userMap,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  ) : (
    <LoadingPage />
  );
}

KeycloakProvider.propTypes = {
  tenantId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

function useKeycloak() {
  const context = React.useContext(KeycloakContext);
  if (!context) {
    throw new Error(`useKeycloak must be used within a KeycloakProvider`);
  }
  return context;
}

export { KeycloakProvider, useKeycloak };
