import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormattedMessage } from 'react-intl';
import FilterSelect from './FilterSelect';
import SelectValue from './SelectValue';
import CountItem from './CountItem';
import { useFolderFilter } from './FolderFilterContext';
import SelectIcon from './SelectIcon';
import ListItemCheckIcon from '../../../common/multi-select/ListItemCheckIcon';
import DropdownListItem from '../../../common/multi-select/DropdownListItem';
import ListItemText from '../../../common/multi-select/ListItemText';
import { useSettings } from '../../../SettingsContext';

function TagsFilter() {
  const { filter, setFilter } = useFolderFilter();
  const [selected, setSelected] = React.useState(filter.tags);
  const { tags: allTags } = useSettings();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === 'string' ? value.split(',') : value);
  };

  React.useEffect(() => {
    setSelected(filter.tags);
  }, [filter.tags]);

  return (
    <FilterSelect
      $active={filter.tags.length}
      IconComponent={SelectIcon}
      multiple
      value={selected}
      onChange={handleChange}
      onClose={() => setFilter({ ...filter, tags: selected })}
      input={<OutlinedInput />}
      displayEmpty={true}
      renderValue={() => (
        <SelectValue>
          {filter.tags.length ? <CountItem count={filter.tags.length} /> : null}
          <FormattedMessage id="TAGS" />
        </SelectValue>
      )}
      data-testid="tags-filter"
      MenuProps={{
        slotProps: {
          backdrop: { 'data-testid': 'filter-backdrop', invisible: true },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {allTags
        ? allTags.map((tag) => (
            <DropdownListItem key={tag.id} value={tag.id}>
              <ListItemText>{tag.name}</ListItemText>
              <ListItemCheckIcon show={selected.includes(tag.id)} />
            </DropdownListItem>
          ))
        : null}
    </FilterSelect>
  );
}

export default TagsFilter;
