import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { mdiLink } from '@mdi/js';
import { DropdownItem, DropdownItemIcon } from '../../common/Dropdown';
import { useNotifications } from '../../NotificationsContext';

function CopyLinkDropdownItem() {
  const intl = useIntl();
  const { showSuccessNotification } = useNotifications();

  return (
    <DropdownItem
      role="button"
      onClick={() => {
        window.navigator.clipboard.writeText(window.location.href);
        showSuccessNotification(
          intl.formatMessage({ id: 'LINK_COPIED_SUCCESSFULLY' }),
        );
      }}
    >
      <DropdownItemIcon path={mdiLink} />
      <FormattedMessage id="COPY_LINK" />
    </DropdownItem>
  );
}

export default CopyLinkDropdownItem;
