import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import theme from '../../../styles/theme';
import ValidationNotification from '../../common/notifications/ValidationNotification';
import WarningButton from '../../common/buttons/WarningButton';
import CancelButton from '../../common/buttons/CancelButton';
import {
  Modal,
  ModalContent,
  ModalContentWrap,
  ModalButtons,
  ModalCloseButton,
} from '../../common/Modal';
import useApi from '../../../hooks/useApi';
import Spinner from '../../common/Spinner';
import { useNotifications } from '../../NotificationsContext';
import { useFolderTree } from '../../FolderTreeContext';

function DeleteRiskModal({ risk, onClose }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showSuccessNotification } = useNotifications();
  const { addValueToDeepRiskCountByFolderId } = useFolderTree();
  const {
    isLoading: isDeleting,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    callEndpoint: deleteRisk,
  } = useApi({
    endpoint: `/api/v1/risks/${risk.id}`,
    method: 'DELETE',
  });

  React.useEffect(() => {
    if (isDeleteSuccess) {
      addValueToDeepRiskCountByFolderId(-1, risk.folder.id);
      onClose();
      const [parentFolder] = risk.breadcrumb.slice(-1);
      navigate(`/folders/${parentFolder ? parentFolder.id : 'root'}`);
      showSuccessNotification(
        intl.formatMessage({ id: 'DELETED_SUCCESSFULLY' }),
      );
    }
  }, [
    intl,
    isDeleteSuccess,
    navigate,
    onClose,
    risk,
    showSuccessNotification,
    addValueToDeepRiskCountByFolderId,
  ]);

  return (
    <Modal open onClose={onClose} closeOnBackdropClick={!isDeleting}>
      <ModalContent title={intl.formatMessage({ id: 'DELETE_RISK' })}>
        <ModalContentWrap>
          <p>
            <FormattedMessage id="RISK_DELETE_CONFIRMATION" />
          </p>
          {isDeleteError ? (
            <ValidationNotification
              validationMessage={intl.formatMessage({ id: 'FAILED_TO_DELETE' })}
            />
          ) : null}
          <ModalButtons>
            <WarningButton onClick={() => deleteRisk()} disabled={isDeleting}>
              <FormattedMessage id="DELETE" />
            </WarningButton>
            <ModalCloseButton>
              <CancelButton disabled={isDeleting}>
                <FormattedMessage id="CANCEL" />
              </CancelButton>
            </ModalCloseButton>
            {isDeleting ? (
              <Spinner size={theme.arter.icon.size.medium} />
            ) : null}
          </ModalButtons>
        </ModalContentWrap>
      </ModalContent>
    </Modal>
  );
}

DeleteRiskModal.propTypes = {
  risk: PropTypes.shape({
    id: PropTypes.string.isRequired,
    breadcrumb: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ).isRequired,
    folder: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  onClose: PropTypes.func,
};

export default DeleteRiskModal;
