import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import DeleteSettingModal from '../DeleteSettingModal';
import { useForm } from '../../../FormContext';

function DeleteLikelihoodModal({ id, ...props }) {
  const intl = useIntl();
  const { state } = useForm();

  return (
    <DeleteSettingModal
      title={intl.formatMessage({ id: 'DELETE_LIKELIHOOD' })}
      confirmationMessage={intl.formatMessage(
        {
          id: 'LIKELIHOOD_DELETE_CONFIRMATION',
        },
        {
          likelihoodName: state.name.value,
          b: (chunks) => <b>{chunks}</b>,
          br: <br />,
        },
      )}
      deleteEndpoint={`/api/v1/likelihoods/${id}`}
      {...props}
    />
  );
}

DeleteLikelihoodModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DeleteLikelihoodModal;
