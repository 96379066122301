import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import MatrixCell from '../../../../common/charts/MatrixCell';
import FolderMatrixTooltip from './FolderMatrixTooltip';
import IMPACT_TYPE from '../../../../../constants/impact-type';
import useOnClickOutsideElement from '../../../../../hooks/useOnClickOutsideElement';
import HelpNotification from '../../../../common/notifications/HelpNotification';
import NoData from '../../../../../styles/images/no_data.svg';
import { useSettings } from '../../../../SettingsContext';

function FolderMatrix({ risks }) {
  const intl = useIntl();
  const { likelihoods, impactValues, impactTargets } = useSettings();
  const [tooltipData, setTooltipData] = React.useState();
  const matrixChartRef = React.useRef();
  useOnClickOutsideElement(matrixChartRef, () => setTooltipData(null));

  function displayTooltip(cell, event) {
    setTooltipData({
      cell,
      x: event.clientX,
      y: event.clientY,
    });
  }

  const data = React.useMemo(() => {
    const chartDataMap = new Map(
      likelihoods.map((likelihood) => [
        likelihood.id,
        {
          id: likelihood.value,
          data: new Map(
            impactValues
              .slice()
              .reverse()
              .map((impactValue) => [
                impactValue.id,
                {
                  x: impactValue.value,
                  y: likelihood.value,
                  leftOfCenter: impactValue.value < impactValues.length / 2,
                  impacts: [],
                },
              ]),
          ),
        },
      ]),
    );

    risks
      .filter((risk) => risk.currentLikelihoodId)
      .forEach((risk) => {
        risk.impacts
          .filter(
            (impact) =>
              impact.currentImpactValueId &&
              impact.type !== IMPACT_TYPE.NOT_SET,
          )
          .forEach((impact) => {
            chartDataMap
              .get(risk.currentLikelihoodId)
              .data.get(impact.currentImpactValueId)
              .impacts.push({
                riskId: risk.id,
                riskName: risk.name,
                impactType: impact.type,
                impactTargetId: impact.impactTargetId,
              });
          });
      });

    return Array.from(chartDataMap.values());
  }, [impactValues, likelihoods, risks]);

  const hasData = React.useMemo(
    () =>
      data.some((dataPoint) =>
        Array.from(dataPoint.data.values()).some((cell) => cell.impacts.length),
      ),
    [data],
  );

  return hasData ? (
    <MatrixWrapper ref={matrixChartRef}>
      <ResponsiveHeatMap
        name="folderMatrix"
        data={data}
        cellComponent={MatrixCell}
        margin={{ top: 8, right: 20, bottom: 45, left: 20 }}
        onClick={(cell, event) => displayTooltip(cell, event)}
        axisTop={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: intl.formatMessage({ id: 'IMPACT' }),
          legendPosition: 'middle',
          legendOffset: 38,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: intl.formatMessage({ id: 'LIKELIHOOD' }),
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        colors={{
          type: 'diverging',
          scheme: 'greys',
          divergeAt: 1.5,
          minValue: 1,
          maxValue: 25,
        }}
        forceSquare={true}
        borderColor="#b4b4b4"
        borderWidth={1}
        emptyColor="#555555"
        hoverTarget="cell"
        tooltip={() => <></>}
      ></ResponsiveHeatMap>
      {tooltipData ? (
        <FolderMatrixTooltip
          tooltipData={tooltipData}
          onClose={() => setTooltipData(null)}
          impactTargets={impactTargets}
        />
      ) : null}
    </MatrixWrapper>
  ) : (
    <HelpNotification
      small
      srcImage={NoData}
      title={intl.formatMessage({ id: 'NO_DATA' })}
    />
  );
}

FolderMatrix.propTypes = {
  risks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      riskCurrentLikelihoodId: PropTypes.string,
      impacts: PropTypes.arrayOf(
        PropTypes.shape({
          currentImpactValueId: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

const MatrixWrapper = styled.div`
  height: calc(100% - 30px);
`;

export default FolderMatrix;
