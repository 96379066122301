import React from 'react';
import { useIntl } from 'react-intl';
import HelpNotification from '../../../common/notifications/HelpNotification';
import NotFound from '../../../../styles/images/not-found.svg';

function NoSearchResultsNotification() {
  const intl = useIntl();

  return (
    <HelpNotification
      srcImage={NotFound}
      title={intl.formatMessage({ id: 'NO_SEARCH_RESULTS' })}
    />
  );
}

export default NoSearchResultsNotification;
