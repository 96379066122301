import React from 'react';
import { FormattedMessage } from 'react-intl';
import { mdiPlus, mdiShieldAccountOutline, mdiFileExcelOutline } from '@mdi/js';
import styled from 'styled-components';
import { Header, HeaderTools, HeaderToolsDropdown } from '../header/Header';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import ButtonIcon from '../../common/buttons/ButtonIcon';
import { DropdownItem, DropdownItemIcon } from '../../common/Dropdown';
import RenderByRightRole from '../../RenderByRightRole';
import RIGHT_ROLE from '../../../constants/right-role';
import { useFolder } from './FolderContext';
import ExportExcelButton from './excel-export/ExportExcelButton';
import CopyLinkDropdownItem from '../header/CopyLinkDropdownItem';

function RootFolderHeader() {
  const {
    setIsCreatingRisk,
    setIsCreatingFolder,
    setIsUpdatingRights,
    setIsSelectingRiskImportFile,
  } = useFolder();

  return (
    <Header>
      <Title>
        <FormattedMessage id="RISKS" />
      </Title>
      <HeaderTools>
        <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
          <PrimaryButton onClick={() => setIsCreatingRisk(true)}>
            <ButtonIcon path={mdiPlus} />
            <FormattedMessage id="CREATE_RISK" />
          </PrimaryButton>
        </RenderByRightRole>
        <HeaderToolsDropdown>
          <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
            <DropdownItem
              role="button"
              onClick={() => setIsCreatingFolder(true)}
            >
              <DropdownItemIcon path={mdiPlus} />
              <FormattedMessage id="CREATE_FOLDER" />
            </DropdownItem>
          </RenderByRightRole>
          <CopyLinkDropdownItem />
          <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
            <DropdownItem
              role="button"
              onClick={() => setIsSelectingRiskImportFile(true)}
            >
              <DropdownItemIcon path={mdiFileExcelOutline} />
              <FormattedMessage id="IMPORT_EXCEL" />
            </DropdownItem>
          </RenderByRightRole>
          <ExportExcelButton />
          <RenderByRightRole role={RIGHT_ROLE.ADMIN}>
            <DropdownItem
              role="button"
              onClick={() => setIsUpdatingRights(true)}
            >
              <DropdownItemIcon path={mdiShieldAccountOutline} />
              <FormattedMessage id="RIGHTS" />
            </DropdownItem>
          </RenderByRightRole>
        </HeaderToolsDropdown>
      </HeaderTools>
    </Header>
  );
}

const Title = styled.h1`
  @media ${(props) => props.theme.arter.device.desktop} {
    align-self: center;
  }
`;

export default RootFolderHeader;
