import styled from 'styled-components';
import Button from './Button';

const SaveButton = styled(Button)`
  color: ${(props) => props.theme.arter.color.white};
  background-color: ${(props) => props.theme.arter.color.green};
  border: 1px solid ${(props) => props.theme.arter.color.green};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.darkGreen};
  }
`;

export default SaveButton;
