<ContentWrapper>
<Navigation>

#### Table of Contents

- <NavItem href="#introduction" linktext="Introduction" />

- <NavItem href="#user-and-roles" linktext="User and Roles" />

- <NavItem href="#rights" linktext="Rights" />

- <NavItem href="#settings" linktext="Settings" />

  - <NavItem href="#tags" linktext="Tags" />

  - <NavItem href="#risk-magnitude" linktext="Risk Magnitude" />

  - <NavItem href="#likelihood" linktext="Likelihood" />

  - <NavItem href="#impact" linktext="Impact" />

  - <NavItem href="#impact-targets" linktext="Impact Targets" />

- <NavItem href="#folders" linktext="Folders" />

- <NavItem href="#excel-import-and-export" linktext="Excel import and export" />

- <NavItem href="#risks" linktext="Risks" />

- <NavItem href="#language" linktext="Language" />

- <NavItem
    href="#technical-usage-information"
    linktext="Technical usage information"
  />

</Navigation>

<Content>

## <a id="introduction"></a>Introduction

The purpose of the module is to support the entire risk management work of the
organization. The software is designed for the use of different kind and size
organizations. Access to the module is through the main product. The software
does not strictly comply with any single framework. Best practices have been
applied from several sources. Identified and assessed risks are maintained in
the module. Measures can be defined for risks, which can be used to minimize
threats and maximize opportunities. Risks can be grouped using folders or tags.
You can set permissions for folders. Various summary information about the risks
can be seen.

## <a id="user-and-roles"></a>Users and Roles

Users are managed in the main product. Active none-admin user in IMS or ARC has
access to risk module and is defined as **risk module user**. Active admin user
in IMS or ARC has access to risk module and is defined as **risk module admin**.

## <a id="rights"></a>Rights

Risk module admin has automatically all the rights for using the module.

The risk module administrator must give rights to the folders so that other
users can use the software correctly. Rights are given only to folders, not
separate risks. Single risk’s rights are based on the folder where risk is
located. Rights are given to user groups and not to individual users.

**Viewer**

- Views folders and risks
- Views risk's changelog

**Modifier**

- Views folders and risks
- Views risk’s changelog
- Creates, moves, updates and deletes folders and risks **Admin**
- View folders and risks
- Views risks’ changelog
- Creates, moves, updates and deletes folders and risks
- Defines rights to folders

**Risk module admin**

- All rights to risk module
- Manages settings of the risk

## <a id="settings"></a>Settings

Only system administrators can access the settings. Admins of the main product
are automatically admins of the module.

#### <a id="tags"></a>Tags

Tags are managed by module admins. They can create, read, update and delete
tags. Admins maintain a controlled vocabulary. Tags are used as metadata for
single risks. Tags can be renamed. The change will take effect of existing
risks. Removing the tag removes the tag from the risks. Deleted tags cannot be
restored afterwards.

#### <a id="risk-magnitude"></a>Risk Magnitude

Risk magnitude is calculated using formula Likelihood \* Impact. Admin can
create organization’s own numeric scale for risk matrix.

> #### A common example is 5 \* 5 risk matrix.
>
> Likelihood:  
> 5 - Very likely  
> 4 - Likely  
> 3 - Possible  
> 2 - Unlikely  
> 1 - Very unlikely
>
> Impact:  
> 5 - Very large  
> 4 - Large  
> 3 - Medium  
> 2 - Small  
> 1 - Very small

#### <a id="likelihood"></a>Likelihood

Define the scales for risk likehood levels. Deleted values cannot be restored
afterwards. The change will take effect of existing risks. Deleted value is
changed to ”not set” automatically in risks.

#### <a id="impact"></a>Impact

Define the scales for risk likehood levels. Deleted values cannot be restored
afterwards. The change will take effect of existing risks. Deleted value is
changed to ”not set” automatically in risks.

#### <a id="impact-targets"></a>Impact targets

Define the impact targets. Deleted values cannot be restored afterwards. The
change will take effect of existing risks. The information is completely removed
from the risks.

## <a id="folders"></a>Folders

Software has a root folder called ”Risks”. It cannot be renamed or deleted. The
folder is visible to everyone, but its contents not necessarily. Other parts it
works similar way as a folder. Module admin must give rights to root so that
other users can use the software properly. When new folders are created the
rights are automatically copied from the upper level but can be changed
afterwards. Sub-folders may have different rights. If certain user does not have
e.g. viewer rights to folder in the middle of the folder structure, user can
still navigate to lower levels. In this case, user sees the hierarchy shorter as
it really is.

> #### Rare use case can be this:
>
> User1 sees hierarchy: Risks / Folder1 / Folder2 / Folder3
>
> User2 sees hierarchy: Risks / Folder1 / Folder3

Folder’s risk list shows only the risks that are saved in the current folder.
Folder’s chart data is based in the risks in the current level and also from all
the risks in the sub-folder.

**Folder actions**

- Create risk
- Create folder
- Move folder
- Delete folder
- Rights
- Import excel
- Export excel

**Good to know**

- You can rename the folder name by clicking on it (editing rights required)
- There is no limit to the size of the folder structure
- You can move the folder under a folder to which you have rights. You cannot
  move the folder under your own subfolders.
- If you delete a folder, you also delete all its risks and possible subfolders.
  Data cannot be recovered.

## <a id="excel-import-and-export"></a>Excel import and export

Excel import enables adding multiple risks to the selected folder at the same
time. The import is made from an Excel (xlsx) file. The data is retrieved from
the first tab of the Excel file and the data must be divided into titled
columns.

> #### Tip <br />
>
> Define the risk settings first. Make sure the probability scale and impacts
> are created to settings. Create one risk to selected folder. <ins>Use "Excel
> Export" in the folder, and you will get a ready-made template. Use template
> for import data.</ins>

When you import data from an Excel file, you first see a preview of the risks.

> #### If Excel import does not work, here is some tips for troubleshooting: <br />
>
> If Excel import does not work, here is some tips for troubleshooting:
>
> - Excel file type is not xlsx or file is otherwise corrupted
> - Excel file is empty
> - The information is not located correctly in the Excel file (you can use
>   Excel export function in some folder to get correct template). Be careful
>   with rows and columns!
> - Risk name is missing (name of the risk is mandatory information, other
>   information is not)

Excel export enables adding multiple risks to Excel-file. The information comes
in rows and columns according to certain logic that cannot be changed.

## <a id="risks"></a>Risks

You create risks to folders. Give the risk a name first, after which you can
fill in other information. No other information is mandatory except the name.
You can freely enter information in different order.

**Risk information**

- Description
- Tags
- Responsibility
- State
- Management strategy
- Priority
- Review date
- Probability (current state, target state)
- Effects (current state, target state)
- Effect sizes (probability \* impact)
- Actions

**Risk functions**

- Delete risk
- Move risk
- Changelog (see who changed, what was changed and when it was changed)

**Good to know**

- You can change the name of the risk and other information directly from the
  view. There is no separate editing mode.
- The tags that are available, the scale of probability and impact, and impact
  targets are the same for all risks, and they are managed through the settings.
  Possible changes in settings also affect existing risks.
- You can assign responsibility to several users and/or user groups. Users and
  groups come automatically from the main product.
- Information recorded in the changelog does not change afterwards. The
  changelog keeps the information as it was at the time of the change (signature
  style manner). The changelog works automatically and cannot be edited
  separately.
- You can only move the risk to a folder where you have editing rights
- If you delete the risk, it cannot be recovered

## <a id="language"></a>Language

The risk module can be implemented in Finnish or English, and it would be good
to decide the language to be used at the beginning of the implementation. The
system administrator of the risk module can define different settings and the
content is created in only one language. The language selection of the risk
module is determined by the language selection of the main product.

## <a id="technical-usage-information"></a>Technical usage information

The user interface of the risk module is responsive. The views are scalable,
i.e. they adapt to different screen sizes. General accessibility criteria have
been taken into account in the development. The user interface supports reading
and editing content on the same page. In a technical sense, the risk module
functions as a separate service, although it appears to the user as part of the
main product. For example, the module can be located on a completely different
server than the main product. The Service's backup and recovery policies may be
different.

</Content>

</ContentWrapper>
