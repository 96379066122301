import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  mdiLightningBoltOutline,
  mdiSpeedometer,
  mdiStateMachine,
} from '@mdi/js';
import ChartCard from '../../../common/ChartCard';
import FolderMatrix from './matrix/FolderMatrix';
import PieChart from './PieChart';
import RISK_STATE from '../../../../constants/risk-state';
import IMPACT_TYPE from '../../../../constants/impact-type';
import { appears } from '../../../../styles/keyframe-styles';

const STATE_COLOR = {
  [RISK_STATE.NOT_SET]: '#4d4d4d',
  [RISK_STATE.UNCONTROLLED]: '#EF6961',
  [RISK_STATE.PARTIALLY_CONTROLLED]: '#FFC25D',
  [RISK_STATE.CONTROLLED]: '#4F8073',
};

const IMPACT_TYPE_COLOR = {
  [IMPACT_TYPE.NOT_SET]: '#4d4d4d',
  [IMPACT_TYPE.THREAT]: '#EF6961',
  [IMPACT_TYPE.OPPORTUNITY]: '#4F8073',
};

function FolderCharts({ risks }) {
  const intl = useIntl();

  function buildPieChartData() {
    const stateCounts = {
      [RISK_STATE.NOT_SET]: 0,
      [RISK_STATE.UNCONTROLLED]: 0,
      [RISK_STATE.PARTIALLY_CONTROLLED]: 0,
      [RISK_STATE.CONTROLLED]: 0,
    };
    const impactTypeCounts = {
      [IMPACT_TYPE.NOT_SET]: 0,
      [IMPACT_TYPE.THREAT]: 0,
      [IMPACT_TYPE.OPPORTUNITY]: 0,
    };

    risks.forEach((risk) => {
      stateCounts[risk.state] += 1;
      risk.impacts.forEach((impact) => {
        impactTypeCounts[impact.type] += 1;
      });
    });

    return {
      stateData: Object.keys(RISK_STATE).map((key) => ({
        id: intl.formatMessage({ id: RISK_STATE[key] }),
        label: intl.formatMessage({ id: RISK_STATE[key] }),
        value: stateCounts[key],
        color: STATE_COLOR[key],
      })),
      impactTypeData: Object.keys(IMPACT_TYPE).map((key) => ({
        id: intl.formatMessage({ id: IMPACT_TYPE[key] }),
        label: intl.formatMessage({ id: IMPACT_TYPE[key] }),
        value: impactTypeCounts[key],
        color: IMPACT_TYPE_COLOR[key],
      })),
    };
  }

  const { stateData, impactTypeData } = buildPieChartData();

  return (
    <Wrapper>
      <FolderChartCard
        data-testid="state-pie-chart-card"
        iconPath={mdiStateMachine}
        title={intl.formatMessage({ id: 'STATES' })}
      >
        <PieWrapper>
          <PieChart data={stateData} />
        </PieWrapper>
      </FolderChartCard>
      <FolderChartCard
        data-testid="impact-target-type-pie-chart-card"
        iconPath={mdiLightningBoltOutline}
        title={intl.formatMessage({ id: 'IMPACT_TARGET_TYPES' })}
      >
        <PieWrapper>
          <PieChart data={impactTypeData} />
        </PieWrapper>
      </FolderChartCard>
      <MatrixChartCard
        data-testid="impact-matrix-chart-card"
        iconPath={mdiSpeedometer}
        title={intl.formatMessage({ id: 'MAGNITUDES' })}
      >
        <FolderMatrix risks={risks} />
      </MatrixChartCard>
    </Wrapper>
  );
}

FolderCharts.propTypes = {
  risks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      riskCurrentLikelihoodId: PropTypes.string,
      impacts: PropTypes.arrayOf(
        PropTypes.shape({
          currentImpactValueId: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

const Wrapper = styled.section`
  display: block;
  position: sticky;
  left: 0;
  z-index: 3;
  min-width: 310px;
  margin-bottom: ${(props) => props.theme.arter.spacing.large};
  animation: ${appears} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  @media ${(props) => props.theme.arter.device.pad} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const FolderChartCard = styled(ChartCard)`
  min-width: 300px;
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
  flex: 1;
  padding: ${(props) =>
    `${props.theme.arter.spacing.medium} ${props.theme.arter.spacing.mediumLarge}`};

  @media ${(props) => props.theme.arter.device.pad} {
    &:nth-child(2) {
      margin-right: ${(props) => props.theme.arter.spacing.small};
      margin-left: ${(props) => props.theme.arter.spacing.small};
    }
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    min-width: 230px;
    max-width: none;
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      margin-right: ${(props) => props.theme.arter.spacing.medium};
      margin-left: ${(props) => props.theme.arter.spacing.medium};
    }
  }
`;

const PieWrapper = styled.div`
  height: 220px;
  width: 100%;
  margin: auto;

  @media ${(props) => props.theme.arter.device.desktop} {
    max-width: 490px;
  }
`;

const MatrixChartCard = styled(FolderChartCard)`
  height: 300px;
  min-width: 320px;

  @media ${(props) => props.theme.arter.device.desktop} {
    height: 320px;
    max-width: 460px;
    min-width: 340px;
    margin-right: 0;
  }

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    height: 370px;
  }
`;

export default FolderCharts;
