export default {
  en: {
    ACCEPT: 'Accept',
    ACTION: 'Action',
    ACTION_NAME: 'Action name',
    ACTION_DONE: 'Action status',
    ACTIONS: 'Actions',
    ADD: 'Add',
    ADD_ACTION: 'Add action',
    ADD_ACTIONS: 'Add actions',
    ADD_DESCRIPTION: 'Add description',
    ADD_GROUP: 'Add group',
    ADD_NAME: 'Add name',
    ADD_TAGS: 'Add tags',
    ADD_IMPACTS: 'Add impacts',
    ADD_RESPONSIBLE: 'Add responsible',
    ADD_VALUE: 'Add value',
    ADDED: 'Added',
    ADMIN: 'Admin',
    ALL_FILTERS: 'All filters',
    BACK: 'Back',
    CANCEL: 'Cancel',
    CHANGED: 'Changed',
    CHANGELOG: 'Changelog',
    CHANGES_SAVED: 'Changes saved',
    CHANGESET: 'Changeset',
    CLICK_TO_EDIT_THE_NAME: 'Click to edit the name',
    CLEAR: 'Clear',
    CLEAR_ALL: 'Clear all',
    CLEAR_RISK_REVISION_DATE: 'Clear risk revision date',
    CLOSE: 'Close',
    CONTROLLED: 'Controlled',
    COLLAPSE_THE_LEFT_MENU: 'Collapse the left menu',
    COPIED_RISK_DEFAULT_NAME: '{sourceRiskName} - copy',
    COPIED_SUCCESSFULLY: 'Copied successfully',
    COPY: 'Copy',
    COPY_LINK: 'Copy link',
    COPY_RISK: 'Copy risk',
    CREATE: 'Create',
    CREATE_ACTION: 'Create action',
    CREATE_FOLDER: 'Create folder',
    CREATE_IMPACT: 'Create impact',
    CREATE_RISK: 'Create risk',
    CREATED: 'Created',
    CREATED_SUCCESSFULLY: 'Created successfully',
    CURRENT: 'Current',
    CURRENT_IMPACT: 'Current impact',
    CURRENT_LIKELIHOOD: 'Current likelihood',
    DELETE: 'Delete',
    DELETED: 'Deleted',
    DELETED_SUCCESSFULLY: 'Deleted successfully',
    DELETED_USER: 'Deleted user',
    DELETE_FOLDER: 'Delete folder',
    DELETE_IMPACT_TARGET: 'Delete impact target',
    DELETE_IMPACT_VALUE: 'Delete impact value',
    DELETE_LIKELIHOOD: 'Delete likelihood',
    DELETE_RISK: 'Delete risk',
    DELETE_SUCCESSFUL: 'Deleted successfully',
    DELETE_TAG: 'Delete tag',
    DESCRIPTION: 'Description',
    DISABLE: 'Disable',
    DONE: 'Done',
    DRAG: 'Drag',
    EDIT: 'Edit',
    EDITED: 'Edited',
    EMPTY_FOLDER_INFO: 'Create and manage content with the tools at the top.',
    EXPAND_THE_LEFT_MENU: 'Expand the left menu',
    EXPORT_EXCEL: 'Export excel',
    FAILED_TO_COPY: 'Failed to copy',
    FAILED_TO_CREATE: 'Failed to create',
    FAILED_TO_DELETE: 'Failed to delete',
    FAILED_TO_IMPORT: 'Failed to import',
    FAILED_TO_MOVE: 'Failed to move',
    FAILED_TO_SAVE_CHANGES: 'Failed to save changes',
    FOLDERS: 'Folders',
    FOLDER_DELETE_CONFIRMATION: 'Are you sure you want to delete this folder?',
    FOLDER_NAME: 'Folder name',
    GENERAL: 'General',
    GROUPS: 'Groups',
    HELP: 'Help',
    HIGH: 'High',
    HISTORY: 'History',
    I_AM_RESPONSIBLE: 'I am responsible',
    IMPACT: 'Impact',
    IMPACT_CURRENT_VALUE: 'Impact current value',
    IMPACT_SCALE: 'Impact value scale',
    IMPACT_SCALE_INFO:
      'Manage impact scale. The impacts on the scale are used to calculate the magnitudes of the risk.',
    IMPACT_TARGET: 'Impact target',
    IMPACT_TARGET_DELETE_CONFIRMATION:
      'Are you sure you want to delete this impact target?{br}This will effect to all existing risks that use the impact.{br}<b>This action is immediate and cannot be undone.</b>',
    IMPACT_TARGET_TYPES: 'Impact target types',
    IMPACT_TARGET_VALUE: 'Impact target value',
    IMPACT_TARGETS: 'Impact targets',
    IMPACT_TARGETS_INFO:
      'Manage impact targets. Impacts can be organizational goals or more general view points.',
    IMPACT_TYPE: 'Impact type',
    IMPACT_VALUE: 'Impact value',
    IMPACTS: 'Impacts',
    IMPACTS_INFO:
      'The risk does not yet have any impacts selected. Click the Select button to select impacts.',
    IMPACT_VALUE_DELETION_CONFIRMATION:
      'Are you sure you want to delete this impact value? The impact value <b>"{impactValueName}"</b> changes to <b>"Not set"</b> for existing risks. {br} <b>This action is immediate and cannot be undone.</b>',
    IMPORT: 'Import',
    IMPORT_EXCEL: 'Import excel',
    IMPORT_EXCEL_FAILED_MESSAGE:
      'There was an error and import failed. Check user guide for possible solutions.',
    IMPORT_EXCEL_INVALID_VALUE_MESSAGE: 'Invalid value. Defaults to “Not set”.',
    IMPORT_EXCEL_ISSUE_COUNT_MESSAGE: 'Issues: {issueCount}',
    IMPORT_EXCEL_PREVIEW: 'Import excel (preview)',
    IMPORT_EXCEL_ROW_COUNT_MESSAGE:
      'This import will generate {rowCount} risks from file {fileName}',
    IMPORT_EXCEL_SKIPPED_ROW_COUNT_MESSAGE: 'Skipped rows: {skippedRowCount}',
    IMPORTED_SUCCESSFULLY: 'Imported successfully',
    INCREASE: 'Increase',
    LIKELIHOOD: 'Likelihood',
    LIKELIHOODS: 'Likelihoods',
    LIKELIHOOD_DELETE_CONFIRMATION:
      'Are you sure you want to delete this likelihood? The likelihood <b>"{likelihoodName}"</b> changes to <b>"Not set"</b> for existing risks. {br} <b>This action is immediate and cannot be undone.</b>',
    LIKELIHOOD_SCALE_INFO:
      'Manage likelihood scale. The likelihoods on the scale are used to calculate the magnitudes of the risk.',
    LIKELIHOOD_SCALE: 'Likelihood scale',
    LINK_COPIED_SUCCESSFULLY: 'Link copied successfully',
    LOADING: 'Loading...',
    LOCATION: 'Location',
    LOW: 'Low',
    MAGNITUDE: 'Magnitude',
    MAGNITUDE_FORMULA: 'Magnitude formula',
    MAGNITUDE_FORMULA_INFO: 'likelihood * impact = magnitude',
    MAGNITUDES: 'Magnitudes',
    MAGNITUDE_CHARTS: 'Magnitude charts',
    MANAGEMENT_STRATEGY: 'Management strategy',
    MAXIMUM_CHARACTERS_REACHED: 'Maximum characters reached',
    MAXIMUM_CHARACTERS_REACHED_MESSAGE: 'Max size is 2000 characters',
    MORE_TOOLS: 'More tools',
    MOVE: 'Move',
    MOVE_TO: 'Move to',
    MOVED_SUCCESSFULLY: 'Moved successfully',
    MEDIUM: 'Medium',
    MITIGATE: 'Mitigate',
    MODIFIER: 'Modifier',
    NAME: 'Name',
    NEW_FOLDER: 'New folder',
    NEW_RISK: 'New risk',
    NO_RESULTS: 'No results were found',
    NO_CONTENT: 'No content',
    NO_DATA: 'No data',
    NO_HISTORY: 'No history data',
    NO_HISTORY_INFO:
      'When changes on impacts magnitude are made, this chart will show the change history.',
    NO_RIGHTS: 'No rights',
    NO_RIGHTS_INFO: 'You don’t have necessary rights',
    NO_RIGHTS_INFO_PAGE:
      "Sorry, but you don't have permission to access this page.",
    NO_RIGHTS_INFO_FOLDER:
      "Sorry, but you don't have permission to access this folder.",
    NO_SEARCH_RESULTS: 'No search results',
    NO_SUBFOLDERS: 'No subfolders',
    NOT_SET: 'Not set',
    OK: 'Ok',
    OPPORTUNITY: 'Opportunity',
    PARTIALLY_CONTROLLED: 'Partially controlled',
    PRIORITY: 'Priority',
    REMOVE: 'Remove',
    REMOVE_RIGHT: 'Remove right',
    REMOVED: 'Removed',
    RESPONSIBLE: 'Responsible',
    RESULTS: 'Results',
    REVISION_DATE: 'Revision date',
    RIGHTS: 'Rights',
    RISK: 'Risk',
    RISK_DELETE_CONFIRMATION: 'Are you sure you want to delete this risk?',
    RISK_NAME: 'Risk name',
    RISK_MATRIX: 'Risk matrix',
    RISK_COUNT: 'Risks',
    RISKS: 'Risks',
    RISK_MAGNITUDE: 'Risk magnitude',
    SAVE: 'Save',
    SEARCH: 'Search',
    SEARCH_FOR_RISKS: 'Search for risks',
    SEARCH_FROM_FOLDER: 'Search from folder',
    SELECT: 'Select',
    SELECT_ALL: 'Select all',
    SELECT_GROUP: 'Select group',
    SELECT_IMPACTS: 'Select impacts',
    SELECTED: 'Selected',
    SETTINGS: 'Settings',
    SHARE: 'Share',
    SHOW_INFORMATION: 'Show information',
    STATE: 'State',
    STATES: 'States',
    STATISTICS: 'Statistics',
    TAG: 'Tag',
    TAG_DELETE_CONFIRMATION:
      'Are you sure you want to delete this tag?{br}This will effect all existing risks that use the tag.{br}<b>This action is immediate and cannot be undone.</b>',
    TAGS: 'Tags',
    TARGET: 'Target',
    TAGS_INFO:
      'You can create tags to manage your risks. Tags allow you to flexibly group risks. You can add as many tags as you want to the risk.',
    TARGET_IMPACT: 'Target impact',
    TARGET_LIKELIHOOD: 'Target likelihood',
    THREAT: 'Threat',
    TIME: 'Time',
    TRANSFER: 'Transfer',
    TYPE: 'Type',
    UNCONTROLLED: 'Uncontrolled',
    UNDONE: 'Undone',
    UNSAVED_CHANGES_CONFIRMATION:
      'You have unsaved changes. Are you sure you want to leave this page?',
    VERY_HIGH: 'Very high',
    VERY_LOW: 'Very low',
    VALUE: 'Value',
    VIEWER: 'Viewer',
  },
  fi: {
    ACCEPT: 'Hyväksy',
    ACTION: 'Toimenpide',
    ACTION_NAME: 'Toimenpiteen nimi',
    ACTION_DONE: 'Toimenpiteen tila',
    ACTIONS: 'Toimenpiteet',
    ADD: 'Lisää',
    ADD_ACTION: 'Lisää toimenpide',
    ADD_ACTIONS: 'Lisää toimenpiteitä',
    ADD_DESCRIPTION: 'Lisää kuvaus',
    ADD_GROUP: 'Lisää ryhmä',
    ADD_NAME: 'Lisää nimi',
    ADD_TAGS: 'Lisää tunnisteita',
    ADD_IMPACTS: 'Lisää vaikuttavuuksia',
    ADD_RESPONSIBLE: 'Lisää vastuullinen',
    ADD_VALUE: 'Lisää arvo',
    ADDED: 'Lisättiin',
    ADMIN: 'Järjestelmänvalvoja',
    ALL_FILTERS: 'Kaikki suodattimet',
    BACK: 'Takaisin',
    CANCEL: 'Peruuta',
    CHANGED: 'Muuttui',
    CHANGELOG: 'Muutosloki',
    CHANGES_SAVED: 'Muutokset tallennettu',
    CHANGESET: 'Muutosjoukko',
    CLICK_TO_EDIT_THE_NAME: 'Klikkaa muokataksesi nimeä',
    CLEAR: 'Tyhjennä',
    CLEAR_ALL: 'Tyhjennä kaikki',
    CLEAR_RISK_REVISION_DATE: 'Tyhjennä riskin tarkastuspäivä',
    CLOSE: 'Sulje',
    CONTROLLED: 'Hallittu',
    COLLAPSE_THE_LEFT_MENU: 'Tiivistä vasen valikko',
    COPIED_RISK_DEFAULT_NAME: '{sourceRiskName} - kopio',
    COPIED_SUCCESSFULLY: 'Kopiointi onnistui',
    COPY: 'Kopioi',
    COPY_LINK: 'Kopioi linkki',
    COPY_RISK: 'Kopioi riski',
    CREATE: 'Luo',
    CREATE_ACTION: 'Luo toimenpide',
    CREATE_FOLDER: 'Luo kansio',
    CREATE_IMPACT: 'Luo vaikuttavuus',
    CREATE_RISK: 'Luo riski',
    CREATED: 'Luotiin',
    CREATED_SUCCESSFULLY: 'Luonti onnistui',
    CURRENT: 'Nykyinen',
    CURRENT_IMPACT: 'Nykyinen vaikuttavuus',
    CURRENT_LIKELIHOOD: 'Nykyinen todennäköisyys',
    DELETE: 'Poista',
    DELETED: 'Poistettiin',
    DELETED_SUCCESSFULLY: 'Poisto onnistui',
    DELETED_USER: 'Poistettu käyttäjä',
    DELETE_FOLDER: 'Poista kansio',
    DELETE_IMPACT_TARGET: 'Poista vaikuttavuuden kohde',
    DELETE_IMPACT_VALUE: 'Poista vaikuttavuus',
    DELETE_LIKELIHOOD: 'Poista todennäköisyys',
    DELETE_RISK: 'Poista riski',
    DELETE_SUCCESSFUL: 'Poisto onnistui',
    DELETE_TAG: 'Poista tunniste',
    DESCRIPTION: 'Kuvaus',
    DISABLE: 'Poista käytöstä',
    DONE: 'Tehty',
    DRAG: 'Raahaa',
    EDIT: 'Muokkaa',
    EDITED: 'Muokattu',
    EMPTY_FOLDER_INFO:
      'Luo ja hallitse sisältöä ylhäältä löytyvillä työkaluilla.',
    EXPAND_THE_LEFT_MENU: 'Laajenna vasen valikko',
    EXPORT_EXCEL: 'Vie excel',
    FAILED_TO_COPY: 'Kopiointi epäonnistui',
    FAILED_TO_CREATE: 'Luonti epäonnistui',
    FAILED_TO_DELETE: 'Poisto epäonnistui',
    FAILED_TO_IMPORT: 'Tuonti epäonnistui',
    FAILED_TO_MOVE: 'Siirto epäonnistui',
    FAILED_TO_SAVE_CHANGES: 'Muutosten tallennus epäonnistui',
    FOLDERS: 'Kansiot',
    FOLDER_DELETE_CONFIRMATION:
      'Oletko varma, että haluat poistaa tämän kansion?',
    FOLDER_NAME: 'Kansion nimi',
    GENERAL: 'Yleinen',
    GROUPS: 'Ryhmät',
    HELP: 'Ohje',
    HIGH: 'Korkea',
    HISTORY: 'Historia',
    I_AM_RESPONSIBLE: 'Olen vastuussa',
    IMPACT: 'Vaikutus',
    IMPACT_CURRENT_VALUE: 'Vaikuttavuuden nykyinen arvo',
    IMPACT_SCALE: 'Vaikuttavuusasteikko',
    IMPACT_SCALE_INFO:
      'Hallinnoi vaikuttavuusasteikkoa. Asteikon vaikutuksia käytetään riskin suuruuksien laskentaan.',
    IMPACT_TARGET: 'Vaikuttavuuden kohde',
    IMPACT_TARGET_DELETE_CONFIRMATION:
      'Oletko varma, että haluat poistaa tämän vaikuttavuuden kohteen?{br}Tämä vaikuttaa kaikkiin olemassa oleviin riskeihin, jotka käyttävät vaikuttavuuden kohdetta.{br}<b>Tämä toimenpide on välitön ja sitä ei voi kumota.</b>',
    IMPACT_TARGET_TYPES: 'Vaikuttavuuden kohteiden tyypit',
    IMPACT_TARGET_VALUE: 'Vaikuttavuuden tavoite arvo',
    IMPACT_TARGETS: 'Vaikuttavuuden kohteet',
    IMPACT_TARGETS_INFO:
      'Hallinnoi vaikuttavuuden kohteita. Yleensä vaikuttavuuden kohteina voidaan pitää organisaation tavoitteita tai yleisempiä näkökulmia.',
    IMPACT_TYPE: 'Vaikuttavuudeen tyyppi',
    IMPACT_VALUE: 'Vaikuttavuuden arvo',
    IMPACTS: 'Vaikuttavuudet',
    IMPACTS_INFO:
      'Riskillä ei vielä vielä yhtään vaikuttavuutta valittuna. Valitse vaikuttavuudet klikkaamalla Valitse-painiketta.',
    IMPACT_VALUE_DELETION_CONFIRMATION:
      'Oletko varma, että haluat poistaa tämän vaikuttavuuden? Vaikuttavuus <b>"{impactValueName}"</b> muuttuu arvoon <b>"Ei asetettu"</b> olemassa oleville riskeille. {br} <b>Tämä toimenpide on välitön ja sitä ei voi kumota.</b>',
    IMPORT: 'Tuo',
    IMPORT_EXCEL: 'Tuo excel',
    IMPORT_EXCEL_FAILED_MESSAGE:
      'Tapahtui virhe ja tuonti epäonnistui. Tarkista mahdolliset ratkaisut ohjeesta.',
    IMPORT_EXCEL_INVALID_VALUE_MESSAGE:
      'Virheellinen arvo. Asetetaan oletusarvoon “Asettamatta”.',
    IMPORT_EXCEL_ISSUE_COUNT_MESSAGE: 'Ongelmia: <b>{issueCount}</b>',
    IMPORT_EXCEL_PREVIEW: 'Tuo excel (esikatselu)',
    IMPORT_EXCEL_ROW_COUNT_MESSAGE:
      'Tämä tuonti luo <b>{rowCount}</b> riskiä tiedostosta <b>{fileName}</b>',
    IMPORT_EXCEL_SKIPPED_ROW_COUNT_MESSAGE:
      'Ohitetut rivit: <b>{skippedRowCount}</b>',
    IMPORTED_SUCCESSFULLY: 'Tuonti onnistui',
    INCREASE: 'Lisää',
    LIKELIHOOD: 'Todennäköisyys',
    LIKELIHOODS: 'Todennäköisyydet',
    LIKELIHOOD_DELETE_CONFIRMATION:
      'Oletko varma, että haluat poistaa tämän todennäköisyyden? Todennäköisyys <b>"{likelihoodName}"</b> muuttuu arvoon <b>"Ei asetettu"</b> olemassa oleville riskeille. {br} <b>Tämä toimenpide on välitön ja sitä ei voi kumota.</b>',
    LIKELIHOOD_SCALE_INFO:
      'Hallinnoi todennäköisyysasteikko. Asteikon todennäköisyyksiä käytetään riskin suuruuksien laskentaan.',
    LIKELIHOOD_SCALE: 'Todennäköisyysasteikko',
    LINK_COPIED_SUCCESSFULLY: 'Linkin kopiointi onnistui',
    LOADING: 'Ladataan...',
    LOCATION: 'Sijainti',
    LOW: 'Matala',
    MAGNITUDE: 'Suuruus',
    MAGNITUDE_FORMULA: 'Suuruuden kaava',
    MAGNITUDE_FORMULA_INFO: 'todennäköisyys * vaikutus = suuruus',
    MAGNITUDES: 'Suuruudet',
    MAGNITUDE_CHARTS: 'Suuruuksien kuvaajat',
    MANAGEMENT_STRATEGY: 'Hallintastrategia',
    MAXIMUM_CHARACTERS_REACHED: 'Merkkien enimmäismäärä saavutettu',
    MAXIMUM_CHARACTERS_REACHED_MESSAGE: 'Enimmäiskoko on 2000 merkkiä',
    MORE_TOOLS: 'Lisää toimintoja',
    MOVE: 'Siirrä',
    MOVE_TO: 'Siirrä',
    MOVED_SUCCESSFULLY: 'Siirto onnistui',
    MEDIUM: 'Keskitaso',
    MITIGATE: 'Vähennä',
    MODIFIER: 'Muokkaaja',
    NAME: 'Nimi',
    NEW_FOLDER: 'Uusi kansio',
    NEW_RISK: 'Uusi riski',
    NO_RESULTS: 'Tuloksia ei löytynyt',
    NO_CONTENT: 'Ei sisältöä',
    NO_DATA: 'Ei näytettävää tietoa',
    NO_HISTORY: 'Ei historiatietoja',
    NO_HISTORY_INFO:
      'Kun vaikuttavuuksien suuruuteen tehdään muutoksia, tämä kaavio näyttää muutoshistorian.',
    NO_RIGHTS: 'Ei oikeuksia',
    NO_RIGHTS_INFO: 'Sinulla ei ole tarvittavia oikeuksia',
    NO_RIGHTS_INFO_PAGE:
      'Pahoittelut, mutta sinulla ei ole tarvittavia oikeuksia nähdäksesi sivua.',
    NO_RIGHTS_INFO_FOLDER:
      'Pahoittelut, mutta sinulla ei ole tarvittavia oikeuksia nähdäksesi kansiota.',
    NO_SEARCH_RESULTS: 'Ei hakutuloksia',
    NO_SUBFOLDERS: 'Ei alikansioita',
    NOT_SET: 'Asettamatta',
    OK: 'Ok',
    OPPORTUNITY: 'Mahdollisuus',
    PARTIALLY_CONTROLLED: 'Osittain hallittu',
    PRIORITY: 'Prioriteetti',
    REMOVE: 'Poista',
    REMOVE_RIGHT: 'Poista oikeus',
    REMOVED: 'Poistettiin',
    RESPONSIBLE: 'Vastuu',
    RESULTS: 'Tulokset',
    REVISION_DATE: 'Tarkistuspäivä',
    RIGHTS: 'Oikeudet',
    RISK: 'Riski',
    RISK_DELETE_CONFIRMATION: 'Oletko varma, että haluat poistaa tämän riskin?',
    RISK_NAME: 'Riskin nimi',
    RISK_MATRIX: 'Riskimatriisi',
    RISK_COUNT: 'Riskejä',
    RISKS: 'Riskit',
    RISK_MAGNITUDE: 'Riskin suuruus',
    SAVE: 'Tallenna',
    SEARCH: 'Hae',
    SEARCH_FOR_RISKS: 'Etsi riskejä',
    SEARCH_FROM_FOLDER: 'Etsi kansiosta',
    SELECT: 'Valitse',
    SELECT_ALL: 'Valitse kaikki',
    SELECT_GROUP: 'Valitse ryhmä',
    SELECT_IMPACTS: 'Valitse vaikuttavuudet',
    SELECTED: 'Valittiin',
    SETTINGS: 'Asetukset',
    SHARE: 'Jaa',
    SHOW_INFORMATION: 'Näytä tiedot',
    STATE: 'Tila',
    STATES: 'Tilat',
    STATISTICS: 'Tilastot',
    TAG: 'Tunniste',
    TAG_DELETE_CONFIRMATION:
      'Oletko varma, että haluat poistaa tämän tunnisteen?{br}Tämä vaikuttaa kaikkiin olemassa oleviin riskeihin, jotka käyttävät tunnistetta.{br}<b>Tämä toimenpide on välitön ja sitä ei voi kumota.</b>',
    TAGS: 'Tunnisteet',
    TAGS_INFO:
      'Voit luoda riskien hallinnointia varten tunnisteita. Tunnisteiden avulla voit joustavasti ryhmitellä riskejä. Voit lisätä riskiin niin monta tunnistetta kuin haluat.',
    TARGET: 'Tavoite',
    TARGET_IMPACT: 'Tavoite vaikuttavuus',
    TARGET_LIKELIHOOD: 'Tavoite todennäköisyys',
    THREAT: 'Uhka',
    TIME: 'Aika',
    TRANSFER: 'Siirrä',
    TYPE: 'Tyyppi',
    UNCONTROLLED: 'Hallitsematon',
    UNDONE: 'Tekemättä',
    UNSAVED_CHANGES_CONFIRMATION:
      'Sinulla on tallentamattomia muutoksia. Oletko varma että haluat poistua tältä sivulta?',
    VERY_HIGH: 'Erittäin korkea',
    VERY_LOW: 'Erittäin matala',
    VALUE: 'Arvo',
    VIEWER: 'Katsoja',
  },
};
