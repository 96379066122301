import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import TextArea from '../../common/TextArea';
import { useForm } from '../../FormContext';

function SettingDescriptionInputField({ disabled }) {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();

  return (
    <TextArea
      aria-label={intl.formatMessage({ id: 'DESCRIPTION' })}
      type="text"
      maxLength="65535"
      value={state.description.value}
      onChange={(event) =>
        dispatchFormChange({
          field: 'description',
          value: event.target.value,
        })
      }
      disabled={disabled}
      placeholder={intl.formatMessage({ id: 'ADD_DESCRIPTION' })}
    />
  );
}

SettingDescriptionInputField.propTypes = {
  disabled: PropTypes.bool,
};

export default SettingDescriptionInputField;
