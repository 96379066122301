import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BaseCell from './BaseCell';
import IMPACT_TYPE from '../../../../../constants/impact-type';

function ImpactTypeCell({ type, issue }) {
  return (
    <BaseCellWithLeftBorder issue={issue}>
      {type ? (
        <ImpactType type={type}>
          <FormattedMessage id={type} />
        </ImpactType>
      ) : null}
    </BaseCellWithLeftBorder>
  );
}

ImpactTypeCell.propTypes = {
  type: PropTypes.oneOf(Object.values(IMPACT_TYPE)),
  issue: PropTypes.bool,
};

const BaseCellWithLeftBorder = styled(BaseCell)`
  border-left: ${(props) => props.theme.arter.border.main};
`;

const ImpactType = styled.div`
  display: inline-block;
  color: ${(props) => {
    switch (props.type) {
      case IMPACT_TYPE.THREAT:
        return props.theme.arter.color.red;
      case IMPACT_TYPE.OPPORTUNITY:
        return props.theme.arter.color.green;
      default:
        return props.theme.arter.font.textColor;
    }
  }};
`;

export default ImpactTypeCell;
