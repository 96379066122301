import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { mdiChevronRight, mdiFolderOutline } from '@mdi/js';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  TreeItemWrapper,
  TreeItem,
  ToggleButton,
  FolderIcon,
  NameWrapper,
  ItemName,
  Count,
} from '../../common/TreeItem';
import { useFolderTree } from '../../FolderTreeContext';

function FolderTreeItem({ folder, root = false }) {
  const { id, name } = folder;
  const { isFolderOpen, isFolderSelected, toggleFolderById } = useFolderTree();
  const isOpen = isFolderOpen(id);
  const isSelected = isFolderSelected(id);
  const toggleable = folder.subFolders.length;

  return (
    <TreeItemWrapper $root={root} key={id}>
      <TreeItem
        $active={isSelected}
        $toggleable={toggleable}
        data-testid={`nav-item-${id}`}
      >
        {toggleable ? (
          <ToggleButton
            path={mdiChevronRight}
            $active={isOpen}
            data-testid={`toggle-${id}`}
            onClick={() => toggleFolderById(id)}
          ></ToggleButton>
        ) : null}
        <Link to={`/folders/${id}`}>
          <FolderIcon path={mdiFolderOutline} />
          <ItemName>{name}</ItemName>
          {folder.deepRiskCount > 0 ? (
            <Count>{folder.deepRiskCount}</Count>
          ) : null}
        </Link>
      </TreeItem>
      {isOpen
        ? folder.subFolders.map((subFolder) => (
            <FolderTreeItem key={subFolder.id} folder={subFolder} />
          ))
        : null}
    </TreeItemWrapper>
  );
}

FolderTreeItem.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subFolders: PropTypes.arrayOf(PropTypes.shape),
    deepRiskCount: PropTypes.number.isRequired,
  }),
  root: PropTypes.bool,
};

const Link = styled(NameWrapper).attrs({
  as: RouterLink,
})`
  line-height: 1;

  &:focus,
  &:active {
    color: $black;
  }
`;

export default FolderTreeItem;
