export default {
  NOT_SET: 'NOT_SET',
  ACCEPT: 'ACCEPT',
  CREATE: 'CREATE',
  INCREASE: 'INCREASE',
  MITIGATE: 'MITIGATE',
  REMOVE: 'REMOVE',
  SHARE: 'SHARE',
  TRANSFER: 'TRANSFER',
};
