import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import theme from '../../../styles/theme';
import ValidationNotification from '../../common/notifications/ValidationNotification';
import WarningButton from '../../common/buttons/WarningButton';
import CancelButton from '../../common/buttons/CancelButton';
import {
  Modal,
  ModalContent,
  ModalContentWrap,
  ModalButtons,
  ModalCloseButton,
} from '../../common/Modal';
import useApi from '../../../hooks/useApi';
import Spinner from '../../common/Spinner';
import { useFolderTree } from '../../FolderTreeContext';
import { useFolder } from './FolderContext';
import { useNotifications } from '../../NotificationsContext';

function DeleteFolderModal() {
  const navigate = useNavigate();
  const { update: updateFolderTree } = useFolderTree();
  const { folder, isDeletingFolder, setIsDeletingFolder } = useFolder();
  const { showSuccessNotification } = useNotifications();
  const intl = useIntl();

  const {
    isLoading: isDeleting,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    callEndpoint: deleteFolder,
    resetStatus,
  } = useApi({
    endpoint: `/api/v1/folders/${folder.id}`,
    method: 'DELETE',
  });

  React.useEffect(() => {
    if (isDeleteSuccess) {
      setIsDeletingFolder(false);
      const [parentFolder] = folder.breadcrumb.slice(-1);
      updateFolderTree();
      navigate(`/folders/${parentFolder ? parentFolder.id : 'root'}`);
      showSuccessNotification(
        intl.formatMessage({ id: 'DELETED_SUCCESSFULLY' }),
      );
      resetStatus();
    }
  }, [
    folder.breadcrumb,
    intl,
    isDeleteSuccess,
    navigate,
    resetStatus,
    setIsDeletingFolder,
    showSuccessNotification,
    updateFolderTree,
  ]);

  return isDeletingFolder ? (
    <Modal
      open
      onClose={() => setIsDeletingFolder(false)}
      closeOnBackdropClick={!isDeleting}
    >
      <ModalContent title={intl.formatMessage({ id: 'DELETE_FOLDER' })}>
        <ModalContentWrap>
          <p>
            <FormattedMessage id="FOLDER_DELETE_CONFIRMATION" />
          </p>
          {isDeleteError ? (
            <ValidationNotification
              validationMessage={intl.formatMessage({ id: 'FAILED_TO_DELETE' })}
            />
          ) : null}
          <ModalButtons>
            <WarningButton onClick={() => deleteFolder()} disabled={isDeleting}>
              <FormattedMessage id="DELETE" />
            </WarningButton>
            <ModalCloseButton>
              <CancelButton disabled={isDeleting}>
                <FormattedMessage id="CANCEL" />
              </CancelButton>
            </ModalCloseButton>
            {isDeleting ? (
              <Spinner size={theme.arter.icon.size.medium} />
            ) : null}
          </ModalButtons>
        </ModalContentWrap>
      </ModalContent>
    </Modal>
  ) : null;
}

export default DeleteFolderModal;
