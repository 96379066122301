import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Backdrop from './Backdrop';
import { slideFromBottom } from '../../styles/keyframe-styles';

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));

const ModalContext = React.createContext();

function Modal({ children, open, onClose, closeOnBackdropClick }) {
  const [show, setShow] = React.useState(open);

  return (
    <ModalContext.Provider
      value={{ show, setShow, onClose, closeOnBackdropClick }}
    >
      {children}
    </ModalContext.Provider>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  closeOnBackdropClick: PropTypes.bool,
};

Modal.defaultProps = {
  onClose: () => {
    // onClose is optional
  },
  closeOnBackdropClick: true,
};

function ModalButtons({ children, ...props }) {
  return <ButtonRow {...props}>{children}</ButtonRow>;
}

ModalButtons.propTypes = {
  children: PropTypes.node,
};

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.arter.spacing.extraLarge};

  button {
    margin-right: ${(props) => props.theme.arter.spacing.medium};
  }
`;

function ModalCloseButton({ children: child }) {
  const { setShow, onClose } = React.useContext(ModalContext);
  return React.cloneElement(child, {
    onClick: callAll(() => setShow(false), child.props.onClick, onClose),
  });
}

ModalCloseButton.propTypes = {
  children: PropTypes.node,
};

function ModalOpenButton({ children: child }) {
  const { setShow } = React.useContext(ModalContext);
  return React.cloneElement(child, {
    onClick: callAll(() => setShow(true), child.props.onClick),
  });
}

ModalOpenButton.propTypes = {
  children: PropTypes.node,
};

function ModalContent({ children, title, ...props }) {
  const { show, setShow, onClose, closeOnBackdropClick } =
    React.useContext(ModalContext);

  function onBackdropClick(event) {
    if (event.target === event.currentTarget && closeOnBackdropClick) {
      event.preventDefault();
      setShow(false);
      onClose();
    }
  }
  return show
    ? ReactDOM.createPortal(
        <Backdrop onClick={(event) => onBackdropClick(event)}>
          <ModalBox {...props} role="dialog">
            <Header>
              <Title>{title}</Title>
            </Header>
            {children}
          </ModalBox>
        </Backdrop>,
        document.getElementById('root'),
      )
    : null;
}

ModalContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  closeOnBackdropClick: PropTypes.bool,
};

const ModalBox = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 440px;
  margin-top: 8%;
  overflow: auto;
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  background-color: ${(props) => props.theme.arter.color.white};
  box-shadow: 0 4px 15px -8px ${(props) => props.theme.arter.color.black};
  animation: ${slideFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};
  animation-delay: 0.2s;
  opacity: 0;
`;

const Header = styled.div`
  background-color: white;
  border-bottom: ${(props) => props.theme.arter.border.main};
  padding: ${(props) =>
    `${props.theme.arter.spacing.mediumLarge} ${props.theme.arter.spacing.large}`};
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.arter.font.size.large};
`;

const ModalContentWrap = styled.div`
  padding: ${(props) => props.theme.arter.spacing.large};
`;

export {
  Modal,
  ModalContent,
  ModalContentWrap,
  ModalButtons,
  ModalCloseButton,
  ModalOpenButton,
};
