import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import DeleteSettingModal from '../DeleteSettingModal';
import { useForm } from '../../../FormContext';

function DeleteImpactValueModal({ id, ...props }) {
  const intl = useIntl();
  const { state } = useForm();

  return (
    <DeleteSettingModal
      title={intl.formatMessage({ id: 'DELETE_IMPACT_VALUE' })}
      confirmationMessage={intl.formatMessage(
        {
          id: 'IMPACT_VALUE_DELETION_CONFIRMATION',
        },
        {
          impactValueName: state.name.value,
          b: (chunks) => <b>{chunks}</b>,
          br: <br />,
        },
      )}
      deleteEndpoint={`/api/v1/impact-values/${id}`}
      {...props}
    />
  );
}

DeleteImpactValueModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DeleteImpactValueModal;
