import React from 'react';
import { useFolder } from '../FolderContext';
import ExcelImportPreviewModal from './ExcelImportPreviewModal';

function ExcelImportInput() {
  const fileInputRef = React.useRef();
  const [file, setFile] = React.useState(null);

  const { isSelectingRiskImportFile, setIsSelectingRiskImportFile } =
    useFolder();

  React.useEffect(() => {
    if (isSelectingRiskImportFile) {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
      setIsSelectingRiskImportFile(false);
    }
  }, [isSelectingRiskImportFile, setIsSelectingRiskImportFile]);

  return (
    <div>
      <input
        data-testid="excel-import-input"
        ref={fileInputRef}
        hidden
        type="file"
        accept=".xlsx"
        onChange={(event) => setFile(event.target.files[0])}
      />
      {file ? (
        <ExcelImportPreviewModal
          file={file}
          onClose={() => {
            setFile(null);
          }}
        />
      ) : null}
    </div>
  );
}

export default ExcelImportInput;
