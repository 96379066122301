import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import RISK_MANAGEMENT_STRATEGY from '../../../../../constants/risk-management-strategy';
import FilterChipSelect from './FilterChipSelect';

function ManagementStrategyFilter({
  selectedManagementStrategies,
  onChange,
  onClear,
}) {
  const intl = useIntl();

  return (
    <FilterChipSelect
      data-testid="all-filters-management-strategy-filter"
      label={intl.formatMessage({ id: 'MANAGEMENT_STRATEGY' })}
      htmlFor="management-strategy-select"
      options={Object.values(RISK_MANAGEMENT_STRATEGY).map(
        (managementStrategy) => ({
          id: managementStrategy,
          name: intl.formatMessage({ id: managementStrategy }),
        }),
      )}
      selectedOptions={selectedManagementStrategies}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

ManagementStrategyFilter.propTypes = {
  selectedManagementStrategies: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(RISK_MANAGEMENT_STRATEGY)),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default ManagementStrategyFilter;
