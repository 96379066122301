/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { useKeycloak } from './KeycloakContext';

function TelemetryProvider(props) {
  const connectionString =
    window.APPLICATIONINSIGHTS_CONNECTION_STRING ===
    '__APPLICATIONINSIGHTS_CONNECTION_STRING__'
      ? ''
      : window.APPLICATIONINSIGHTS_CONNECTION_STRING;
  const { keycloak } = useKeycloak();
  const appInsightsContext = React.useMemo(
    () => ({ reactPlugin: new ReactPlugin() }),
    [],
  );

  React.useEffect(() => {
    if (connectionString) {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString,
          extensions: [appInsightsContext.reactPlugin],
          enableAutoRouteTracking: true,
        },
      });
      appInsights.loadAppInsights();

      appInsights.addTelemetryInitializer((envelope) => {
        envelope.data.arterTid = keycloak.tokenParsed.arter_tid;
        envelope.data.authIssuer = keycloak.tokenParsed.iss;
        if (
          envelope.baseType === 'PageviewData' ||
          envelope.baseType === 'PageviewPerformanceData'
        ) {
          envelope.baseData.name = window.location.pathname
            .replaceAll(
              /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g,
              '{id}',
            )
            .split('/')
            .slice(2)
            .join('/');
        }
      });
    }
  }, [connectionString, keycloak.tokenParsed, appInsightsContext]);

  return connectionString ? (
    <AppInsightsContext.Provider value={appInsightsContext}>
      {props.children}
    </AppInsightsContext.Provider>
  ) : (
    props.children
  );
}

TelemetryProvider.propTypes = {
  children: PropTypes.node,
};

export default TelemetryProvider;
