import styled from 'styled-components';
import IMPACT_TYPE from '../../../constants/impact-type';

const MagnitudeBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 26px;
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  background-color: ${(props) => {
    switch (props.$impactType) {
      case IMPACT_TYPE.THREAT:
        return '#f3b4b1';
      case IMPACT_TYPE.OPPORTUNITY:
        return '#beebcf';
      default:
        return 'transparent';
    }
  }};
  padding: ${(props) => props.theme.arter.spacing.small};
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  margin-right: ${(props) => props.theme.arter.spacing.small};
`;

export default MagnitudeBadge;
