<ContentWrapper>
<Navigation>

#### Sisällysluettelo

- <NavItem href="#introduction" linktext="Esittely" />

- <NavItem href="#user-and-roles" linktext="Käyttäjät ja roolit" />

- <NavItem href="#rights" linktext="Oikeudet" />

- <NavItem href="#settings" linktext="Asetukset" />

  - <NavItem href="#tags" linktext="Tunnisteet" />

  - <NavItem href="#risk-magnitude" linktext="Riskin suuruus" />

  - <NavItem href="#likelihood" linktext="Todennäköisyys" />

  - <NavItem href="#impact" linktext="Vaikutus" />

  - <NavItem href="#impact-targets" linktext="Vaikutuksen kohteet" />

- <NavItem href="#folders" linktext="Kansiot" />

- <NavItem href="#excel-import-and-export" linktext="Excel tuonti ja vienti" />

- <NavItem href="#risks" linktext="Riskit" />

- <NavItem href="#language" linktext="Kieli" />

- <NavItem
    href="#technical-usage-information"
    linktext="Teknistä käyttötietoa"
  />

</Navigation>

<Content>

## <a id="introduction"></a>Esittely

Moduulin on tarkoitus tukea organisaation koko riskien hallinta työtä.
Ohjelmisto on suunniteltu erilaisten ja erikokoisten organisaatioiden käyttöön.
Pääsy moduuliin tapahtuu päätuotteen kautta. Ohjelmisto ei noudata tarkasti
mitään yksittäistä viitekehystä. Hyviä käytäntöjä on sovellettu useista
lähteistä. Tunnistettuja ja arvioituja riskejä ylläpidetään moduulissa.
Riskeille voi määrittää toimenpiteitä, joilla voi pyrkiä minimoimaan uhat ja
maksimoimaan mahdollisuudet. Riskejä voi ryhmitellä kansioiden tai tunnisteiden
avulla. Kansioille pystyy määrittämään käyttöoikeuksia. Riskeistä on nähtävillä
erilaista yhteenvetotietoa.

## <a id="user-and-roles"></a>Käyttäjät ja roolit

Käyttäjiä hallitaan päätuotteessa. Aktiivisella ei-järjestelmänvalvojalla
IMS:ssä tai ARC:ssä on pääsy riskimoduuliin ja hänet määritellään
**riskimoduulin käyttäjäksi**. IMS:n tai ARC:n aktiivisella admin-käyttäjällä on
pääsy riskimoduuliin ja hänet määritellään riskimoduulin
**järjestelmänvalvojaksi**

## <a id="rights"></a>Oikeudet

Riskimoduulin järjestelmänvalvojalla on automaattisesti kaikki oikeudet moduulin
käyttöön.

Riskimoduulin järjestelmänvalvojan on annettava kansioille oikeuksia, jotta muut
käyttäjät voivat käyttää ohjelmistoa oikein. Oikeudet annetaan ainoastaan
kansioihin, ei erillisiin riskeihin. Yhden riskin oikeudet perustuvat kansioon,
jossa riski sijaitsee. Oikeudet annetaan käyttäjäryhmille eikä yksittäisille
käyttäjille.

**Katsoja**

- Näkee kansiot ja riskit
- Näkee riskin muutoslokin

**Muokkaaja**

- Muokkaa kansioita ja riskejä
- Näkee riskin muutoslokin
- Lisää, siirtää, päivittää ja poistaa kansioita ja riskejä

**Admin**

- Näkee kansiot ja riskit
- Näkee riskin muutoslokin
- muokkaa kansioita ja riskejä
- Lisää, siirtää, päivittää ja poistaa kansioita ja riskejä

**Riskimoduulin ylläpitäjä**

- Kaikki oikeudet riskimoduuliin
- Hallitsee riskimoduulien asetuksia

## <a id="settings"></a>Asetukset

Asetuksiin pääsevät käsiksi vain järjestelmänvalvojat. Päätuotteen
järjestelmänvalvojat ovat automaattisesti moduulin järjestelmänvalvojia.

#### <a id="tags"></a>Tunnisteet

Tunnisteita hallinnoivat moduulin järjestelmänvalvojat. He voivat luoda, lukea,
päivittää ja poistaa tunnisteita. Järjestelmänvalvojat ylläpitävät hallittua
sanastoa. Tunnisteita käytetään metatietona yksittäisillä riskeillä. Tunnisteet
voidaan nimetä uudelleen. Muutos vaikuttaa olemassa oleviin riskeihin.
Tunnisteen poisto poistaa tunnisteen riskeiltä. Poistettuja tunnisteita ei voi
palauttaa jälkikäteen.

#### <a id="risk-magnitude"></a>Riskin suuruus

Riskin suuruus lasketaan kaavalla Todennäköisyys \* Vaikutus.
Järjestelmänvalvoja voi luoda organisaation oman numeerisen asteikon
riskimatriisille

> #### Yleinen esimerkki on 5 \* 5 riskimatriisi.
>
> Todennäköisyys:  
> 5 - Erittäin yleinen  
> 4 - Yleinen  
> 3 - Mahdollinen  
> 2 - Harvinainen  
> 1 - Erittäin harvinainen
>
> Vaikutus:  
> 5 - Erittäin suuri  
> 4 - Suuri  
> 3 - keskikokoinen  
> 2 - Pieni  
> 1 - Erittäin pieni

#### <a id="likelihood"></a>Todennäköisyys

Määritä asteikot riskin todennäköisyystasoille. Poistettuja arvoja ei voi
palauttaa jälkikäteen. Muutos vaikuttaa olemassa oleviin riskeihin. Poistettu
arvo muuttuu automaattisesti arvoon ”ei asetettu” riskeissä.

#### <a id="impact"></a>Vaikutus

Määritä asteikot riskin todennäköisyystasoille. Poistettuja arvoja ei voi
palauttaa jälkikäteen. Muutos vaikuttaa olemassa oleviin riskeihin. Poistettu
arvo muuttuu automaattisesti arvoon ”ei asetettu” riskeissä.

#### <a id="impact-targets"></a>Vaikutuksen kohteet

Määrittele vaikutusten kohteet. Poistettuja arvoja ei voi palauttaa jälkikäteen.
Muutos vaikuttaa olemassa oleviin riskeihin. Tieto poistuu kokonaan riskeiltä.

## <a id="folders"></a>Kansiot

Ohjelmistolla on juurikansio nimeltä ”Riskit”. Sitä ei voi nimetä uudelleen tai
poistaa. Kansio näkyy kaikille, mutta sen sisältö ei välttämättä. Muilta osin se
toimii samalla tavalla kuin kansio. Moduulin järjestelmänvalvojan on annettava
oikeudet juureen, jotta muut käyttäjät voivat käyttää ohjelmistoa oikein. Kun
uusia kansioita luodaan, oikeudet kopioidaan automaattisesti ylemmältä tasolta,
mutta niitä voidaan muuttaa jälkikäteen. Alikansioilla voi olla erilaiset
oikeudet. Jos tietyllä käyttäjällä ei ole esim. katsojan oikeudet kansioon
keskellä kansiorakennetta, käyttäjä voi silti navigoida alemmille tasoille.
Tässä tapauksessa käyttäjä näkee hierarkian lyhyempänä kuin se oikeasti on.

> #### Harvinainen käyttötapaus voi olla tämä: <br />
>
> Käyttäjä1 näkee hierarkian: Riskit / Kansio1 / Kansio2 / Kansio3  
> Käyttäjä2 näkee hierarkian: Riskit / Kansio1 / Kansio3

Kansion riskiluettelo näyttää vain ne riskit, jotka on tallennettu nykyiseen
kansioon. Kansion kaaviotiedot perustuvat nykyisen tason riskeihin ja myös
kaikista alikansion riskeistä.

**Kansion toiminnot**

- Lisää riski
- Lisää kansio
- Siirrä kansio
- Poista kansio
- Oikeudet
- Tuo excel
- Vie excel

**Hyvä tietää**

- Voit uudelleen nimetä kansion nimen klikkaamalla sitä (muokkausoikeudet
  vaaditaan)
- Kansiorakenteen suuruudella ei ole rajoituksia
- Voit siirtää kansion sellaisen kansion alle johon sinulla on oikeudet.
  Kansiota ei voi siirtää omien alikansioiden alle.
- Jos poistat kansion, niin poistat samalla kaikki sen riskit ja mahdolliset
  alikansiot. Tietoja ei pysty palauttamaan.

## <a id="excel-import-and-export"></a>Excel tuonti ja vienti

Excel tuonti mahdollistaa usean riskin lisäämisen samalla kertaa valittuun
kansioon. Tuonti tehdään Excel (xlsx) tiedostosta. Tiedot haetaan
Excel-tiedoston ensimmäiseltä välilehdeltä ja tiedot pitää olla jaetultuna
otsikoituihin sarakkeisiin.

> #### Vinkki <br />
>
> Määrittele riskien asetukset ensin valmiiksi. Varmista, että
> todennäköisyysasteikko ja vaikuttavuudet on luotu asetuksiin. Luo valittuun
> kansioon yksi riski esimerkkinä. <ins>Käytä kansiolla “Vie Exceliin”, jolloin
> saat valmiin mallipohjan. Käytä mallipohjaa tietojen tuonnissa.</ins>

Kun tuot tietoja Excel-tiedostosta, näet ensin esikatselun riskeistä.

> #### Jos Excel-tuonti ei toimi, tässä on joitakin vinkkejä vianetsintään: <br />
>
> - Excel-tiedostotyyppi ei ole xlsx tai tiedosto on muuten vioittunut
> - Excel tiedosto on tyhjä
> - Tieto ei sijaitse Excel tiedostossa oikein (voit käyttää Excel vienti
>   -toimintoa tietyssä kansiossa saadaksesi oikean mallipohjan). Ole tarkka
>   rivien ja sarakkeiden kanssa!
> - Riskin nimi puuttuu (riskin nimi on pakollinen tieto, muut tiedot eivät ole)

Excel-vienti mahdollistaa useiden riskien lisäämisen Excel-tiedostoon. Tiedot
tulevat riveinä ja sarakkeina tietyn logiikan mukaisesti, jota ei voi muuttaa.

## <a id="risks"></a>Riskit

Lisäät riskejä kansioihin. Anna riskille ensin nimi, jonka jälkeen pystyt
täyttämään muita tietoja. Mikään muu tieto ei ole pakollinen paitsi nimi.
Tietoja voi vapaasti syöttää eri järjestyksessä.

**Riskin tietoja**

- Kuvaus
- Tunnisteet
- Vastuu
- Tila
- Hallintastrategia
- Prioriteetti
- Tarkistuspäivä
- Todennäköisyys (nykytila, tavoitetila)
- Vaikuttavuudet (nykytila, tavoitetila)
- Vaikuttavuuksien suuruudet (todennäköisyys \* vaikutus)
- Toimenpiteet

**Riskin toiminnot**

- Poista riski
- Siirrä riski
- Muutosloki (kato kuka muutti, mitä muutti ja milloin muutti)

**Hyvä tietää**

- Voit muuttaa riskin nimeä ja muita tietoja suoraan näkymästä. Erilistä
  muokkautilaa ei ole.
- Käytössä olevat tunnisteet, todennäköisyys- ja vaikutusasteikko sekä
  vaikutusten kohteet ovat samoja kaikille riskeille ja niiden hallinnointi
  tapahtuu asetusten kautta. Mahdolliset muutokset asetuksissa, vaikuttavat myös
  olemassa oleviin riskeihin.
- Vastuuksi voit määrittää useita käyttäjiä ja/tai käyttäjäryhmiä. Käyttäjät ja
  ryhmät tulevat automaattisesti päätuotteesta.
- Muutoslokiin kirjatut tiedot eivät muutu jälkikäteen. Muutosloki säilyttää
  tiedot sellaisina kuin ne oli muutoshetkellä (allekirjoitustyylinen tapa).
  Muutosloki toimii automaattisesti eikä sitä pysty erikseen muokkaamaan.
- Voit siirtää riskin vain kansioon johon sinulla on muokkausokeudet
- Jos poistat riskin, niin sitä ei pysty palauttamaan

## <a id="language"></a>Kieli

Riskimoduuli voidaan ottaa käyttöön suomeksi tai englanniksi ja käytettävä kieli
olisi hyvä päättää käyttöönoton alussa. Riskimoduulin järjestelmänvalvoja pystyy
määrittelemään erilaisia asetuksia ja sisältö luodaan vain yhdellä kielellä.
Riskimoduulin kielivalinta määräytyy päätuotteen kielivalinnan mukaan.

## <a id="technical-usage-information"></a>Teknistä käyttötietoa

Riskimoduulin käyttöliittymä on responsiivinen. Näkymät skaalautuvat eli
mukautuvat eri näyttökoiden mukaan. Yleisiä saavutettavuuskriteerejä on
huomioitu kehityksessä. Käyttöliittymä tukee sisällön lukemista ja editointia
samalla sivulla. Riskimoduuli toimii teknisessä mielessä irrallisena palveluna
vaikka käyttäjälle esiintyy osana päätuotetta. Moduuli voi esimerksi sijaita
täysin eri palvelimella mitä päätuote. Palvelun varmuuskopiointi- ja
palautuskäytännöt voivat olla erilaisia.

</Content>

</ContentWrapper>
