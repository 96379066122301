import styled from 'styled-components';
import Icon from '@mdi/react';

const ButtonIcon = styled(Icon)`
  height: ${(props) => props.theme.arter.icon.size.medium};
  width: ${(props) => props.theme.arter.icon.size.medium};
  margin-right: ${(props) => props.theme.arter.spacing.small};
`;

export default ButtonIcon;
