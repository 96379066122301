import { css } from 'styled-components';

export default css`
  width: 100%;
  outline: none;
  padding: ${(props) =>
    `${props.theme.arter.spacing.small} ${props.theme.arter.spacing.medium}`};
  color: ${(props) => props.theme.arter.font.textColor};
  min-height: 38px;
  background-color: ${(props) => props.theme.arter.color.white};
  border: 1px solid ${(props) => props.theme.arter.color.darkGray};
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  font-size: ${(props) => props.theme.arter.font.size.medium};
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover {
    border-color: ${(props) => props.theme.arter.color.black};
  }

  &:active,
  &:focus,
  &:focus-visible {
    border: 1px solid ${(props) => props.theme.arter.color.product.default};
    box-shadow: inset 0 0 0 1px
      ${(props) => props.theme.arter.color.product.default};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
