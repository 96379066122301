import React from 'react';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import {
  mdiFolderOutline,
  mdiCogOutline,
  mdiChevronLeft,
  mdiHelpCircleOutline,
} from '@mdi/js';
import Tooltip from '@mui/material/Tooltip';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import SectionLink from './SectionLink';
import Spinner from '../../common/Spinner';
import Button from '../../common/buttons/Button';
import theme from '../../../styles/theme';
import FolderTreeItem from './FolderTreeItem';
import useWidthResize from '../../../hooks/useWidthResize';
import { useFolderTree } from '../../FolderTreeContext';
import RenderByRealmRole from '../../RenderByRealmRole';
import REALM_ROLE from '../../../constants/realm-role';
import { useHelpMenu } from '../../HelpMenuContext';
import { MainLeftMenuItem, ItemIcon } from './MainLeftMenuItem';
import { Count } from '../../common/TreeItem';

export const LEFT_MENU_WIDTH = {
  OPEN: 236,
  CLOSED: 20,
  MAX: 500,
};

function LeftMenu() {
  const location = useLocation();
  const intl = useIntl();
  const {
    folderTree,
    isLoading: isLoadingFolderTree,
    isFolderSelected,
    setSelectedFolder,
  } = useFolderTree();
  const { toggleHelpMenu } = useHelpMenu();
  const [openMenu, setOpenMenu] = React.useState(true);
  const [menuWidth, setMenuWidth] = React.useState(
    window.localStorage.getItem('risks-module-left-menu-width')
      ? parseInt(
          window.localStorage.getItem('risks-module-left-menu-width'),
          10,
        )
      : LEFT_MENU_WIDTH.OPEN,
  );
  const { handler, isResizing } = useWidthResize({
    onResize: (mouseMovement) => {
      if (openMenu) {
        const newWidth = menuWidth + mouseMovement;
        setMenuWidth(
          newWidth > LEFT_MENU_WIDTH.MAX ? LEFT_MENU_WIDTH.MAX : newWidth,
        );
      }
    },
  });

  function toggleMenuVisibility() {
    setOpenMenu(!openMenu);
  }

  React.useEffect(() => {
    if (!isResizing) {
      if (menuWidth < LEFT_MENU_WIDTH.OPEN / 2) {
        setOpenMenu(false);
        setMenuWidth(LEFT_MENU_WIDTH.OPEN);
      } else {
        window.localStorage.setItem('risks-module-left-menu-width', menuWidth);
      }
    }
  }, [isResizing, menuWidth]);

  React.useEffect(() => {
    if (
      !location.pathname.includes('/risks/') &&
      !location.pathname.includes('/folders/')
    ) {
      setSelectedFolder(null);
    }
  }, [location, setSelectedFolder]);

  return (
    <Wrapper
      $width={openMenu ? menuWidth : LEFT_MENU_WIDTH.CLOSED}
      $openMenu={openMenu}
      $menuWidth={menuWidth}
      data-testid="left-menu"
    >
      <Tooltip
        title={intl.formatMessage({
          id: openMenu ? 'COLLAPSE_THE_LEFT_MENU' : 'EXPAND_THE_LEFT_MENU',
        })}
      >
        <ToggleMenuButton
          data-testid="toggle-left-menu-btn"
          onClick={toggleMenuVisibility}
          $openMenu={openMenu}
        >
          <Icon path={mdiChevronLeft} />
        </ToggleMenuButton>
      </Tooltip>
      {openMenu ? (
        <Content data-testid="left-menu-content">
          <SectionLink
            to="/folders/root"
            iconPath={mdiFolderOutline}
            active={isFolderSelected('root')}
            data-testid="nav-item-root"
          >
            <FormattedMessage id="RISKS" />
            {folderTree && folderTree.deepRiskCount > 0 ? (
              <AllRisksCount>{folderTree.deepRiskCount}</AllRisksCount>
            ) : null}
          </SectionLink>
          <FolderTree>
            {!isLoadingFolderTree && folderTree ? (
              folderTree.subFolders.map((folder) => (
                <FolderTreeItem root key={folder.id} folder={folder} />
              ))
            ) : (
              <LeftMenuSpinner size={theme.arter.icon.size.medium} />
            )}
          </FolderTree>
          <BottomLinks>
            <HelpButton
              onClick={() => toggleHelpMenu()}
              data-testid="nav-item-help"
            >
              <ItemIcon path={mdiHelpCircleOutline} />
              <FormattedMessage id="HELP" />
            </HelpButton>
            <RenderByRealmRole role={REALM_ROLE.RISKS_ADMIN}>
              <SectionLink
                to="/settings"
                iconPath={mdiCogOutline}
                active={location.pathname.includes('/settings')}
                data-testid="nav-item-settings"
              >
                <FormattedMessage id="SETTINGS" />
              </SectionLink>
            </RenderByRealmRole>
          </BottomLinks>
        </Content>
      ) : null}
      <ResizeHandle
        data-testid="left-menu-resize-handle"
        onMouseDown={handler}
        $isResizing={isResizing}
        $openMenu={openMenu}
      />
    </Wrapper>
  );
}

LeftMenu.defaultProps = {
  openMenu: true,
};

const AllRisksCount = styled(Count)`
  margin-right: ${(props) => props.theme.arter.spacing.medium};
`;

const ToggleMenuButton = styled(Button)`
  position: absolute;
  top: 14px;
  right: -${(props) => props.theme.arter.spacing.mediumLarge};
  padding: 3px;
  opacity: ${(props) => (props.$openMenu ? 0 : 1)};
  background-color: ${(props) => props.theme.arter.color.white};
  border: 1px solid ${(props) => props.theme.arter.color.gray};
  border-radius: ${(props) => props.theme.arter.border.radius.full};
  width: 26px;
  height: 26px;
  min-width: auto;
  font-size: ${(props) => props.theme.arter.icon.size.small};
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  svg {
    margin-right: 0;
    color: ${(props) => props.theme.arter.color.product.default};
    transition: ${(props) => props.theme.arter.animation.speed.slow};
    transform: rotate(${(props) => (props.$openMenu ? '0deg' : '180deg')});
    width: 20px;
  }

  &:hover {
    background-color: ${(props) => props.theme.arter.color.product.default};
    color: ${(props) => props.theme.arter.color.white};
    border-color: ${(props) => props.theme.arter.color.product.default};

    svg {
      color: ${(props) => props.theme.arter.color.white};
    }
  }
`;

ToggleMenuButton.defaultProps = {
  path: mdiChevronLeft,
};

const LeftMenuSpinner = styled(Spinner)`
  margin-left: ${(props) => props.theme.arter.spacing.medium};
`;

const Wrapper = styled.nav.attrs((props) => ({
  style: {
    width: `${props.$width}px`,
  },
}))`
  display: flex;
  flex-shrink: 0;
  position: relative;
  background-color: ${(props) => props.theme.arter.color.product.extraLight};
  z-index: 5;

  &:hover > ${ToggleMenuButton} {
    opacity: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${(props) => `${props.theme.arter.spacing.medium} 0`};
  overflow: hidden;
`;

const FolderTree = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 1px;
  padding: ${(props) =>
    `0 ${props.theme.arter.spacing.small} ${props.theme.arter.spacing.medium} ${props.theme.arter.spacing.small}`};
`;

const BottomLinks = styled.div`
  margin-top: auto;
  padding-top: ${(props) => props.theme.arter.spacing.small};
  border-top: ${(props) =>
    `1px solid ${props.theme.arter.color.veryLightGray}`};
`;

const HelpButton = styled(MainLeftMenuItem).attrs({
  as: 'button',
})`
  outline: none;
  border: none;
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
`;

const ResizeHandle = styled.div`
  position: relative;
  display: block;
  margin-left: auto;
  width: 0px;
  background-color: transparent;
  cursor: ew-resize;
  pointer-events: ${(props) => (props.$openMenu ? 'auto' : 'none')};
  border-left: 1px solid ${(props) => props.theme.arter.color.veryLightGray};

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: ${(props) =>
      props.$isResizing ? props.theme.arter.color.product.dark : 'transparent'};
    transition: ${(props) => props.theme.arter.animation.speed.slow};
  }

  &:hover::after {
    background-color: ${(props) =>
      props.$openMenu ? props.theme.arter.color.product.dark : 'transparent'};
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: -20px;
    width: 20px;
    height: 100%;
    background-color: transparent;
    cursor: ew-resize;
    transition: ${(props) => props.theme.arter.animation.speed.slow};
  }
`;

export default LeftMenu;
