import { css } from 'styled-components';

export default css`
  .MuiCalendarPicker-root {
    .Mui-selected,
    .Mui-selected:hover,
    .Mui-selected:focus {
      background-color: ${(props) => props.theme.arter.color.product.default};
    }
  }
`;
