import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../../FormContext';
import SettingDescriptionInputField from '../SettingDescriptionInputField';
import SettingNameInputField from '../SettingNameInputField';
import DeleteLikelihoodModal from './DeleteLikelihoodModal';
import SettingRow from '../SettingRow';
import SettingValueInputField from '../SettingValueInputField';

function LikelihoodRow({ id, ...props }) {
  const { state } = useForm();

  return (
    <SettingRow
      id={id}
      data-testid={`likelihood-scale-row-${id}`}
      inputFields={[
        SettingNameInputField,
        SettingDescriptionInputField,
        SettingValueInputField,
      ]}
      DeleteModalComponent={DeleteLikelihoodModal}
      disableSave={
        state.name.value.trim().length === 0 || state.value.value.length === 0
      }
      {...props}
    />
  );
}

LikelihoodRow.propTypes = {
  id: PropTypes.string,
};

export default LikelihoodRow;
