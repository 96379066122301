import 'regenerator-runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import AppProviders from './components/AppProviders';
import App from './components/App';
import loadDevTools from './dev-tools/load';

if (process.env.NODE_ENV === 'development') {
  const worker = require('./server/dev-server');
  worker.default.start();
}

loadDevTools(() => {
  createRoot(document.getElementById('root')).render(
    <AppProviders>
      <App />
    </AppProviders>,
  );
});
