import styled from 'styled-components';
import { mdiLoading } from '@mdi/js';
import { Icon } from '@mdi/react';
import { spin } from '../../styles/keyframe-styles';

const Spinner = styled(Icon)`
  color: ${(props) => props.theme.arter.color.product.default};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  min-width: ${(props) => props.size};
  min-height: ${(props) => props.size};
  animation: ${spin} 1s linear infinite;
`;

Spinner.defaultProps = {
  path: mdiLoading,
  'aria-label': 'loading',
};

export default Spinner;
