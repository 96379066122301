import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import theme from '../../../styles/theme';
import RiskImpacts from './RiskImpacts';
import RiskLikelihoods from './RiskLikelihoods';
import SectionTitle from '../../common/SectionTitle';
import Spinner from '../../common/Spinner';

function RiskMagnitudes({ likelihoods }) {
  return (
    <Wrapper>
      <SectionTitle>
        <FormattedMessage id="MAGNITUDES" />
        <InfoText>
          (<FormattedMessage id="MAGNITUDE_FORMULA_INFO" />)
        </InfoText>
      </SectionTitle>
      {likelihoods ? (
        <Content>
          <RiskLikelihoods likelihoods={likelihoods} />
          <RiskImpacts likelihoods={likelihoods} />
        </Content>
      ) : (
        <Spinner size={theme.arter.icon.size.medium} />
      )}
    </Wrapper>
  );
}

RiskMagnitudes.propTypes = {
  likelihoods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
};

const Wrapper = styled.section`
  margin-bottom: 66px;
`;

const Content = styled.div`
  @media ${(props) => props.theme.arter.device.desktopLarge} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const InfoText = styled.span`
  font-size: ${(props) => props.theme.arter.font.size.medium};
  font-weight: ${(props) => props.theme.arter.font.weight.regular};
  margin-left: ${(props) => props.theme.arter.spacing.small};
`;

export default RiskMagnitudes;
