import { isValid } from 'date-fns';
import messages from '../../../../lang/messages';
import RISK_STATE from '../../../../constants/risk-state';
import RISK_MANAGEMENT_STRATEGY from '../../../../constants/risk-management-strategy';
import RISK_PRIORITY from '../../../../constants/risk-priority';
import IMPACT_TYPE from '../../../../constants/impact-type';

function translationToRiskState(translation) {
  switch (translation?.toLocaleLowerCase()) {
    case messages.fi.UNCONTROLLED.toLocaleLowerCase():
    case messages.en.UNCONTROLLED.toLocaleLowerCase():
      return RISK_STATE.UNCONTROLLED;
    case messages.fi.PARTIALLY_CONTROLLED.toLocaleLowerCase():
    case messages.en.PARTIALLY_CONTROLLED.toLocaleLowerCase():
      return RISK_STATE.PARTIALLY_CONTROLLED;
    case messages.fi.CONTROLLED.toLocaleLowerCase():
    case messages.en.CONTROLLED.toLocaleLowerCase():
      return RISK_STATE.CONTROLLED;
    case messages.fi.NOT_SET.toLocaleLowerCase():
    case messages.en.NOT_SET.toLocaleLowerCase():
    case '':
    case undefined:
      return RISK_STATE.NOT_SET;
    default:
      return undefined;
  }
}

function translationToRiskManagementStrategy(translation) {
  switch (translation?.toLocaleLowerCase()) {
    case messages.fi.ACCEPT.toLocaleLowerCase():
    case messages.en.ACCEPT.toLocaleLowerCase():
      return RISK_MANAGEMENT_STRATEGY.ACCEPT;
    case messages.fi.CREATE.toLocaleLowerCase():
    case messages.en.CREATE.toLocaleLowerCase():
      return RISK_MANAGEMENT_STRATEGY.CREATE;
    case messages.en.INCREASE.toLocaleLowerCase():
    case messages.fi.INCREASE.toLocaleLowerCase():
      return RISK_MANAGEMENT_STRATEGY.INCREASE;
    case messages.fi.MITIGATE.toLocaleLowerCase():
    case messages.en.MITIGATE.toLocaleLowerCase():
      return RISK_MANAGEMENT_STRATEGY.MITIGATE;
    case messages.fi.REMOVE.toLocaleLowerCase():
    case messages.en.REMOVE.toLocaleLowerCase():
      return RISK_MANAGEMENT_STRATEGY.REMOVE;
    case messages.fi.SHARE.toLocaleLowerCase():
    case messages.en.SHARE.toLocaleLowerCase():
      return RISK_MANAGEMENT_STRATEGY.SHARE;
    case messages.fi.TRANSFER.toLocaleLowerCase():
    case messages.en.TRANSFER.toLocaleLowerCase():
      return RISK_MANAGEMENT_STRATEGY.TRANSFER;
    case messages.fi.NOT_SET.toLocaleLowerCase():
    case messages.en.NOT_SET.toLocaleLowerCase():
    case '':
    case undefined:
      return RISK_MANAGEMENT_STRATEGY.NOT_SET;
    default:
      return undefined;
  }
}

function translationToRiskPriority(translation) {
  switch (translation?.toLocaleLowerCase()) {
    case messages.fi.VERY_LOW.toLocaleLowerCase():
    case messages.en.VERY_LOW.toLocaleLowerCase():
      return RISK_PRIORITY.VERY_LOW;
    case messages.fi.LOW.toLocaleLowerCase():
    case messages.en.LOW.toLocaleLowerCase():
      return RISK_PRIORITY.LOW;
    case messages.fi.MEDIUM.toLocaleLowerCase():
    case messages.en.MEDIUM.toLocaleLowerCase():
      return RISK_PRIORITY.MEDIUM;
    case messages.fi.HIGH.toLocaleLowerCase():
    case messages.en.HIGH.toLocaleLowerCase():
      return RISK_PRIORITY.HIGH;
    case messages.fi.VERY_HIGH.toLocaleLowerCase():
    case messages.en.VERY_HIGH.toLocaleLowerCase():
      return RISK_PRIORITY.VERY_HIGH;
    case messages.fi.NOT_SET.toLocaleLowerCase():
    case messages.en.NOT_SET.toLocaleLowerCase():
    case '':
    case undefined:
      return RISK_PRIORITY.NOT_SET;
    default:
      return undefined;
  }
}

function translationToImpactType(translation) {
  switch (translation?.toLocaleLowerCase()) {
    case messages.fi.OPPORTUNITY.toLocaleLowerCase():
    case messages.en.OPPORTUNITY.toLocaleLowerCase():
      return IMPACT_TYPE.OPPORTUNITY;
    case messages.fi.THREAT.toLocaleLowerCase():
    case messages.en.THREAT.toLocaleLowerCase():
      return IMPACT_TYPE.THREAT;
    case messages.fi.NOT_SET.toLocaleLowerCase():
    case messages.en.NOT_SET.toLocaleLowerCase():
      return IMPACT_TYPE.NOT_SET;
    default:
      return undefined;
  }
}

function isValidRisk(risk) {
  return risk.name && risk.name.length < 256;
}

class SheetJSONToRisksConverter {
  constructor(likelihoods = [], impactTargets = [], impactValues = []) {
    this.likelihoods = likelihoods;
    this.impactTargets = impactTargets;
    this.impactValues = impactValues;
  }

  convert(data) {
    const impactTargetsInUse = this.getImpactTargetsInUse(data[0]);

    return data.slice(1).reduce(
      (result, row) => {
        let { invalidCount, issueCount } = result;
        const issues = { impacts: [] };

        let state = translationToRiskState(row[2]);
        if (!state) {
          issues.state = true;
          issueCount += 1;
          state = RISK_STATE.NOT_SET;
        }

        let managementStrategy = translationToRiskManagementStrategy(row[3]);
        if (!managementStrategy) {
          issues.managementStrategy = true;
          issueCount += 1;
          managementStrategy = RISK_MANAGEMENT_STRATEGY.NOT_SET;
        }

        let priority = translationToRiskPriority(row[4]);
        if (!priority) {
          issues.priority = true;
          issueCount += 1;
          priority = RISK_PRIORITY.NOT_SET;
        }

        let revisionDate = row[5];
        if (revisionDate && !isValid(new Date(revisionDate))) {
          issues.revisionDate = true;
          issueCount += 1;
          revisionDate = undefined;
        }

        let likelihood = row[6];
        if (likelihood) {
          likelihood = this.likelihoods.find(
            ({ value }) => value === parseFloat(row[6]),
          );
          if (!likelihood) {
            issues.currentLikelihoodId = true;
            issueCount += 1;
          }
        }

        const impacts = impactTargetsInUse
          .map((impactTarget, index) => {
            issues.impacts[index] = {};
            let type = row[8 + index * 2];
            if (type) {
              type = translationToImpactType(row[8 + index * 2]);
              if (!type) {
                issues.impacts[index].type = true;
                issueCount += 1;
              }
            }

            let currentImpactValue = row[7 + index * 2];
            if (currentImpactValue) {
              currentImpactValue = this.impactValues.find(
                (value) => value.value === parseFloat(currentImpactValue, 2),
              );
              if (!currentImpactValue) {
                issues.impacts[index].currentImpactValueId = true;
                issueCount += 1;
              }
            }

            return type || currentImpactValue
              ? {
                  impactTargetId: impactTarget.id,
                  type: type || IMPACT_TYPE.NOT_SET,
                  currentImpactValueId: currentImpactValue?.id,
                }
              : undefined;
          })
          .filter((impact) => impact);

        const risk = {
          name: row[0],
          description: row[1],
          state,
          managementStrategy,
          priority,
          revisionDate: revisionDate || undefined,
          currentLikelihoodId: likelihood?.id,
          impacts,
        };

        if (isValidRisk(risk)) {
          result.risks.push({ risk, issues });
        } else {
          invalidCount += 1;
        }

        return {
          risks: result.risks,
          invalidCount,
          issueCount,
        };
      },
      { risks: [], invalidCount: 0, issueCount: 0 },
    );
  }

  getImpactTargetsInUse(headerRow) {
    return headerRow.slice(7).flatMap((column, index) => {
      let impactTarget;
      if (index % 2 === 0) {
        impactTarget = this.impactTargets.find(({ name }) => name === column);
        if (!impactTarget) {
          throw new Error('Impact target not found');
        }
      }
      return impactTarget || [];
    });
  }
}

export default SheetJSONToRisksConverter;
