import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useIntl } from 'react-intl';
import useDeviceMediaQuery from '../../../../hooks/useDeviceMediaQuery';
import FOLDER_COLUMN from '../../../../constants/folder-column';
import SORT_DIRECTION from '../../../../constants/sort-direction';
import { useFolderFilter } from '../filter/FolderFilterContext';
import { BaseTableHeadCell } from '../../../common/tables/BaseTable';

function FolderContentTableHeader({ onSortChange, sortBy, sortDirection }) {
  const intl = useIntl();
  const { anyFiltersApplied } = useFolderFilter();
  const { isDesktopMedium } = useDeviceMediaQuery();

  return (
    <TableHead>
      <TableRow>
        <NameHeaderCell
          id={FOLDER_COLUMN.NAME}
          label={intl.formatMessage({ id: FOLDER_COLUMN.NAME })}
          sortHandler={() =>
            onSortChange(FOLDER_COLUMN.NAME, SORT_DIRECTION.ASC)
          }
          sortDirection={sortDirection}
          sortBy={sortBy}
        />
        {anyFiltersApplied && isDesktopMedium ? (
          <FolderContentTableHeaderCell
            id={FOLDER_COLUMN.LOCATION}
            label={intl.formatMessage({ id: FOLDER_COLUMN.LOCATION })}
          />
        ) : null}
        <StateHeaderCell
          id={FOLDER_COLUMN.STATE}
          label={intl.formatMessage({ id: FOLDER_COLUMN.STATE })}
          sortHandler={() =>
            onSortChange(FOLDER_COLUMN.STATE, SORT_DIRECTION.ASC)
          }
          sortDirection={sortDirection}
          sortBy={sortBy}
        />
        <DateHeaderCell
          id={FOLDER_COLUMN.EDITED}
          label={intl.formatMessage({ id: FOLDER_COLUMN.EDITED })}
          sortHandler={() =>
            onSortChange(FOLDER_COLUMN.EDITED, SORT_DIRECTION.DESC)
          }
          sortDirection={sortDirection}
          sortBy={sortBy}
        />
        {isDesktopMedium ? (
          <FolderContentTableHeaderCell
            id={FOLDER_COLUMN.RESPONSIBLE}
            label={intl.formatMessage({ id: FOLDER_COLUMN.RESPONSIBLE })}
          />
        ) : null}
        <DateHeaderCell
          id={FOLDER_COLUMN.REVISION_DATE}
          label={intl.formatMessage({ id: FOLDER_COLUMN.REVISION_DATE })}
          sortHandler={() =>
            onSortChange(FOLDER_COLUMN.REVISION_DATE, SORT_DIRECTION.DESC)
          }
          sortDirection={sortDirection}
          sortBy={sortBy}
        />
        <LikelihoodHeaderCell
          id={FOLDER_COLUMN.LIKELIHOOD}
          label={intl.formatMessage({ id: FOLDER_COLUMN.LIKELIHOOD })}
          sortHandler={() =>
            onSortChange(FOLDER_COLUMN.LIKELIHOOD, SORT_DIRECTION.DESC)
          }
          sortDirection={sortDirection}
          sortBy={sortBy}
        />
        <FolderContentTableHeaderCell
          id={FOLDER_COLUMN.MAGNITUDES}
          label={intl.formatMessage({ id: FOLDER_COLUMN.MAGNITUDES })}
        />
      </TableRow>
    </TableHead>
  );
}

FolderContentTableHeader.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf([SORT_DIRECTION.ASC, SORT_DIRECTION.DESC])
    .isRequired,
};

function FolderContentTableHeaderCell({
  id,
  label,
  sortHandler,
  sortDirection,
  sortBy,
  ...props
}) {
  return (
    <BaseTableHeadCell
      sortDirection={sortBy === id ? sortDirection : SORT_DIRECTION.ASC}
      {...props}
    >
      <TableSortLabel
        active={sortBy === id}
        disabled={!sortBy}
        direction={sortBy === id ? sortDirection : SORT_DIRECTION.ASC}
        onClick={sortBy ? () => sortHandler() : null}
      >
        {label}
      </TableSortLabel>
    </BaseTableHeadCell>
  );
}

FolderContentTableHeaderCell.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sortHandler: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf([SORT_DIRECTION.ASC, SORT_DIRECTION.DESC]),
  active: PropTypes.bool,
};

const NameHeaderCell = styled(FolderContentTableHeaderCell)`
  width: 24%;
`;

const LikelihoodHeaderCell = styled(FolderContentTableHeaderCell)`
  width: 10%;
`;

const DateHeaderCell = styled(FolderContentTableHeaderCell)`
  width: 10%;
`;

const StateHeaderCell = styled(FolderContentTableHeaderCell)`
  width: 15%;
`;

export default FolderContentTableHeader;
