import { css } from 'styled-components';

export default css`
  .MuiInputBase-root,
  &.MuiInputBase-root {
    background-color: ${(props) => props.theme.arter.color.extraLightGray};
    transition: ${(props) => props.theme.arter.animation.speed.normal};
    min-height: 46px;
    .MuiInputBase-input {
      font-size: ${(props) => props.theme.arter.font.size.medium};
      color: ${(props) => props.theme.arter.color.black};
      padding: ${(props) => props.theme.arter.spacing.medium};

      &::placeholder {
        color: ${(props) => props.theme.arter.color.black};
        opacity: 0.8;
      }
    }

    fieldset {
      border: transparent;
    }

    &.Mui-focused {
      &:hover {
        background-color: ${(props) =>
          props.theme.arter.color.extraLightGray} !important;
      }

      fieldset {
        border: 1px solid ${(props) => props.theme.arter.color.product.default} !important;
        box-shadow: inset 0 0 0 1px
          ${(props) => props.theme.arter.color.product.default};
      }
    }

    &:hover {
      background-color: ${(props) =>
        props.theme.arter.color.veryLightGray} !important;
    }
  }

  &.Mui-disabled,
  .Mui-disabled {
    pointer-events: none !important;
    opacity: 1 !important;

    .MuiChip-label {
      -webkit-text-fill-color: ${(props) => props.theme.arter.color.black};
    }
  }
`;
