import styled from 'styled-components';
import IconButton from './buttons/IconButton';

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.mediumLarge};
`;

const MenuTitle = styled.h2`
  font-size: ${(props) => props.theme.arter.font.size.extraLarge};
`;

const MenuCloseButton = styled(IconButton)`
  margin-left: auto;
`;

export { MenuHeader, MenuTitle, MenuCloseButton };
