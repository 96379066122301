import React from 'react';
import { useIntl } from 'react-intl';
import { useSettings } from '../../../SettingsContext';
import SettingPage from '../SettingPage';
import LikelihoodHeaderRow from './LikelihoodHeaderRow';
import LikelihoodRow from './LikelihoodRow';

function likelihoodFormInitializer({ name, description, value }) {
  return {
    name: { initialValue: name, value: name, dirty: false },
    description: {
      initialValue: description,
      value: description,
      dirty: false,
    },
    value: {
      initialValue: value,
      value,
      dirty: false,
    },
  };
}

function Likelihoods() {
  const intl = useIntl();
  const { likelihoods, fetchLikelihoods, isFetchingLikelihoods } =
    useSettings();

  return (
    <SettingPage
      title={intl.formatMessage({ id: 'LIKELIHOOD_SCALE' })}
      HeaderRowComponent={LikelihoodHeaderRow}
      RowComponent={LikelihoodRow}
      formDataInitializer={likelihoodFormInitializer}
      createFormInitialData={{ name: '', description: '', value: '' }}
      rootEndpoint="/api/v1/likelihoods"
      settings={likelihoods}
      fetchSettings={fetchLikelihoods}
      isFetchingSettings={isFetchingLikelihoods}
    />
  );
}

export default Likelihoods;
