import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@mdi/react';
import { mdiArrowRight } from '@mdi/js';
import IconWithBackground from '../../common/IconWithBackground';

function SettingsCard({ icon, title, infoText, ...props }) {
  return (
    <Wrapper {...props}>
      <Header>
        <IconWithBackground iconPath={icon}></IconWithBackground>
        <Title>{title}</Title>
        <ArrowIcon path={mdiArrowRight} />
      </Header>
      <InfoText>{infoText}</InfoText>
    </Wrapper>
  );
}

SettingsCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  infoText: PropTypes.string,
};

const ArrowIcon = styled(Icon)`
  opacity: 0;
  margin-left: auto;
  transform: translateX(-8px);
  width: ${(props) => props.theme.arter.icon.size.small};
  color: ${(props) => props.theme.arter.color.product.default};
  transition: ${(props) => props.theme.arter.animation.speed.normal};
`;

const Wrapper = styled(RouterLink)`
  display: block;
  width: 100%;
  min-height: 120px;
  padding: ${(props) => props.theme.arter.spacing.extraLarge};
  margin: ${(props) => props.theme.arter.spacing.mediumLarge};
  box-shadow: 0 3px 20px -17px ${(props) => props.theme.arter.color.black};
  border: 1px solid ${(props) => props.theme.arter.color.veryLightGray};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  cursor: pointer;

  &:hover {
    ${ArrowIcon} {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    width: 45%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};
`;

const Title = styled.h4`
  font-size: ${(props) => props.theme.arter.font.size.medium};
  margin: 0;
`;

const InfoText = styled.p`
  margin: ${(props) => props.theme.arter.spacing.medium} 0;
`;

export default SettingsCard;
