export default {
  NAME: 'NAME',
  LOCATION: 'LOCATION',
  STATE: 'STATE',
  EDITED: 'EDITED',
  RESPONSIBLE: 'RESPONSIBLE',
  REVISION_DATE: 'REVISION_DATE',
  LIKELIHOOD: 'LIKELIHOOD',
  MAGNITUDES: 'MAGNITUDES',
};
