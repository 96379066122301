import styled from 'styled-components';
import { EditTable, TableHeader } from '../../common/tables/EditTable';

const SettingsTable = styled(EditTable)`
  min-width: 400px;
  margin: 0;
`;

const ValueTableHeader = styled(TableHeader)`
  width: 100px;
`;

export { SettingsTable, ValueTableHeader };
