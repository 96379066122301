import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function HelpNotification({ srcImage, title, infoText, small, ...props }) {
  return (
    <Wrapper {...props} $small={small}>
      <Image $small={small} alt="Notification image" src={srcImage} />
      <Title $small={small}>{title}</Title>
      <InfoText>{infoText}</InfoText>
    </Wrapper>
  );
}

HelpNotification.propTypes = {
  title: PropTypes.string,
  infoText: PropTypes.string,
  srcImage: PropTypes.string,
  small: PropTypes.bool,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: ${(props) => (props.$small ? '48px' : '150px')};
  max-width: 350px;
  width: 100%;
  padding: ${(props) => props.theme.arter.spacing.large};
`;

const Image = styled.img`
  max-width: ${(props) => (props.$small ? '92px' : '120px')};
  width: 100%;
`;

const Title = styled.h2`
  font-size: ${(props) =>
    props.$small
      ? props.theme.arter.font.size.medium
      : props.theme.arter.font.size.large};
  margin: ${(props) => props.theme.arter.spacing.large} 0;
  margin-top: ${(props) =>
    props.$small ? props.theme.arter.spacing.large : '28px'};
`;

const InfoText = styled.p`
  text-align: center;
  font-size: ${(props) => props.theme.arter.font.size.medium};
  margin: 0;
  line-height: 1.5;
`;

export default HelpNotification;
