import React from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useKeycloak } from '../components/KeycloakContext';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  FORBIDDEN: 'forbidden',
};

function useApi({ endpoint, method }) {
  const { keycloak } = useKeycloak();
  const controller = React.useMemo(() => new AbortController(), []);
  const [response, setResponse] = React.useState(null);
  const [status, setStatus] = React.useState(STATUS.IDLE);
  const [error, setError] = React.useState(null);

  const resetStatus = React.useCallback(() => {
    setStatus(STATUS.IDLE);
  }, []);

  const cancelRequest = React.useCallback(() => {
    controller.abort();
  }, [controller]);

  const callEndpoint = React.useCallback(
    async (data) => {
      try {
        setStatus(STATUS.LOADING);
        setResponse(null);
        await keycloak.updateToken(10);
        const result = await axios({
          method,
          url: endpoint,
          signal: controller.signal,
          ...(data && { data }),
          headers: {
            ...(keycloak.token && {
              Authorization: `Bearer ${keycloak.token}`,
            }),
          },
        });
        setResponse(result.data);
        setStatus(STATUS.SUCCESS);
      } catch (err) {
        setError(err);
        if (err.response && err.response.status === 403) {
          setStatus(STATUS.FORBIDDEN);
        } else {
          setStatus(STATUS.ERROR);
        }
      }
    },
    [endpoint, method, controller, keycloak],
  );

  React.useEffect(() => () => cancelRequest(), [cancelRequest]);

  return {
    callEndpoint,
    response,
    error,
    status,
    resetStatus,
    isLoading: status === STATUS.LOADING,
    isError: status === STATUS.ERROR,
    isSuccess: status === STATUS.SUCCESS,
    isForbidden: status === STATUS.FORBIDDEN,
  };
}

export default useApi;
