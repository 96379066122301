import PropTypes from 'prop-types';
import React from 'react';
import { PageForbiddenAccess } from './ForbiddenAccess';
import { useKeycloak } from './KeycloakContext';

function SecureByRole({ role, children }) {
  const { hasRole } = useKeycloak();

  return hasRole(role) ? children : <PageForbiddenAccess />;
}

SecureByRole.propTypes = {
  role: PropTypes.string,
  children: PropTypes.node,
};

export default SecureByRole;
