import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { mdiClose, mdiFolderOutline, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import muiInputOverride from '../../../../styles/mui-input-override';
import IconButton from '../../../common/buttons/IconButton';
import { useFolderFilter } from './FolderFilterContext';
import { useFolder } from '../FolderContext';

function NameSearch({ risks, folders }) {
  const { folder } = useFolder();
  const { filter, setFilter } = useFolderFilter();
  const searchFieldRef = React.useRef(null);
  const options = React.useMemo(
    () => [
      ...folders.map((subFolder) => ({
        id: subFolder.id,
        label: subFolder.name,
        isFolder: true,
      })),
      ...risks.map((risk) => ({
        id: risk.id,
        label: risk.name,
      })),
    ],
    [folders, risks],
  );
  const [value, setValue] = React.useState(filter.text);
  const [isOpen, setIsOpen] = React.useState(false);
  const intl = useIntl();

  function handleInputChange(input, reason) {
    setValue(input);
    if (reason !== 'reset') {
      setIsOpen(true);
    }
  }

  function handleChange() {
    setFilter({ ...filter, text: value });
    setIsOpen(false);
    searchFieldRef.current.blur();
  }

  React.useEffect(() => {
    setValue(filter.text);
  }, [filter.text]);

  return (
    <AutocompleteWrapper
      freeSolo
      value={value}
      options={options}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
      onInputChange={(event, input, reason) => handleInputChange(input, reason)}
      renderOption={(props, option) => (
        <LinkItem
          {...props}
          key={option.id}
          to={`/${option.isFolder ? 'folders' : 'risks'}/${option.id}`}
        >
          {option.isFolder ? <FolderIcon path={mdiFolderOutline} /> : null}
          <LintText>{option.label}</LintText>
        </LinkItem>
      )}
      renderInput={(params) => (
        <Input
          {...params}
          inputRef={searchFieldRef}
          placeholder={`${intl.formatMessage({
            id: 'SEARCH_FROM_FOLDER',
          })} ${folder.name || intl.formatMessage({ id: 'RISKS' })}`}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleChange();
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <ClearIconButton
                  small
                  tooltip={intl.formatMessage({
                    id: 'CLEAR',
                  })}
                  iconPath={mdiClose}
                  onClick={() => setValue('')}
                />
                <SearchIconButton
                  tooltip={intl.formatMessage({ id: 'SEARCH' })}
                  iconPath={mdiMagnify}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleChange();
                  }}
                ></SearchIconButton>
              </>
            ),
          }}
        />
      )}
    />
  );
}

NameSearch.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  risks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

const AutocompleteWrapper = styled(Autocomplete)`
  width: 100%;
  min-width: 120px;
  flex: 1;
  margin-right: ${(props) => props.theme.arter.spacing.small};
  margin-bottom: ${(props) => props.theme.arter.spacing.small};

  @media ${(props) => props.theme.arter.device.desktopMedium} {
    max-width: 300px;
  }
`;

const ClearIconButton = styled(IconButton)`
  opacity: 0;
`;

const SearchIconButton = styled(IconButton)`
  background-color: transparent;
  margin-top: -1px;
  margin-right: -1px;
  margin: ${(props) => `-1px 0  0 ${props.theme.arter.spacing.medium}`};
  border-radius: ${(props) => props.theme.arter.border.radius.small};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.product.default};
  }
`;

const Input = styled(TextField)`
  ${muiInputOverride};
  .MuiInputBase-root,
  &.MuiInputBase-root {
    height: 38px;
    min-height: auto;
    background-color: ${(props) => props.theme.arter.color.white};
    border-color: transparent;
    padding: 0;
    padding-right: 0 !important;
    padding-left: ${(props) => props.theme.arter.spacing.medium};

    fieldset {
      border: 1px solid ${(props) => props.theme.arter.color.darkGray} !important;
    }

    ${SearchIconButton} {
      &:hover {
        background-color: transparent;
      }
    }

    &.Mui-focused,
    &:hover {
      ${ClearIconButton} {
        opacity: 1;
      }

      ${SearchIconButton} {
        background-color: ${(props) => props.theme.arter.color.product.default};

        svg {
          color: ${(props) => props.theme.arter.color.white};
        }
      }
    }

    &.Mui-focused {
      &:hover {
        background-color: ${(props) =>
          props.theme.arter.color.white} !important;
      }

      fieldset {
        border: 1px solid ${(props) => props.theme.arter.color.product.default} !important;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.arter.color.white} !important;

      fieldset {
        border-color: ${(props) => props.theme.arter.color.black};
      }
    }
  }
`;

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.arter.color.product.default};

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.arter.color.product.default};
  }
`;

const FolderIcon = styled(Icon)`
  width: ${(props) => props.theme.arter.icon.size.medium};
  height: ${(props) => props.theme.arter.icon.size.medium};
  margin-right: ${(props) => props.theme.arter.spacing.medium};
  color: ${(props) => props.theme.arter.color.black};
`;

const LintText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default NameSearch;
