import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { mdiTune } from '@mdi/js';
import { useFolder } from '../FolderContext';
import SecondaryButton from '../../../common/buttons/SecondaryButton';
import ButtonIcon from '../../../common/buttons/ButtonIcon';
import useDeviceMediaQuery from '../../../../hooks/useDeviceMediaQuery';
import CountItem from './CountItem';
import { useFolderFilter } from './FolderFilterContext';

function AllFiltersButton() {
  const { setIsUpdatingAllFilters } = useFolder();
  const { filter, anyFiltersApplied } = useFolderFilter();
  const { isDesktopMedium } = useDeviceMediaQuery();
  const filterOptionCount =
    (filter.text ? 1 : 0) +
    filter.states.length +
    filter.responsible.length +
    (filter.includeSubfolders ? 0 : 1) +
    filter.tags.length +
    filter.priorities.length +
    filter.managementStrategies.length +
    filter.likelihoods.length +
    filter.impactTargets.length +
    filter.impactValues.length;

  return (
    <Wrapper
      $active={anyFiltersApplied}
      onClick={() => setIsUpdatingAllFilters(true)}
      data-testid="all-filters-button"
    >
      {isDesktopMedium ? (
        <ButtonText $active={anyFiltersApplied}>
          {anyFiltersApplied ? <CountItem count={filterOptionCount} /> : null}
          <FormattedMessage id="ALL_FILTERS" />
        </ButtonText>
      ) : null}
      <ButtonIcon path={mdiTune} />
    </Wrapper>
  );
}

const Wrapper = styled(SecondaryButton)`
  padding: 0 ${(props) => props.theme.arter.spacing.medium};
  min-width: auto;
  border-color: ${(props) =>
    props.$active
      ? props.theme.arter.color.black
      : props.theme.arter.color.darkGray} !important;
  box-shadow: 0 0 0 ${(props) => (props.$active ? '1px' : '0')} #4d4d4d;
  box-shadow: inset 0 0 0 ${(props) => (props.$active ? '2px' : '0')} #4d4d4d;

  ${ButtonIcon} {
    margin-right: 0;
  }

  @media ${(props) => props.theme.arter.device.desktopMedium} {
    padding: 0 20px 0 28px;
    min-width: 80px;
    margin-right: ${(props) =>
      props.theme.arter.spacing.mediumLarge} !important;

    ${ButtonIcon} {
      margin-left: ${(props) => props.theme.arter.spacing.mediumLarge};
    }
  }
`;

const ButtonText = styled.span`
  display: inline-flex;
  align-items: center;
  transition: ${(props) => props.theme.arter.animation.speed.normal};
  transform: ${(props) =>
    props.$active ? 'translateX(6px)' : 'translateX(0)'} !important;
`;

export default AllFiltersButton;
