import React from 'react';
import { useIntl } from 'react-intl';
import { useSettings } from '../../../SettingsContext';
import SettingPage from '../SettingPage';
import ImpactValueHeaderRow from './ImpactValueHeaderRow';
import ImpactValueRow from './ImpactValueRow';

function impactvalueFormInitializer({ name, description, value }) {
  return {
    name: { initialValue: name, value: name, dirty: false },
    description: {
      initialValue: description,
      value: description,
      dirty: false,
    },
    value: {
      initialValue: value,
      value,
      dirty: false,
    },
  };
}

function ImpactValues() {
  const intl = useIntl();
  const { impactValues, fetchImpactValues, isFetchingImpactValues } =
    useSettings();

  return (
    <SettingPage
      title={intl.formatMessage({ id: 'IMPACT_SCALE' })}
      HeaderRowComponent={ImpactValueHeaderRow}
      RowComponent={ImpactValueRow}
      formDataInitializer={impactvalueFormInitializer}
      createFormInitialData={{ name: '', description: '', value: '' }}
      rootEndpoint="/api/v1/impact-values"
      settings={impactValues}
      fetchSettings={fetchImpactValues}
      isFetchingSettings={isFetchingImpactValues}
    />
  );
}

export default ImpactValues;
