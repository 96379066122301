import styled from 'styled-components';
import baseInputStyles from '../../styles/base-input-styles';

const TextArea = styled.textarea`
  ${baseInputStyles};
  padding: ${(props) => props.theme.arter.spacing.medium};
  height: 120px;
  cursor: text;
  resize: none;
  line-height: 1.5;
`;

export default TextArea;
