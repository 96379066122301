import React from 'react';

function useWidthResize({ onResize }) {
  const [isResizing, setIsResizing] = React.useState(false);
  const handler = React.useCallback(
    (startResizeEvent) => {
      setIsResizing(true);

      function resize(mouseMoveEvent) {
        mouseMoveEvent.preventDefault();
        onResize(mouseMoveEvent.clientX - startResizeEvent.clientX);
      }

      function endResize() {
        setIsResizing(false);
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', endResize);
      }

      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', endResize);
    },
    [onResize],
  );

  return { handler, isResizing };
}

export default useWidthResize;
