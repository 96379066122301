import styled from 'styled-components';

const Blockquote = styled.blockquote`
  font-size: ${(props) => props.theme.arter.font.size.medium};
  padding: ${(props) => props.theme.arter.spacing.large};
  margin: ${(props) => props.theme.arter.spacing.large} 0;
  border-left: 3px solid ${(props) => props.theme.arter.color.product.default};
  background-color: ${(props) => props.theme.arter.color.extraLightGray};
  border-top-right-radius: ${(props) => props.theme.arter.border.radius.small};
  border-bottom-right-radius: ${(props) =>
    props.theme.arter.border.radius.small};

  h4,
  li {
    font-size: ${(props) => props.theme.arter.font.size.medium};
  }

  h4 {
    margin-bottom: ${(props) => props.theme.arter.spacing.medium};
  }
`;

export default Blockquote;
