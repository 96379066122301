import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FilterChipSelect from './FilterChipSelect';
import { useSettings } from '../../../../SettingsContext';

function LikelihoodFilter({ selectedLikelihoods, onChange, onClear }) {
  const intl = useIntl();
  const { likelihoods } = useSettings();

  return (
    <FilterChipSelect
      data-testid="all-filters-likelihood-filter"
      label={intl.formatMessage({ id: 'LIKELIHOOD' })}
      htmlFor="likelihood-select"
      options={likelihoods}
      selectedOptions={selectedLikelihoods}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

LikelihoodFilter.propTypes = {
  selectedLikelihoods: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default LikelihoodFilter;
