import React from 'react';
import { useIntl } from 'react-intl';
import { mdiTagOutline } from '@mdi/js';
import { Section, Header, Content } from './RightMenuSection';
import theme from '../../../../styles/theme';
import Spinner from '../../../common/Spinner';
import { useForm } from '../../../FormContext';
import { useSettings } from '../../../SettingsContext';
import ChipSelect from '../../../common/multi-select/ChipSelect';
import { useRights } from '../../../RightsContext';

function RiskTags() {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();
  const { tags } = useSettings();
  const { hasModifierRight } = useRights();

  function handleChange(value) {
    dispatchFormChange({
      field: 'tags',
      value,
    });
  }

  return (
    <Section>
      <Header
        label={intl.formatMessage({ id: 'TAGS' })}
        htmlFor="tags-select"
        iconPath={mdiTagOutline}
      />
      {tags ? (
        <Content>
          <ChipSelect
            id="tags-select"
            data-testid="tag-select"
            options={tags}
            selectedOptions={state.tags.value.map((id) =>
              tags.find((tag) => tag.id === id),
            )}
            isDisabled={!hasModifierRight()}
            onChange={(value) => handleChange(value.map(({ id }) => id))}
            placeholderText={
              hasModifierRight() ? intl.formatMessage({ id: 'ADD_TAGS' }) : null
            }
          />
        </Content>
      ) : (
        <Spinner size={theme.arter.icon.size.small} />
      )}
    </Section>
  );
}

export default RiskTags;
