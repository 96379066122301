import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function NavItem({ href, linktext }) {
  return <Link href={href}>{linktext}</Link>;
}

const Link = styled.a`
  display: block;
  font-size: ${(props) => props.theme.arter.font.size.medium};
  color: ${(props) => props.theme.arter.color.black};
  background-color: transparent;
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  padding: ${(props) => props.theme.arter.spacing.medium};
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.product.light};
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: ${(props) => props.theme.arter.font.size.small};
    padding-left: ${(props) => props.theme.arter.spacing.mediumLarge};
  }
`;

NavItem.propTypes = {
  href: PropTypes.string,
  linktext: PropTypes.string,
};
export default NavItem;
