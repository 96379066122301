import MUIChip from '@mui/material/Chip';
import styled from 'styled-components';

const Chip = styled(MUIChip)`
  background-color: ${(props) => props.theme.arter.color.white} !important;
  border: 1px solid ${(props) => props.theme.arter.color.darkGray};
  color: ${(props) => props.theme.arter.color.black};
  font-size: ${(props) => props.theme.arter.font.size.medium};
  max-width: 200px;
  height: 30px;

  .MuiChip-label {
    height: 24px;
  }
`;

export default Chip;
