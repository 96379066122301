import styled from 'styled-components';
import Select from '../Select';
import InputField from '../InputField';
import TextArea from '../TextArea';

const EditTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  padding: ${(props) => props.theme.arter.spacing.mediumLarge};
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

const TableHead = styled.thead``;

const TableHeader = styled.th`
  border-bottom: 1px solid ${(props) => props.theme.arter.color.gray};
  padding: ${(props) =>
    `0 ${props.theme.arter.spacing.medium} ${props.theme.arter.spacing.small} ${props.theme.arter.spacing.medium}`};
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  font-weight: ${(props) => props.theme.arter.font.weight.regular};
  opacity: ${(props) => (props.$deactive ? 0.5 : 1)};

  ${Select},
  ${InputField},
  ${TextArea} {
    border-color: ${(props) =>
      props.$active ? props.theme.arter.color.darkGray : 'transparent'};
  }
`;

const TableData = styled.td`
  vertical-align: top;
  padding: ${(props) => props.theme.arter.spacing.medium} 0;
  padding-right: ${(props) => props.theme.arter.spacing.medium};
  border-bottom: ${(props) => props.theme.arter.border.main};

  ${Select},
  ${InputField},
  ${TextArea} {
    border-color: transparent;

    &:focus,
    &:active,
    &:hover {
      border-color: ${(props) => props.theme.arter.color.black};
    }

    &:disabled {
      opacity: 1;
      pointer-events: auto;
      cursor: initial;

      &:focus,
      &:active,
      &:hover {
        border-color: transparent;
        background: none;
        box-shadow: none;
      }
    }
  }

  ${TextArea} {
    padding-top: ${(props) => props.theme.arter.spacing.medium};
    height: 100px;
  }

  ${Select} {
    background-image: linear-gradient(45deg, transparent 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, transparent 50%);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 240px;

    &:hover {
      background-image: linear-gradient(
          45deg,
          transparent 50%,
          ${(props) => props.theme.arter.color.black} 50%
        ),
        linear-gradient(
          135deg,
          ${(props) => props.theme.arter.color.black} 50%,
          transparent 50%
        );
    }
  }
`;

const RowTools = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  button {
    margin-left: ${(props) => props.theme.arter.spacing.small};
  }
`;

export {
  EditTable,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableData,
  RowTools,
};
