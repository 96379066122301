import styled from 'styled-components';

const SelectValue = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.arter.color.black};
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

export default SelectValue;
