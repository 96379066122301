import styled from 'styled-components';
import Icon from '@mdi/react';
import IMPACT_TYPE from '../../../constants/impact-type';

const Tooltip = styled.div`
  position: absolute;
  left: ${(props) => (props.$leftOfCenter ? 20 : 'auto')};
  right: ${(props) => (props.$leftOfCenter ? 'auto' : 20)};
  z-index: 2;
  display: inline-flex;
  flex-direction: column;
  min-width: 260px;
  max-width: 300px;
  background-color: ${(props) => props.theme.arter.color.white};
  padding: ${(props) => props.theme.arter.spacing.mediumLarge};
  color: ${(props) => props.theme.arter.color.black};
  border: 1px solid ${(props) => props.theme.arter.color.lightGray};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  box-shadow: 0 3px 20px -13px ${(props) => props.theme.arter.color.black};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h4`
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

const Separator = styled.span`
  margin: 0 ${(props) => props.theme.arter.spacing.small};

  &::before {
    content: '-';
  }
`;
const Content = styled.div``;

const ImpactsList = styled.ul`
  margin-top: ${(props) => props.theme.arter.spacing.medium};
  margin-bottom: ${(props) => props.theme.arter.spacing.mediumLarge};
  padding-bottom: ${(props) => props.theme.arter.spacing.mediumLarge};
  border-bottom: ${(props) => props.theme.arter.border.main};

  &:last-child {
    border: none;
    margin-bottom: 0;
  }
`;

const TooltipListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props.theme.arter.spacing.small};
`;

const MagnitudeTypeListItem = styled.p`
  font-size: ${(props) => props.theme.arter.font.size.small};
  color: ${(props) => props.color};
  margin-left: 14px;
  color: ${(props) =>
    props.type === IMPACT_TYPE.THREAT
      ? props.theme.arter.color.red
      : props.theme.arter.color.green};
`;

const TypeIcon = styled(Icon)`
  color: ${(props) =>
    props.type === IMPACT_TYPE.THREAT
      ? props.theme.arter.color.red
      : props.theme.arter.color.green};
  min-width: ${(props) => props.theme.arter.icon.size.small};
  height: ${(props) => props.theme.arter.icon.size.small};
  margin-right: ${(props) => props.theme.arter.spacing.small};
`;

const ImpactName = styled.p`
  font-size: ${(props) => props.theme.arter.font.size.small};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  Tooltip,
  Header,
  Title,
  Separator,
  Content,
  ImpactsList,
  TooltipListItem,
  TypeIcon,
  ImpactName,
  MagnitudeTypeListItem,
};
