import React from 'react';
import { useLocation } from 'react-router-dom';

function useEffectOnLocationChange(effect) {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = React.useState(null);

  React.useEffect(() => {
    if (prevLocation?.pathname !== location.pathname) {
      effect();
      setPrevLocation(location);
    }
  }, [effect, location, prevLocation?.pathname]);
}

export default useEffectOnLocationChange;
