import React from 'react';
import PropTypes from 'prop-types';

const HelpMenuContext = React.createContext();

function HelpMenuProvider({ children }) {
  const [showHelpMenu, setShowHelpMenu] = React.useState(false);

  const toggleHelpMenu = React.useCallback(() => {
    setShowHelpMenu(!showHelpMenu);
  }, [showHelpMenu]);

  return (
    <HelpMenuContext.Provider value={{ showHelpMenu, toggleHelpMenu }}>
      {children}
    </HelpMenuContext.Provider>
  );
}

HelpMenuProvider.propTypes = {
  children: PropTypes.node,
};

function useHelpMenu() {
  const context = React.useContext(HelpMenuContext);
  if (!context) {
    throw new Error(`useHelpMenu must be used within a HelpMenuProvider`);
  }
  return context;
}

export { HelpMenuProvider, useHelpMenu };
