import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { unstable_usePrompt } from 'react-router-dom';
import { formReducer, dirtyCheck, FORM_ACTION } from '../reducers/formReducer';
import useApi from '../hooks/useApi';

const FormContext = React.createContext();

function FormProvider({
  initialData,
  formDataInitializer,
  endpoint,
  method,
  onSuccess,
  onError,
  ...props
}) {
  const intl = useIntl();
  const [state, dispatch] = React.useReducer(
    formReducer,
    initialData,
    formDataInitializer,
  );
  const {
    response: submitResponse,
    isError,
    isSuccess,
    isLoading: submitting,
    callEndpoint: submit,
    resetStatus: resetSubmitStatus,
  } = useApi({
    endpoint,
    method,
  });

  const resetChanges = React.useCallback(() => {
    dispatch({
      type: FORM_ACTION.SET_INITIAL_STATE,
      data: submitResponse || initialData,
      form: formDataInitializer,
    });
  }, [initialData, submitResponse, formDataInitializer]);

  const dispatchFormChange = React.useCallback((dispatchData) => {
    dispatch({ type: FORM_ACTION.CHANGE, data: dispatchData });
  }, []);

  const isChanged = React.useCallback(
    () => Object.values(state).some((field) => field.dirty),
    [state],
  );

  const submitForm = React.useCallback(() => {
    if (method === 'PATCH') {
      submit(
        Object.fromEntries(
          Object.entries(state)
            .filter(([, field]) => field.dirty)
            .map(([key, field]) => [key, field.value]),
        ),
      );
    }
    if (method === 'PUT' || method === 'POST') {
      submit(
        Object.fromEntries(
          Object.entries(state).map(([key, field]) => [key, field.value]),
        ),
      );
    }
  }, [state, submit, method]);

  React.useEffect(() => {
    if (submitResponse) {
      resetChanges();
    }
  }, [submitResponse, resetChanges]);

  React.useEffect(() => {
    if (isSuccess && onSuccess) {
      onSuccess(submitResponse);
      resetSubmitStatus();
    }
  }, [isSuccess, onSuccess, resetSubmitStatus, submitResponse]);

  React.useEffect(() => {
    if (isError && onError) {
      onError();
      resetSubmitStatus();
    }
  }, [isError, onError, resetSubmitStatus]);

  unstable_usePrompt({
    when: isChanged(),
    message: intl.formatMessage({ id: 'UNSAVED_CHANGES_CONFIRMATION' }),
  });

  return (
    <FormContext.Provider
      value={{
        state,
        dispatchFormChange,
        dirtyCheck,
        submitForm,
        submitting,
        isChanged,
        isError,
        isSuccess,
        resetSubmitStatus,
        resetChanges,
      }}
      {...props}
    >
      {props.children}
    </FormContext.Provider>
  );
}

FormProvider.propTypes = {
  initialData: PropTypes.shape().isRequired,
  formDataInitializer: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node,
};

function useForm() {
  const context = React.useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
}

function ShowWhenFormChanged({ children }) {
  const { isChanged } = useForm();

  return isChanged() ? children : null;
}

ShowWhenFormChanged.propTypes = {
  children: PropTypes.node,
};

function HideWhenFormChanged({ children }) {
  const { isChanged } = useForm();

  return isChanged() ? null : children;
}

HideWhenFormChanged.propTypes = {
  children: PropTypes.node,
};

export { FormProvider, useForm, ShowWhenFormChanged, HideWhenFormChanged };
