import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import EmptyCellData from './EmptyCellData';
import { BaseTableCell } from '../../../common/tables/BaseTable';
import MagnitudeBadge from '../../../common/tables/MagnitudeBadge';

function MagnitudesCell({ impacts, likelihood }) {
  return (
    <Wrapper>
      {impacts && impacts.length && likelihood ? (
        impacts
          .filter(({ value }) => !!value)
          .sort(({ type }) => (type === 'THREAT' ? -1 : 1))
          .map(({ id, type, targetName, value }) => (
            <Tooltip key={id} placement="top" title={targetName}>
              <Magnitude $impactType={type}>{value * likelihood}</Magnitude>
            </Tooltip>
          ))
      ) : (
        <EmptyCellData empty />
      )}
    </Wrapper>
  );
}

MagnitudesCell.propTypes = {
  impacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  likelihood: PropTypes.number,
};

const Wrapper = styled(BaseTableCell)`
  white-space: break-spaces;
`;

const Magnitude = styled(MagnitudeBadge)`
  margin: 2px;
`;

export default React.memo(MagnitudesCell);
