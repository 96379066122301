import styled from 'styled-components';
import Button from './Button';

const WarningButton = styled(Button)`
  color: ${(props) => props.theme.arter.color.white};
  background-color: ${(props) => props.theme.arter.color.red};
  border: 1px solid ${(props) => props.theme.arter.color.red};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.darkRed};
  }
`;

export default WarningButton;
