import { useHref } from 'react-router-dom';

function useTenantId() {
  const href = useHref('/');
  const tenantId = href.substring(href.indexOf('/') + 1);

  return tenantId;
}

export default useTenantId;
