import styled from 'styled-components';
import Button from './Button';

const PrimaryButton = styled(Button)`
  color: ${(props) => props.theme.arter.color.white};
  background-color: ${(props) => props.theme.arter.color.product.default};
  border: 1px solid ${(props) => props.theme.arter.color.product.default};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.product.dark};
  }
`;

export default PrimaryButton;
