import React from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { mdiClose } from '@mdi/js';
import PropTypes from 'prop-types';
import { MenuHeader, MenuCloseButton } from '../../../common/MenuHeader';
import {
  popFromBottom,
  popFromRight,
} from '../../../../styles/keyframe-styles';
import ActionsTab from './ActionsTab';
import { useForm } from '../../../FormContext';
import { TabContent, TabsWrapper, TabItem } from '../../../common/Tabs';
import GeneralTab from './GeneralTab';

const TABS = {
  GENERAL: 'general',
  ACTIONS: 'actions',
};

function RightMenu({ onClose, showRightMenuOnMobile }) {
  const intl = useIntl();
  const { state } = useForm();
  const [tabToShow, setTabToShow] = React.useState(TABS.GENERAL);

  function getActionTabText() {
    return `${intl.formatMessage({ id: 'ACTIONS' })} (${
      state.actions.value.filter((action) => !!action.done).length
    }/${state.actions.value.length})`;
  }

  return (
    <Wrapper
      $showRightMenuOnMobile={showRightMenuOnMobile}
      aria-label="Risk right menu"
    >
      <MenuHeaderWrapper>
        {showRightMenuOnMobile ? (
          <CloseButton
            tooltip={intl.formatMessage({ id: 'CLOSE' })}
            iconPath={mdiClose}
            onClick={() => onClose()}
          ></CloseButton>
        ) : null}
      </MenuHeaderWrapper>
      <TabsWrapper>
        <TabItem
          $active={tabToShow === TABS.GENERAL}
          onClick={() => setTabToShow(TABS.GENERAL)}
        >
          <FormattedMessage id="GENERAL" />
        </TabItem>
        <TabItem
          $active={tabToShow === TABS.ACTIONS}
          onClick={() => setTabToShow(TABS.ACTIONS)}
        >
          {getActionTabText()}
        </TabItem>
      </TabsWrapper>
      <Content>
        <TabContent active={tabToShow === TABS.GENERAL}>
          <GeneralTab />
        </TabContent>
        <TabContent active={tabToShow === TABS.ACTIONS}>
          <ActionsTab />
        </TabContent>
      </Content>
    </Wrapper>
  );
}

RightMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  showRightMenuOnMobile: PropTypes.bool,
};

const Wrapper = styled.section`
  overflow: auto;
  display: ${(props) =>
    props.$showRightMenuOnMobile ? 'inline-block' : 'none'};
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: ${(props) => props.theme.arter.color.white};
  padding: ${(props) =>
    `${props.theme.arter.spacing.large} ${props.theme.arter.spacing.large}`};
  border-top-left-radius: ${(props) => props.theme.arter.border.radius.large};
  border-top-right-radius: ${(props) => props.theme.arter.border.radius.large};
  box-shadow: 0 -2px 15px -10px rgba(0, 0, 0, 0.75);
  animation: ${popFromBottom} forwards
    ${(props) => props.theme.arter.animation.speed.normal};

  @media ${(props) => props.theme.arter.device.pad} {
    width: 100%;
    max-width: 450px;
    flex: 1;
    top: 0;
    left: auto;
    box-shadow: -2px 0 15px -10px rgba(0, 0, 0, 0.75);
    border-top-right-radius: 0;
    border-bottom-left-radius: ${(props) =>
      props.theme.arter.border.radius.large};
    animation-name: ${popFromRight};
  }

  @media ${(props) => props.theme.arter.device.desktopMedium} {
    max-width: 360px;
    display: block;
    position: initial;
    height: calc(100% - 87px);
    padding: ${(props) =>
      `${props.theme.arter.spacing.mediumLarge} ${props.theme.arter.spacing.mediumLarge}`};
    box-shadow: none;
    border-radius: 0;
    animation: none;
    z-index: 1;
  }

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    max-width: 400px;
  }
`;

const MenuHeaderWrapper = styled(MenuHeader)`
  margin-bottom: 0;
`;

const CloseButton = styled(MenuCloseButton)`
  @media ${(props) => props.theme.arter.device.desktopMedium} {
    display: none;
  }
`;

const Content = styled.div``;

export default RightMenu;
