import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { MainLeftMenuItem, ItemIcon } from './MainLeftMenuItem';

function SectionLink({ to, iconPath, children, active, ...props }) {
  return (
    <Link to={to} $active={active} {...props}>
      <ItemIcon path={iconPath} />
      {children}
    </Link>
  );
}

SectionLink.propTypes = {
  to: PropTypes.string,
  iconPath: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
};

const Link = styled(MainLeftMenuItem).attrs({
  as: RouterLink,
})``;

export default SectionLink;
