import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mdiChartTimelineVariant, mdiSpeedometer } from '@mdi/js';
import { useIntl } from 'react-intl';
import SectionTitle from '../../../common/SectionTitle';
import RiskMatrix from './matrix/RiskMatrix';
import RiskMagnitudeHistory from './line/RiskMagnitudeHistory';
import { useForm } from '../../../FormContext';
import ChartCard from '../../../common/ChartCard';

function RiskCharts({
  magnitudeHistory,
  likelihoods,
  impactValues,
  impactTargets,
}) {
  const intl = useIntl();
  const { state } = useForm();

  return (
    <Wrapper>
      <SectionTitle>
        {intl.formatMessage({ id: 'MAGNITUDE_CHARTS' })}
      </SectionTitle>
      <ChartsWrapper>
        <HistoryChartCard
          iconPath={mdiChartTimelineVariant}
          title={intl.formatMessage({ id: 'HISTORY' })}
        >
          <RiskMagnitudeHistory magnitudeHistory={magnitudeHistory} />
        </HistoryChartCard>
        <MatrixChartCard
          iconPath={mdiSpeedometer}
          title={intl.formatMessage({ id: 'RISK_MATRIX' })}
        >
          <RiskMatrix
            riskImpacts={state.impacts.value}
            riskCurrentLikelihoodId={state.currentLikelihoodId.value}
            likelihoods={likelihoods}
            impactValues={impactValues}
            impactTargets={impactTargets}
          />
        </MatrixChartCard>
      </ChartsWrapper>
    </Wrapper>
  );
}

RiskCharts.propTypes = {
  magnitudeHistory: PropTypes.arrayOf(
    PropTypes.shape({
      impactTargetId: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          timestamp: PropTypes.string,
          likelihoodValue: PropTypes.number,
          impactType: PropTypes.string,
          impactValue: PropTypes.number,
        }),
      ),
    }),
  ),
  likelihoods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
  impactValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
  impactTargets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};

RiskCharts.defaultProps = {
  magnitudeHistory: [],
};

const Wrapper = styled.section`
  margin: ${(props) => props.theme.arter.spacing.extraLarge} 0;
  padding: ${(props) => props.theme.arter.spacing.extraLarge} 0;
  border-top: ${(props) => props.theme.arter.border.main};
  border-bottom: ${(props) => props.theme.arter.border.main};

  @media ${(props) => props.theme.arter.device.pad} {
    margin-top: ${(props) => props.theme.arter.spacing.mediumLarge};
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    border: none;
    margin: 0 0 ${(props) => props.theme.arter.spacing.small} 0;
    padding: ${(props) => props.theme.arter.spacing.mediumLarge} 0;
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${(props) => props.theme.arter.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const RiskChartCard = styled(ChartCard)`
  display: block;
  height: 380px;

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    height: 430px;
  }
`;

const HistoryChartCard = styled(RiskChartCard)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.arter.spacing.extraLarge};

  @media ${(props) => props.theme.arter.device.desktop} {
    width: 60%;
    margin-bottom: 0;
    margin-right: 2%;
  }
`;

const MatrixChartCard = styled(RiskChartCard)`
  max-width: 380px;

  @media ${(props) => props.theme.arter.device.desktop} {
    min-width: auto;
    width: 38%;
    max-width: initial;
  }
`;

export default RiskCharts;
