import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import RISK_STATE from '../../../../../constants/risk-state';
import FilterChipSelect from './FilterChipSelect';

function StateFilter({ selectedStates, onChange, onClear }) {
  const intl = useIntl();

  return (
    <FilterChipSelect
      data-testid="all-filters-state-filter"
      label={intl.formatMessage({ id: 'STATE' })}
      htmlFor="state-select"
      options={Object.values(RISK_STATE).map((state) => ({
        id: state,
        name: intl.formatMessage({ id: state }),
      }))}
      selectedOptions={selectedStates}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

StateFilter.propTypes = {
  selectedStates: PropTypes.arrayOf(PropTypes.oneOf(Object.values(RISK_STATE)))
    .isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default StateFilter;
