import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FilterChipSelect from './FilterChipSelect';
import { useSettings } from '../../../../SettingsContext';

function ImpactValueFilter({ selectedImpactValues, onChange, onClear }) {
  const intl = useIntl();
  const { impactValues } = useSettings();

  return (
    <FilterChipSelect
      data-testid="all-filters-impact-value-filter"
      label={intl.formatMessage({ id: 'IMPACT_VALUE' })}
      htmlFor="impact-value-select"
      options={impactValues}
      selectedOptions={selectedImpactValues}
      onChange={(selected) => onChange(selected)}
      onClear={() => onClear()}
    />
  );
}

ImpactValueFilter.propTypes = {
  selectedImpactValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default ImpactValueFilter;
