import React from 'react';
import { mdiClose } from '@mdi/js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Select from '../../common/Select';
import IconButton from '../../common/buttons/IconButton';
import RIGHT_ROLE from '../../../constants/right-role';

function RightItem({
  securityIdentity,
  groupId,
  accessGroup,
  role,
  onChange,
  onRemove,
}) {
  const intl = useIntl();

  return (
    <Wrapper data-testid={`right-group-${groupId}`}>
      <NameWrapper>
        <SecurityIdentity>{securityIdentity}</SecurityIdentity>
        <AccessGroup data-testid="access-group">{accessGroup}</AccessGroup>
      </NameWrapper>
      <Tools>
        <RightSelect
          value={role}
          onChange={(event) => onChange(event.target.value, groupId)}
        >
          {Object.values(RIGHT_ROLE).map((rightRole) => (
            <option key={rightRole} value={rightRole}>
              {intl.formatMessage({ id: rightRole })}
            </option>
          ))}
        </RightSelect>
        <RemoveButton
          tooltip={intl.formatMessage({ id: 'REMOVE_RIGHT' })}
          iconPath={mdiClose}
          small
          onClick={() => onRemove()}
        />
      </Tools>
    </Wrapper>
  );
}

RightItem.propTypes = {
  securityIdentity: PropTypes.string,
  groupId: PropTypes.string,
  accessGroup: PropTypes.string,
  role: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

const Wrapper = styled.li`
  display: flex;
  padding: ${(props) =>
    `${props.theme.arter.spacing.medium} ${props.theme.arter.spacing.mediumLarge}`};
  background-color: ${(props) => props.theme.arter.color.white};
  border: 1px solid ${(props) => props.theme.arter.color.lightGray};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
`;

const NameWrapper = styled.div`
  flex: 1;
`;

const SecurityIdentity = styled.h3`
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

const AccessGroup = styled.p`
  opacity: 0.85;
`;

const Tools = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 216px;
  margin-left: ${(props) => props.theme.arter.spacing.medium};
  padding-left: ${(props) => props.theme.arter.spacing.medium};
  border-left: ${(props) => props.theme.arter.border.main};
`;

const RightSelect = styled(Select)`
  border-color: transparent;
  background-image: none;
  margin-right: ${(props) => props.theme.arter.spacing.medium};

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }

  &:hover {
    border-color: ${(props) => props.theme.arter.color.darkGray};
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        ${(props) => props.theme.arter.color.black} 50%
      ),
      linear-gradient(
        135deg,
        ${(props) => props.theme.arter.color.black} 50%,
        transparent 50%
      );
  }
`;

const RemoveButton = styled(IconButton)``;

export default RightItem;
