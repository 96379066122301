import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../../FormContext';
import SettingDescriptionInputField from '../SettingDescriptionInputField';
import SettingNameInputField from '../SettingNameInputField';
import DeleteImpactValueModal from './DeleteImpactValueModal';
import SettingRow from '../SettingRow';
import SettingValueInputField from '../SettingValueInputField';

function ImpactValueRow({ id, ...props }) {
  const { state } = useForm();

  return (
    <SettingRow
      id={id}
      data-testid={`impact-value-scale-row-${id}`}
      inputFields={[
        SettingNameInputField,
        SettingDescriptionInputField,
        SettingValueInputField,
      ]}
      DeleteModalComponent={DeleteImpactValueModal}
      disableSave={
        state.name.value.trim().length === 0 || state.value.value.length === 0
      }
      {...props}
    />
  );
}

ImpactValueRow.propTypes = {
  id: PropTypes.string,
};

export default ImpactValueRow;
