import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import FolderCharts from './charts/FolderCharts';
import FolderContentTable from './content-table/FolderContentTable';
import FolderFilterRow from './filter/FolderFilterRow';
import { useFolderFilter } from './filter/FolderFilterContext';
import { useFolder } from './FolderContext';
import EmptyFolderContentNotification from './notifications/EmptyFolderContentNotification';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import FolderContentCount from './FolderContentCount';
import NoSearchResultsNotification from './notifications/NoSearchResultsNotification';
import Checkbox from '../../common/Checkbox';

function FolderContent() {
  const intl = useIntl();
  const { folder } = useFolder();
  const { anyFiltersApplied, allContent, searchResult, chartRisks } =
    useFolderFilter();
  const [isViewingStatistics, setIsViewingStatistics] = useLocalStorageState({
    key: 'risks-module-show-statistics',
    defaultValue: true,
  });
  const folderHasContent = folder.subFolders.length || folder.risks.length;
  const searchResultCount =
    searchResult.folders.length + searchResult.risks.length;
  const contentFound = searchResultCount > 0;

  return folderHasContent ? (
    <Content>
      <Main>
        <ContentHeader>
          <FolderFilterRow
            folders={allContent.folders}
            risks={allContent.risks}
          />
        </ContentHeader>
        <FolderContentCountWrapper>
          {anyFiltersApplied ? (
            <FolderContentCount
              title={intl.formatMessage({ id: 'RESULTS' })}
              count={searchResultCount}
              data-testid="search-result-count"
            />
          ) : (
            <FolderContentCount
              title={intl.formatMessage({ id: 'RISK_COUNT' })}
              count={allContent.risks.length}
              data-testid="risk-count"
            />
          )}
          <Checkbox
            isDisabled={!contentFound}
            isChecked={isViewingStatistics}
            onChange={(event) => setIsViewingStatistics(event.target.checked)}
            labelText={intl.formatMessage({ id: 'STATISTICS' })}
          />
        </FolderContentCountWrapper>
        {contentFound ? (
          <>
            {isViewingStatistics ? <FolderCharts risks={chartRisks} /> : null}
            <FolderContentTable
              folders={searchResult.folders}
              risks={searchResult.risks}
            />
          </>
        ) : (
          <NoSearchResultsNotification />
        )}
      </Main>
    </Content>
  ) : (
    <EmptyFolderContentNotification />
  );
}

const Content = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: calc(100% - 84px);
`;

const Main = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: ${(props) =>
    `${props.theme.arter.spacing.medium} ${props.theme.arter.spacing.large}`};

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    padding: ${(props) =>
      `${props.theme.arter.spacing.mediumLarge} ${props.theme.arter.spacing.extraLarge}`};
  }
`;

const ContentHeader = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  justify-content: space-between;
`;

const FolderContentCountWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export default FolderContent;
