import React from 'react';
import styled from 'styled-components';
import FolderContent from './FolderContent';
import CreateFolderModal from './CreateFolderModal';
import CreateRiskModal from './CreateRiskModal';
import { FolderFilterProvider } from './filter/FolderFilterContext';
import { FolderProvider } from './FolderContext';
import FolderRights from './FolderRights';
import RootFolderHeader from './RootFolderHeader';
import AllFiltersMenu from './filter/all-filters-menu/AllFiltersMenu';
import ExcelImport from './excel-import/Excelmport';

function RootFolder() {
  return (
    <FolderProvider>
      <Wrapper>
        <FolderFilterProvider>
          <RootFolderHeader />
          <FolderContent />
          <CreateFolderModal />
          <CreateRiskModal />
          <FolderRights />
          <AllFiltersMenu />
          <ExcelImport />
        </FolderFilterProvider>
      </Wrapper>
    </FolderProvider>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  align-content: flex-start;
`;

export default RootFolder;
