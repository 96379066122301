import React from 'react';
import { Icon as MdiIcon } from '@mdi/react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function IconWithBackground({ iconPath }) {
  return (
    <Wrapper>
      <Icon aria-hidden="true" path={iconPath}></Icon>
      <Background></Background>
    </Wrapper>
  );
}

IconWithBackground.propTypes = {
  iconPath: PropTypes.string,
};

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.arter.spacing.medium};
`;

const Background = styled.span`
  position: absolute;
  left: 1px;
  display: block;
  height: 18px;
  width: 21px;
  transform: rotate(31deg);
  border-radius: 8px;
  background-color: #e3e0ff;
  z-index: 1;
`;

const Icon = styled(MdiIcon)`
  z-index: 2;
  height: 24px;
  width: 24px;
`;

export default IconWithBackground;
