import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TableHeader, TableRow } from '../../../common/tables/EditTable';
import { ValueTableHeader } from '../SettingsTable';

function LikelihoodHeaderRow() {
  return (
    <TableRow>
      <TableHeader>
        <FormattedMessage id="NAME" />
      </TableHeader>
      <TableHeader>
        <FormattedMessage id="DESCRIPTION" />
      </TableHeader>
      <ValueTableHeader>
        <FormattedMessage id="VALUE" />
      </ValueTableHeader>
      <TableHeader />
    </TableRow>
  );
}

export default LikelihoodHeaderRow;
