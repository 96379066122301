import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { mdiChevronRight, mdiDotsVertical } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '../../common/buttons/IconButton';
import InputField from '../../common/InputField';
import {
  Dropdown,
  DropdownContent,
  DropdownToggleButton,
} from '../../common/Dropdown';

function Header({ children, breadcrumbItems }) {
  return (
    <Wrapper>
      {breadcrumbItems && <Breadcrumb breadcrumbItems={breadcrumbItems} />}
      <HeaderContent>{children}</HeaderContent>
    </Wrapper>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ),
};

function Breadcrumb({ breadcrumbItems }) {
  return (
    <BreadcrumbWrapper data-testid="breadcrumbs-wrapper">
      {breadcrumbItems.map((item, index) => (
        <BreadcrumbItem key={item.id}>
          {index !== 0 && <BreadcrumbSeparator />}
          {item.link ? (
            <Link to={item.link}>{item.name}</Link>
          ) : (
            <span>{item.name}</span>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbWrapper>
  );
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ),
};

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 4;
  display: block;
  padding: ${(props) => props.theme.arter.spacing.medium}
    ${(props) => props.theme.arter.spacing.extraLarge};
  background-color: ${(props) => props.theme.arter.color.white};
  border-bottom: ${(props) => props.theme.arter.border.main};
  flex: 0 0 100%;
  align-self: flex-start;
`;

const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media ${(props) => props.theme.arter.device.desktop} {
    display: flex;
  }
`;

const BreadcrumbItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
  font-size: ${(props) => props.theme.arter.font.size.medium};

  span {
    font-weight: ${(props) => props.theme.arter.font.weight.bold};

    @media ${(props) => props.theme.arter.device.desktopLarge} {
      font-size: ${(props) => props.theme.arter.font.size.large};
    }
  }
`;

const Link = styled(RouterLink)`
  font-size: ${(props) => props.theme.arter.font.size.medium};
  color: ${(props) => props.theme.arter.color.product.default};

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.arter.color.product.default};
  }

  &:active,
  &:focus {
    text-decoration: underline;
  }

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    font-size: ${(props) => props.theme.arter.font.size.large};
  }
`;

const BreadcrumbSeparator = styled(Icon)`
  margin: 0 ${(props) => props.theme.arter.spacing.small};
  color: ${(props) => props.theme.arter.color.textColor};
  width: ${(props) => props.theme.arter.icon.size.small};
  height: ${(props) => props.theme.arter.icon.size.small};
`;

BreadcrumbSeparator.defaultProps = {
  path: mdiChevronRight,
};

const HeaderContent = styled.div`
  display: flex;
`;

const HeaderTitleInput = styled(InputField)`
  text-overflow: ellipsis;
  color: ${(props) => props.theme.arter.color.textColor};
  font-size: ${(props) => props.theme.arter.font.size.extraLarge};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  padding: ${(props) => props.theme.arter.spacing.small} 0;
  margin: 0;
  width: 100%;
  min-height: auto;
  border: none;
  border-radius: 0;
  box-shadow: 0 1.3px 0 0 transparent;
  transition: all ${(props) => props.theme.arter.animation.speed.slow};

  &:disabled {
    opacity: 1;
  }

  &:hover,
  &:focus,
  &:active {
    border: none;
    box-shadow: 0 1.3px 0 0
      ${(props) => props.theme.arter.color.product.default};
  }

  @media ${(props) => props.theme.arter.device.desktop} {
    font-size: ${(props) => props.theme.arter.font.size.extraExtraLarge};
    margin-right: ${(props) => props.theme.arter.spacing.large};
    margin-bottom: 0;
  }
`;
function HeaderTools({ children }) {
  return <Tools>{children}</Tools>;
}

HeaderTools.propTypes = {
  children: PropTypes.node,
};

const Tools = styled.div`
  margin-left: auto;
  display: flex;

  button {
    margin-left: ${(props) => props.theme.arter.spacing.medium};
  }
`;

function HeaderToolsDropdown({ children }) {
  const intl = useIntl();
  return (
    <Dropdown>
      <DropdownToggleButton>
        <MoreButton
          tooltip={intl.formatMessage({ id: 'MORE_TOOLS' })}
          aria-label="More tools"
          iconPath={mdiDotsVertical}
        ></MoreButton>
      </DropdownToggleButton>
      <DropdownContent>{children}</DropdownContent>
    </Dropdown>
  );
}

HeaderToolsDropdown.propTypes = {
  children: PropTypes.node,
};

const MoreButton = styled(IconButton)`
  background-color: ${(props) => props.theme.arter.color.veryLightGray};

  &:hover {
    background-color: ${(props) => props.theme.arter.color.lightGray};
  }
`;

export { Header, HeaderTitleInput, HeaderTools, HeaderToolsDropdown };
