import styled from 'styled-components';

const Button = styled.button`
  position: relative;
  outline: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 38px;
  min-width: 80px;
  padding: 0 ${(props) => props.theme.arter.spacing.large};
  font-size: ${(props) => props.theme.arter.font.size.medium};
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  border-radius: ${(props) => props.theme.arter.border.radius.small};
  transition: ${(props) => props.theme.arter.animation.speed.normal};

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${(props) => props.theme.arter.color.product.light};
  }

  &:hover {
    background-color: ${(props) => props.theme.arter.color.extraLightGray};
  }
`;

export default Button;
