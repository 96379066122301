import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mdiCalendarOutline, mdiClose } from '@mdi/js';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import RiskCard from './RiskCard';
import { useLocalStorageConfig } from '../../../hooks/useLocalStorageConfig';
import languageConfig from '../../../lang/config';
import ClearIconButton from '../../common/buttons/ClearIconButton';
import { useForm } from '../../FormContext';
import { useRights } from '../../RightsContext';

function RiskRevisionDateCard() {
  const [{ lang }] = useLocalStorageConfig();
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();
  const [open, setOpen] = React.useState(false);
  const { hasModifierRight } = useRights();

  function openDatePicker() {
    setOpen(hasModifierRight());
  }

  return (
    <RevisionDateCard
      icon={mdiCalendarOutline}
      title={intl.formatMessage({ id: 'REVISION_DATE' })}
      data-testid="risk-revision-date-card"
    >
      <Wrapper>
        <DesktopDatePicker
          disabled={!hasModifierRight()}
          open={open}
          onClose={() => setOpen(false)}
          defaultValue={null}
          value={state.revisionDate.value || null}
          disableMaskedInput
          onChange={(value) => {
            dispatchFormChange({
              field: 'revisionDate',
              value: format(value, 'yyyy-MM-dd'),
            });
          }}
          inputFormat={languageConfig[lang].mui.inputFormat}
          InputProps={{
            onClick: () => openDatePicker(),
          }}
          InputAdornmentProps={{
            component: () =>
              hasModifierRight() ? (
                <DatePickerClearButton
                  clear={() =>
                    dispatchFormChange({
                      field: 'revisionDate',
                      value: '',
                    })
                  }
                />
              ) : null,
          }}
          renderInput={(params) => (
            <DatePickerCustomInput
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: intl.formatMessage({ id: 'NOT_SET' }),
                readOnly: true,
              }}
            />
          )}
        />
      </Wrapper>
    </RevisionDateCard>
  );
}

const DatePickerCustomInput = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    padding-right: ${(props) => props.theme.arter.spacing.medium};

    .MuiInputBase-input {
      font-size: ${(props) => props.theme.arter.font.size.medium};
      padding: ${(props) =>
        `${props.theme.arter.spacing.small} ${props.theme.arter.spacing.medium}`};
      height: 40px;
      box-sizing: border-box;

      &::placeholder {
        color: ${(props) => props.theme.arter.color.black};
        opacity: 1;
      }

      &:disabled {
        opacity: 1;
        -webkit-text-fill-color: ${(props) => props.theme.arter.color.black};
      }

      &:enabled {
        cursor: pointer;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 1px;
    }
  }
`;

const RevisionDateCard = styled(RiskCard)`
  &:hover {
    ${ClearIconButton} {
      opacity: 1;
    }

    &:enabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.arter.color.black} !important;
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

function DatePickerClearButton({ clear }) {
  const intl = useIntl();

  function handleClick(event) {
    event.stopPropagation();
    clear();
  }

  return (
    <ClearIconButton
      tooltip={intl.formatMessage({ id: 'CLEAR_RISK_REVISION_DATE' })}
      iconPath={mdiClose}
      onClick={(event) => handleClick(event)}
    />
  );
}

DatePickerClearButton.propTypes = {
  clear: PropTypes.func.isRequired,
};

export default RiskRevisionDateCard;
