import { css } from 'styled-components';

export default css`
  .MuiTextField-root {
    .MuiOutlinedInput-root {
      padding: ${(props) => props.theme.arter.spacing.small};
      min-height: 46px;
      padding-right: ${(props) => props.theme.arter.spacing.large} !important;
    }

    .MuiInputBase-root {
      .MuiAutocomplete-input {
        padding ${(props) => props.theme.arter.spacing.medium};

        &::placeholder {
          color: ${(props) => props.theme.arter.color.black};
          opacity: 1;
        }
      }
    }
  }
`;
