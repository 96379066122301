import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import EmptyCellData from './EmptyCellData';
import { BaseTableCell } from '../../../common/tables/BaseTable';

function RevisionDateCell({ revisionDate, isFolder, ...props }) {
  const intl = useIntl();

  return (
    <BaseTableCell {...props}>
      {revisionDate ? (
        intl.formatDate(revisionDate, {
          format: 'shortDateFormat',
        })
      ) : (
        <EmptyCellData empty={isFolder} />
      )}
    </BaseTableCell>
  );
}

RevisionDateCell.propTypes = {
  isFolder: PropTypes.bool,
  revisionDate: PropTypes.string,
};

export default React.memo(RevisionDateCell);
