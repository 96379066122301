import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { useIntl } from 'react-intl';
import { startOfDay } from 'date-fns';
import theme from '../../../../../styles/theme';
import lineChartColors from './line-chart-colors';
import RiskMagnitudeHistoryTooltip from './RiskMagnitudeChartTooltip';
import {
  xAxisMinForChartData,
  xTickFormattingFunctionForInterval,
  xTickValuesForInterval,
} from './magnitude-chart-utils';
import HideLastPointLayer from './HideLastPointLayer';
import { useSettings } from '../../../../SettingsContext';

function MagnitudeHistoryChart({ data }) {
  const intl = useIntl();
  const { likelihoods, impactValues } = useSettings();
  const yAxisMax =
    Math.max(...likelihoods.map(({ value }) => value)) *
    Math.max(...impactValues.map(({ value }) => value));
  const xAxisMin = startOfDay(xAxisMinForChartData(data));
  const xAxisMax = startOfDay(new Date());
  const xTickValues = xTickValuesForInterval({
    start: xAxisMin,
    end: xAxisMax,
  });
  const xTickFormatFunction = xTickFormattingFunctionForInterval(
    {
      start: xAxisMin,
      end: xAxisMax,
    },
    intl,
  );

  return (
    <ResponsiveLine
      margin={{ top: 20, right: 30, bottom: 90, left: 50 }}
      data={data}
      curve="stepAfter"
      xScale={{
        min: xAxisMin,
        max: xAxisMax,
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
      }}
      axisBottom={{
        format: xTickFormatFunction,
        tickValues: xTickValues,
        tickSize: 0,
        tickPadding: 12,
        legendOffset: 40,
        legendPosition: 'middle',
      }}
      yScale={{
        max: yAxisMax,
        type: 'linear',
        stacked: false,
      }}
      axisLeft={{
        tickValues: 5,
        tickSize: 0,
        tickPadding: 12,
        legend: intl.formatMessage({ id: 'MAGNITUDE' }),
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      gridXValues={xTickValues}
      lineWidth={2}
      colors={lineChartColors}
      pointSize={8}
      pointColor={theme.arter.color.white}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableGridY={false}
      enableGridX={true}
      enableSlices="x"
      sliceTooltip={RiskMagnitudeHistoryTooltip}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'slices',
        HideLastPointLayer,
        'mesh',
        'legends',
      ]}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 60,
          itemsSpacing: 4,
          itemDirection: 'left-to-right',
          itemWidth: 140,
          itemHeight: 20,
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, 1)',
          itemTextColor: theme.arter.color.black,
        },
      ]}
    />
  );
}

MagnitudeHistoryChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.string,
          y: PropTypes.number,
          type: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default MagnitudeHistoryChart;
