import React from 'react';

function useOnClickOutsideElement(ref, onClick) {
  React.useEffect(() => {
    const onClickOutsideElement = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    };

    document.addEventListener('mousedown', onClickOutsideElement);

    return () => {
      document.removeEventListener('mousedown', onClickOutsideElement);
    };
  }, [ref, onClick]);
}

export default useOnClickOutsideElement;
