import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormattedMessage } from 'react-intl';
import { useSettings } from '../../../../SettingsContext';
import {
  BaseTableHeadCell,
  BaseTableBodyRow,
} from '../../../../common/tables/BaseTable';
import RISK_STATE from '../../../../../constants/risk-state';
import RISK_MANAGEMENT_STRATEGY from '../../../../../constants/risk-management-strategy';
import RISK_PRIORITY from '../../../../../constants/risk-priority';
import IMPACT_TYPE from '../../../../../constants/impact-type';
import RevisionDateCell from './RevisionDateCell';
import NameCell from './NameCell';
import DescriptionCell from './DescriptionCell';
import StateCell from './StateCell';
import ManagementStrategyCell from './ManagementStrategyCell';
import PriorityCell from './PriorityCell';
import LikelihoodCell from './LikelihoodCell';
import ImpactTypeCell from './ImpactTypeCell';
import ImpactValueCell from './ImpactValueCell';
import MagnitudeCell from './MagnitudeCell';

function ExcelImportPreviewTable({ impactTargetsInUse, imports }) {
  const { likelihoods, impactValues } = useSettings();

  return (
    <Wrapper>
      <TableWrapper>
        <TableHead>
          <TableRow data-testid="excel-import-preview-impact-target-header-row">
            <HeadCell colSpan={7} />
            {impactTargetsInUse
              ? impactTargetsInUse.map((impactTarget) => (
                  <HeadCellWithLeftBorder
                    key={`impact-target-header-${impactTarget.id}`}
                    colSpan={3}
                  >
                    {impactTarget.name}
                  </HeadCellWithLeftBorder>
                ))
              : null}
          </TableRow>
          <TableRow data-testid="excel-import-preview-header-row">
            <LargeHeadCell>
              <FormattedMessage id="NAME" />
            </LargeHeadCell>
            <LargeHeadCell>
              <FormattedMessage id="DESCRIPTION" />
            </LargeHeadCell>
            <HeadCell>
              <FormattedMessage id="STATE" />
            </HeadCell>
            <HeadCell>
              <FormattedMessage id="MANAGEMENT_STRATEGY" />
            </HeadCell>
            <HeadCell>
              <FormattedMessage id="PRIORITY" />
            </HeadCell>
            <HeadCell>
              <FormattedMessage id="REVISION_DATE" />
            </HeadCell>
            <HeadCell>
              <FormattedMessage id="LIKELIHOOD" />
            </HeadCell>
            {impactTargetsInUse
              ? impactTargetsInUse.map((impactTarget) => (
                  <React.Fragment
                    key={`impact-target-header-${impactTarget.id}-attributes`}
                  >
                    <HeadCellWithLeftBorder>
                      <FormattedMessage id="TYPE" />
                    </HeadCellWithLeftBorder>
                    <HeadCell>
                      <FormattedMessage id="IMPACT_VALUE" />
                    </HeadCell>
                    <HeadCell>
                      <FormattedMessage id="MAGNITUDE" />
                    </HeadCell>
                  </React.Fragment>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {imports.map(({ risk, issues }, index) => {
            const likelihood = likelihoods.find(
              ({ id }) => id === risk.currentLikelihoodId,
            );
            return (
              <BaseTableBodyRow
                key={`risk-row-${index}`}
                data-testid={`excel-import-preview-risk-row-${index}`}
              >
                <NameCell name={risk.name} />
                <DescriptionCell description={risk.description} />
                <StateCell state={risk.state} issue={issues.state} />
                <ManagementStrategyCell
                  managementStrategy={risk.managementStrategy}
                  issue={issues.managementStrategy}
                />
                <PriorityCell
                  priority={risk.priority}
                  issue={issues.priority}
                />
                <RevisionDateCell
                  revisionDate={risk.revisionDate}
                  issue={issues.revisionDate}
                />
                <LikelihoodCell
                  likelihood={likelihood}
                  issue={issues.currentLikelihoodId}
                />
                {impactTargetsInUse.map((impactTarget, impactIndex) => {
                  const currentImpact = risk.impacts.find(
                    (impact) => impact.impactTargetId === impactTarget.id,
                  );

                  const impactValue = currentImpact
                    ? impactValues.find(
                        ({ id }) => id === currentImpact.currentImpactValueId,
                      )
                    : null;

                  const magnitude =
                    likelihood && impactValue
                      ? likelihood.value * impactValue.value
                      : null;

                  return (
                    <React.Fragment
                      key={`risk-row-${index}-impact-target-${impactTarget.id}-attributes`}
                    >
                      <ImpactTypeCell
                        type={currentImpact?.type}
                        issue={issues.impacts[impactIndex]?.type}
                      />
                      <ImpactValueCell
                        impact={!!currentImpact}
                        value={impactValue?.value}
                        issue={
                          issues.impacts[impactIndex]?.currentImpactValueId
                        }
                      />
                      <MagnitudeCell
                        impactType={currentImpact?.type}
                        magnitude={magnitude}
                      />
                    </React.Fragment>
                  );
                })}
              </BaseTableBodyRow>
            );
          })}
        </TableBody>
      </TableWrapper>
    </Wrapper>
  );
}

ExcelImportPreviewTable.propTypes = {
  impactTargetsInUse: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  imports: PropTypes.arrayOf(
    PropTypes.shape({
      risk: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        state: PropTypes.oneOf(Object.values(RISK_STATE)),
        managementStrategy: PropTypes.oneOf(
          Object.values(RISK_MANAGEMENT_STRATEGY),
        ),
        priority: PropTypes.oneOf(Object.values(RISK_PRIORITY)),
        revisionDate: PropTypes.string,
        currentLikelihoodId: PropTypes.string,
        impacts: PropTypes.arrayOf(
          PropTypes.shape({
            impactTargetId: PropTypes.string,
            type: PropTypes.oneOf(Object.values(IMPACT_TYPE)),
            currentImpactValueId: PropTypes.string,
          }),
        ),
      }),
      issues: PropTypes.shape({
        state: PropTypes.bool,
        managementStrategy: PropTypes.bool,
        priority: PropTypes.bool,
        revisionDate: PropTypes.bool,
        currentLikelihoodId: PropTypes.bool,
        impacts: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.bool,
            currentImpactValueId: PropTypes.bool,
          }),
        ),
      }),
      invalid: PropTypes.bool,
    }),
  ),
};

const Wrapper = styled(TableContainer)`
  overflow: auto;
`;

const TableWrapper = styled(Table)`
  min-width: 1600px;
`;

const HeadCell = styled(BaseTableHeadCell)`
  white-space: initial;
  line-height: 1.2;

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    font-size: ${(props) => props.theme.arter.font.size.small};
  }
`;

const HeadCellWithLeftBorder = styled(HeadCell)`
  border-left: ${(props) => props.theme.arter.border.main};
`;

const LargeHeadCell = styled(HeadCell)`
  min-width: 300px;
`;

export default ExcelImportPreviewTable;
