const FORM_ACTION = {
  CHANGE: 'CHANGE',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

function dirtyCheck(initialValue, currentValue) {
  return JSON.stringify(initialValue) !== JSON.stringify(currentValue);
}

function formReducer(state, action) {
  const { type, data } = action;

  switch (type) {
    case FORM_ACTION.CHANGE: {
      const { field, value, dirty } = data;
      return {
        ...state,
        [field]: {
          ...state[field],
          value,
          dirty:
            typeof dirty === 'boolean'
              ? dirty
              : dirtyCheck(state[field].initialValue, value),
        },
      };
    }
    case FORM_ACTION.SET_INITIAL_STATE: {
      return action.form(data);
    }
    default: {
      throw new Error(`Unsupported action type ${type}`);
    }
  }
}

export { formReducer, dirtyCheck, FORM_ACTION };
