import styled from 'styled-components';
import baseInputStyles from '../../styles/base-input-styles';

const Select = styled.select`
  ${baseInputStyles};
  line-height: 2;
  -webkit-appearance: none;
  cursor: pointer;
  padding-right: 30px;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${(props) => props.theme.arter.color.black} 50%
    ),
    linear-gradient(
      135deg,
      ${(props) => props.theme.arter.color.black} 50%,
      transparent 50%
    );
  background-position: calc(100% - 20px) calc(16px + 2px),
    calc(100% - 15px) calc(16px + 2px), calc(100% - 30px) 8px;
  background-size: 5px 5px, 5px 5px, 1px 24px;
  background-repeat: no-repeat;
`;

export default Select;
