import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { mdiCircleMedium, mdiLightningBoltOutline } from '@mdi/js';
import IMPACT_TYPE from '../../../../../constants/impact-type';

import {
  Tooltip,
  Header,
  Title,
  Separator,
  Content,
  ImpactsList,
  TooltipListItem,
  TypeIcon,
  ImpactName,
} from '../../../../common/charts/CustomTooltip';
import IconWithBackground from '../../../../common/IconWithBackground';

function RiskMatrixTooltip({ cell, impactTargets }) {
  const intl = useIntl();

  const generateImpactList = React.useCallback(
    (impacts) =>
      impacts.length ? (
        <ImpactsList>
          {impacts.map((impact) => (
            <TooltipListItem key={impact.impactTargetId}>
              <TypeIcon type={impact.impactType} path={mdiCircleMedium} />
              <ImpactName>
                {
                  impactTargets.find(
                    (impactTarget) => impactTarget.id === impact.impactTargetId,
                  ).name
                }
              </ImpactName>
            </TooltipListItem>
          ))}
        </ImpactsList>
      ) : null,
    [impactTargets],
  );

  const opportunityImpactsList = React.useMemo(
    () =>
      generateImpactList(
        cell.data.impacts.filter(
          (impact) => impact.impactType === IMPACT_TYPE.OPPORTUNITY,
        ),
      ),
    [cell.data.impacts, generateImpactList],
  );

  const threatImpactsList = React.useMemo(
    () =>
      generateImpactList(
        cell.data.impacts.filter(
          (impact) => impact.impactType === IMPACT_TYPE.THREAT,
        ),
      ),
    [cell.data.impacts, generateImpactList],
  );

  return (
    <Tooltip $leftOfCenter={cell.data.leftOfCenter}>
      <Header>
        <IconWithBackground iconPath={mdiLightningBoltOutline} />
        <Title>
          {intl.formatMessage({ id: 'IMPACTS' })}
          <Separator />
          {cell.value}
        </Title>
      </Header>
      <Content>
        {opportunityImpactsList}
        {threatImpactsList}
      </Content>
    </Tooltip>
  );
}

RiskMatrixTooltip.propTypes = {
  impactTargets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  cell: PropTypes.shape({
    data: PropTypes.shape({
      impacts: PropTypes.arrayOf(
        PropTypes.shape({
          impactType: PropTypes.oneOf(Object.values(IMPACT_TYPE)).isRequired,
          impactTargetId: PropTypes.string.isRequired,
        }),
      ).isRequired,
      leftOfCenter: PropTypes.bool.isRequired,
    }),
    value: PropTypes.number.isRequired,
  }),
};

export default RiskMatrixTooltip;
