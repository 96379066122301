import React from 'react';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';

function SelectIcon() {
  return <Wrapper path={mdiChevronDown} />;
}

const Wrapper = styled(Icon)`
  position: absolute;
  right: 8px;
  pointer-events: none;
  width: ${(props) => props.theme.arter.icon.size.medium};
  height: ${(props) => props.theme.arter.icon.size.medium};
`;

export default SelectIcon;
