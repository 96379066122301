import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconWithBackground from './IconWithBackground';

function ChartCard({ iconPath, title, children, ...props }) {
  return (
    <Wrapper {...props}>
      <Header>
        <IconWithBackground iconPath={iconPath} />
        <Title>{title}</Title>
      </Header>
      {children}
    </Wrapper>
  );
}

ChartCard.propTypes = {
  iconPath: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.arter.color.white};
  padding: ${(props) => props.theme.arter.spacing.mediumLarge};
  border: ${(props) => props.theme.arter.border.main};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.small};
`;

const Title = styled.h4`
  font-size: ${(props) => props.theme.arter.font.size.medium};
`;

export default ChartCard;
