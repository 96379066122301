import React from 'react';
import messages from '../lang/messages';

const LOCAL_STORAGE_CONFIG_KEY = 'risks-module-config';
const LANGUAGE_URL_PARAMETER = 'lang';
const DEFAULT_CONFIG = {
  lang: 'en',
};

function checkLangIsSupported(lang) {
  return lang && Object.prototype.hasOwnProperty.call(messages, lang)
    ? lang
    : DEFAULT_CONFIG.lang;
}

function getConfigFromURL() {
  const languageFromURL = new URLSearchParams(window.location.search).get(
    LANGUAGE_URL_PARAMETER,
  );

  return {
    ...(languageFromURL && { lang: checkLangIsSupported(languageFromURL) }),
  };
}

function getConfigFromLocalStorage() {
  const storedConfigJSON = window.localStorage.getItem(
    LOCAL_STORAGE_CONFIG_KEY,
  );
  const storedConfig = storedConfigJSON
    ? JSON.parse(storedConfigJSON)
    : DEFAULT_CONFIG;

  return {
    ...storedConfig,
    lang: checkLangIsSupported(storedConfig.lang),
  };
}

function useLocalStorageConfig() {
  const [config, setConfig] = React.useState(() => ({
    ...getConfigFromLocalStorage(),
    ...getConfigFromURL(),
  }));

  React.useEffect(() => {
    window.localStorage.setItem(
      LOCAL_STORAGE_CONFIG_KEY,
      JSON.stringify({
        ...config,
        lang: checkLangIsSupported(config.lang),
      }),
    );
  }, [config]);

  return [config, setConfig];
}

export { LOCAL_STORAGE_CONFIG_KEY, DEFAULT_CONFIG, useLocalStorageConfig };
