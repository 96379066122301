import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FormattedDate, useIntl } from 'react-intl';
import {
  mdiOrderBoolAscendingVariant,
  mdiDice5Outline,
  mdiLightningBoltOutline,
  mdiFinance,
  mdiPriorityHigh,
  mdiShieldAccountOutline,
  mdiCalendarOutline,
  mdiCogOutline,
  mdiScaleBalance,
  mdiCardTextOutline,
  mdiPencilOutline,
  mdiStateMachine,
  mdiTagOutline,
} from '@mdi/js';
import ChangeGroup from './ChangeGroup';

const changeGroupIconMap = {
  ACTION: mdiOrderBoolAscendingVariant,
  DESCRIPTION: mdiCardTextOutline,
  IMPACT: mdiLightningBoltOutline,
  MANAGEMENT_STRATEGY: mdiFinance,
  NAME: mdiPencilOutline,
  LIKELIHOOD: mdiDice5Outline,
  PRIORITY: mdiPriorityHigh,
  RISK: mdiScaleBalance,
  RESPONSIBLE: mdiShieldAccountOutline,
  REVISION: mdiCalendarOutline,
  SETTINGS: mdiCogOutline,
  STATE: mdiStateMachine,
  TAG: mdiTagOutline,
};

function Changeset({ timestamp, user, changes }) {
  const intl = useIntl();
  const groupedChanges = Array.from(
    changes.reduce(
      (entryMap, change) =>
        entryMap.set(change.group || change.targetType, [
          ...(entryMap.get(change.group || change.targetType) || []),
          change,
        ]),
      new Map(),
    ),
  );

  return (
    <Wrapper aria-label={intl.formatMessage({ id: 'CHANGESET' })}>
      <Header>
        <Time>
          <FormattedDate
            value={timestamp}
            format="dateFormat"
            hour="numeric"
            minute="numeric"
          />
        </Time>
        <Name>{user}</Name>
      </Header>
      <Content>
        {groupedChanges.map(([group, groupChanges]) => (
          <ChangeGroup
            key={group}
            icon={changeGroupIconMap[group]}
            changes={groupChanges}
          />
        ))}
      </Content>
    </Wrapper>
  );
}

Changeset.propTypes = {
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      targetType: PropTypes.string,
    }),
  ).isRequired,
  timestamp: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
};

const Wrapper = styled.section`
  margin-bottom: ${(props) => props.theme.arter.spacing.medium};
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.arter.spacing.large};
`;

const Time = styled.p`
  font-size: ${(props) => props.theme.arter.font.size.small};
  display: flex;
  flex: 1;
  align-items: center;

  &:after {
    content: '';
    display: inline-block;
    flex: 1;
    height: 1px;
    margin: 0 ${(props) => props.theme.arter.spacing.medium};
    background-color: ${(props) => props.theme.arter.color.lightGray};
  }
`;

const Name = styled.p`
  font-size: ${(props) => props.theme.arter.font.size.small};
`;

const Content = styled.div``;

export default Changeset;
