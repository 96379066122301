import React from 'react';
import styled from 'styled-components';
import FolderContent from './FolderContent';
import FolderForm from './FolderForm';
import CreateRiskModal from './CreateRiskModal';
import CreateFolderModal from './CreateFolderModal';
import DeleteFolderModal from './DeleteFolderModal';
import { FolderFilterProvider } from './filter/FolderFilterContext';
import { FolderProvider } from './FolderContext';
import FolderRights from './FolderRights';
import MoveFolderModal from './MoveFolderModal';
import FolderHeader from './FolderHeader';
import AllFiltersMenu from './filter/all-filters-menu/AllFiltersMenu';
import ExcelImport from './excel-import/Excelmport';

function Folder() {
  return (
    <FolderProvider>
      <Wrapper>
        <FolderFilterProvider>
          <FolderForm>
            <FolderHeader />
          </FolderForm>
          <DeleteFolderModal />
          <CreateFolderModal />
          <CreateRiskModal />
          <FolderRights />
          <MoveFolderModal />
          <FolderContent />
          <AllFiltersMenu />
          <ExcelImport />
        </FolderFilterProvider>
      </Wrapper>
    </FolderProvider>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-grow: 1;
  position: relative;
  min-width: 0;
  flex-wrap: wrap;
  align-content: flex-start;
`;

export default Folder;
