import React from 'react';
import * as XLSX from 'xlsx';
import { mdiFileExcelOutline } from '@mdi/js';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownItem, DropdownItemIcon } from '../../../common/Dropdown';
import { useFolderFilter } from '../filter/FolderFilterContext';
import { useSettings } from '../../../SettingsContext';
import RisksToSheetJSONConverter from './risks-to-sheet-json-converter';
import { useLocalStorageConfig } from '../../../../hooks/useLocalStorageConfig';

function ExportExcelButton() {
  const intl = useIntl();
  const [{ lang }] = useLocalStorageConfig();
  const { searchResult } = useFolderFilter();
  const { impactTargets, impactValues, likelihoods } = useSettings();

  function exportExcel() {
    const wb = XLSX.utils.book_new();
    const converter = new RisksToSheetJSONConverter(
      lang,
      likelihoods,
      impactTargets,
      impactValues,
    );
    const impactTargetMerges = [];
    impactTargets.forEach((_impactTarget, index) => {
      impactTargetMerges.push({
        s: { r: 0, c: 7 + index * 2 },
        e: { r: 0, c: 8 + index * 2 },
      });
    });

    const ws = XLSX.utils.aoa_to_sheet(converter.convert(searchResult.risks));
    ws['!merges'] = impactTargetMerges;
    XLSX.utils.book_append_sheet(wb, ws, intl.formatMessage({ id: 'RISKS' }));
    XLSX.writeFile(wb, `${intl.formatMessage({ id: 'RISKS' })}.xlsx`);
  }

  return (
    <DropdownItem role="button" onClick={() => exportExcel()}>
      <DropdownItemIcon path={mdiFileExcelOutline} />
      <FormattedMessage id="EXPORT_EXCEL" />
    </DropdownItem>
  );
}

export default ExportExcelButton;
