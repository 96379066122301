import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import BaseCell from './BaseCell';
import IMPACT_TYPE from '../../../../../constants/impact-type';
import MagnitudeBadge from '../../../../common/tables/MagnitudeBadge';

function MagnitudeCell({ impactType, magnitude }) {
  return (
    <BaseCell>
      {magnitude ? (
        <MagnitudeBadge $impactType={impactType}>
          <FormattedNumber maximumFractionDigits={1} value={magnitude} />
        </MagnitudeBadge>
      ) : null}
    </BaseCell>
  );
}

MagnitudeCell.propTypes = {
  impactType: PropTypes.oneOf(Object.values(IMPACT_TYPE)),
  magnitude: PropTypes.number,
  issue: PropTypes.bool,
};

export default MagnitudeCell;
