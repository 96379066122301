import React from 'react';
import { mdiStateMachine } from '@mdi/js';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import RISK_STATE from '../../../constants/risk-state';
import Select from '../../common/Select';
import RiskCard from './RiskCard';
import { useForm } from '../../FormContext';

function RiskStateCard() {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();

  return (
    <StateCard
      icon={mdiStateMachine}
      title={intl.formatMessage({ id: 'STATE' })}
      data-testid="risk-state-card"
      $riskState={state.state.value}
    >
      <Select
        value={state.state.value}
        onChange={(event) =>
          dispatchFormChange({ field: 'state', value: event.target.value })
        }
      >
        {Object.values(RISK_STATE).map((riskState) => (
          <option key={riskState} value={riskState}>
            {intl.formatMessage({ id: riskState })}
          </option>
        ))}
      </Select>
      <PatternShape
        xmlns="http://www.w3.org/2000/svg"
        width="170"
        height="255"
        viewBox="0 0 170 255"
        fill="none"
      >
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.907 24.8846C117.798 16.997 127.164 10.7399 137.472 6.47024C147.785 2.19859 158.838 0 170 0V85H85C85 73.8376 87.1986 62.7846 91.4702 52.4719C95.6615 42.3533 101.768 33.1419 109.452 25.3431C109.599 25.1935 109.747 25.0444 109.896 24.8959"
            fill="black"
            fillOpacity="0.6"
          />
        </g>
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0928 24.8846C52.2021 16.997 42.8358 10.7399 32.5281 6.47024C22.2154 2.19859 11.1623 0 -2.28882e-05 0V85H84.9999C84.9999 73.8376 82.8013 62.7846 78.5297 52.4719C74.3385 42.3533 68.232 33.1419 60.548 25.3431C60.4005 25.1935 60.2526 25.0444 60.104 24.8959"
            fill="black"
            fillOpacity="0.3"
          />
        </g>
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.9072 109.885C32.7978 101.997 42.1641 95.7399 52.4719 91.4702C62.7846 87.1986 73.8376 85 85 85V170H0C0 158.838 2.19859 147.785 6.47024 137.472C10.6615 127.353 16.7679 118.142 24.452 110.343C24.5994 110.194 24.7474 110.044 24.8959 109.896"
            fill="black"
            fillOpacity="0.15"
          />
        </g>
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145.093 194.885C137.202 186.997 127.836 180.74 117.528 176.47C107.215 172.199 96.1623 170 85 170V255H170C170 243.838 167.801 232.785 163.53 222.472C159.338 212.353 153.232 203.142 145.548 195.343C145.401 195.194 145.253 195.044 145.104 194.896"
            fill="black"
            fillOpacity="0.3"
          />
        </g>
      </PatternShape>
    </StateCard>
  );
}

const PatternShape = styled.svg`
  position: absolute;
  right: -4px;
  top: -8px;
  width: 100px;
  height: 136px;
  z-index: 0;
  opacity: 0.5;
  transition: ${(props) => props.theme.arter.animation.speed.normal};
`;

const StateCard = styled(RiskCard)`
  border-color: transparent;
  background-color: ${(props) => props.theme.arter.color.white};
  background: ${(props) => {
    switch (props.$riskState) {
      case RISK_STATE.CONTROLLED:
        return 'linear-gradient(90deg, rgba(217,245,228,1) 0%, rgba(130,198,156,1) 100%)';
      case RISK_STATE.UNCONTROLLED:
        return 'linear-gradient(90deg,rgb(252 208 207) 0%,rgb(241 173 170) 100%)';
      case RISK_STATE.PARTIALLY_CONTROLLED:
        return 'linear-gradient(90deg, rgba(253,239,221,1) 0%, rgb(251 191 114) 100%)';
      default:
        return props.theme.arter.color.veryLightGray;
    }
  }};
  background-origin: border-box;

  &:hover:enabled {
    ${PatternShape} {
      display: block;
      transform: scale(1.1);
      opacity: 1;
    }

    ${Select} {
      border-color: ${(props) => props.theme.arter.color.black};
    }
  }

  ${Select} {
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  div {
    span {
      background-color: transparent;
    }
  }
`;

export default RiskStateCard;
