import DEVICE from './device';

const theme = {
  arter: {
    color: {
      black: '#4d4d4d',
      white: '#ffffff',
      extraLightGray: '#f5f5f5',
      veryLightGray: '#eeeeee',
      lightGray: '#e2e2e2',
      gray: '#b4b4b4',
      darkGray: '#949494',
      veryDarkGray: '#7a7a7a',
      lightGreen: '#e1f9ea',
      green: '#1B8843',
      darkGreen: '#1A6b38',
      lightRed: '#f9e2e1',
      red: '#d73f37',
      darkRed: '#c33028',
      lightOrange: '#fdefdd',
      product: {
        dark: '#5045a1',
        default: '#5e53b5',
        light: '#e3e0ff',
        extraLight: '#fcfcff',
      },
    },
    font: {
      size: {
        small: '12px',
        medium: '14px',
        large: '16px',
        extraLarge: '18px',
        extraExtraLarge: '24px',
      },
      weight: {
        bold: 700,
        regular: 400,
      },
      get textColor() {
        return theme.arter.color.black;
      },
    },
    spacing: {
      extraLarge: '20px',
      large: '16px',
      mediumLarge: '12px',
      medium: '8px',
      small: '4px',
    },
    icon: {
      size: {
        small: '20px',
        medium: '24px',
        large: '36px',
      },
      color: {
        get default() {
          return theme.arter.color.black;
        },
      },
    },
    animation: {
      speed: {
        slow: '.4s',
        normal: '.2s',
        fast: '.1s',
      },
    },
    border: {
      width: '1px',
      radius: {
        small: '4px',
        medium: '8px',
        large: '20px',
        full: '50%',
      },
      get main() {
        return `${theme.arter.border.width} solid ${theme.arter.color.lightGray}`;
      },
    },
    device: {
      pad: `(min-width: ${DEVICE.PAD}px)`,
      desktop: `(min-width: ${DEVICE.DESKTOP}px)`,
      desktopMedium: `(min-width: ${DEVICE.DESKTOP_MEDIUM}px)`,
      desktopLarge: `(min-width: ${DEVICE.DESKTOP_LARGE}px)`,
    },
  },
};

export default theme;
