import PropTypes from 'prop-types';
import REALM_ROLE from '../constants/realm-role';
import { useKeycloak } from './KeycloakContext';

function RenderByRealmRole({ role, children }) {
  const { hasRole } = useKeycloak();

  return hasRole(role) ? children : null;
}

RenderByRealmRole.propTypes = {
  role: PropTypes.oneOf(Object.values(REALM_ROLE)),
  children: PropTypes.node,
};

export default RenderByRealmRole;
