import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';

function Change({ targetType, changeType, specifier, from, to }) {
  const intl = useIntl();

  return (
    <Wrapper>
      <Title role="heading">
        <TargetType>{intl.formatMessage({ id: targetType })}</TargetType>
        <ChangeType>{` ${intl
          .formatMessage({ id: changeType })
          .toLowerCase()}`}</ChangeType>
        {specifier ? (
          <Tooltip enterDelay={1000} title={specifier}>
            <Specifier>{` – ${specifier}`}</Specifier>
          </Tooltip>
        ) : null}
      </Title>
      {from && to ? (
        <ChangeWrapper>
          <From data-testid="change-from">{from}</From>
          <ChangeArrow />
          <To data-testid="change-to">{to}</To>
        </ChangeWrapper>
      ) : null}
    </Wrapper>
  );
}

Change.propTypes = {
  targetType: PropTypes.string,
  changeType: PropTypes.string,
  specifier: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

const Wrapper = styled.div`
  padding-bottom: ${(props) => props.theme.arter.spacing.extraLarge};

  &:last-child {
    padding-bottom: ${(props) => props.theme.arter.spacing.small};
  }
`;

const Title = styled.p`
  display: flex;
  font-size: ${(props) => props.theme.arter.font.size.small};
  max-height: 150px;
  overflow: auto;
`;

const TargetType = styled.span`
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  white-space: pre;
`;

const ChangeType = styled.span`
  white-space: pre;
`;

const Specifier = styled.span`
  font-weight: ${(props) => props.theme.arter.font.weight.bold};
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const ChangeWrapper = styled.div`
  display: flex;
  align-self: center;
  align-items: baseline;
  margin-top: ${(props) => props.theme.arter.spacing.small};
  max-height: 200px;
  overflow: auto;
`;

const From = styled.span`
  font-size: ${(props) => props.theme.arter.font.size.small};
  max-width: 44%;
`;

const ChangeArrow = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 20px;
  height: 1px;
  margin: 0 2%;
  background-color: ${(props) => props.theme.arter.color.darkGray};

  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 2px;
    border: 1px solid transparent;
    border-right-color: ${(props) => props.theme.arter.color.darkGray};
    border-bottom-color: ${(props) => props.theme.arter.color.darkGray};
    transform: rotate(-45deg);
    background-color: transparent;
  }
`;

const To = styled.span`
  font-size: ${(props) => props.theme.arter.font.size.small};
  max-width: 44%;
`;

export default Change;
